import { Container } from '../_app.style'
import { styled } from '../stitches.config'
import { TIMELINE_COL_WIDTH } from './constants.style'

export const DesktopTimelineContainer = styled(Container, {
  $$columnWidth: TIMELINE_COL_WIDTH,
  $$rowHeight: '7rem',
  $$navHeight: '4rem',
  display: 'grid',
  gridTemplateAreas: "'switcher weeks' 'modules background'",
  gridTemplateRows: 'auto',
  gridTemplateColumns: '$$columnWidth auto',
  gridGap: '0.5rem',

  /* Overridden default values */
  padding: '0',
  maxWidth: '100%',
})
