import { useMemo } from 'react'

import { Footer } from '../../styles/exercise/exercise.style'
import { ExerciseStyleOptions } from '../../styles/exerciseItem.style'
import { TableDiv } from '../../styles/records/records.style'
import { Module } from '../../types/schemas/abc'
import { Exercise } from '../../types/schemas/emarking'
import ModuleMarksCard from './ModuleMarksCard'

const AllModulesCard = ({
  studentModules,
  moduleSummaries,
  exerciseStyleOptions,
}: {
  studentModules: Module[]
  moduleSummaries: Map<string, Exercise[]>
  exerciseStyleOptions: ExerciseStyleOptions
}) => {
  const sortedModules = useMemo(
    () => studentModules.sort((a, b) => a.code.localeCompare(b.code)),
    [studentModules]
  )

  const renderModules = (modules: Module[]) =>
    modules.map((module, moduleIndex) => (
      <ModuleMarksCard
        key={moduleIndex}
        moduleCode={module.code}
        moduleTitle={module.title}
        exerciseSummaries={moduleSummaries.get(module.code) || []}
        exerciseStyleOptions={exerciseStyleOptions}
      />
    ))

  const moduleHasSummaries = ({ code }: Module) =>
    moduleSummaries.get(code) && moduleSummaries.get(code)?.length !== 0

  return (
    <TableDiv>
      {renderModules(sortedModules.filter(moduleHasSummaries))}
      {renderModules(sortedModules.filter((m) => !moduleHasSummaries(m)))}
      <Footer squished>
        Please note that the marks shown above do not constitute an official transcript.
      </Footer>
    </TableDiv>
  )
}

export default AllModulesCard
