import { useMemo } from 'react'

import { useTerms } from '../../hooks/terms.service'
import {
  TableDiv,
  TutorialTableHeader,
  TutorialTableHeaderDiv,
} from '../../styles/records/tutorial.style'
import { Term, TutorialGroup } from '../../types/schemas/abc'
import { StudentTutoringSession } from '../../types/schemas/tutoring'
import { computeTermAndWeekFromDate } from '../../utils'
import StudentViewAttendanceTable from '../tables/StudentViewAttendanceTable'

const TutorialCard = ({
  header,
  tutorialGroup,
  sessions,
}: {
  header: string
  tutorialGroup?: TutorialGroup
  sessions: StudentTutoringSession[]
}) => {
  const terms = useTerms()

  const sessionsByTerm = useMemo(() => {
    const termToSessionsMap: Record<
      string,
      { termData?: Term; sessions: StudentTutoringSession[] }
    > = {}

    sessions
      .sort((s1, s2) => s2.date.getTime() - s1.date.getTime())
      .forEach((session) => {
        const term = computeTermAndWeekFromDate(session.date, terms)
        const mapKey = term?.term.name ?? 'unknown'
        if (termToSessionsMap[mapKey]) {
          termToSessionsMap[mapKey].sessions.push(session)
        } else {
          termToSessionsMap[mapKey] = {
            termData: term?.term,
            sessions: [session],
          }
        }
      })

    return termToSessionsMap
  }, [sessions, terms])

  return (
    <TableDiv>
      <TutorialTableHeaderDiv>
        <TutorialTableHeader>
          <u>{header}</u>
        </TutorialTableHeader>
        <TutorialTableHeaderDiv subHeading>
          <TutorialTableHeader subHeading>
            Tutor: {tutorialGroup?.tutor.login ?? 'unknown'}
          </TutorialTableHeader>
          <TutorialTableHeader subHeading>
            Group: {tutorialGroup?.toString() ?? 'unknown'}
          </TutorialTableHeader>
        </TutorialTableHeaderDiv>
      </TutorialTableHeaderDiv>

      {Object.entries(sessionsByTerm)
        .sort(
          ([termNameA, dataA], [termNameB, dataB]) =>
            (dataA.termData?.start.getTime() ?? 0) - (dataB.termData?.start.getTime() ?? 0)
        )
        .map(([termName, data], index) => (
          <StudentViewAttendanceTable key={index} data={data.sessions} term={data.termData} />
        ))}
    </TableDiv>
  )
}

export default TutorialCard
