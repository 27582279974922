import { fromPairs, groupBy, head, isEmpty, keyBy, map, mapValues } from 'lodash'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { useUser } from '../../contextManagers/user.context'
import { useResponsiveness } from '../../hooks/breakpoints.service'
import {
  useExternalModuleSelection,
  useExternalModules,
} from '../../hooks/externalModuleSelection.service'
import {
  useInternalModuleSelection,
  useInternalModules,
} from '../../hooks/internalModuleSelection.service'
import { useModuleSelectionConfiguration } from '../../hooks/moduleSelectionConfiguration.service'
import { Banner, Button } from '../../styles/_app.style'
import { Main, Section } from '../../styles/root.style'
import AvailableModulesPane from './AvailableModulesPane'
import ChosenModulesPane from './ChosenModulesPane'
import ExternalModuleOptionsPane from './ExternalModuleOptionsPane'
import ModuleDescriptionPane from './ModuleDescriptionPane'
import { TERMS } from './constants'
import { FixedBottomContainer, Grid, GridItem, GridItemContent } from './moduleSelection.style'

enum CentralPaneViews {
  USAGE,
  MODULE_INFO,
  EXTERNAL_MODULES,
}

enum MobileFocusView {
  LEFT_PANE = 'On offer',
  CENTRAL_PANE = '',
  RIGHT_PANE = 'Your choices',
}

const MOBILE_TABS = [MobileFocusView.LEFT_PANE, MobileFocusView.RIGHT_PANE]

const ModuleSelection = () => {
  const { isDesktop } = useResponsiveness()
  const { year } = useParams()
  const { userDetails } = useUser()
  const { configuration } = useModuleSelectionConfiguration(year as string)
  const [moduleToView, setModuleToView] = useState<any>()
  const [term, setTerm] = useState(TERMS[0])
  const [centralView, setCentralView] = useState(CentralPaneViews.USAGE)
  const [mobileFocusView, setMobileFocusView] = useState(MobileFocusView.LEFT_PANE)

  // External modules =============================================
  const { externalModulesOnOffer } = useExternalModules(year as string)
  const { chosenExternalModules, actions: externalChoiceActions } = useExternalModuleSelection(
    year as string
  )
  const availableExternalModules = useMemo(
    () => keyBy(externalModulesOnOffer, 'id'),
    [externalModulesOnOffer]
  )

  // Internal modules =============================================
  const { internalModulesOnOffer, internalModulesAreLoaded } = useInternalModules(
    year!,
    userDetails?.degreeYear
  )
  const { chosenInternalModules, actions: internalChoiceActions } = useInternalModuleSelection(
    year as string
  )

  const chosenModuleIDs = useMemo(
    () => new Set(chosenInternalModules.map((m) => m.degreeRegulations.moduleId)),
    [chosenInternalModules]
  )

  const modulesByTerm = useMemo(
    () =>
      mapValues(
        groupBy(internalModulesOnOffer, (m) => head(m.terms.sort())),
        (ms) => groupBy(ms, (m) => head(m.regulations)!.offeringGroup.label)
      ),
    [internalModulesOnOffer]
  )
  const availableInternalModules = useMemo(
    () => keyBy(internalModulesOnOffer, 'id'),
    [internalModulesOnOffer]
  )

  const timetableClashes = useMemo(
    () =>
      isEmpty(availableInternalModules)
        ? {}
        : fromPairs(
            map(chosenInternalModules, (c) => {
              const { examTimetableConstraint, code } =
                availableInternalModules[c.degreeRegulations.moduleId]
              return [examTimetableConstraint, code]
            })
          ),
    [availableInternalModules, chosenInternalModules]
  )

  // Handlers ================================================
  function handleExternalModuleApplyClick() {
    setCentralView(CentralPaneViews.EXTERNAL_MODULES)
    setMobileFocusView(MobileFocusView.CENTRAL_PANE)
  }

  function handleModuleInfoClick(module: any) {
    setModuleToView(module)
    setCentralView(CentralPaneViews.MODULE_INFO)
    setMobileFocusView(MobileFocusView.CENTRAL_PANE)
  }

  function renderCentralView(view: CentralPaneViews): JSX.Element {
    switch (view) {
      case CentralPaneViews.MODULE_INFO:
        return <ModuleDescriptionPane module={moduleToView} timetableClashes={timetableClashes} />
      case CentralPaneViews.EXTERNAL_MODULES:
        return (
          <ExternalModuleOptionsPane
            onApply={externalChoiceActions.apply}
            externalModules={externalModulesOnOffer}
          />
        )
      default:
        return <Banner>Click on a module to view its description</Banner>
    }
  }

  if (!configuration || !internalModulesAreLoaded)
    return (
      <Main>
        <Helmet>
          <title>Module selection</title>
        </Helmet>
        <Section>
          <Banner>Loading...</Banner>
        </Section>
      </Main>
    )

  return (
    <Main noMargins fullHeight>
      <Helmet>
        <title>Module selection</title>
      </Helmet>
      <Grid>
        {(isDesktop || mobileFocusView === MobileFocusView.LEFT_PANE) && (
          <GridItem>
            <GridItemContent>
              <AvailableModulesPane
                moduleSelectionIsOpen={configuration?.isOpen}
                modulesPerTerm={modulesByTerm}
                onTermChange={setTerm}
                onModuleInfoClick={handleModuleInfoClick}
                onModuleAdd={internalChoiceActions.add}
                selectedTerm={term || TERMS[0]}
                timetableClashes={timetableClashes}
                chosenModuleIDs={chosenModuleIDs}
              />
            </GridItemContent>
          </GridItem>
        )}
        {(isDesktop || mobileFocusView === MobileFocusView.CENTRAL_PANE) && (
          <GridItem>
            <GridItemContent>{renderCentralView(centralView)}</GridItemContent>
          </GridItem>
        )}
        {(isDesktop || mobileFocusView === MobileFocusView.RIGHT_PANE) && (
          <GridItem>
            <GridItemContent>
              <ChosenModulesPane
                availableExternalModules={availableExternalModules}
                availableInternalModules={availableInternalModules}
                chosenInternalModules={chosenInternalModules}
                onChoiceDelete={internalChoiceActions.remove}
                chosenExternalModules={chosenExternalModules}
                onExternalApplyButtonClick={handleExternalModuleApplyClick}
              />
            </GridItemContent>
          </GridItem>
        )}
      </Grid>
      {!isDesktop && (
        <FixedBottomContainer>
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {MOBILE_TABS.map((tab) => (
              <Button
                key={tab}
                css={{ minWidth: '9rem', flex: '1 1 9rem', margin: 0 }}
                active={mobileFocusView === tab}
                onClick={() => setMobileFocusView(tab)}
                animate
              >
                {tab}
              </Button>
            ))}
          </div>
        </FixedBottomContainer>
      )}
    </Main>
  )
}

export default ModuleSelection
