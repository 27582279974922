import PhdAdminView from '../components/phdadmin/PhdAdminView'
import { useUser } from '../contextManagers/user.context'
import { Banner } from '../styles/_app.style'

const PhdAdmin = () => {
  const { userDetails } = useUser()
  if (!userDetails) return <Banner>Could not load your personal details.</Banner>

  return <PhdAdminView />
}

export default PhdAdmin
