import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { keyBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import Select from '../../components/select/Select'
import GenericTanStackTableRenderer, {
  createDatetimeColumn,
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useExternalChoicesReview } from '../../hooks/externalChoicesReview.service'
import { useExternalModules } from '../../hooks/externalModuleSelection.service'
import { Banner } from '../../styles/_app.style'
import { Main, TableSection } from '../../styles/root.style'
import { ApprovalStatus, ExternalModuleChoice } from '../../types/schemas/moduleSelection'
import { formatEnumValue } from '../../utils'

const APPROVAL_OPTIONS = Object.values(ApprovalStatus).map((value) => ({
  value,
  label: formatEnumValue(value),
}))

const ExternalModuleChoicesReview = () => {
  const { year } = useParams()
  const { externalModulesOnOffer, externalModulesAreLoaded } = useExternalModules(year as string)
  const { externalChoices, reviewChoice } = useExternalChoicesReview(year as string)

  const data = useMemo(() => externalChoices, [externalChoices])

  const columns = useMemo(() => {
    function handleStatusChangeByID(id: number) {
      return (newValue: any) => {
        if (newValue) reviewChoice(id, newValue.value as ApprovalStatus)
      }
    }
    const modulesById = keyBy(externalModulesOnOffer, 'id')
    const columnHelper = createColumnHelper<ExternalModuleChoice>()
    return [
      columnHelper.accessor(
        (row) => row.username,
        createPlainColumn<ExternalModuleChoice>('Username')
      ),
      columnHelper.accessor(
        (row) => row.timestamp,
        createDatetimeColumn<ExternalModuleChoice>('Timestamp')
      ),
      columnHelper.accessor(
        (row) => row.reviewedOn,
        createDatetimeColumn<ExternalModuleChoice>('Reviewed On')
      ),
      columnHelper.accessor(
        (row) => row.reviewedBy,
        createPlainColumn<ExternalModuleChoice>('Reviewed By')
      ),
      columnHelper.accessor(
        (row) => modulesById[row.externalModuleId]?.code ?? 'Loading...',
        createPlainColumn<ExternalModuleChoice>('Module')
      ),

      columnHelper.accessor('status', {
        cell: (info) => {
          const value = info.getValue()
          const currentRow = info.row.original

          return (
            <Select
              menuPortalTarget={document.body}
              options={APPROVAL_OPTIONS}
              placeholder={'Status'}
              value={APPROVAL_OPTIONS.find((o) => o.value === value)}
              onChange={handleStatusChangeByID(currentRow.id)}
            />
          )
        },
        header: 'Status',
      }),
    ]
  }, [externalModulesOnOffer, reviewChoice])

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!externalModulesAreLoaded) return <Banner>Loading data...</Banner>

  return (
    <Main>
      <PageHeader title={'External Module Choices'} />
      <TableSection>
        <GenericTanStackTableRenderer table={table} size={'wide'} />
      </TableSection>
    </Main>
  )
}

export default ExternalModuleChoicesReview
