import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { UserDetails } from '../types/schemas/abc'
import { formatShortYear } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useStudent = (year: string, username: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [studentDetails, setstudentDetails] = useState<UserDetails | undefined>(undefined)

  useEffect(() => {
    if (!year || !username) return
    axiosInstance
      .get(endpoints.studentInfo(year), { params: { login: username } })
      .then(({ data }) => {
        if (!data?.length) throw new Error()
        setstudentDetails(plainToInstance(UserDetails, data[0]))
      })
      .catch(displayError(`No details found for '${username}' in year ${formatShortYear(year)}`))
  }, [axiosInstance, displayError, username, year])

  return studentDetails
}
