import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { ExerciseForm } from '../components/exercise/ExerciseForm'
import titles from '../constants/titles'
import { useExercisesForModule } from '../hooks/exercises.service'
import { useModule } from '../hooks/module.service'
import { Banner, Container, Wrapper } from '../styles/_app.style'
import { css } from '../styles/stitches.config'

const ExerciseCreate = () => {
  const { year, moduleCode } = useParams()
  const { module, moduleIsLoaded, addExercise } = useModule()
  const { exercises } = useExercisesForModule(year as string, moduleCode as string)

  const pageTitle = titles.exerciseCreate(year!, moduleCode!, module?.title)

  if (module === undefined) {
    return (
      <Container>
        <Wrapper inline>
          <h1> {moduleCode}</h1>
        </Wrapper>
        <Banner>{moduleIsLoaded ? 'Module not found' : 'Loading...'}</Banner>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Wrapper inline>
          <h1 style={{ width: '100%', margin: 0 }}>{module.title}</h1>
        </Wrapper>
        <h3
          className={css({
            color: '$lowContrast',
            margin: '0.5rem 0',
            marginBottom: '1rem',
            fontWeight: '400',
          })()}
        >
          {moduleCode}
        </h3>
      </Container>
      <Container section>
        <h2 style={{ marginBottom: '1rem' }}>New Exercise</h2>
        <ExerciseForm exerciseNumbers={exercises.map((e) => e.number)} onSubmit={addExercise} />
      </Container>
    </>
  )
}

export default ExerciseCreate
