import { DialogClose, Dialog as DialogRoot } from '@radix-ui/react-dialog'
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Search, XCircle } from 'react-bootstrap-icons'

import { SearchStatus } from '../../hooks/search.service'
import { Container, P, Span } from '../../styles/_app.style'
import { OutlinedCard } from '../../styles/card.style'
import { GeneralDiv, Input, SearchContent } from '../../styles/search/search.style'
import { SearchResult } from '../../types/schemas/search'
import { groupByProperty } from '../../utils'
import { ContentFrame } from '../dialogs/Dialog'
import GroupResult from './GroupResult'

const SearchModal = ({
  status,
  results,
  isOpen,
  close,
  doSearch,
  resetSearch,
}: {
  status: SearchStatus
  results: SearchResult[]
  isOpen: boolean
  close: () => void
  doSearch: (query: string) => void
  resetSearch: () => void
}) => {
  const [query, setQuery] = useState('')

  const handleKeyDownForInputBox: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') doSearch(query)
    },
    [doSearch, query]
  )

  const groupedResults = useMemo(() => groupByProperty(results, 'fileType', 'title'), [results])

  const closeModal = useCallback(() => {
    setQuery('')
    resetSearch()
    close()
  }, [resetSearch])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        closeModal()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [closeModal])

  const SearchError = () => (
    <Container stripPadding inlineHeaderWithButtons css={{ svg: { fill: '$red9' } }}>
      <XCircle />
      <Span css={{ color: '$red9' }}>Error loading search results</Span>
    </Container>
  )

  const searchOutcome = useMemo(() => {
    switch (status) {
      case SearchStatus.LOADING:
        return <P>Loading...</P>
      case SearchStatus.ERROR:
        return <SearchError />
      case SearchStatus.IDLE:
      default:
        return Object.keys(groupedResults).map((t) => (
          <GroupResult key={t} results={groupedResults[t]} />
        ))
    }
  }, [groupedResults, status])

  return (
    <DialogRoot open={isOpen}>
      <ContentFrame>
        <SearchContent>
          <GeneralDiv row={'between'}>
            <GeneralDiv row={'start'} css={{ width: '100%', gap: '1rem' }}>
              <Search size={16} />
              <Input
                type="text"
                placeholder="Search course notes, lecture videos and more..."
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                onKeyDown={handleKeyDownForInputBox}
              />
            </GeneralDiv>
            <DialogClose asChild onClick={closeModal}>
              <OutlinedCard pill>ESC</OutlinedCard>
            </DialogClose>
          </GeneralDiv>
          {searchOutcome}
        </SearchContent>
      </ContentFrame>
    </DialogRoot>
  )
}

export default SearchModal
