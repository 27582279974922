import { GeneralDiv, ResultsFrame } from '../../styles/search/search.style'
import { SearchResult } from '../../types/schemas/search'
import Result from './Result'

const GroupResult = ({ results }: { results: SearchResult[] }) => {
  return (
    <GeneralDiv col={'start'} css={{ gap: '5px' }}>
      <ResultsFrame>
        {results.map((result, resultIndex) => (
          <Result key={resultIndex} data={result} />
        ))}
      </ResultsFrame>
    </GeneralDiv>
  )
}

export default GroupResult
