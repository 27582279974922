import React, { useState } from 'react'

import { DeliverablePlain } from '../../hooks/exercise.service'
import { Control, Field, Input, Message } from '../../styles/form.style'
import Dialog from '../dialogs/Dialog'

const FILENAME_REGEX = /^[a-zA-Z0-9_-]+$/
const EXTENSION_REGEX = /^[a-zA-Z]+$/

export const NewFileDeliverableDialog = ({
  open,
  onOpenChange,
  addDeliverable,
  deliverableNames,
}: {
  open: boolean
  onOpenChange: (_: boolean) => void
  addDeliverable: (_: DeliverablePlain) => void
  deliverableNames: string[]
}) => {
  const basename = (filename: string) => filename.split('.')[0]

  const [fileName, setFileName] = useState<string>('')

  function basenameIsDuplicate() {
    return deliverableNames.map((n) => basename(n)).includes(basename(fileName))
  }

  function filenameViolatesFormat() {
    const filenameComponents = fileName.split('.')
    return filenameComponents.length !== 2 || !FILENAME_REGEX.test(filenameComponents[0])
  }

  function extensionViolatesFormat() {
    const filenameComponents = fileName.split('.')
    return filenameComponents.length !== 2 || !EXTENSION_REGEX.test(filenameComponents[1])
  }

  return (
    <Dialog
      primaryButtonText={'Confirm'}
      isFormValid={() => !!fileName && !basenameIsDuplicate()}
      onPrimaryClick={() => addDeliverable({ name: fileName, type: 'file' })}
      onOpenChange={onOpenChange}
      open={open}
    >
      <p>
        Enter your filename below. <br />
        File names can include alphanumerical characters, dashes and underscores.
      </p>
      <Field name="filename" css={{ marginTop: '2rem' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            marginBottom: '0.5rem',
          }}
        >
          <Message match="valueMissing">Filename required</Message>
          <Message match={extensionViolatesFormat}>Missing or invalid Extension</Message>
          <Message match={filenameViolatesFormat}>
            File name must comply to format specified above
          </Message>
          <Message match={basenameIsDuplicate}>{`A deliverable called '${basename(
            fileName
          )}' already exists`}</Message>
        </div>
        <Control asChild>
          <Input
            type="text"
            value={fileName}
            placeholder="e.g. report.pdf"
            onChange={({ target: { value } }) => setFileName(value)}
            required
          />
        </Control>
      </Field>
    </Dialog>
  )
}
