import React from 'react'
import { BoxArrowUpRight } from 'react-bootstrap-icons'

import { endpoints } from '../../constants/endpoints'
import { TUTORIAL_GROUP_TYPES } from '../../constants/global'
import { useUser } from '../../contextManagers/user.context'
import { Span } from '../../styles/_app.style'
import { ListBlock, ListItem, ListItemContainer, ListItemMainText } from '../../styles/list.style'
import { Exercise } from '../../types/schemas/emarking'
import { encodeURL } from '../../utils'
import Card from '../Card'
import { Tabs } from '../Tabs'

const ProvidedFilesSection = ({
  exercise,
  showModelAnswer,
}: {
  exercise: Exercise
  showModelAnswer: boolean
}) => {
  const { year, moduleCode, number } = exercise
  const buildLink = (endpoint: string) => `/external-resource?url=${encodeURL(endpoint)}`

  const providedFiles = [
    {
      label: 'Spec',
      link: exercise.spec ? buildLink(endpoints.exerciseSpec(year, moduleCode, number)) : null,
    },
    {
      label: 'Data file',
      link: exercise.supplementaryFile
        ? buildLink(endpoints.exerciseSupplementaryFile(year, moduleCode, number))
        : null,
    },
    {
      label: 'Model answer',
      link:
        exercise.modelAnswerIsAvailable && showModelAnswer
          ? buildLink(endpoints.exerciseModelAnswer(year, moduleCode, number))
          : null,
    },
  ].filter(({ link }) => !!link)

  return (
    <Card title="Provided files">
      <ListBlock>
        <Tabs
          attribute={'label'}
          data={providedFiles}
          generator={({ label }) => {
            return (
              <ListItemContainer>
                <ListItem fillSpace>
                  <ListItemMainText>
                    <Span flexFillSpace>{label}</Span>
                    <BoxArrowUpRight />
                  </ListItemMainText>
                </ListItem>
              </ListItemContainer>
            )
          }}
          href={({ link }) => link as string}
          allTabsCss={{
            border: '1px solid $separator',
          }}
          css={{
            gap: '0.8rem',
          }}
        />
      </ListBlock>
    </Card>
  )
}

export default ProvidedFilesSection
