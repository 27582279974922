import React, { ReactNode } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useUser } from '../../contextManagers/user.context'

const DEFAULT_TARGET = '/404'
export const StaffAndMatchingStudentOnly = ({ to }: { to: ReactNode }) => {
  const { userDetails } = useUser()
  const { username } = useParams()

  return userDetails?.isStaff || userDetails?.login === username ? (
    <>{to}</>
  ) : (
    <Navigate to={DEFAULT_TARGET} replace />
  )
}
