import { styled } from '../stitches.config'

export const ThemesDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '1.25rem',
})

export const ColourDisplayDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  borderRadius: '.5rem',
  fontSize: '$sm',
  cursor: 'pointer',
  variants: {
    box: {
      accentsSquare: {
        width: '3.125rem',
        height: '3.125rem',
      },
      rectangle: {
        padding: '0.7rem 1.5rem',
      },
      accentsRectangle: {
        height: '3.125rem',
        width: '7.5rem',
      },
    },
    textColour: {
      dark: {
        color: 'black',
      },
      light: {
        color: 'white',
      },
    },
    backgroundColour: {
      dark: {
        backgroundColor: '#30302E',
        '&:hover': {
          backgroundColor: '#383734',
        },
      },
      light: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#EEEEEC',
        },
      },
    },
    active: {
      true: {
        border: '.1875rem solid $sand12',
      },
      defaultBox: {
        border: '.1875rem solid $sand6',
      },
    },
  },
})

export const PreviewContainer = styled('div', {
  '> div': {
    paddingTop: 0,
  },
})
