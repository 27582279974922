import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  CellValues,
  createDatetimeColumn,
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useAllStudents } from '../../hooks/allStudents.service'
import { useExercisesForYear } from '../../hooks/exercises.service'
import { useSubmissionsForYear } from '../../hooks/submissions.service'
import { Banner } from '../../styles/_app.style'
import { Input } from '../../styles/form.style'
import { Main, Section, TableSection } from '../../styles/root.style'
import { formatShortYear, toLookupTable } from '../../utils'

type LatestSubmissionRow = {
  login: string
  name: string
  cohort: string
  module: string
  exercise: string
  deadline: Date
  submission: Date
  deliverable: string
}

const LatestSubmissionsDashboard = () => {
  const { year } = useParams()
  const { allStudents, allStudentsAreLoaded } = useAllStudents(year as string)
  const { exercises, exercisesAreLoaded } = useExercisesForYear(year as string)

  const { submissions, submissionsAreLoaded } = useSubmissionsForYear(year as string, {
    latest_only: true,
  })

  const columnHelper = createColumnHelper<LatestSubmissionRow>()
  const columns = [
    columnHelper.accessor((row) => row.login, createPlainColumn<LatestSubmissionRow>('Login')),
    columnHelper.accessor((row) => row.name, createPlainColumn<LatestSubmissionRow>('Name')),
    columnHelper.accessor((row) => row.cohort, createPlainColumn<LatestSubmissionRow>('Cohort')),
    columnHelper.accessor((row) => row.module, createPlainColumn<LatestSubmissionRow>('Module')),
    columnHelper.accessor(
      (row) => row.exercise,
      createPlainColumn<LatestSubmissionRow>('Exercise')
    ),
    columnHelper.accessor(
      (row) => row.deadline,
      createDatetimeColumn<LatestSubmissionRow>('Deadline')
    ),
    columnHelper.accessor(
      (row) => row.submission,
      createDatetimeColumn<LatestSubmissionRow>('Submission', { dateFormat: 'Y-M-dd HH:mm:ss' })
    ),
    columnHelper.accessor(
      (row) => row.deliverable,
      createPlainColumn<LatestSubmissionRow>('Deliverable')
    ),
  ]

  const data = useMemo(() => {
    const studentLookup = toLookupTable(allStudents, 'login')
    const exerciseLookup = toLookupTable(exercises, 'id')
    return !exercises.length
      ? []
      : submissions
          .sort((s1, s2) => (s1.username > s2.username ? 1 : -1))
          .map((s) => {
            let e = exerciseLookup.get(s.exerciseId)!
            let student = studentLookup.get(s.username)
            return {
              login: s.username,
              name: allStudentsAreLoaded
                ? student?.fullName ?? CellValues.NOT_FOUND
                : CellValues.LOADING,
              cohort: allStudentsAreLoaded
                ? student?.cohort ?? CellValues.NOT_FOUND
                : CellValues.LOADING,
              module: e.moduleCode,
              exercise: e.number.toString(),
              deadline: e.endDate,
              submission: s.timestamp,
              deliverable: s.targetFileName,
            }
          })
  }, [allStudents, allStudentsAreLoaded, exercises, submissions])

  const [globalFilter, setGlobalFilter] = React.useState('')
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      expanded,
      sorting,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <Main>
      <PageHeader title="Latest Available CW Interactions">
        <p>
          This is an overview of the latest available interactions on CWs for{' '}
          {formatShortYear(year)}.
        </p>
      </PageHeader>
      <Section>
        {!(exercisesAreLoaded && submissionsAreLoaded) ? (
          <Banner>Loading data...</Banner>
        ) : (
          !data.length && <Banner>No data to show.</Banner>
        )}
      </Section>
      {exercisesAreLoaded && submissionsAreLoaded && !!data.length && (
        <>
          <Section>
            <Input
              type="text"
              placeholder="Type your filter here..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </Section>
          <TableSection expanded>
            <GenericTanStackTableRenderer table={table} size="wide" />
          </TableSection>
        </>
      )}
    </Main>
  )
}

export default LatestSubmissionsDashboard
