import { ComponentProps, FC } from 'react'

import { endpoints } from '../../constants/endpoints'
import { Link } from '../../styles/exercise/exercise.style'
import { SubmissionFeedback } from '../../types/schemas/emarking'

interface FeedbackLinkProps extends ComponentProps<typeof Link> {
  feedback: SubmissionFeedback
  exerciseTitle: string
}

export const FeedbackLink: FC<FeedbackLinkProps> = ({ feedback, exerciseTitle, ...linkProps }) => {
  return (
    <Link
      target="_blank"
      href={endpoints.feedbackFile(feedback.distributionId, feedback.id)}
      title={`Feedback for ${exerciseTitle}`}
      {...linkProps}
    >
      Feedback
    </Link>
  )
}
