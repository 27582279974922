import { instanceToPlain, plainToInstance } from 'class-transformer'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { useUser } from '../contextManagers/user.context'
import { Exercise } from '../types/schemas/emarking'
import { objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export interface DeliverablePlain {
  name: string
  type: string
}

export interface ExercisePayload {
  number?: number
  type?: string
  submissionType?: string
  title?: string
  start?: Date
  end?: Date
  maximumMark?: number
  expectedHours?: number
  weight?: number
  modelAnswerVisibleOn?: Date
  locked?: boolean
}

export interface ProvidedFilesActions {
  uploadSpec: (_: FileList) => void
  uploadDataFile: (_: FileList) => void
  uploadModelAnswer: (_: FileList) => void
  deleteModelAnswer: () => void
}

export interface DeliverablesActions {
  addDeliverable: (_: DeliverablePlain) => void
  deleteDeliverable: (_: number) => void
}

export interface ExerciseHookActions {
  updateExercise: (_: ExercisePayload) => void
  providedFiles: ProvidedFilesActions
  deliverables: DeliverablesActions
}

export interface ExerciseHookType {
  exercise: Exercise | undefined
  setExercise: Dispatch<SetStateAction<Exercise | undefined>>
  exerciseIsLoaded: boolean
  actions: ExerciseHookActions
}

export const useExercise = (): ExerciseHookType => {
  const axiosInstance = useContext(AxiosContext)
  const { year, moduleCode, exerciseNumber } = useParams()
  const navigate = useNavigate()
  const { userDetails } = useUser()
  const { addToast } = useToast()
  const displayError = useErrorMessage()

  const [exercise, setExercise] = useState<Exercise>()
  const [exerciseIsLoaded, setExerciseIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!userDetails) return
    let endpoint =
      userDetails.isStaff || userDetails.isGTAForModule(moduleCode!)
        ? endpoints.emarkingExercise
        : endpoints.emarkingExercisePersonal
    axiosInstance
      .get(endpoint(year!, moduleCode!, parseInt(exerciseNumber!)))
      .then(({ data }) => {
        setExercise(plainToInstance(Exercise, data))
      })
      .catch(displayError('Unable to fetch exercise'))
      .finally(() => setExerciseIsLoaded(true))
  }, [axiosInstance, displayError, exerciseNumber, moduleCode, userDetails, year])

  function uploadFile(url: string) {
    return (files: FileList) => {
      let formData = new FormData()
      formData.append('file', files[0])
      axiosInstance
        .patch(url, formData)
        .then(({ data }) => {
          setExercise(plainToInstance(Exercise, data))
          addToast({
            variant: 'success',
            title: 'File uploaded successfully.',
          })
        })
        .catch(displayError('Error uploading file.'))
    }
  }

  const uploadSpec = uploadFile(
    endpoints.exerciseSpec(year!, moduleCode!, parseInt(exerciseNumber!))
  )
  const uploadDataFile = uploadFile(
    endpoints.exerciseSupplementaryFile(year!, moduleCode!, parseInt(exerciseNumber!))
  )
  const uploadModelAnswer = uploadFile(
    endpoints.exerciseModelAnswer(year!, moduleCode!, parseInt(exerciseNumber!))
  )

  const deleteModelAnswer = () => {
    const url = endpoints.exerciseModelAnswer(year!, moduleCode!, parseInt(exerciseNumber!))
    axiosInstance
      .delete(url)
      .then(({ data }) => {
        setExercise(plainToInstance(Exercise, data))
        addToast({
          variant: 'success',
          title: 'Sample Answer deleted successfully.',
        })
      })
      .catch(displayError('Error deleting model answer.'))
  }

  function updateExercise(payload: ExercisePayload) {
    let plainExercise = instanceToPlain(exercise)
    let exercisePatch = objectKeysToSnakeCase(payload)
    exercisePatch = Object.fromEntries(
      Object.entries(exercisePatch).filter(([k, v]) => {
        if (['start', 'end'].includes(k)) return plainExercise[k].getTime() !== v.getTime()
        return plainExercise[k] !== v
      })
    )
    axiosInstance
      .patch(
        endpoints.emarkingExercise(year!, moduleCode!, parseInt(exerciseNumber!)),
        exercisePatch
      )
      .then(({ data }) => {
        if (exercisePatch.hasOwnProperty('number')) navigate(`./../${payload.number}/staff`)
        else setExercise(plainToInstance(Exercise, data))
        addToast({
          variant: 'success',
          title:
            'Exercise updated successfully. Please check any existing mark against the new maximum mark.',
        })
      })
      .catch(displayError('Error updating exercise.'))
  }

  function addDeliverable(deliverable: DeliverablePlain) {
    axiosInstance
      .post(endpoints.deliverables(year!, moduleCode!, parseInt(exerciseNumber!)), deliverable)
      .then(({ data }) => {
        let rawExercise = instanceToPlain(exercise)
        setExercise(
          plainToInstance(Exercise, {
            ...rawExercise,
            deliverables: [...rawExercise.deliverables, data],
          })
        )
      })
      .catch(displayError('Failed to add new deliverable.'))
  }

  function deleteDeliverable(deliverableId: number) {
    axiosInstance
      .delete(endpoints.deliverable(year!, moduleCode!, parseInt(exerciseNumber!), deliverableId))
      .then(() => {
        let rawExercise = instanceToPlain(exercise)
        setExercise(
          plainToInstance(Exercise, {
            ...rawExercise,
            deliverables: rawExercise.deliverables.filter((d: any) => d.id !== deliverableId),
          })
        )
      })
      .catch(displayError('Failed to delete deliverable.'))
  }

  return {
    exercise,
    setExercise,
    exerciseIsLoaded,

    actions: {
      providedFiles: { uploadSpec, uploadDataFile, uploadModelAnswer, deleteModelAnswer },
      deliverables: { addDeliverable, deleteDeliverable },
      updateExercise,
    },
  }
}
