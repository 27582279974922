import { Item, Root } from '@radix-ui/react-toggle-group'

import { styled } from './stitches.config'

export const ToggleGroup = styled(Root, {
  display: 'inline-flex',
  variants: {
    block: {
      true: {
        width: '100%',
      },
    },
  },
})

export const ToggleGroupItem = styled(Item, {
  height: '2.75rem',
  display: 'flex',
  fontSize: '$md',
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  backgroundColor: '$elementHover',
  padding: '0 1rem 0 1rem',

  '&:first-child': {
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
  },
  '&:last-child': {
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
  },
  '&[data-state=on]': {
    backgroundColor: '$elementActive',
  },
  '&:hover': {
    backgroundColor: '$elementActive',
  },
  variants: {
    block: {
      true: {
        width: '100%',
      },
    },
  },
})
