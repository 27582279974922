import React from 'react'

import { Tabs } from '../../components/Tabs'
import PageHeader from '../../components/common/PageHeader'
import { ListBlock } from '../../styles/list.style'
import { Main, Section } from '../../styles/root.style'

const SeniorTutorHome = () => {
  const services = [
    { label: 'Personal Tutoring Dashboard', link: 'personal-tutoring' },
    { label: 'Y1 Tutorial Session Attendances Dashboard', link: 'tutorial-sessions' },
    { label: 'Deadline Extensions Dashboard', link: 'deadline-extensions' },
    { label: 'Late Submissions Dashboard', link: 'late-submissions' },
    { label: 'Latest CW Interactions Dashboard', link: 'latest-submissions' },
  ]

  return (
    <Main>
      <PageHeader title="Senior Tutor Home">
        <p>
          This is the central hub for <b>student tracking activities</b>.
          <br />
          Select the service you require from the list to access the relevant dashboard.
        </p>
      </PageHeader>
      <Section>
        <ListBlock>
          <Tabs attribute="label" data={services} generator={(s) => s.label} href={(s) => s.link} />
        </ListBlock>
      </Section>
    </Main>
  )
}

export default SeniorTutorHome
