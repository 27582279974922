import { isWithinInterval } from 'date-fns'
import { useMemo } from 'react'

import { BaseTermPill } from '../../styles/attendance/attendance.style'
import { Term } from '../../types/schemas/abc'
import { capitaliseFirstLetter } from '../../utils'

const TermPill = ({ date, terms }: { date: Date; terms: Term[] }) => {
  const termInQuestion = useMemo(() => {
    const term = terms.find((term) => isWithinInterval(date, { start: term.start, end: term.end }))
    return term
  }, [date, terms])
  return (
    <BaseTermPill term={(termInQuestion?.name.split(' ')?.[0] ?? 'holidays') as any}>
      {capitaliseFirstLetter(termInQuestion?.name ?? '')}
    </BaseTermPill>
  )
}

export default TermPill
