import { differenceInDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import Dialog from '../../components/dialogs/Dialog'
import FeedbackSection from '../../components/exercise/FeedbackSection'
import FileUploadArea from '../../components/exercise/FileUploadArea'
import {
  DefaultGroupArea,
  GroupManagementArea,
} from '../../components/exercise/GroupManagementArea'
import ProvidedFilesSection from '../../components/exercise/ProvidedFilesSection'
import Select from '../../components/select/Select'
import { GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS } from '../../constants/global'
import { useUser } from '../../contextManagers/user.context'
import { useExerciseForStudent } from '../../hooks/exerciseForStudent.service'
import { useGroup } from '../../hooks/groupFormation.service'
import { Banner, Button, Container, Div, Hr, P } from '../../styles/_app.style'
import {
  FeedbackActionDiv,
  FeedbackDiv,
  Footer,
  ProgressBar,
  UploadWrapper,
} from '../../styles/exercise/exercise.style'
import { Section } from '../../styles/root.style'
import {
  Exercise,
  WorkloadSurveyOption,
  WorkloadSurveyResponse,
} from '../../types/schemas/emarking'
import { capitaliseFirstLetter, now } from '../../utils'

const ExerciseStudent = () => {
  const { userDetails } = useUser()
  const { exercise } = useOutletContext<{
    exercise: Exercise
  }>()
  const { workloadSurvey, submittedFiles, submitFile, deleteFile, loadSubmittedFiles } =
    useExerciseForStudent(exercise)
  const { groupIsLoaded, enrolledStudents, group, createGroup, membersActions } = useGroup(exercise)

  const workloadSurveyOptions = Object.values(WorkloadSurveyOption).map((value) => ({
    value,
    label: capitaliseFirstLetter(value.replace('_', ' ')),
  }))
  const [selectedWorkloadResponse, setSelectedWorkloadResponse] = useState(workloadSurvey.response)
  useEffect(() => {
    if (!exercise) return
    loadSubmittedFiles()
  }, [exercise, group, loadSubmittedFiles])
  const [submissionIdToDelete, setSubmissionIdToDelete] = useState<number>()

  function dropDownHandler(newValue: any) {
    if (newValue) setSelectedWorkloadResponse(newValue.value)
  }

  function studentIsLeader(): boolean {
    return exercise.submissionType === 'individual' || group?.leader === userDetails?.login
  }

  function exerciseIsOpen(): boolean {
    // This is admittedly a hack, but gets easily around the pure date comparison
    // limitations (which would make dev interactions cumbersome)
    const appIsRunningInDev = process.env.NODE_ENV === 'development'
    const isWithinExercisePeriod = now() < exercise.latePeriodDeadline && now() > exercise.startDate
    return !userDetails?.isStaff && (appIsRunningInDev || isWithinExercisePeriod)
  }

  function handleWorkloadResponse(): void {
    if (selectedWorkloadResponse)
      workloadSurvey.submit(selectedWorkloadResponse as keyof typeof WorkloadSurveyResponse)
  }

  const SubmissionUploadAvailabilityWarning = () => {
    if (now() < exercise.latePeriodDeadline)
      return (
        <Banner level={'warning'}>
          <p>
            Your deadline has passed.
            <br /> Further modifications to your work will be classified as <b>late</b>.
          </p>
        </Banner>
      )
    return (
      <Banner>
        <p>
          You are more than {GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS} days past your deadline. <br />
          No further modifications to your work are possible.
        </p>
      </Banner>
    )
  }

  const GroupSection = () => {
    if (!group) {
      if (!exerciseIsOpen()) return <></>
      if (!groupIsLoaded) return <Banner>Loading group data...</Banner>
      return <DefaultGroupArea onCreateGroup={createGroup} />
    }
    return (
      <GroupManagementArea
        group={group}
        enrolledStudents={enrolledStudents}
        membersActions={membersActions}
        disabled={!exerciseIsOpen()}
      />
    )
  }

  const FooterMessage = () => {
    const GROUP_FORMATION_FOOTER =
      'This is a group formation exercise. No file nor code submission is expected.'
    const GROUP_SUBMISSION_FOOTER =
      "By accepting membership of this group, you agree that this is the group's collective work."
    const INDIVIDUAL_SUBMISSION_FOOTER =
      'By submitting, you agree that this is your own, unaided work.'

    return (
      <Footer>
        {exercise.isGroupFormation
          ? GROUP_FORMATION_FOOTER
          : exercise.isGroup
          ? GROUP_SUBMISSION_FOOTER
          : INDIVIDUAL_SUBMISSION_FOOTER}
      </Footer>
    )
  }

  if (!exercise.spec && !exercise.deliverables.length && !exercise.isGroupFormation) {
    return (
      <Container section>
        <Banner>
          <span>{'No details available for this exercise.'}</span>
        </Banner>
      </Container>
    )
  }

  return (
    <Section>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          gap: '2rem',
        }}
      >
        {(exercise.feedback || exercise.mark) && (
          <FeedbackSection
            mark={exercise.mark}
            feedback={exercise.feedback}
            exerciseTitle={exercise.title}
            maximumMark={exercise.maximumMark}
          />
        )}
        {(exercise.spec || exercise.supplementaryFile) &&
          (userDetails?.isStaff || exercise.isOpen) && (
            <ProvidedFilesSection exercise={exercise} showModelAnswer={exercise.type === 'TUT'} />
          )}

        {now() > exercise.deadline && <SubmissionUploadAvailabilityWarning />}
        {exercise.submissionType === 'group' && <GroupSection />}

        {/* Upload Area */}
        {(exercise.submissionType === 'individual' || group) && (
          <>
            {exercise.isAssessed && (
              <>
                <Hr inset />
                <FeedbackDiv>
                  <h5>
                    This exercise was estimated to take {exercise.estimatedWorkHours} hours. Would
                    you say that this estimate is:
                  </h5>
                  <FeedbackActionDiv>
                    <Select
                      isDisabled={differenceInDays(now(), exercise.deadline) >= 1}
                      options={workloadSurveyOptions}
                      value={workloadSurveyOptions.find(
                        (o) => o.value === selectedWorkloadResponse
                      )}
                      onChange={dropDownHandler}
                      placeholder="Select your response"
                    />
                    <Button
                      onClick={handleWorkloadResponse}
                      disabled={differenceInDays(now(), exercise.deadline) >= 1}
                    >
                      Submit
                    </Button>
                  </FeedbackActionDiv>
                </FeedbackDiv>
              </>
            )}
            <>
              {!!exercise.deliverables?.length && (
                <UploadWrapper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap-reverse',
                      gap: '0.5rem 2rem',
                    }}
                  >
                    {!userDetails?.isStaff && (
                      <P css={{ color: '$green11' }}>
                        {submittedFiles.length} out of {exercise.deliverables.length} submitted
                        {submittedFiles.length === exercise.deliverables.length && (
                          <>, and you are all done! 🎉</>
                        )}
                      </P>
                    )}
                  </div>
                  <ProgressBar value={submittedFiles.length} max={exercise.deliverables.length} />
                  {exercise.deliverables.map((deliverable, index) => (
                    <FileUploadArea
                      key={index}
                      exercise={exercise}
                      disabled={!(exerciseIsOpen() && studentIsLeader())}
                      deliverable={deliverable}
                      submittedFiles={submittedFiles}
                      onFileSubmit={submitFile(exercise.deliverables.length)}
                      onFileDelete={setSubmissionIdToDelete}
                    />
                  ))}
                </UploadWrapper>
              )}
              {(!!exercise.deliverables?.length || exercise.isGroupFormation) && (
                <Div>
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {submittedFiles.length === exercise.deliverables.length && (
                      <span style={{ fontWeight: 'bold' }}>
                        Click on the files above to download and view them. It is simpler to rectify
                        mistakes before the submission deadline.
                      </span>
                    )}
                  </Div>
                  <FooterMessage />
                </Div>
              )}
            </>
          </>
        )}
      </div>
      <Dialog
        title={'Are you sure you want to delete this file?'}
        primaryButtonText={'Delete'}
        secondaryButtonText={'Cancel'}
        onPrimaryClick={() => deleteFile(submissionIdToDelete as number)}
        onOpenChange={() => setSubmissionIdToDelete(undefined)}
        open={submissionIdToDelete !== undefined}
      />
    </Section>
  )
}

export default ExerciseStudent
