import * as Accordion from '@radix-ui/react-accordion'
import { useMemo } from 'react'

import { AccordianDiv, AllTablesDiv } from '../../styles/records/tutorial.style'
import { TutorialGroup } from '../../types/schemas/abc'
import { StudentTutoringSession } from '../../types/schemas/tutoring'
import { groupByProperty, toLookupTable } from '../../utils'
import DropDownHeader from './DropDownHeader'
import TutorialCard from './TutorialCard'

export const WeeklyTutorialAttendanceSection = ({
  tutoringSessions,
  tutorialGroups,
}: {
  tutoringSessions: StudentTutoringSession[]
  tutorialGroups: TutorialGroup[]
}) => {
  const groupNameToSessionsMap = useMemo(
    () => groupByProperty(tutoringSessions, 'group', 'group'),
    [tutoringSessions]
  )

  const tutorialGroupNameToObjectMap = useMemo(
    () => toLookupTable(tutorialGroups, 'groupLabel'),
    [tutorialGroups]
  )

  return (
    <AccordianDiv>
      <Accordion.Root style={{ width: '100%' }} type="single" collapsible>
        <Accordion.Item value="tutorials">
          {/* Accordian Header */}
          <Accordion.Trigger style={{ all: 'unset', width: '100%' }}>
            <DropDownHeader />
          </Accordion.Trigger>

          {/* Accordian Content */}
          <Accordion.Content>
            <AllTablesDiv>
              {Object.entries(groupNameToSessionsMap).map(([groupName, sessions]) => (
                <TutorialCard
                  key={groupName}
                  header={tutorialGroupNameToObjectMap.get(groupName)?.type ?? groupName}
                  tutorialGroup={tutorialGroupNameToObjectMap.get(groupName)}
                  sessions={sessions}
                />
              ))}
            </AllTablesDiv>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </AccordianDiv>
  )
}
