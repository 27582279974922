import {
  RowSelectionState,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format, isSameDay } from 'date-fns'
import { useEffect, useState } from 'react'
import { Check, Dash } from 'react-bootstrap-icons'

import { Button, Checkbox, Container, Hr, Indicator } from '../../styles/_app.style'
import { Control, Field, Form, Input } from '../../styles/form.style'
import { Term } from '../../types/schemas/abc'
import { Attendance, Session } from '../../types/schemas/tutoring'
import { tutorialSessionTitle } from '../../utils'
import Dialog from '../dialogs/Dialog'
import { AttendanceStatus, ComputedStatusMarker } from '../records/ComputedStatusMarker'
import Tooltip from '../tooltip/Tooltip'
import GenericTanStackTableRenderer from './tableRenderer/GenericTanStackTableRenderer'

const DATETIME_LOCAL_FORMAT = "yyyy-MM-dd'"

const TutorialWeekAttendance = ({
  session,
  terms,
  onSessionUpdate,
  deleteSession,
  studentsLookup,
}: {
  session: Session
  terms: Term[]
  onSessionUpdate: (sessionId: number, payload: Session) => void
  deleteSession: (sessionId: number) => void
  studentsLookup: Map<string, any>
}) => {
  const columnHelper = createColumnHelper<Attendance>()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    checkbox: false,
  })
  const [tutorIsPresent, setTutorIsPresent] = useState(session.tutorIsPresent ?? true)

  const columns = [
    columnHelper.display({
      id: 'checkbox',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(checkedState) => table.toggleAllRowsSelected(!!checkedState)}
        >
          <Indicator>
            {table.getIsAllRowsSelected() ? <Check size={22} /> : <Dash size={22} />}
          </Indicator>
        </Checkbox>
      ),
      cell: ({ row }) => (
        <Checkbox checked={row.getIsSelected()} onCheckedChange={row.getToggleSelectedHandler()}>
          <Indicator>
            <Check size={22} />
          </Indicator>
        </Checkbox>
      ),
      meta: {
        css: {
          width: '28px',
          padding: '0.5rem',
          boxSizing: 'content-box',
        },
      },
    }),
    columnHelper.accessor(
      ({ username }) =>
        `${studentsLookup.get(username)?.firstname} ${
          studentsLookup.get(username)?.lastname
        } (${username})`,
      {
        cell: (info) => `${info.getValue()}`,
        id: 'student',
        header: 'Student',
        meta: {
          css: {
            padding: '0.5rem',
          },
        },
      }
    ),
    columnHelper.accessor(({ present }) => present, {
      id: 'status',
      cell: (info) => <ComputedStatusMarker status={AttendanceStatus.fromValue(info.getValue())} />,
      header: 'Status',
      meta: {
        css: {
          width: '7.5rem',
        },
      },
    }),
  ]

  const table = useReactTable({
    data: session.attendances,
    columns,
    enableMultiRowSelection: true,
    enableRowSelection: true,
    enableHiding: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
  })

  // Auto select rows NOT marked absent
  useEffect(() => {
    table
      .getRowModel()
      .rows.forEach((row) => row.original.present !== false && row.toggleSelected())
  }, [])

  const handleSaveOrEdit = () => {
    if (columnVisibility.checkbox) {
      // Save
      const attendances = session.attendances.map((student, studentIndex) => {
        const updatedStudent = { ...student }
        updatedStudent.present = rowSelection[studentIndex] ?? false
        return updatedStudent
      })
      setColumnVisibility({ checkbox: false })

      onSessionUpdate(session.id, { ...session, attendances, tutorIsPresent })
    } else {
      // Show checkboxes for editing
      setColumnVisibility({ checkbox: true })
    }
  }

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  // If any row has status "NO_STATUS" auto mark for editing
  useEffect(() => {
    if (session.attendances.some(({ present }) => typeof present === 'undefined')) {
      setColumnVisibility({ checkbox: true })
    }
  }, [])

  return (
    <Container stripPadding css={{ gap: '1rem' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!columnVisibility.checkbox ? (
          <em>Tutor marked as {tutorIsPresent ? 'present' : 'absent'}.</em>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              padding: '0.5rem',
            }}
          >
            <Checkbox checked={tutorIsPresent} onCheckedChange={() => setTutorIsPresent((v) => !v)}>
              <Indicator>
                <Check size={22} />
              </Indicator>
            </Checkbox>
            <span>Tick if the tutor is present</span>
          </div>
        )}
        <Form onSubmit={(event) => event.preventDefault()}>
          <Field name="tutorialDate" inline css={{ fontSize: '$sm' }}>
            <Control asChild>
              <Input
                type="date"
                value={format(session.date, DATETIME_LOCAL_FORMAT)}
                onChange={({
                  target: {
                    value,
                    validity: { valid },
                  },
                }) => {
                  const parsedDate = new Date(Date.parse(value))
                  if (valid && !isSameDay(parsedDate, session.date)) {
                    onSessionUpdate(session.id, {
                      ...session,
                      date: parsedDate,
                    })
                  }
                }}
                required
              />
            </Control>
          </Field>
        </Form>
      </div>
      <Hr />
      <GenericTanStackTableRenderer
        table={table}
        style={{ table: { tableLayout: 'fixed', width: '95%' } }}
        size="wide"
        inlineHeader
      />

      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '0.5rem', gap: '0.5rem' }}>
        {columnVisibility.checkbox && (
          <Tooltip label={'Delete session record'}>
            <Button onClick={() => setShowDeleteDialog(true)}>Delete</Button>
          </Tooltip>
        )}
        <Tooltip label={`${columnVisibility.checkbox ? 'Save' : 'Edit'} attendances for session`}>
          <Button onClick={handleSaveOrEdit}>{columnVisibility.checkbox ? 'Save' : 'Edit'}</Button>
        </Tooltip>
      </div>

      <Dialog
        title={`Are you sure you want to delete the session for "${tutorialSessionTitle(
          session.date,
          terms
        )}"?`}
        primaryButtonText={'Delete'}
        secondaryButtonText={'Cancel'}
        onPrimaryClick={() => deleteSession(session.id)}
        onOpenChange={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
      />
    </Container>
  )
}

export default TutorialWeekAttendance
