/**
 * ==============================
 * CATE THEME
 * =============================
 */
import { blue, sky, skyDark } from '@radix-ui/colors'
import { createTheme } from '@stitches/react'

import { colorFamily, globalCss } from '../stitches.config'
import { ACCENT_THEME_OVERRIDES } from './colourConstants'
import { generateColourScale } from './colourUtils'
import { HexValue } from './types'

// Constants
const CATE_MAIN_COLOUR: HexValue = '#e0f9f9'
export const CATE_THEME_ACCENT_NAME = 'cate'

const cateThemeColoursBase = {
  ...colorFamily('sky', sky),
  ...colorFamily('neutral', sky),
  ...ACCENT_THEME_OVERRIDES,
  ...colorFamily('red', generateColourScale('red', 'red', '#fffcfc', '#641723')),
  ...colorFamily('blue', generateColourScale('blue', 'blue', blue.blue1, blue.blue12)),
  appBackground: CATE_MAIN_COLOUR,
  subtleBackground: '$sky2',
  elementBackground: '$sky5',
  elementHover: '$sky6',
  elementActive: '$sky7',
  separator: '$neutral10',
  highContrast: 'black',
  lowContrast: 'gray',
}

// Create cate Theme with stiches
const cateThemeLight = createTheme({
  colors: cateThemeColoursBase,
  fonts: {
    body: 'Times New Roman, sans-serif',
  },
})
const cateThemeDark = createTheme({
  colors: {
    ...cateThemeColoursBase,
    ...colorFamily('sky', skyDark),
    ...colorFamily('neutral', skyDark),
    appBackground: 'black',
    subtleBackground: 'grey',
    elementBackground: 'grey',
    elementActive: 'grey',
    highContrast: 'white',
  },
  fonts: {
    body: 'Times New Roman, sans-serif',
  },
})

// Global CSS we patch in to make everything more cate like
export const cateModeGlobalCSS = globalCss({
  [`.${cateThemeLight}, .${cateThemeDark}`]: {
    'html, body': {
      // Font family: time new roman
      fontFamily: 'serif',
    },
    'h1, h2, h3': {
      color: 'blue',
    },
    table: {
      backgroundColor: '#bbf9f9',
      borderSpacing: '2px 2px',
      'td, th': {
        border: '1px black solid',
        paddingLeft: '5px',
      },
    },
    '*': {
      borderRadius: '0 !important',
    },
  },
})

// Export a generated theme
export default {
  mainColors: {
    light: CATE_MAIN_COLOUR,
    dark: CATE_MAIN_COLOUR,
  },
  name: CATE_THEME_ACCENT_NAME,
  colors: {
    light: sky,
    dark: sky,
  },
  radixThemes: {
    light: cateThemeLight,
    dark: cateThemeDark,
  },
}
