import React from 'react'
import { useOutletContext } from 'react-router-dom'

import Card from '../../components/Card'
import DeliverablesList from '../../components/exercise/DeliverablesList'
import ProvidedFilesSection from '../../components/exercise/ProvidedFilesSection'
import { TUTORIAL_GROUP_TYPES } from '../../constants/global'
import { Section } from '../../styles/root.style'
import { Tab } from '../../styles/tabs.style'
import { Exercise } from '../../types/schemas/emarking'

const ExerciseUTA = () => {
  const { exercise } = useOutletContext<{
    exercise: Exercise
  }>()

  const tabs = [
    { title: 'Submissions overview', to: '../marks' },
    { title: 'Marking', to: '../emarking' },
  ]

  const DeliverablesSection = () => (
    <Card title="Deliverables">
      <DeliverablesList deliverables={exercise.deliverables} />
    </Card>
  )

  return (
    <Section>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginBottom: '1rem' }}>
        {tabs.map((tab) => (
          <Tab trueTab css={{ flexGrow: 2, flexBasis: 0 }} key={tab.title} to={tab.to}>
            {tab.title}
          </Tab>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          gap: '1rem',
          marginBottom: '4rem',
        }}
      >
        {exercise.hasProvidedFiles && (
          <ProvidedFilesSection
            exercise={exercise}
            showModelAnswer={TUTORIAL_GROUP_TYPES.includes(exercise.type)}
          />
        )}
        {exercise.deliverables.length > 0 && <DeliverablesSection />}
      </div>
    </Section>
  )
}

export default ExerciseUTA
