import { instanceToPlain, plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { GroupMembersActions } from '../types/hooks'
import { CandidateGroupMember, Exercise, Group, GroupMember } from '../types/schemas/emarking'
import { errorMessage, mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useGroup = (exercise: Exercise | undefined) => {
  const axiosInstance = useContext(AxiosContext)
  const { addToast } = useToast()
  const displayError = useErrorMessage()

  const [groupIsLoaded, setGroupIsLoaded] = useState<boolean>(false)

  const [group, setGroup] = useState<Group | null>(null)
  useEffect(() => {
    if (!exercise || exercise.submissionType !== 'group') return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .get(endpoints.submissionGroup(year, moduleCode, number))
      .then(({ data }) => {
        setGroup(plainToInstance(Group, data))
      })
      .catch((error) => {
        const message = errorMessage(error)
        if (
          !message?.startsWith('No group exists') &&
          !message?.startsWith('You are not a member of a group')
        )
          displayError('Unable to get group details')(error)
      })
      .finally(() => setGroupIsLoaded(true))
  }, [axiosInstance, displayError, exercise])

  const [enrolledStudents, setEnrolledStudents] = useState<CandidateGroupMember[]>([])
  useEffect(() => {
    if (!exercise || exercise.submissionType !== 'group') return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .get(endpoints.enrolledStudentsWithAvailability(year, moduleCode, number))
      .then(({ data }) => {
        setEnrolledStudents(mapPlainToInstance(CandidateGroupMember, data))
      })
      .catch(displayError('Unable to get list of students enrolled to module'))
  }, [axiosInstance, displayError, exercise])

  const deleteMember = (member: GroupMember, asMember: boolean = false) => {
    if (!exercise || !group) return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .delete(endpoints.groupMember(year, moduleCode, number, member.username))
      .then(() => {
        let deletedMember = group.members.find((m) => m.id === member.id)
        if (asMember) {
          // User is leaving the group: reset the group
          setGroup(null)
          setEnrolledStudents([])
        } else if (deletedMember !== undefined) {
          // Leader is deleting a member: update the group
          setGroup((group) => {
            const newMembers = group!.members.filter((m) => m.id !== member.id)

            // There *must* be a way to avoid (de)serialisation and treat Group as a simple class. Need to dig deeper.
            return plainToInstance(Group, {
              ...instanceToPlain(group),
              members: newMembers.map((m) => instanceToPlain(m)),
            })
          })
          setEnrolledStudents((people) =>
            people.map((person) =>
              person.login === deletedMember!.username
                ? plainToInstance(CandidateGroupMember, { ...person, available: true })
                : person
            )
          )
        }
      })
      .catch(displayError('Failed to remove the indicated member'))
  }

  const sendInvite = (invitedUsernames: string[]) => {
    if (!exercise || !group) return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .post(
        endpoints.inviteMembers(year, moduleCode, number),
        invitedUsernames.map((username) => ({ username }))
      )
      .then(({ data }) => {
        setGroup((group) => {
          return plainToInstance(Group, {
            ...instanceToPlain(group),
            members: data,
          })
        })
        setEnrolledStudents((people) =>
          people.map((person) =>
            invitedUsernames.includes(person.login)
              ? plainToInstance(CandidateGroupMember, {
                  ...instanceToPlain(person),
                  available: false,
                })
              : person
          )
        )
      })
      .catch(displayError('Failed to add the indicated member'))
  }

  /**
   * @param accepted Answer invitation with an accept or decline.
   * @param memberId ID of the member to accept/decline.
   */
  const answerInvite = (accepted: boolean, memberId: number) => {
    if (!exercise || !group) return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .patch(endpoints.membershipInvitation(year, moduleCode, number), null, {
        params: {
          accepted,
        },
      })
      .then(() => {
        if (accepted) {
          setGroup((group) => {
            let currMembers = group!.members
            let newMembers = !accepted
              ? currMembers.filter((member) => member.id !== memberId)
              : currMembers.map((member) => {
                  return member.id === memberId
                    ? { ...instanceToPlain(member), accepted: new Date().toJSON() }
                    : instanceToPlain(member)
                })
            return plainToInstance(Group, {
              ...instanceToPlain(group),
              members: newMembers,
            })
          })
        } else {
          setGroup(null)
          setEnrolledStudents([])
        }
      })
      .catch(displayError('Failed to update status of membership invite'))
  }

  const createGroup = () => {
    if (!exercise) return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .post(endpoints.submissionGroups, {
        year,
        module_code: moduleCode,
        exercise_number: number,
      })
      .then(({ data }) => setGroup(plainToInstance(Group, data)))
      .catch(displayError('Failed to create a group'))
  }

  const deleteGroup = () => {
    if (!exercise || !group) return
    let { year, moduleCode, number } = exercise
    axiosInstance
      .delete(endpoints.submissionGroup(year, moduleCode, number))
      .then(() => {
        setGroup(null)
        addToast({ variant: 'success', title: 'The group was deleted' })
      })
      .catch(displayError('Unable to delete group'))
  }

  const membersActions: GroupMembersActions = {
    deleteMember,
    sendInvite,
    answerInvite,
    deleteGroup,
  }

  return { groupIsLoaded, enrolledStudents, group, setGroup, createGroup, membersActions }
}
