import React, { useContext } from 'react'
import { LockFill, PlusLg, Trash3Fill, UnlockFill } from 'react-bootstrap-icons'
import { Link as RouterLink, useNavigate, useOutletContext } from 'react-router-dom'

import EditableDeliverablesSection from '../../components/exercise/EditableDeliverablesSection'
import EditableProvidedFilesSection from '../../components/exercise/EditableProvidedFilesSection'
import { ExerciseForm } from '../../components/exercise/ExerciseForm'
import Tooltip from '../../components/tooltip/Tooltip'
import { endpoints } from '../../constants/endpoints'
import { AxiosContext } from '../../contextManagers/axios.context'
import { useUser } from '../../contextManagers/user.context'
import { ExerciseHookActions } from '../../hooks/exercise.service'
import { useExercisesForModule } from '../../hooks/exercises.service'
import { Button } from '../../styles/_app.style'
import { Section } from '../../styles/root.style'
import { Tab } from '../../styles/tabs.style'
import { Exercise } from '../../types/schemas/emarking'

const ExerciseStaff = () => {
  const axiosInstance = useContext(AxiosContext)
  const navigate = useNavigate()

  const { exercise, actions } = useOutletContext<{
    exercise: Exercise
    actions: ExerciseHookActions
  }>()

  const { userDetails } = useUser()
  const { exercises } = useExercisesForModule(exercise.year, exercise.moduleCode)

  const tabs = [
    { title: 'Submissions overview', to: '../marks' },
    { title: 'Marking', to: '../emarking' },
  ]

  const deleteExercise = (exercise: Exercise) => {
    axiosInstance
      .delete(endpoints.emarkingExercise(exercise.year, exercise.moduleCode, exercise.number))
      .then((response) => {
        if (response.status === 204) {
          navigate(`/${exercise.year}/modules/${exercise.moduleCode}/exercises/`)
        }
      })
  }

  return (
    <Section>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginBottom: '1rem' }}>
        {tabs.map((tab) => (
          <Tab trueTab css={{ flexGrow: 2, flexBasis: 0 }} key={tab.title} to={tab.to}>
            {tab.title}
          </Tab>
        ))}

        {!exercise.isOpen && (
          <Tooltip label={'Delete exercise'}>
            <Button onClick={() => deleteExercise(exercise)}>
              <Trash3Fill size={20} />
            </Button>
          </Tooltip>
        )}
        {userDetails?.isYearCoordinator && (
          <Tooltip label={`${exercise.locked ? 'Unlock' : 'Lock'} exercise`}>
            <Button onClick={() => actions.updateExercise({ locked: !exercise.locked })}>
              {exercise.locked ? <UnlockFill size={20} /> : <LockFill size={20} />}
            </Button>
          </Tooltip>
        )}

        <Tooltip label={'Create new exercise'}>
          <Button>
            <RouterLink to={`../../${exercise.moduleCode}/exercises/new`}>
              <PlusLg size={22} />
            </RouterLink>
          </Button>
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          gap: '2rem',
          marginBottom: '4rem',
        }}
      >
        <EditableProvidedFilesSection exercise={exercise} actions={actions.providedFiles} />
        {exercise.submissionType !== 'no submission required' && (
          <EditableDeliverablesSection exercise={exercise} actions={actions.deliverables} />
        )}
        <ExerciseForm
          exercise={exercise}
          exerciseNumbers={exercises.map((e) => e.number)}
          onSubmit={actions.updateExercise}
        ></ExerciseForm>
      </div>
    </Section>
  )
}

export default ExerciseStaff
