import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { useTerms } from '../../hooks/terms.service'
import { Container } from '../../styles/_app.style'
import { TutorialTableHeader } from '../../styles/records/tutorial.style'
import { Term } from '../../types/schemas/abc'
import { StudentTutoringSession } from '../../types/schemas/tutoring'
import {
  capitalizeEachWord,
  computeTermAndWeekFromDate,
  formatTutorialSessionDate,
} from '../../utils'
import { AttendanceStatus, ComputedStatusMarker } from '../records/ComputedStatusMarker'
import GenericTanStackTableRenderer from './tableRenderer/GenericTanStackTableRenderer'

const StudentViewAttendanceTable = ({
  term,
  data,
}: {
  term?: Term
  data: StudentTutoringSession[]
}) => {
  const columnHelper = createColumnHelper<StudentTutoringSession>()
  const terms = useTerms()

  const columns = [
    columnHelper.accessor(({ date }) => date || '-', {
      id: 'weekNumber',
      cell: (info) => <> {computeTermAndWeekFromDate(info.getValue(), terms)?.week} </>,
      header: 'Week',
      meta: {
        textIsCentred: true,
        css: {
          width: '4.19rem',
        },
      },
    }),
    columnHelper.accessor(({ date }) => formatTutorialSessionDate(date), {
      id: 'date',
      header: 'Date',
      meta: {
        textIsCentred: true,
      },
    }),
    columnHelper.accessor(({ present }) => present, {
      id: 'status',
      cell: (info) => <ComputedStatusMarker status={AttendanceStatus.fromValue(info.getValue())} />,
      header: 'Status',
      meta: {
        textIsCentred: true,
        css: {
          width: '6.41rem',
        },
      },
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Container stripPadding center css={{ gap: '0.75rem' }}>
      <TutorialTableHeader subHeading>
        <u>{capitalizeEachWord(term?.name ?? 'Unknown Term')}</u>
      </TutorialTableHeader>
      <GenericTanStackTableRenderer table={table} style={{ table: { tableLayout: 'fixed' } }} />
    </Container>
  )
}

export default StudentViewAttendanceTable
