import { Container } from '../_app.style'
import { styled } from '../stitches.config'

export const MobileTimelineContainer = styled(Container, {
  alignItems: 'center',
  gap: '0.5rem',
})

export const ExercisesDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  marginTop: '1rem',
  width: '100%',
})

export const MobileTimelineFooter = styled('p', {
  marginTop: '1rem',
})

export const DueTodayDiv = styled('div', {
  display: 'flex',
  width: '90%',
  alignItems: 'center',
  gap: '1.3rem',
})
