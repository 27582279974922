import { Root, TooltipPortal, TooltipTrigger } from '@radix-ui/react-tooltip'
import React, { ReactElement } from 'react'

import { Arrow, Content } from './tooltip.style'

const Tooltip = ({ children, label }: { children: ReactElement; label: string }) => {
  return (
    <Root>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPortal>
        <Content>
          {label}
          <Arrow />
        </Content>
      </TooltipPortal>
    </Root>
  )
}

export default Tooltip
