import {
  amber,
  amberDark,
  blue,
  blueDark,
  brown,
  brownDark,
  crimson,
  crimsonDark,
  cyan,
  cyanDark,
  grass,
  grassDark,
  green,
  greenDark,
  indigo,
  indigoDark,
  lime,
  limeDark,
  mint,
  mintDark,
  orange,
  orangeDark,
  pink,
  pinkDark,
  plum,
  plumDark,
  purple,
  purpleDark,
  red,
  redDark,
  sand,
  sky,
  skyDark,
  teal,
  tealDark,
  violet,
  violetDark,
  yellow,
  yellowDark,
} from '@radix-ui/colors'

import { RadixColorRange, RawColor } from './types'

/** Constants */
export const EXTRACT_PICKER_COLOUR_FROM_INDEX: RadixColorRange = 9
export const THEMES_RAW: RawColor[] = [
  ['red', red, redDark],
  ['crimson', crimson, crimsonDark],
  ['pink', pink, pinkDark],
  ['plum', plum, plumDark],
  ['purple', purple, purpleDark],
  ['violet', violet, violetDark],
  ['indigo', indigo, indigoDark],
  ['blue', blue, blueDark],
  ['cyan', cyan, cyanDark],
  ['teal', teal, tealDark],
  ['green', green, greenDark],
  ['grass', grass, grassDark],
  ['brown', brown, brownDark],
  ['orange', orange, orangeDark],
  ['amber', amber, amberDark],
  ['yellow', yellow, yellowDark],
  ['lime', lime, limeDark],
  ['mint', mint, mintDark],
  ['sky', sky, skyDark],
]
export const NO_ACCENT = 'none'

/** We use these to make colours a bit brighter to make the accent more apparent */
export const ACCENT_THEME_OVERRIDES = {
  appBackground: '$neutral2',
  subtleBackground: '$neutral3',
  separator: '$neutral7',
  elementBackground: '$neutral4',
  elementHover: '$neutral5',
  elementActive: '$neutral6',
}
