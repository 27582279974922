import { Expose, Transform, Type } from 'class-transformer'

import { stringToEnumValue } from '../../utils'

export enum OfferingGroupLabel {
  OPTIONAL = 'OPTIONAL',
  OPTIONAL1 = 'OPTIONAL1',
  OPTIONAL2 = 'OPTIONAL2',
  OPTIONAL3 = 'OPTIONAL3',
  REQUIRED = 'REQUIRED',
  REQUIRED1 = 'REQUIRED1',
  REQUIRED2 = 'REQUIRED2',
  REQUIRED3 = 'REQUIRED3',
  SELECTIVE = 'SELECTIVE',
  SELECTIVE1 = 'SELECTIVE1',
  SELECTIVE2 = 'SELECTIVE2',
  SELECTIVE3 = 'SELECTIVE3',
  SELECTIVE4 = 'SELECTIVE4',
  SUBTOTAL = 'SUBTOTAL',
  SUBTOTAL1 = 'SUBTOTAL1',
  SUBTOTAL2 = 'SUBTOTAL2',
  SUBTOTAL3 = 'SUBTOTAL3',
  EXTRACURRICULAR = 'EXTRACURRICULAR',
  XOR = 'XOR',
  XOR1 = 'XOR1',
}

export enum ExamTimetableConstraint {
  Tx101 = 'Tx101',
  Tx102 = 'Tx102',
  Tx103 = 'Tx103',
  Tx104 = 'Tx104',
  Tx105 = 'Tx105',
  Tx106 = 'Tx106',
  Tx107 = 'Tx107',
  Tx108 = 'Tx108',
  Tx109 = 'Tx109',
  Tx201 = 'Tx201',
  Tx202 = 'Tx202',
  Tx203 = 'Tx203',
  Tx204 = 'Tx204',
  Tx205 = 'Tx205',
  Tx206 = 'Tx206',
  Tx207 = 'Tx207',
  Tx208 = 'Tx208',
  Tx209 = 'Tx209',
}

export const OfferingGroupLabelOrder = [
  OfferingGroupLabel.REQUIRED,
  OfferingGroupLabel.REQUIRED1,
  OfferingGroupLabel.REQUIRED2,
  OfferingGroupLabel.REQUIRED3,
  OfferingGroupLabel.SELECTIVE,
  OfferingGroupLabel.SELECTIVE1,
  OfferingGroupLabel.SELECTIVE2,
  OfferingGroupLabel.SELECTIVE3,
  OfferingGroupLabel.SELECTIVE4,
  OfferingGroupLabel.OPTIONAL,
  OfferingGroupLabel.OPTIONAL1,
  OfferingGroupLabel.OPTIONAL2,
  OfferingGroupLabel.OPTIONAL3,
  OfferingGroupLabel.EXTRACURRICULAR,
  OfferingGroupLabel.XOR,
  OfferingGroupLabel.XOR1,
  OfferingGroupLabel.SUBTOTAL,
  OfferingGroupLabel.SUBTOTAL1,
  OfferingGroupLabel.SUBTOTAL2,
  OfferingGroupLabel.SUBTOTAL3,
]
export enum ModuleSelectionStatus {
  CLOSED = 'CLOSED',
  USE_PERIODS = 'USE_PERIODS',
}

export enum ApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export class Configuration {
  id: number

  @Transform(({ value }) => stringToEnumValue(ModuleSelectionStatus, value), { toClassOnly: true })
  status: ModuleSelectionStatus

  @Type(() => ModuleSelectionPeriod)
  periods: ModuleSelectionPeriod[]

  @Expose({ name: 'degree_year' })
  degreeYear: number

  get isOpen(): boolean {
    return this.status === ModuleSelectionStatus.USE_PERIODS
  }
}

export class ModuleSelectionPeriod {
  id: number

  @Type(() => Date)
  start: Date

  @Type(() => Date)
  end: Date
}
export class ExternalModuleChoice {
  id: number
  username: string

  @Expose({ name: 'external_module_id' })
  externalModuleId: number

  @Type(() => Date)
  timestamp: Date

  @Type(() => Date)
  @Expose({ name: 'reviewed_on' })
  reviewedOn: Date | null

  @Expose({ name: 'reviewed_by' })
  reviewedBy: string | null

  @Transform(({ value }) => stringToEnumValue(ApprovalStatus, value), { toClassOnly: true })
  status: ApprovalStatus
}

export class ExternalModule {
  id: number
  title: string
  code: string
  ects: number
  terms: number[]
}

export class OfferingGroup {
  id: number

  @Transform(({ value }) => stringToEnumValue(OfferingGroupLabel, value), { toClassOnly: true })
  label: OfferingGroupLabel

  min: number
  max: number
}

export class Regulation {
  id: number

  @Expose({ name: 'module_id' })
  moduleId: number
  degree: string
  ects: number

  @Expose({ name: 'exam_component' })
  examComponent: number
  @Expose({ name: 'cw_component' })
  cwComponent: number
  @Expose({ name: 'offering_group' })
  @Type(() => OfferingGroup)
  offeringGroup: OfferingGroup
}

export class InternalModule {
  id: number
  year: string
  title: string
  code: string
  description: string
  terms: number[]

  @Expose({ name: 'exam_timetable_constraint' })
  @Transform(({ value }) => stringToEnumValue(ExamTimetableConstraint, value), {
    toClassOnly: true,
  })
  examTimetableConstraint: ExamTimetableConstraint

  ects: number

  @Type(() => Regulation)
  regulations: Regulation[]
}

export class InternalModuleChoice {
  id: number
  username: string

  @Type(() => Date)
  timestamp: Date

  @Expose({ name: 'degree_regulations' })
  @Type(() => Regulation)
  degreeRegulations: Regulation
}
