import { styled } from './stitches.config'

export const Input = styled('input', {
  fontSize: '1rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$appBackground',
  borderBottom: '0.02rem solid $sand5',
  variants: {
    bordered: {
      true: {
        border: '1px solid $elementBorder',
      },
      false: {
        border: 'none',
      },
    },
  },
})

export const Table = styled('table', {
  borderSpacing: '0',
  width: '100%',
  fontSize: '$sm',
})

export const Th = styled('th', {
  padding: '0.5rem',
  textAlign: 'left',
  borderBottom: '0.1rem solid $neutral7',
  variants: {
    expander: {
      true: {
        padding: '1rem 0 1rem 0',
      },
    },
    textCentred: {
      true: {
        textAlign: 'center',
      },
    },
    inlineHeader: {
      true: {
        borderBottom: 'none',
        padding: '0.5rem',
      },
    },
    size: {
      compact: {
        borderBottom: 'none',
        padding: '0.25rem 0.5rem',
      },
      wide: {
        height: '2rem',
        boxSizing: 'content-box',
      },
    },
  },
})

export const ThContainer = styled('div', {
  display: 'flex',
  gap: '0.25rem',
})

export const Tr = styled('tr', {
  variants: {
    size: {
      wide: {
        height: '3.75rem',
      },
    },
  },
})

export const Td = styled('td', {
  padding: '0.5rem',
  variants: {
    expander: {
      true: {
        padding: '0.5rem 0 0.5rem 0',
      },
    },
    subRow: {
      true: {
        backgroundColor: '$neutral3',
        borderRightColor: '$neutral3',
        borderLeftColor: '$neutral3',
      },
    },
    size: {
      compact: {
        padding: '0.25rem 0.5rem',
      },
      wide: {
        height: '2rem',
        boxSizing: 'content-box',
      },
    },
    textCentred: {
      true: {
        textAlign: 'center',
      },
    },
  },
})
