import {
  ExpandedState,
  RowData,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useState } from 'react'
import { ClockFill, ExclamationCircleFill, Stopwatch, Trash3Fill } from 'react-bootstrap-icons'

import { endpoints } from '../../constants/endpoints'
import { EMPTY_CELL_VALUE } from '../../hooks/exerciseForStaff.service'
import { AnchorButton, Button } from '../../styles/_app.style'
import { Exercise, Mark, MarkHistory } from '../../types/schemas/emarking'
import { Flag, SubmissionDataRow } from '../../types/tablesDataRows'
import Tooltip from '../tooltip/Tooltip'
import { EditableCell } from './tableRenderer/EditableCell'
import GenericTanStackTableRenderer, {
  createExpanderColumn,
  createPlainColumn,
} from './tableRenderer/GenericTanStackTableRenderer'

const DATE_PATTERN = /\d{2}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}/g

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: string, columnId: keyof SubmissionDataRow, value: string) => void
  }
}
const Flags = ({ flags }: { flags: Flag[] }) => {
  const FlagIcon = ({ flag }: { flag: Flag }) => {
    const props = { size: 22, title: flag.value }
    return {
      late: <ExclamationCircleFill color="red" {...props} />,
      extension: <ClockFill color="green" {...props} />,
    }[flag.label]
  }

  return (
    <div style={{ display: 'flex', gap: '0.5rem' }}>
      {flags.sort().map((f) => (
        <FlagIcon flag={f} key={f.key} />
      ))}
    </div>
  )
}

const ActionButtons = ({
  deleteMark,
  viewHistory,
  openCapDialog,
}: {
  deleteMark: () => void
  viewHistory: () => void
  openCapDialog: () => void
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Tooltip label={'Delete mark'}>
        <Button icon onClick={deleteMark}>
          <Trash3Fill size={20} />
        </Button>
      </Tooltip>
      <Tooltip label={"View submission marks' history"}>
        <Button icon onClick={viewHistory}>
          <ClockFill size={20} />
        </Button>
      </Tooltip>
      <Tooltip label={'Open capping dialog'}>
        <Button icon onClick={openCapDialog}>
          <Stopwatch size={20} />
        </Button>
      </Tooltip>
    </div>
  )
}

const RawSubmissionsTable = ({
  exercise,
  data,
  marksUpdateEnabled,
  updateData,
  onDeleteMark,
  onViewHistory,
  onOpenCapDialog,
}: {
  exercise: Exercise
  data: SubmissionDataRow[]
  marksUpdateEnabled: boolean
  updateData: (oldData: any) => void
  onDeleteMark: (username: string) => void
  onViewHistory: (history: MarkHistory[]) => void
  onOpenCapDialog: (login: string) => void
}) => {
  const columnHelper = createColumnHelper<SubmissionDataRow>()
  // Conditional columns ------------------------------------------
  const markActionsColumn = columnHelper.accessor((row) => row, {
    id: 'actions',
    cell: (info) => {
      const { login, history } = info.getValue()
      return (
        <ActionButtons
          deleteMark={() => onDeleteMark(login)}
          viewHistory={() => onViewHistory(history)}
          openCapDialog={() => onOpenCapDialog(login)}
        />
      )
    },
    header: 'Mark actions',
    footer: (info) => info.column.id,
    enableSorting: false,
  })
  const tutorialGroupColumn = columnHelper.accessor(
    (row) => row.group,
    createPlainColumn<SubmissionDataRow>('Group')
  )
  // ----------------------------------------------------------------

  const columns = [
    columnHelper.accessor(() => {}, createExpanderColumn<SubmissionDataRow>()),
    columnHelper.accessor((row) => row.flags, {
      id: 'flags',
      cell: (info) => <Flags flags={info.getValue()} />,
      header: () => 'Flags',
    }),
    columnHelper.accessor((row) => row.login, createPlainColumn<SubmissionDataRow>('Login')),
    columnHelper.accessor((row) => row.fullName, createPlainColumn<SubmissionDataRow>('Name')),
    columnHelper.accessor((row) => row.level, createPlainColumn<SubmissionDataRow>('Level')),
    ...(exercise.isForYearOneTutorialGroups ? [tutorialGroupColumn] : []),
    columnHelper.accessor((row) => row.latestSubmission, {
      id: 'latestSubmission',
      cell: (info) => {
        let cellValue = info.getValue()
        return cellValue.match(DATE_PATTERN) ? (
          <AnchorButton
            thin
            href={endpoints.submissionZipped(
              exercise.year,
              exercise.moduleCode,
              exercise.number,
              info.row.original.login
            )}
          >
            {cellValue}
          </AnchorButton>
        ) : (
          cellValue
        )
      },
      header: () => 'Latest Submission',
    }),
    columnHelper.accessor((row) => row.mark, {
      id: 'mark',
      cell: ({ getValue, row, column, table }) => {
        function editValue(value: string) {
          table.options.meta?.updateData(row.id, column.id as keyof SubmissionDataRow, value)
        }

        const inputProps = { type: 'number', min: 0, max: exercise.maximumMark }
        const value = getValue() as string
        return marksUpdateEnabled ? (
          <EditableCell inputProps={inputProps} currentValue={value} onEdit={editValue} />
        ) : (
          value
        )
      },
      header: () => 'Mark',
    }),

    columnHelper.accessor(
      (row) => row.cap?.toString() || EMPTY_CELL_VALUE,
      createPlainColumn<SubmissionDataRow>('Cap')
    ),
    columnHelper.accessor(
      (row) => row.marker,
      createPlainColumn<SubmissionDataRow>('Latest marker')
    ),
    ...(marksUpdateEnabled ? [markActionsColumn] : []),
  ]

  function propagateMark(subRows: SubmissionDataRow[], value: string): SubmissionDataRow[] {
    return subRows.map((subRow: SubmissionDataRow) => {
      return !subRow.mark ? { ...subRow, mark: value } : subRow
    })
  }

  function updateMarks(rowId: string, columnId: keyof SubmissionDataRow, value: string) {
    // Extract main- and sub-row indices from id
    // See https://tanstack.com/table/v8/docs/api/core/table#getrowid
    // NOTE: subRowIndex is NaN if update happens in main row
    const [mainRowIndex, subRowIndex] = rowId.split('.').map((s) => parseInt(s))
    updateData((old: SubmissionDataRow[]) => {
      return old.map((row: SubmissionDataRow, index: number) => {
        // If this is the main row we are looking for...
        if (index === mainRowIndex) {
          // ...and we have updated the main row itself...
          if (isNaN(subRowIndex) && row[columnId] !== value) {
            if (columnId === 'mark') {
              // Propagate mark of main row to sub rows without one
              let newSubRows = row.subRows ? propagateMark(row.subRows, value) : row.subRows
              return { ...row, [columnId]: value, subRows: newSubRows }
            }
            return { ...row, [columnId]: value }
          } else {
            // ...and we have updated a sub row...
            const newSubRows = row.subRows?.map((subRow: SubmissionDataRow, index: number) => {
              if (subRowIndex === index && subRow[columnId] !== value)
                return { ...subRow, [columnId]: value }
              return subRow
            })
            return { ...row, subRows: newSubRows }
          }
        }
        // Not the main row we are looking for.
        return row
      })
    })
  }

  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: { updateData: updateMarks },
  })

  return <GenericTanStackTableRenderer table={table} size="wide" />
}

export default RawSubmissionsTable
