import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { styled } from '@stitches/react'

export const PeriodContainer = styled('div', {
  position: 'relative',
  padding: '1rem',
  borderRadius: '8px',
  backgroundColor: '$elementHover',
  width: '100%',
  marginBottom: '1rem',
})

export const CalendarContainer = styled('div', {
  display: 'flex',
  marginBottom: '1rem',
})

export const FromToHeader = styled('h4', {
  marginBottom: '0.5rem',
  fontWeight: 'normal',
})

export const EnrolmentStatusLabel = styled('div', {
  width: '100%',
  borderRadius: '8px',
  padding: '0.75rem 1.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  cursor: 'default',
  fontWeight: 600,
  marginBottom: '1rem',
  textAlign: 'center',
  transition: 'background-color 0.3s, color 0.3s',
  '&.OPEN': {
    backgroundColor: '#095d00',
    color: '#c7ffb0',
  },
  '&.CLOSED': {
    backgroundColor: '#5d0000',
    color: '#ffb0b0',
  },
  '&.USE_PERIODS': {
    backgroundColor: '$primary4',
    color: 'white',
  },
})

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
  display: 'inline-flex',
  borderRadius: '8px',
  border: '2px solid $primary8',
  color: '$primary11',
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '1rem',
  width: '100%',
})

export const ToggleGroupItem = styled(ToggleGroup.Item, {
  all: 'unset',
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.75rem 1.5rem',
  marginLeft: 1,
  '&:first-child': { marginLeft: 0, borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' },
  '&:last-child': { borderTopRightRadius: '8px', borderBottomRightRadius: '8px' },
  '&:hover': { backgroundColor: '$primary4', cursor: 'pointer' },
  '&[data-state=on]': { backgroundColor: '$primary6', color: 'white' },
  '&:focus': { position: 'relative' },
})
