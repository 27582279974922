import { styled } from '../stitches.config'

export const SearchDiv = styled('div', {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
})

export const TextIconGroup = styled('div', {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  gap: '1rem',
})

export const ShortcutLabel = styled('p', {
  textAlign: 'center',
  fontWeight: 500,
  color: '$lowContrast',
})

export const GeneralDiv = styled('div', {
  display: 'flex',
  variants: {
    row: {
      center: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
      },
      start: {
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'row',
      },
      between: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    col: {
      center: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
      start: {
        alignItems: 'start',
        flexDirection: 'column',
      },
    },
  },
})

export const Input = styled('input', {
  all: 'unset',
  backgroundColor: 'transparent',
  width: '100%',
})

export const ResultTitle = styled('p', {
  fontWeight: 'bold',
})

export const ResultContent = styled('div', {
  fontSize: '$sm',
  overflow: 'scroll',
  width: '100%',
  lineHeight: '1.2rem',
  pre: {
    whiteSpace: 'pre-wrap',
  },
})

export const ResultsFrame = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '14px',
})

export const SearchContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  height: '100%',
  overflowY: 'scroll',
})
