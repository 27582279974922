import * as Tooltip from '@radix-ui/react-tooltip'
import type { FC } from 'react'

import { ToolTipContent, ToolTipTrigger } from '../styles/tooltips.style'

export const ScientiaTooltip: FC<{ tooltipText: string }> = ({ tooltipText, children }) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <ToolTipTrigger>{children}</ToolTipTrigger>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <ToolTipContent sideOffset={5}>{tooltipText}</ToolTipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
