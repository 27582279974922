import { DropdownMenu, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { FC, ReactElement, ReactNode } from 'react'

import { Button } from '../../../styles/_app.style'
import Tooltip from '../../tooltip/Tooltip'
import { Content } from './navDropdown.style'

interface DropdownNavButtonProps {
  tooltipLabel: string
  icon: ReactElement
  children: ReactNode
}

const NavDropdown: FC<DropdownNavButtonProps> = ({ tooltipLabel, icon, children }) => {
  return (
    <DropdownMenu>
      <Tooltip label={tooltipLabel}>
        <DropdownMenuTrigger asChild>
          <Button icon>{icon}</Button>
        </DropdownMenuTrigger>
      </Tooltip>
      <Content
        align="start"
        style={{ cursor: 'default', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
      >
        {children}
      </Content>
    </DropdownMenu>
  )
}

export default NavDropdown
