import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

import cohortMappings from '../../constants/cohortMappings'
import { TIMELINE_DEFAULT_VALUE, TIMELINE_DEFAULT_VIEW_LABEL } from '../../constants/global'
import { Wrapper } from '../../styles/_app.style'
import { SwitcherArea } from '../../styles/timeline/gridAreas'
import { TermSwitchArrow } from '../../styles/timeline/switcher.style'
import { Term } from '../../types/schemas/abc'
import { groupByProperty } from '../../utils'
import Select from '../select/Select'

export const Switcher = ({
  term,
  setTerm,
  terms,
  onModuleCohortFilterChange,
}: {
  term: string
  setTerm: (_: Term) => void
  terms: Term[]
  onModuleCohortFilterChange: (_: string) => void
}) => {
  // filter options -----------------------------------
  const cohortFilterOptions = [
    {
      value: TIMELINE_DEFAULT_VALUE,
      label: TIMELINE_DEFAULT_VIEW_LABEL,
    },
    ...Object.entries(cohortMappings).map(([code, title]) => {
      return { value: code, label: `${code}: ${title}` }
    }),
  ]
  const periods = Object.keys(groupByProperty(terms, 'name', 'start'))
  const periodOptions = periods.map((p) => {
    return {
      value: p,
      label: p
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    }
  })
  // ----------------------------------------------------------

  // current filter values
  const [period, setPeriod] = useState(periods.find((p) => p === term)!)
  const [cohortFilter, setCohortFilter] = useState(TIMELINE_DEFAULT_VALUE)

  function handlePeriodChange(newValue: any) {
    if (newValue) setPeriod(newValue.value)
  }

  function handleCohortFilterChange(newValue: any) {
    if (newValue) setCohortFilter(newValue.value)
  }

  useEffect(() => {
    setTerm(terms.find((term) => term.name === period) || terms[0])
  }, [period, setTerm, terms])

  useEffect(() => {
    onModuleCohortFilterChange(cohortFilter)
  }, [cohortFilter, onModuleCohortFilterChange, terms])

  const TermSwitcher = () => {
    return (
      <Wrapper
        css={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          verticalAlign: 'middle',
          padding: '0 0.5rem 0 0.5rem',
        }}
      >
        <TermSwitchArrow
          title="Previous academic period"
          onClick={() => setPeriod((current) => periods[Math.max(periods.indexOf(current) - 1, 0)])}
          disabled={periods.indexOf(period) <= 0}
        >
          <ChevronLeft size={20} />
        </TermSwitchArrow>

        <Select
          options={periodOptions}
          value={periodOptions.find((o) => o.value === period)}
          onChange={handlePeriodChange}
          placeholder="Period"
        />
        <TermSwitchArrow
          title="Next academic period"
          onClick={() =>
            setPeriod(
              (current) => periods[Math.min(periods.indexOf(current) + 1, periods.length - 1)]
            )
          }
          disabled={periods.indexOf(period) >= periods.length - 1}
        >
          <ChevronRight size={20} />
        </TermSwitchArrow>
      </Wrapper>
    )
  }

  const CohortViewSwitcher = () => {
    return (
      <Wrapper
        css={{
          display: 'flex',
          padding: '0 0.5rem 0 0.5rem',
        }}
      >
        <Select
          options={cohortFilterOptions}
          value={cohortFilterOptions.find((o) => o.value === cohortFilter)}
          onChange={handleCohortFilterChange}
          placeholder="Cohort"
        />
      </Wrapper>
    )
  }

  return (
    <SwitcherArea>
      <TermSwitcher />
      <CohortViewSwitcher />
    </SwitcherArea>
  )
}
