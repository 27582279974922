import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ExercisePayload } from '../../hooks/exercise.service'
import { Button } from '../../styles/_app.style'
import {
  Control,
  Field,
  Form,
  Input,
  Label,
  LabelContainer,
  Message,
  Submit,
} from '../../styles/form.style'
import { Exercise } from '../../types/schemas/emarking'
import Select from '../select/Select'

const DEFAULT_EXERCISE_TYPE = 'CW'
const DEFAULT_SUBMISSION_TYPE = 'individual'
const DEFAULT_WEIGHT = 100
const DEFAULT_MAX_MARK = 20
const DEFAULT_DEADLINE_DAYS_AFTER_START = 14
const DEFAULT_START_TIME_HOUR = 9
const DEFAULT_END_TIME_HOUR = 19

export const ExerciseForm = ({
  exercise,
  exerciseNumbers,
  onSubmit,
}: {
  exercise?: Exercise
  exerciseNumbers: number[]
  onSubmit: (_: ExercisePayload) => void
}) => {
  const DATETIME_LOCAL_FORMAT = "yyyy-MM-dd'T'HH:mm"

  // options ----------------------------
  const exerciseTypeOptions = [
    { value: 'CW', label: 'CW - Coursework' },
    { value: 'TUT', label: 'TUT - Tutorial' },
    { value: 'PMT', label: 'PMT - PMT tutorial' },
    { value: 'PPT', label: 'PPT - PPT tutorial' },
    { value: 'MMT', label: 'MMT - MMT tutorial' },
    { value: 'GF', label: 'GF - Group formation' },
    { value: 'T', label: 'T - Test' },
  ]

  const submissionTypeOptions = [
    { value: 'no submission required', label: 'No submission required' },
    { value: 'individual', label: 'Individual submission' },
    { value: 'group', label: 'Group submission' },
  ]
  const initialPayload = useMemo(() => {
    // Default values -----------------------
    const defaultDeadline = new Date()
    defaultDeadline.setDate(new Date().getDate() + DEFAULT_DEADLINE_DAYS_AFTER_START)
    defaultDeadline.setHours(DEFAULT_END_TIME_HOUR, 0, 0, 0)

    const defaultStartTime = new Date()
    defaultStartTime.setDate(new Date().getDate() + 1)
    defaultStartTime.setHours(DEFAULT_START_TIME_HOUR, 0, 0, 0)

    const defaultModelAnswerVisibility = new Date()
    defaultModelAnswerVisibility.setHours(DEFAULT_END_TIME_HOUR, 0, 0, 0)
    // ----------------------------------------

    let nextExerciseNumber = Math.max(...exerciseNumbers, 0) + 1
    return {
      type: exercise?.type || DEFAULT_EXERCISE_TYPE,
      submissionType: exercise?.submissionType || DEFAULT_SUBMISSION_TYPE,
      number: exercise?.number ?? nextExerciseNumber,
      title: exercise?.title || '',
      start: exercise?.startDate || defaultStartTime,
      end: exercise?.endDate || defaultDeadline,
      maximumMark: exercise?.maximumMark ?? DEFAULT_MAX_MARK,
      expectedHours: exercise?.estimatedWorkHours,
      weight: exercise?.weight ?? DEFAULT_WEIGHT,
      ...(!!exercise?.modelAnswer
        ? { modelAnswerVisibleOn: exercise?.modelAnswerVisibleOn || defaultModelAnswerVisibility }
        : {}),
    }
  }, [exercise, exerciseNumbers])

  const datesAreInconsistent = useCallback(
    (startDate?: Date, endDate?: Date) =>
      !!startDate && !!endDate && startDate.getTime() >= endDate.getTime(),
    []
  )

  const startsInThePast = useCallback(
    (startDate?: Date) => !!startDate && startDate.getTime() < new Date().getTime(),
    []
  )

  const fallsOnWeekend = useCallback(
    (date?: Date) => date?.getDay() === 6 || date?.getDay() === 0,
    []
  )

  const exerciseNumberIsTaken = useCallback(
    (number: number) => exerciseNumbers.filter((n) => n !== exercise?.number).includes(number),
    [exercise, exerciseNumbers]
  )

  function applyPatch(key: keyof ExercisePayload, value: ExercisePayload[typeof key]) {
    setExercisePatch((current) => {
      return { ...current, [key]: value }
    })
  }

  const [exercisePatch, setExercisePatch] = useState<ExercisePayload>(initialPayload)
  useEffect(() => {
    setExercisePatch(initialPayload)
  }, [initialPayload])

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  useEffect(() => {
    setSaveButtonDisabled(
      JSON.stringify(exercisePatch) === JSON.stringify(initialPayload) ||
        Object.values(exercisePatch).some((v) => v === undefined || v === '') ||
        exerciseNumberIsTaken(exercisePatch.number!) ||
        datesAreInconsistent(exercisePatch.start, exercisePatch.end) ||
        fallsOnWeekend(exercisePatch.start) ||
        fallsOnWeekend(exercisePatch.end)
    )
  }, [datesAreInconsistent, exerciseNumberIsTaken, exercisePatch, initialPayload, fallsOnWeekend])

  const [inputsAreDisabled, setInputsAreDisabled] = useState<boolean>(false)
  useEffect(() => {
    setInputsAreDisabled(!!exercise && exercise.isLocked)
  }, [exercise])

  function handleOptionChange(exerciseAttribute: keyof ExercisePayload) {
    return (newValue: any) => applyPatch(exerciseAttribute, newValue?.value)
  }

  return (
    <>
      <Form
        css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit(exercisePatch)
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Select
            isDisabled={inputsAreDisabled}
            options={exerciseTypeOptions}
            value={exerciseTypeOptions.find((o) => o.value === exercisePatch.type)}
            onChange={handleOptionChange('type')}
            placeholder="Exercise type..."
          />
          <Select
            isDisabled={inputsAreDisabled}
            options={submissionTypeOptions}
            value={submissionTypeOptions.find((o) => o.value === exercisePatch.submissionType)}
            onChange={handleOptionChange('submissionType')}
            placeholder="Submission type..."
          />
        </div>
        <Field name="number">
          <LabelContainer>
            <Label>Number</Label>
            <Message match={(value, _) => exerciseNumberIsTaken(parseInt(value))}>
              Exercise number already in use
            </Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="number"
              min={1}
              value={exercisePatch.number}
              onChange={({ target: { value } }) => value && applyPatch('number', parseInt(value))}
              required
            />
          </Control>
        </Field>
        <Field name="title">
          <LabelContainer>
            <Label>Title</Label>
            <Message match="valueMissing">Title required</Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="text"
              value={exercisePatch.title}
              onChange={({ target: { value } }) => applyPatch('title', value)}
              required
            />
          </Control>
        </Field>
        <Field name="start">
          <LabelContainer>
            <Label>Starting</Label>
            <Message match="valueMissing">Start date required</Message>
            <Message match={() => startsInThePast(exercisePatch?.start)}>
              Start date cannot be in the past
            </Message>
            <Message match={() => fallsOnWeekend(exercisePatch?.start)}>
              Start date cannot be on a weekend
            </Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="datetime-local"
              value={exercisePatch.start ? format(exercisePatch.start, DATETIME_LOCAL_FORMAT) : ''}
              onChange={({
                target: {
                  value,
                  validity: { valid },
                },
              }) => {
                applyPatch('start', valid ? new Date(Date.parse(value)) : undefined)
              }}
              required
            />
          </Control>
        </Field>
        <Field name="end">
          <LabelContainer>
            <Label>Ending</Label>
            <Message match="valueMissing">End date required</Message>
            <Message match={() => datesAreInconsistent(exercisePatch.start, exercisePatch.end)}>
              Deadline cannot be before exercise start
            </Message>
            <Message match={() => fallsOnWeekend(exercisePatch?.end)}>
              End date cannot be on a weekend
            </Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="datetime-local"
              value={exercisePatch.end ? format(exercisePatch.end, DATETIME_LOCAL_FORMAT) : ''}
              onChange={({
                target: {
                  value,
                  validity: { valid },
                },
              }) => applyPatch('end', valid ? new Date(Date.parse(value)) : undefined)}
              required
            />
          </Control>
        </Field>
        <Field name="expected-hours">
          <LabelContainer>
            <Label>Estimated hours of work</Label>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="number"
              min={0}
              value={exercisePatch.expectedHours ?? ''}
              onChange={({ target: { value } }) =>
                value && applyPatch('expectedHours', parseInt(value))
              }
              required
            />
          </Control>
        </Field>
        <Field name="maxmark">
          <LabelContainer>
            <Label>Maximum Mark</Label>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="number"
              min={0}
              value={exercisePatch.maximumMark}
              onChange={({ target: { value } }) =>
                value && applyPatch('maximumMark', parseInt(value))
              }
              required
            />
          </Control>
        </Field>
        <Field name="weight">
          <LabelContainer>
            <Label>Weight</Label>
            <Message info match={(value) => parseInt(value) === 200}>
              CW Contribution calculated as AVG
            </Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="number"
              min={0}
              value={exercisePatch.weight}
              onChange={({ target: { value } }) => value && applyPatch('weight', parseInt(value))}
              required
            />
          </Control>
        </Field>
        {!!exercise?.modelAnswer && exercise.type === 'TUT' && (
          <Field name="modelAnswerVisibleOn">
            <LabelContainer>
              <Label>Model answer visible on</Label>
              <Message match="valueMissing">Model answer release date required</Message>
            </LabelContainer>
            <Control asChild>
              <Input
                disabled={inputsAreDisabled}
                type="datetime-local"
                value={
                  exercisePatch.modelAnswerVisibleOn
                    ? format(exercisePatch.modelAnswerVisibleOn, DATETIME_LOCAL_FORMAT)
                    : ''
                }
                onChange={({
                  target: {
                    value,
                    validity: { valid },
                  },
                }) => {
                  applyPatch(
                    'modelAnswerVisibleOn',
                    valid ? new Date(Date.parse(value)) : undefined
                  )
                }}
                required
              />
            </Control>
          </Field>
        )}
        <Submit asChild>
          <Button disabled={saveButtonDisabled}>Save</Button>
        </Submit>
      </Form>
    </>
  )
}
