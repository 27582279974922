export const NAVIGATION_HEIGHT = '4rem'
export const TIMELINE_TRACK_HEIGHT = '3.438rem'
export const DIVIDER_HEIGHT = '0.063rem'

export const INDEXING_OFFSET = 1 /* Cols in grid 1-indexed */

export const LONDON_TIMEZONE = 'Europe/London'

export const GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS = 14

export const SHORT_YEAR_REGEX = /^\d{4}$/

export const TIMELINE_DEFAULT_VIEW_LABEL = 'Your selected modules'
export const TIMELINE_DEFAULT_VALUE = 'student-selected-modules'

export const TUTORIAL_GROUP_TYPES = ['PPT', 'PMT', 'MMT']

export const SENIOR_TUTOR = 'Senior Tutor'

export const ONE_DAY = 24 * 60 * 60 * 1000
