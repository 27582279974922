import { css, styled } from '../stitches.config'

export const Header = styled('th', {
  textAlign: 'left',
  fontWeight: 600,
})

export const SubText = styled('div', {
  color: '$sand10',
  fontSize: '$sm',
  marginTop: '2px',
})

export const shadowSm = '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'

export const Table = styled('table', {
  flexGrow: 1,
  alignSelf: 'center',
  borderSpacing: '1.25rem',
  '@media (max-width: 35rem)': { borderSpacing: '1rem' },
})

export const HorizontalRow = ({ colSpan }: { colSpan: number }) => (
  <tr>
    <td className={css({ borderBottom: '1px solid $neutral7' })()} colSpan={colSpan}></td>
  </tr>
)
