import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { capitalize, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Card from '../components/Card'
import PageHeader from '../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  columnsFromAttributes,
} from '../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useExams } from '../hooks/exams.service'
import { Banner, Footnote } from '../styles/_app.style'
import { Main, Section } from '../styles/root.style'
import { formatShortYear } from '../utils'

const Exams = () => {
  const { year } = useParams()

  const { studentResult, studentResultIsLoaded } = useExams(year as string)

  const orderedYearResults = studentResult?.years.sort((a, b) => b.year - a.year)
  const mostRecentMessage = useMemo(
    () => orderedYearResults?.[orderedYearResults.length - 1]?.message,
    [orderedYearResults]
  )

  const yearTableData = useMemo(
    () =>
      orderedYearResults?.map((yearResult) => ({
        year: formatShortYear(yearResult.year.toString()),
        mark: yearResult.mark.toFixed(2),
      })) ?? [],
    [orderedYearResults]
  )
  const yearTable = useReactTable({
    data: yearTableData,
    columns: columnsFromAttributes(['year', 'mark']),
    getCoreRowModel: getCoreRowModel(),
  })

  const moduleTableData = useMemo(
    () =>
      studentResult?.modules
        .sort((a, b) => a.moduleCode.localeCompare(b.moduleCode))
        .map((moduleResult) => ({
          moduleCode: moduleResult.moduleCode,
          outcome: capitalize(moduleResult.outcome).replace(/_/g, ' '),
        })) ?? [],
    [studentResult]
  )
  const moduleTable = useReactTable({
    data: moduleTableData,
    columns: columnsFromAttributes(['moduleCode', 'outcome']),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!studentResultIsLoaded)
    return (
      <Main>
        <Section>
          <Banner>Loading results page...</Banner>
        </Section>
      </Main>
    )

  return studentResult && !(isEmpty(studentResult.years) && isEmpty(studentResult.modules)) ? (
    <Main>
      <PageHeader title="Exams" header="Your provisional exam grades" />
      {mostRecentMessage && (
        <Section>
          <h4>Message from the Senior Tutor:</h4>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '5px',
              whiteSpace: 'pre-line',
            }}
          >
            {mostRecentMessage}
          </div>
        </Section>
      )}

      {!isEmpty(orderedYearResults) && (
        <Section>
          <Card title="Overall year results">
            <GenericTanStackTableRenderer table={yearTable} size="wide" />
          </Card>
        </Section>
      )}

      {!isEmpty(studentResult?.modules) && (
        <Section>
          <Card title="Module results">
            <GenericTanStackTableRenderer table={moduleTable} size="wide" />
          </Card>
        </Section>
      )}

      <Section>
        <Footnote muted>
          These provisional grades are for your information only. They are not an official record.
          Official transcripts are issued by Registry.
        </Footnote>
      </Section>
    </Main>
  ) : (
    <Main>
      <Section>
        <Banner>
          No exam results have been published for the {formatShortYear(year)} academic year yet.
          <br />
          Check back at a later date.
        </Banner>
      </Section>
    </Main>
  )
}

export default Exams
