import React, { useState } from 'react'

import PageHeader from '../components/common/PageHeader'
import ContributionCard, { Contributor } from '../components/credits/ContributionCard'
import ContributionDialog from '../components/dialogs/ContributionDialog'
import { creditsData } from '../creditsData'
import { Main, Section } from '../styles/root.style'

const Credits = () => {
  const [selectedContributor, setSelectedContributor] = useState<Contributor | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const cardOpenDialog = (entry: Contributor) => {
    setSelectedContributor(entry)
    setDialogOpen(true)
  }

  return (
    <Main>
      <PageHeader title="Scientia Credits" header="Credits" />
      <Section>
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            gap: '0.7em',
            justifyContent: 'center',
          }}
        >
          {creditsData.map((contributor: Contributor) => {
            return <ContributionCard contributor={contributor} openDialog={cardOpenDialog} />
          })}
        </div>
        <ContributionDialog
          contributor={selectedContributor}
          open={dialogOpen}
          onOpenChange={setDialogOpen}
        />
      </Section>
    </Main>
  )
}

export default Credits
