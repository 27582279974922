import { InfoCircle } from 'react-bootstrap-icons'

import { styleForSubmissionType } from '../../../styles/exerciseItem.style'
import { styled } from '../../../styles/stitches.config'
import NavDropdown from './NavDropdown'

const ExerciseExample = styled('div', { borderRadius: '0.5rem', padding: '0.75rem' })

const TimelineGuideDropdown = () => {
  const examples = [
    {
      title: 'Assessed group exercise',
      combination: { submissionType: 'group', isAssessed: true },
    },
    {
      title: 'Assessed individual exercise',
      combination: { submissionType: 'individual', isAssessed: true },
    },
    {
      title: 'Unassessed and requires submission',
      combination: { submissionType: 'individual', isAssessed: false },
    },
    {
      title: 'Unassessed, no submission required',
      combination: { submissionType: 'no submission required', isAssessed: false },
    },
  ]

  return (
    <NavDropdown tooltipLabel={'Show timeline guide'} icon={<InfoCircle size={22} />}>
      {examples.map(({ title, combination }) => {
        let { submissionType, isAssessed } = combination
        return (
          <ExerciseExample key={title} css={styleForSubmissionType(submissionType, isAssessed)}>
            {title}
          </ExerciseExample>
        )
      })}
    </NavDropdown>
  )
}

export default TimelineGuideDropdown
