import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { TIMELINE_DEFAULT_VALUE, TIMELINE_TRACK_HEIGHT } from '../constants/global'
import { AxiosContext } from '../contextManagers/axios.context'
import { useUser } from '../contextManagers/user.context'
import { Module, Term } from '../types/schemas/abc'
import { Exercise } from '../types/schemas/emarking'
import { TrackMap } from '../types/timeline'
import {
  exerciseInTermFilter,
  generateTrackMap,
  groupByProperty,
  mapPlainToInstance,
  now,
  padForModulesWithNoExercises,
  sortObjectByKey,
} from '../utils'
import { useErrorMessage } from './errorMessage.service'
import { useTerms } from './terms.service'

export interface UseTimelineVars {
  terms: Term[]
  term: Term
  modulesForTerm: Module[]
  trackMapForTerm: TrackMap
  rowHeights: { [code: string]: string }
  setTerm: (_: Term) => void
  setModulesCohortFilter: (_: string) => void
}

function termToNumber({ name }: Term): number {
  if (name === 'autumn term') return 1
  if (name === 'spring term') return 2
  if (name === 'summer term') return 3
  return -1
}

function includeWeekendInEndOfTerm(endDate: Date): Date {
  const EXTRA_DAYS = 3 // Sunday night/monday morning
  let d = new Date(endDate)
  d.setDate(d.getDate() + EXTRA_DAYS)
  return d
}

export const useTimeline = (): any => {
  const { userDetails } = useUser()
  const { year } = useParams()
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const terms = useTerms()

  const [term, setTerm] = useState<Term>()
  useEffect(() => {
    if (terms.length > 0) {
      setTerm(
        terms.find(
          (term: Term) => term.start < now() && includeWeekendInEndOfTerm(term.end) > now()
        ) || terms[0]
      )
    }
  }, [terms])

  const [modulesForCohort, setModulesForCohort] = useState<Module[]>(
    userDetails?.modules as Module[]
  )
  const [modulesCohortFilter, setModulesCohortFilter] = useState<string>(TIMELINE_DEFAULT_VALUE)
  useEffect(() => {
    if (!year) return
    if (modulesCohortFilter === TIMELINE_DEFAULT_VALUE)
      setModulesForCohort(userDetails?.modules as Module[])
    else {
      axiosInstance
        .get(endpoints.modules(year), { params: { cohort: modulesCohortFilter } })
        .then(({ data }) => {
          setModulesForCohort(!data?.length ? [] : mapPlainToInstance(Module, data))
        })
        .catch(displayError(`Unable to fetch modules for cohort '${modulesCohortFilter}'`))
    }
  }, [axiosInstance, year, modulesCohortFilter, userDetails?.modules, displayError])

  const [exercises, setExercises] = useState<{ [code: string]: Exercise[] }>({})
  useEffect(() => {
    axiosInstance
      .get(endpoints.myExercises(year!), {
        params: { module_code: modulesForCohort.map((m) => m.code) },
      })
      .then(({ data }) => {
        const deserialisedExercises = mapPlainToInstance(Exercise, data)
        setExercises(
          groupByProperty(deserialisedExercises, 'moduleCode', 'number') as {
            [code: string]: Exercise[]
          }
        )
      })
  }, [year, modulesForCohort, axiosInstance])

  const [modulesForTerm, setModulesForTerm] = useState<Module[]>([])
  useEffect(() => {
    if (!term) return

    const moduleCodesForExercisesInTerm = (Object.values(exercises) as Exercise[][])
      .flat()
      .filter(exerciseInTermFilter(term))
      .map((e) => e.moduleCode)

    // For given term, show only modules
    // (a) taught in that term or
    // (b) whose exercises fall within the term's start and end dates.
    const modulesToShow = modulesForCohort
      .filter(
        (module) =>
          module.terms.includes(termToNumber(term)) ||
          moduleCodesForExercisesInTerm.includes(module.code)
      )
      .sort((m1, m2) => (m1.code < m2.code ? -1 : 1))
    setModulesForTerm(modulesToShow)
  }, [term, exercises, modulesCohortFilter, modulesForCohort])

  const [trackMapForTerm, setTrackMapForTerm] = useState<TrackMap>({})
  useEffect(() => {
    if (!term) return

    const moduleCodesForTerm = modulesForTerm.map((m) => m.code)
    const trackMap: TrackMap = sortObjectByKey(
      padForModulesWithNoExercises(moduleCodesForTerm, generateTrackMap(exercises, term))
    )
    setTrackMapForTerm(
      Object.fromEntries(
        Object.entries(trackMap).filter(([code, _]) => moduleCodesForTerm.includes(code))
      )
    )
  }, [exercises, modulesForTerm, term])

  const [rowHeights, setRowHeights] = useState<{ [code: string]: string }>({})
  useEffect(() => {
    setRowHeights(
      Object.entries(trackMapForTerm).reduce(
        (accumulator: { [code: string]: string }, [code, tracks]): { [code: string]: string } => {
          accumulator[code] = `calc(${TIMELINE_TRACK_HEIGHT} * ${tracks.length || 1})`
          return accumulator
        },
        {}
      )
    )
  }, [trackMapForTerm])

  return {
    terms,
    term,
    modulesForTerm,
    trackMapForTerm,
    rowHeights,
    setTerm,
    setModulesCohortFilter,
  }
}
