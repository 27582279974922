import { Dictionary } from 'lodash'
import { FC } from 'react'

import { Banner } from '../../styles/_app.style'
import { Pill } from '../../styles/pill.style'
import { InternalModule } from '../../types/schemas/moduleSelection'
import { Card, Markdown } from './moduleSelection.style'

interface ModuleDescriptionPaneProps {
  module: InternalModule
  timetableClashes: Dictionary<string>
}

const ModuleDescriptionPane: FC<ModuleDescriptionPaneProps> = ({ module, timetableClashes }) => {
  return (
    <Card>
      <div>
        <h2>
          {module.title} <span style={{ color: 'grey', marginLeft: '1rem' }}>{module.code}</span>
        </h2>
        <div style={{ display: 'flex', gap: '0.25rem' }}>
          <Pill>ECTS: {module.regulations[0].ects}</Pill>
          <Pill>Exam: {module.regulations[0].examComponent}%</Pill>
          <Pill>CW: {module.regulations[0].cwComponent}%</Pill>
          <Pill>Term: {module.terms.join(', ')}</Pill>
          <Pill>{module.regulations[0].offeringGroup.label}</Pill>
        </div>
      </div>
      <Markdown>{module.description ?? 'No description provided.'}</Markdown>
      {![undefined, module.code].includes(timetableClashes[module.examTimetableConstraint]) && (
        <Banner thin level={'warning'}>
          Timetable clash with selected module {timetableClashes[module.examTimetableConstraint]}
        </Banner>
      )}
    </Card>
  )
}

export default ModuleDescriptionPane
