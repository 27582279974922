import { Justify } from 'react-bootstrap-icons'

import { LINKS } from '../../../constants/links'
import { Item } from '../../../styles/navigation.style'
import NavDropdown from './NavDropdown'

const ExternalLinksDropdown = () => {
  return (
    <NavDropdown tooltipLabel={'Show external links'} icon={<Justify size={22} />}>
      {LINKS.map((link) => (
        <a
          href={link.url}
          key={link.title}
          target="_blank"
          rel="noreferrer"
          title={link.description}
        >
          <Item link style={{ padding: '0.5rem' }}>
            <link.icon size={16}></link.icon>
            {link.title}
          </Item>
        </a>
      ))}
    </NavDropdown>
  )
}

export default ExternalLinksDropdown
