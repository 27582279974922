import { ComponentProps, Fragment, ReactNode } from 'react'

import { DragDropOptions, addDroppable } from '../hooks/dragDrop.service'
import { Hr } from '../styles/_app.style'
import { Tab, TabsWrapper } from '../styles/tabs.style'

/**
 * The following was heavily inspired by Emil Kowalski's blog on tabs and the accompanying code sandbox:
 * https://emilkowal.ski/ui/tabs
 *
 * data: list of data items
 * attribute: the attribute on each data item to use as key for each tab
 * dividers: toggle <hr/> divider between tabs
 * tabProps: list of props to pass down to each tab. TabProp item at index I is applied for tab of data item at index I.
 */
export const Tabs = ({
  data,
  generator,
  attribute = 'title',
  href = (_: any) => '#',
  target = '_self',
  dragDropOptions = null,
  dividers = false,
  tabProps = [],
  allTabsCss = {},
  ...props
}: {
  data: any
  generator: (_: any) => ReactNode
  attribute?: string
  href?: (tab: any) => string
  target?: string
  dragDropOptions?: DragDropOptions | null
  dividers?: boolean
  tabProps?: { [_: string]: any }[]
  allTabsCss?: ComponentProps<typeof Tab>['css']
} & ComponentProps<typeof TabsWrapper>) => {
  const renderTab = (tab: any, index: number, props: { [_: string]: any } = {}) => (
    <Fragment key={tab[attribute]}>
      {dividers && index > 0 && <Hr />}
      <Tab {...props} to={href(tab)} target={target} css={{ ...allTabsCss, ...props.css }}>
        {generator(tab)}
      </Tab>
    </Fragment>
  )

  return (
    <TabsWrapper {...props}>
      {dragDropOptions !== null
        ? addDroppable(data, attribute, dragDropOptions, renderTab)
        : data?.map((d: any, i: number) => renderTab(d, i, tabProps[i] || {}))}
    </TabsWrapper>
  )
}
