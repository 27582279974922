import { styled } from '../stitches.config'

export const MemberName = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '0.5rem',
})

export const MemberRole = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: '1rem',
  fontWeight: 500,
  color: '$sand11',
})
