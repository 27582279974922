import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { Term } from '../types/schemas/abc'
import { mapPlainToInstance, now } from '../utils'
import { useErrorMessage } from './errorMessage.service'

const TOTAL_ACADEMIC_TERMS = 3

export function useTerms(): Term[] {
  const [terms, setTerms] = useState<Term[]>([])

  const { year } = useParams()
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  useEffect(() => {
    axiosInstance
      .get(endpoints.periods(year!))
      .then(({ data }) => {
        setTerms(!data?.length ? [] : mapPlainToInstance(Term, data))
      })
      .catch(displayError('Failed to fetch terms'))
  }, [axiosInstance, displayError, year])

  return terms
}

/**
 * Find the most recent academic term.
 * i.e. the index of the most recent non-break period.
 * */
export function useRecentAcademicTerm(): { term: Term | undefined; termIndex: number } {
  const terms = useTerms()

  /* Assume periods are in ascending order of start date. */
  const recentTermIndex = [...terms]
    .reverse()
    .findIndex((term) => now() >= term.start && now() < term.end)

  /* If no periods have started yet */
  if (recentTermIndex === -1) {
    return { term: undefined, termIndex: -1 }
  }

  /* Assume each academic period is followed by a break period */
  const recentAcademicTermIndex = TOTAL_ACADEMIC_TERMS - Math.floor(recentTermIndex / 2)
  return { term: terms[(recentAcademicTermIndex - 1) * 2], termIndex: recentAcademicTermIndex }
}
