import React, { useState } from 'react'
import { ArrowLeft } from 'react-bootstrap-icons'

import { Toolbar } from '../../components/Toolbar'
import Select, { StandardSelectOption } from '../../components/select/Select'
import Tooltip from '../../components/tooltip/Tooltip'
import { Button, Div } from '../../styles/_app.style'

const ReallocationToolbar = ({
  availableMarkers,
  onClose,
  onConfirm,
}: {
  availableMarkers: StandardSelectOption[]
  onClose: () => void
  onConfirm: (_: string) => void
}) => {
  const [selectedMarker, setSelectedMarker] = useState<string>()

  return (
    <Toolbar>
      <Tooltip label={'Close re-allocation toolbar'}>
        <Button icon onClick={onClose}>
          <ArrowLeft size={22} />
        </Button>
      </Tooltip>
      <Div css={{ display: 'flex', flexGrow: 1, gap: '0.5rem', justifyContent: 'space-between' }}>
        <Select
          options={availableMarkers}
          value={availableMarkers.find((m) => m.value === selectedMarker)}
          onChange={(v: any) => setSelectedMarker(v.value)}
          placeholder="Available markers..."
        />

        <Tooltip label={'Confirm re-allocation'}>
          <Button disabled={!selectedMarker} onClick={() => onConfirm(selectedMarker as string)}>
            Go
          </Button>
        </Tooltip>
      </Div>
    </Toolbar>
  )
}

export default ReallocationToolbar
