import { useMemo } from 'react'
import { FileEarmarkPdf, Icon, PlayBtn } from 'react-bootstrap-icons'

import { OutlinedCard } from '../../styles/card.style'
import { Pill } from '../../styles/pill.style'
import { GeneralDiv, ResultContent, ResultTitle } from '../../styles/search/search.style'
import { SearchResult, SearchResultTypes } from '../../types/schemas/search'
import Markdown from './Markdown'

const ResultTypeToIconMap: Record<SearchResultTypes, Icon> = {
  [SearchResultTypes.MATERIALS_PDF]: FileEarmarkPdf,
  [SearchResultTypes.PANOPTO_VIDEO]: PlayBtn,
}

const ResultTypeToColour: Record<SearchResultTypes, string> = {
  [SearchResultTypes.MATERIALS_PDF]: '$orange4',
  [SearchResultTypes.PANOPTO_VIDEO]: '$yellow4',
}

const OffsetTag = ({ result }: { result: SearchResult }) => {
  return <Pill css={{ backgroundColor: ResultTypeToColour[result.fileType] }}>{result.offset}</Pill>
}

const removeLastCodeBlockIfEmpty = (markdown: string): string => {
  const lines = markdown.split('\n')

  // Check if the last line matches the pattern "```string"
  const lastLine = lines[lines.length - 1]
  const codeBlockPattern = /^```[a-z]+$/

  if (codeBlockPattern.test(lastLine)) {
    // Remove the last line if it's a code block
    lines.pop()
  }

  // Join the lines back together to form the modified markdown
  return lines.join('\n')
}

/** Figure out how to add trailing ... to either side of the markdown */
const addTrailingEllipses = (markdownRaw: string) => {
  // 1: Cleanup - if last line of the format "```string", remove it as otherwise it's a miss formatted code block
  const processedMarkdown1 = removeLastCodeBlockIfEmpty(markdownRaw)
  const processedMarkdownEndTrimmed = processedMarkdown1.trimEnd()
  // 1: If first char is a-z or A-Z, add it
  // 2: If last char is a-z or A-Z, add it
  const firstChar = processedMarkdown1.charAt(0)
  const lastChar = processedMarkdownEndTrimmed.charAt(processedMarkdownEndTrimmed.length - 1)
  const regexpAlpha = /^[a-zA-Z]+$/
  const regexpPunctuation = /^[\.,:;!?]+$/
  const hasFirstChar = regexpAlpha.test(firstChar)
  const hasLastChar = regexpAlpha.test(lastChar)
  const hasLastCharPunctuation = regexpPunctuation.test(lastChar)
  const ellipses = '...'
  let result = processedMarkdown1
  result = hasFirstChar ? ellipses + result : result
  result = hasLastCharPunctuation ? result.slice(0, -1) + ellipses : result
  result = hasLastChar ? result + ellipses : result
  return result
}

const Result = ({ data }: { data: SearchResult }) => {
  const ResultIcon = useMemo(() => ResultTypeToIconMap[data.fileType], [data])
  const ResultLink = useMemo(() => data.url, [data])

  return (
    <OutlinedCard result css={{ padding: '15px 22px' }}>
      <a href={ResultLink} target="_blank" style={{ display: 'flex' }}>
        <GeneralDiv row={'start'} css={{ gap: '22px', maxHeight: '8rem' }}>
          <ResultIcon size={48} />
          <GeneralDiv col={'start'} css={{ width: '100%', height: '100%', gap: '0.4rem' }}>
            <GeneralDiv row={'start'} css={{ gap: '10px' }}>
              <ResultTitle>{data.title}</ResultTitle>
              <Pill>{data.moduleCode}</Pill>
              <OffsetTag result={data} />
            </GeneralDiv>
            <ResultContent>
              <Markdown>{addTrailingEllipses(data.snippet)}</Markdown>
            </ResultContent>
          </GeneralDiv>
        </GeneralDiv>
      </a>
    </OutlinedCard>
  )
}

export default Result
