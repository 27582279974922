import React from 'react'

import { Button } from '../../styles/_app.style'
import { Container } from '../../styles/card.style'
import ContributorImage from './ContributorImage'

export interface Contributor {
  name: string
  role: string
  year: string
  contribution: string
  image?: string
  links?: { github?: string; linkedin?: string; personal?: string }
}

interface ContributionCardProps {
  contributor: Contributor
  openDialog: (contributor: Contributor) => void
}

export const ContributionCard = ({ contributor, openDialog }: ContributionCardProps) => {
  return (
    <Container style={{ padding: '1em' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.25em' }}>
        <ContributorImage image={contributor.image} size={80} />

        <div style={{ display: 'flex', flexDirection: 'column', flex: 2, gap: '0.5em' }}>
          <h3>{contributor.name}</h3>
          <p>
            <b>
              {contributor.role}, {contributor.year}
            </b>
          </p>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={() => openDialog(contributor)}>Read more</Button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ContributionCard
