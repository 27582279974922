import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useMemo } from 'react'

import { EMPTY_CELL_VALUE } from '../../hooks/exerciseForStaff.service'
import { MarkHistory } from '../../types/schemas/emarking'
import Dialog from '../dialogs/Dialog'
import GenericTanStackTableRenderer, {
  createDatetimeColumn,
  createPlainColumn,
} from '../tables/tableRenderer/GenericTanStackTableRenderer'

type MarkHistoryRow = {
  timestamp: Date
  marker: string
  mark: string
  cap: string
  calculated: string
}

const MarkHistoryDialog = ({
  marks,
  open,
  onOpenChange,
}: {
  marks: MarkHistory[]
  open: boolean
  onOpenChange: (_: boolean) => void
}) => {
  const columnHelper = createColumnHelper<MarkHistoryRow>()
  const columns = [
    columnHelper.accessor((row) => row.timestamp, createDatetimeColumn<MarkHistoryRow>('Date')),
    columnHelper.accessor((row) => row.marker, createPlainColumn<MarkHistoryRow>('Marker')),
    columnHelper.accessor((row) => row.mark, createPlainColumn<MarkHistoryRow>('Mark')),
    columnHelper.accessor((row) => row.cap, createPlainColumn<MarkHistoryRow>('Cap')),
    columnHelper.accessor((row) => row.calculated, createPlainColumn<MarkHistoryRow>('Calculated')),
  ]

  const data = useMemo(
    () =>
      marks
        .sort((entry1, entry2) => entry2.timestamp.getTime() - entry1.timestamp.getTime())
        .map(({ timestamp, mark, marker, cap }) => ({
          timestamp,
          mark: (mark ?? EMPTY_CELL_VALUE).toString(),
          marker,
          cap: (cap ?? EMPTY_CELL_VALUE).toString(),
          calculated: (mark !== null
            ? Math.min(mark, cap ?? Number.MAX_SAFE_INTEGER)
            : EMPTY_CELL_VALUE
          ).toString(),
        })),
    [marks]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Dialog open={open} onOpenChange={onOpenChange} title={`Mark History`}>
      <GenericTanStackTableRenderer table={table} />
    </Dialog>
  )
}

export default MarkHistoryDialog
