import React, { FC, useState } from 'react'

import Select from '../../components/select/Select'
import Tooltip from '../../components/tooltip/Tooltip'
import { Button } from '../../styles/_app.style'
import { ExternalModule } from '../../types/schemas/moduleSelection'
import { Card } from './moduleSelection.style'

type ExternalModuleOption = {
  value: string
  label: string
  code: string
}

interface ExternalModuleOptionsPaneProps {
  externalModules: ExternalModule[]
  onApply: (_: string) => void
}

const ExternalModuleOptionsPane: FC<ExternalModuleOptionsPaneProps> = ({
  externalModules,
  onApply,
}) => {
  const [selected, setSelected] = useState<ExternalModuleOption>()
  const options: ExternalModuleOption[] = externalModules.map(({ title, code }) => ({
    value: title,
    label: title,
    code: code,
  }))
  return (
    <Card>
      <h2>External Module Options</h2>
      <p>
        Optional modules must be approved by a staff member of the department so you will not be
        able to select any modules for credit registration until the application has been approved.
        To apply, select the module from the dropdown below and click "Apply".
      </p>

      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Select
          menuPortalTarget={document.body}
          isSearchable
          options={options}
          placeholder={'Choose an external module...'}
          value={selected}
          onChange={(v: any) => setSelected(v)}
        />
        <Tooltip label={'Open external module application pane'}>
          <Button disabled={!selected?.value} onClick={() => onApply(selected!.code)}>
            Apply
          </Button>
        </Tooltip>
      </div>
    </Card>
  )
}

export default ExternalModuleOptionsPane
