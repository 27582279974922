import { plainToInstance } from 'class-transformer'
import { format } from 'date-fns'
import { useContext, useEffect, useMemo, useState } from 'react'

import { ExtensionPayload } from '../components/exercise/ExtensionDialog'
import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { useUser } from '../contextManagers/user.context'
import { Mapping, RawMarks } from '../types/global'
import { EnrolledStudent, TutorialGroup } from '../types/schemas/abc'
import { Exercise, ExerciseSubmission, Extension, Group, Mark } from '../types/schemas/emarking'
import { Flag, SubmissionDataRow } from '../types/tablesDataRows'
import { groupByProperty, mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const EMPTY_CELL_VALUE = ''
export const DATA_PLACEHOLDER_VALUE = '-'

function buildFlags(
  keyPrefix: string,
  deadline: Date,
  referenceSubmission: ExerciseSubmission | undefined,
  extension: Date | undefined
): Flag[] {
  let flags = []
  if (referenceSubmission && referenceSubmission.timestamp > (extension || deadline))
    flags.push({ label: 'late' as 'late', key: `${keyPrefix}-late`, value: 'Late submission' })
  if (extension)
    flags.push({
      label: 'extension' as 'extension',
      key: `${keyPrefix}-extension`,
      value: `Extension until ${format(extension, 'dd/MM/yy HH:mm:ss')}`,
    })
  return flags
}

export const useSubmissionGroups = (exercise: Exercise) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [groups, setGroups] = useState<Group[]>([])
  useEffect(() => {
    if (!exercise) return
    axiosInstance
      .get(endpoints.submissionGroups, {
        params: {
          year: exercise.year,
          module_code: exercise.moduleCode,
          exercise_number: exercise.number,
        },
      })
      .then(({ data }) => {
        setGroups(mapPlainToInstance(Group, data))
      })
      .catch(displayError('Failed to fetch groups'))
  }, [axiosInstance, displayError, exercise])
  return { groups }
}

export const useExerciseForStaff = (
  exercise: Exercise,
  marks: Mark[],
  groups: Group[],
  tutorialGroups: TutorialGroup[],
  enrolledStudentsLookup: Mapping<string, EnrolledStudent>
) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { addToast } = useToast()
  const { userDetails } = useUser()

  const [extensionLookup, setExtensionLookup] = useState<Mapping<string, Date>>({})
  useEffect(() => {
    setExtensionLookup(
      (exercise.extensions ?? []).reduce((acc: Mapping<string, Date>, ext: Extension) => {
        return { ...acc, [ext.studentUsername]: ext.revisedDeadline }
      }, {})
    )
  }, [exercise])

  const [tutorialGroupLookup, setTutorialGroupLookup] = useState<Mapping<string, number>>({})
  useEffect(() => {
    if (!tutorialGroups.length) return
    setTutorialGroupLookup(
      Object.fromEntries(
        tutorialGroups.flatMap((g: TutorialGroup) =>
          g.members.map(({ login }) => [login, g.number])
        )
      )
    )
  }, [tutorialGroups])

  const [submissionLookup, setSubmissionLookup] = useState<Mapping<string, ExerciseSubmission[]>>()
  useEffect(() => {
    if (!exercise) return
    axiosInstance
      .get(endpoints.submissionsForStaff(exercise.year, exercise.moduleCode, exercise.number))
      .then(({ data }) => {
        let deserialisedData = mapPlainToInstance(ExerciseSubmission, data)
        setSubmissionLookup(
          groupByProperty(deserialisedData, 'username', 'timestamp') as {
            [username: string]: ExerciseSubmission[]
          }
        )
      })
      .catch(displayError('Unable to get student submissions'))
  }, [axiosInstance, displayError, exercise])

  const groupLookup = useMemo(
    () =>
      groups.reduce((grouped: Mapping<string, Group>, group: Group) => {
        return { ...grouped, [group.leader as string]: group }
      }, {}),
    [groups]
  )
  const marksLookup = useMemo(
    () =>
      marks.reduce((grouped: Mapping<string, Mark>, mark: Mark) => {
        return { ...grouped, [mark.student_username]: mark }
      }, {}),
    [marks]
  )

  const [tableRows, setTableRows] = useState<SubmissionDataRow[]>([])
  useEffect(() => {
    if (marksLookup === undefined) return
    if (enrolledStudentsLookup === undefined) return
    if (groupLookup === undefined) return
    if (submissionLookup === undefined) return

    function filterForGUTA(
      dataEntries: { [p: string]: ExerciseSubmission[] },
      tutorialRole: 'uta' | 'tutor'
    ) {
      if (!tutorialGroups.length) return {}
      let relevantStudents = tutorialGroups
        .filter((g) => g[tutorialRole]?.login === userDetails?.login)
        .flatMap((g) => g.members.map((m) => m.login))
      return Object.fromEntries(
        Object.entries(dataEntries).filter(([login, _]) => relevantStudents.includes(login))
      )
    }

    const members = new Set(
      Object.values(groupLookup)
        .flatMap((g) => g.members)
        .filter((m) => !m.isLeader)
        .map((m) => m.username)
    )
    const submitters = new Set(Object.keys(submissionLookup))
    let missing: Mapping<string, ExerciseSubmission[]> = Object.keys(enrolledStudentsLookup)
      .filter((s) => !(members.has(s) || submitters.has(s)))
      .reduce((acc, login) => {
        return { ...acc, [login]: [] }
      }, {})

    let fullDataEntries = { ...submissionLookup, ...missing }
    if (!userDetails?.isGTAMarkerForModule(exercise.moduleCode)) {
      fullDataEntries = userDetails?.isGUTAForModule(exercise.moduleCode)
        ? filterForGUTA(fullDataEntries, exercise.type === 'MMT' ? 'tutor' : 'uta')
        : fullDataEntries
    }

    const rows = Object.entries(fullDataEntries).map(([leader, submissions]) => {
      let mark = marksLookup[leader]
      const submission = submissions.length > 0 ? submissions[submissions.length - 1] : undefined
      return {
        login: leader,
        fullName: enrolledStudentsLookup[leader]?.fullName || DATA_PLACEHOLDER_VALUE,
        level: enrolledStudentsLookup[leader]?.level || EMPTY_CELL_VALUE,
        group: tutorialGroupLookup[leader] || EMPTY_CELL_VALUE,
        latestSubmission: submission
          ? format(submission.timestamp, 'dd/MM/yy HH:mm:ss')
          : DATA_PLACEHOLDER_VALUE,
        mark: mark?.mark !== null && !isNaN(mark?.mark) ? mark.mark : EMPTY_CELL_VALUE,
        markID: mark?.id,
        history: mark?.history ?? [],
        marker: mark?.marker || EMPTY_CELL_VALUE,
        cap: mark?.cap ?? null,
        flags: buildFlags(leader, exercise.deadline, submission, extensionLookup[leader]),
        subRows:
          groupLookup[leader]?.members
            .filter((m) => !m.isLeader)
            .map(({ username }) => {
              let mark = marksLookup[username]
              return {
                login: username,
                fullName: enrolledStudentsLookup[username]?.fullName || DATA_PLACEHOLDER_VALUE,
                level: enrolledStudentsLookup[username]?.level || EMPTY_CELL_VALUE,
                group: tutorialGroupLookup[username] || EMPTY_CELL_VALUE,
                latestSubmission: DATA_PLACEHOLDER_VALUE,
                mark: mark?.mark !== null && !isNaN(mark?.mark) ? mark.mark : EMPTY_CELL_VALUE,
                markID: mark?.id,
                history: mark?.history ?? [],
                marker: mark?.marker || EMPTY_CELL_VALUE,
                cap: mark?.cap ?? null,
                flags: [],
              }
            }) || [],
      }
    })

    setTableRows(rows)
  }, [
    marksLookup,
    groupLookup,
    enrolledStudentsLookup,
    submissionLookup,
    exercise,
    extensionLookup,
    tutorialGroupLookup,
    tutorialGroups,
    userDetails,
  ])

  function updateTableWithMarks(marks: RawMarks) {
    setTableRows((old: SubmissionDataRow[]) => {
      return old.map((r: SubmissionDataRow) => {
        if (r.login in marks) {
          const leaderMark = marks[r.login]
          return {
            ...r,
            mark: leaderMark,
            subRows: r.subRows?.map((subRow) => {
              return {
                ...subRow,
                mark: subRow.login in marks ? marks[subRow.login] : leaderMark,
              }
            }),
          }
        }
        return r
      })
    })
  }

  function toggleMarksVisibility(visible: boolean) {
    return axiosInstance
      .patch(endpoints.emarkingExercise(exercise.year, exercise.moduleCode, exercise.number), {
        marks_published: visible,
      })
      .then(({ data }) => {
        let exercise = plainToInstance(Exercise, data)

        addToast({
          variant: 'success',
          title: `Your marks are ${
            exercise.marksHiddenToStudents
              ? visible
                ? 'now finalised'
                : 'now unfinalised'
              : visible
              ? 'now visible to students'
              : 'now hidden from students'
          } `,
        })

        return exercise
      })
      .catch(displayError('Unable to change marks visibility'))
  }

  function postExtension(extension: ExtensionPayload) {
    let payload = objectKeysToSnakeCase(extension)
    return axiosInstance
      .post(endpoints.extensions(exercise.year, exercise.moduleCode, exercise.number), payload)
      .then(({ data }) => {
        let newExtension = plainToInstance(Extension, data)
        setExtensionLookup((extensions) => ({
          ...extensions,
          [newExtension.studentUsername]: newExtension.revisedDeadline,
        }))
        addToast({ variant: 'success', title: 'Extension granted' })
      })
      .catch(displayError('Failed to grant extension'))
  }

  return {
    tableRows,
    lastSavedMarks: marksLookup,
    setTableRows,
    updateTableWithMarks,
    toggleMarksVisibility,
    postExtension,
  }
}
