import { instanceToPlain, plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { Mapping } from '../types/global'
import { Distribution, Exercise } from '../types/schemas/emarking'
import { useErrorMessage } from './errorMessage.service'

export interface DistributionConfiguration extends MarkersConfiguration {
  platform: string
  target_files: string | null
  add_blank_page: boolean
}

export interface MarkersConfiguration {
  markers?: string[]
  allocations?: Mapping<string, string>
}

export const useEmarkingDistribution = (exercise: Exercise) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { addToast } = useToast()
  const [distribution, setDistribution] = useState<Distribution>()
  const [distributionIsLoaded, setDistributionIsLoaded] = useState<boolean>(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.personalDistributions(exercise.year, exercise.moduleCode, exercise.number))
      .then(({ data }) => {
        setDistribution(plainToInstance(Distribution, data))
      })
      .catch((error) => {
        if (
          !(
            error.response.status === 404 &&
            error.response.data.message === 'No distribution found.'
          )
        )
          displayError('Unable to fetch emarking distributions')(error)
      })
      .finally(() => setDistributionIsLoaded(true))
  }, [exercise, axiosInstance, displayError])

  function createDistribution(payload: DistributionConfiguration) {
    setDistributionIsLoaded(false)
    return axiosInstance
      .post(endpoints.distributions(exercise.year, exercise.moduleCode, exercise.number), payload)
      .then(({ data }) => {
        setDistribution(plainToInstance(Distribution, data))
      })
      .catch(displayError('Unable to create emarking distribution'))
      .finally(() => setDistributionIsLoaded(true))
  }

  function deleteDistribution(distributionID: number) {
    setDistributionIsLoaded(false)
    axiosInstance
      .delete(endpoints.distribution(distributionID))
      .then(() => setDistribution(undefined))
      .catch(displayError('Error deleting the selected resources.'))
      .finally(() => setDistributionIsLoaded(true))
  }

  function uploadFeedback(url: string, payload: FormData) {
    axiosInstance
      .post(url, payload)
      .then(({ data }: { data: object | object[] }) => {
        setDistribution((distribution) => {
          let plainDistribution = instanceToPlain(distribution)
          return plainToInstance(Distribution, {
            ...plainDistribution,
            feedback: [...plainDistribution.feedback, ...(Array.isArray(data) ? data : [data])],
          })
        })
      })
      .catch(displayError('Error submitting feedback.'))
  }

  function reUploadFeedback(url: string, payload: FormData) {
    axiosInstance
      .put(url, payload)
      .then(({ data }) => {
        addToast({
          variant: 'success',
          title: 'Feedback re-uploaded',
        })
        setDistribution((distribution) => {
          let plainDistribution = instanceToPlain(distribution)
          return plainToInstance(Distribution, {
            ...plainDistribution,
            feedback: [...plainDistribution.feedback, data],
          })
        })
      })
      .catch(displayError('Error re-submitting feedback.'))
  }
  function uploadFeedbackFile(submissionId: number) {
    return (files: FileList) => {
      if (!distribution) return
      let formData = new FormData()
      formData.append('file', files[0])
      uploadFeedback(endpoints.newFeedback(distribution.id, submissionId), formData)
    }
  }
  function reUploadFeedbackFile(feedbackId: number) {
    return (files: FileList) => {
      if (!distribution) return
      let formData = new FormData()
      formData.append('file', files[0])
      reUploadFeedback(endpoints.feedback(distribution.id, feedbackId), formData)
    }
  }
  function uploadFeedbackBatch(marker: string) {
    return (files: FileList) => {
      if (!distribution) return
      let formData = new FormData()
      Array.from(files).forEach((f) => formData.append('files', f))
      uploadFeedback(endpoints.feedbackBatchUpload(distribution.id, marker), formData)
    }
  }

  function deleteFeedback(feedbackId: number) {
    if (!distribution) return
    axiosInstance
      .delete(endpoints.feedback(distribution.id, feedbackId))
      .then(({ data }) => {
        setDistribution((distribution) => {
          let plainDistribution = instanceToPlain(distribution)
          return plainToInstance(Distribution, {
            ...plainDistribution,
            feedback: plainDistribution.feedback.filter((f: any) => f.id !== feedbackId),
          })
        })
      })
      .catch(displayError('Error deleting feedback.'))
  }

  function toggleFeedbackVisibility(newVisibility: boolean) {
    if (!distribution) return
    return axiosInstance
      .patch(endpoints.distribution(distribution.id), {
        feedback_published: newVisibility,
      })
      .then(() => {
        addToast({
          variant: 'success',
          title: `Feedback now ${
            distribution.feedbackPublished ? 'hidden from' : 'visible to'
          } students`,
        })
        setDistribution((distribution) => {
          let plainDistribution = instanceToPlain(distribution)
          return plainToInstance(Distribution, {
            ...plainDistribution,
            feedback_published: !plainDistribution.feedback_published,
          })
        })
      })
      .catch(displayError('Error updating feedback visibility.'))
  }

  function refreshDistribution(payload: MarkersConfiguration) {
    if (!distribution) return
    axiosInstance
      .put(endpoints.distribution(distribution.id), payload)
      .then(({ data }) => {
        let updatedDistribution = plainToInstance(Distribution, data)
        if (updatedDistribution.submissions.length !== distribution.submissions.length) {
          setDistribution(updatedDistribution)
          addToast({
            variant: 'success',
            title: 'New submissions found: distribution updated.',
          })
        } else
          addToast({
            variant: 'info',
            title: 'There are no new submissions to pick up.',
          })
      })
      .catch(displayError('Error updating the distribution.'))
  }

  function reallocateSubmissions(marker: string, submitters: string[]) {
    if (!distribution) return
    const payload = submitters.map((s) => ({ marker, student: s }))
    axiosInstance
      .patch(endpoints.collatedSubmission(distribution.id), payload)
      .then(({ data }) => {
        let plainDistribution = instanceToPlain(distribution)
        let updatedDistribution = plainToInstance(Distribution, {
          ...plainDistribution,
          submissions: [
            ...plainDistribution.submissions.filter(
              (s: any) => !submitters.includes(s.student_username)
            ),
            ...data,
          ],
        })
        setDistribution(updatedDistribution)
      })
      .catch(displayError('Error re-allocating submissions.'))
  }

  return {
    distribution,
    distributionIsLoaded,
    deleteDistribution,
    setDistributionIsLoaded,
    actions: {
      create: createDistribution,
      refresh: refreshDistribution,
      reallocate: reallocateSubmissions,
      feedback: {
        uploadSingle: uploadFeedbackFile,
        reUploadSingle: reUploadFeedbackFile,
        uploadBatch: uploadFeedbackBatch,
        delete: deleteFeedback,
        toggleVisibility: toggleFeedbackVisibility,
      },
    },
  }
}
