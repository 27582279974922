import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { Module, ModuleBase } from '../types/schemas/abc'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useModules = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [modules, setModules] = useState<Module[]>([])
  const [modulesAreLoaded, setModulesAreLoaded] = useState(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.modules(year!))
      .then(({ data }) => {
        setModules(mapPlainToInstance(Module, data))
      })
      .catch(displayError('Failed to fetch module'))
      .finally(() => setModulesAreLoaded(true))
  }, [axiosInstance, displayError, year])

  return { modules, modulesAreLoaded }
}

export const usePublicModules = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [modules, setModules] = useState<ModuleBase[]>([])
  useEffect(() => {
    axiosInstance
      .get(endpoints.publicAllModules(year!))
      .then(({ data }) => {
        setModules(mapPlainToInstance(ModuleBase, data))
      })
      .catch(displayError('Failed to fetch module'))
  }, [axiosInstance, displayError, year])

  return { modules }
}
