import { Accordion, Content, Header, Item, Trigger } from '@radix-ui/react-accordion'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import { useUser } from '../../contextManagers/user.context'
import { usePhdStudent } from '../../hooks/phd.service'
import { Banner, Container, Div } from '../../styles/_app.style'
import { Caret } from '../../styles/collapsible-list.style'
import { StudentMilestone, Supervisor } from '../../types/phds'
import { EsaStage } from './EsaStage'
import { LsrStage } from './LsrStage'
import { PrrStage } from './PrrStage'
import { RpcStage } from './RpcStage'
import { SetSupervisors } from './SetSupervisors'
import { SubmissionStatusIcon } from './StatusIcons'

//
// const SubmissionStatusIcon = ({
//   submissionStatus,
//   stageStatus,
// }: {
//   submissionStatus: string
//   stageStatus: string
// }) => {
//   const props = { size: 22, title: submissionStatus }
//   const Pass = <Check2Circle color="green" {...props} />
//   const FailorRevise = <ExclamationCircleFill color="red" {...props} />
//   return stageStatus == 'future' ? (
//     <span></span>
//   ) : (
//     <span>{submissionStatus?.toUpperCase() == 'PASS' ? Pass : FailorRevise}</span>
//   )
// }
//
// export const TickOrCrossStatus = ({ reportSubmitted }: { reportSubmitted: boolean }) => {
//   const props = { size: 22 }
//
//   return reportSubmitted ? (
//     <Check2Square color="green" style={{ paddingTop: '5px' }} {...props} />
//   ) : (
//     <XCircle color="red" style={{ paddingTop: '5px' }} {...props} />
//   )
// }
//
// export const SubmissionStatus = ({
//   submissionStatus,
//   stageStatus,
// }: {
//   submissionStatus: string | null
//   stageStatus: string
// }) => {
//   const props = { size: 22, title: submissionStatus ?? 'OK' }
//
//   return stageStatus == 'future' ? (
//     <span>Future</span>
//   ) : (
//     <span>
//       {submissionStatus?.toUpperCase() == 'PASS' ? (
//         <span>
//           <strong>Pass</strong>{' '}
//           <Check2Square color="green" style={{ paddingTop: '5px' }} {...props} />
//         </span>
//       ) : (
//         <span>
//           <strong>{submissionStatus}</strong>{' '}
//           <XCircle color="red" style={{ paddingTop: '5px' }} {...props} />
//         </span>
//       )}
//     </span>
//   )
// }

interface Relationship {
  staff_login: string
  supervisors: Supervisor[] | undefined
}

const getStaffStudentRelationship = (relationship: Relationship): String => {
  const result = relationship.supervisors!.find(({ login }) => login === relationship.staff_login)
  if (result?.role === undefined) {
    return 'None'
  }
  return result?.role
}

const isSupervisorToStudent = (relationship: Relationship): boolean => {
  const rel: String = getStaffStudentRelationship(relationship)

  return rel.includes('Supervisor')
}

const isAssessorToStudent = (relationship: Relationship): boolean => {
  const rel: String = getStaffStudentRelationship(relationship)

  return rel.includes('Assessor')
}

export const PhdStudentDetails = () => {
  const { phdStudent, phdDetailLoading } = usePhdStudent()

  const { userDetails } = useUser()
  if (!userDetails) return <Banner>Could not load your personal details.</Banner>

  const expandedStatuses: string[] = ['ESA']

  const pageTitle: string = `Details for ${phdStudent?.firstName} ${phdStudent?.lastName}`

  const stageComponent = (stageCode: string, stageMilestone: StudentMilestone) => {
    const relationships: Relationship = {
      staff_login: userDetails.login,
      supervisors: phdStudent?.supervisors,
    }

    const isSupervisor: boolean = isSupervisorToStudent(relationships)
    const isAssessor: boolean = isAssessorToStudent(relationships)
    switch (stageCode) {
      case 'RPC': {
        return <RpcStage studentLogin={phdStudent?.login!} stage={stageMilestone} />
      }
      case 'ESA': {
        return (
          <EsaStage
            studentLogin={phdStudent?.login!}
            isSupervisor={isSupervisor}
            isAssessor={isAssessor}
            stage={stageMilestone}
          />
        )
      }
      case 'LSR': {
        return (
          <LsrStage
            studentLogin={phdStudent?.login!}
            isSupervisor={isSupervisor}
            isAssessor={isAssessor}
            stage={stageMilestone}
          />
        )
      }
      case 'PRR': {
        return <PrrStage stage={stageMilestone} studentLogin={phdStudent?.login!} />
      }
      default: {
        return <h1>Unknown stage</h1>
      }
    }
  }

  return (
    <Container expandX>
      {phdDetailLoading ? (
        <div>Loading</div>
      ) : (
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <h1>{pageTitle}</h1>

          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              border: '2px solid $sand8',
              borderRadius: '1rem',
              padding: '1rem',
              width: '50%',
              marginBottom: '2rem',
            }}
          >
            <ul>
              <li>CID: {phdStudent?.cid}</li>
              <li>Login: {phdStudent?.login}</li>
              <li>Email: {phdStudent?.emailAddress}</li>
              <li>PhD Title: {phdStudent?.phdTitle}</li>
            </ul>
          </Div>

          <SetSupervisors
            studentLogin={phdStudent?.login!}
            isSupervisor={true}
            isAssessor={false}
          />

          <Accordion type="multiple" defaultValue={expandedStatuses}>
            {phdStudent?.milestones
              .filter((row) => row.code === 'ESA')
              .map((row, idx) => (
                <Item value={row.code} key={idx}>
                  <Header>
                    <span
                      style={{
                        fontSize: '0.8em',
                        width: '60%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                      }}
                    >
                      <span
                        style={{
                          flexBasis: '5%',
                        }}
                      >
                        <Trigger
                          style={{
                            backgroundColor: 'transparent',
                            background: 'transparent',
                            border: '0',
                          }}
                        >
                          <Caret />
                        </Trigger>
                      </span>
                      <span
                        style={{
                          flexBasis: '70%',
                        }}
                      >
                        {`${row.name} (${row.code})`}
                      </span>
                      <span
                        style={{
                          flexBasis: '25%',
                        }}
                      >
                        <SubmissionStatusIcon
                          submissionStatus={row.status}
                          stageStatus={row.status}
                        ></SubmissionStatusIcon>
                      </span>
                    </span>
                  </Header>
                  <Content>{stageComponent(row.code, row)}</Content>
                </Item>
              ))}
          </Accordion>
        </div>
      )}
    </Container>
  )
}
