import React from 'react'
import { Check2Circle, Check2Square, ExclamationCircleFill, XCircle } from 'react-bootstrap-icons'

export const SubmissionStatusIcon = ({
  submissionStatus,
  stageStatus,
}: {
  submissionStatus: string
  stageStatus: string
}) => {
  const props = { size: 22, title: submissionStatus }
  const Pass = <Check2Circle color="green" {...props} />
  const FailorRevise = <ExclamationCircleFill color="red" {...props} />
  return stageStatus == 'future' ? (
    <span></span>
  ) : (
    <span>{submissionStatus?.toUpperCase() == 'PASS' ? Pass : FailorRevise}</span>
  )
}

export const TickOrCrossStatus = ({ reportSubmitted }: { reportSubmitted: boolean }) => {
  const props = { size: 22 }

  return reportSubmitted ? (
    <Check2Square color="green" style={{ paddingTop: '5px' }} {...props} />
  ) : (
    <XCircle color="red" style={{ paddingTop: '5px' }} {...props} />
  )
}

export const SubmissionStatus = ({
  submissionStatus,
  stageStatus,
}: {
  submissionStatus: string | null
  stageStatus: string
}) => {
  const props = { size: 22, title: submissionStatus ?? 'OK' }

  return stageStatus == 'future' ? (
    <span>Future</span>
  ) : (
    <span>
      {submissionStatus?.toUpperCase() == 'PASS' ? (
        <span>
          <strong>Pass</strong>{' '}
          <Check2Square color="green" style={{ paddingTop: '5px' }} {...props} />
        </span>
      ) : (
        <span>
          <strong>{submissionStatus}</strong>{' '}
          <XCircle color="red" style={{ paddingTop: '5px' }} {...props} />
        </span>
      )}
    </span>
  )
}
