import confetti from 'canvas-confetti'
import { plainToInstance } from 'class-transformer'
import { useCallback, useContext, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { Exercise, ExerciseSubmission, WorkloadSurveyResponse } from '../types/schemas/emarking'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useExerciseForStudent = (exercise: Exercise) => {
  const axiosInstance = useContext(AxiosContext)
  const { addToast } = useToast()
  const displayError = useErrorMessage()

  const [submittedFiles, setSubmittedFiles] = useState<ExerciseSubmission[]>([])
  const loadSubmittedFiles = useCallback(() => {
    axiosInstance
      .get(endpoints.submissions(exercise.year, exercise.moduleCode, exercise.number))
      .then(({ data }) => {
        setSubmittedFiles(mapPlainToInstance(ExerciseSubmission, data))
      })
      .catch(displayError('Error fetching submitted files'))
  }, [axiosInstance, displayError, exercise])

  const submitFile = (totalFilesToSubmit: number) => (file: File, targetFileName: string) => {
    let formData = new FormData()
    formData.append('file', new File([file], targetFileName))
    axiosInstance
      .post(endpoints.submissions(exercise.year, exercise.moduleCode, exercise.number), formData)
      .then(({ data }: { data: ExerciseSubmission }) => {
        const submittedFile = plainToInstance(ExerciseSubmission, data)
        addToast({
          variant: 'success',
          title: `${targetFileName} submitted successfully.`,
        })
        if (totalFilesToSubmit === submittedFiles.length + 1) setTimeout(confetti, 330)
        setSubmittedFiles((prevFiles) => [
          ...prevFiles.filter((file) => file.targetFileName !== targetFileName),
          submittedFile,
        ])
      })
      .catch(displayError('Error submitting file.'))
  }

  const deleteFile = (submissionId: number) => {
    axiosInstance
      .delete(
        endpoints.submission(exercise.year, exercise.moduleCode, exercise.number, submissionId)
      )
      .then(() => {
        addToast({ variant: 'info', title: `File deleted successfully.` })
        setSubmittedFiles((submittedFiles) =>
          submittedFiles.filter((submission) => submission.id !== submissionId)
        )
      })
      .catch(displayError("Can't delete submitted file"))
  }

  const [workloadSurveyResponse, setWorkloadSurveyResponse] =
    useState<WorkloadSurveyResponse | null>(exercise.workloadSurveyResponse)

  function submitNewWorkloadSurveyResponse(response: keyof typeof WorkloadSurveyResponse) {
    axiosInstance
      .post(endpoints.workloadSurveys(exercise.year, exercise.moduleCode, exercise.number), {
        response: response,
      })
      .then(({ data }) => {
        addToast({ variant: 'info', title: `Response submitted successfully.` })
        setWorkloadSurveyResponse(plainToInstance(WorkloadSurveyResponse, data))
      })
      .catch(displayError('Error submitting response'))
  }

  function updateWorkloadSurveyResponse(response: keyof typeof WorkloadSurveyResponse) {
    if (!exercise.workloadSurveyResponse) return
    axiosInstance
      .put(
        endpoints.workloadSurvey(
          exercise.year,
          exercise.moduleCode,
          exercise.number,
          exercise.workloadSurveyResponse.id
        ),
        { response: response }
      )
      .then(({ data }) => {
        addToast({ variant: 'info', title: `Response submitted successfully.` })
        setWorkloadSurveyResponse(plainToInstance(WorkloadSurveyResponse, data))
      })
      .catch(displayError('Error submitting response'))
  }

  function submitWorkloadSurveyResponse(response: keyof typeof WorkloadSurveyResponse) {
    return !exercise.workloadSurveyResponse
      ? submitNewWorkloadSurveyResponse(response)
      : updateWorkloadSurveyResponse(response)
  }

  return {
    workloadSurvey: {
      response: workloadSurveyResponse?.response,
      submit: submitWorkloadSurveyResponse,
    },
    submittedFiles,
    submitFile,
    deleteFile,
    loadSubmittedFiles,
  }
}
