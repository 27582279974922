import React from 'react'
import { Helmet } from 'react-helmet-async'

import { PhdStudentsTable } from '../components/phd/PhdStudentsTable'
import { usePhdStudents } from '../hooks/phd.service'
import { Banner, Container } from '../styles/_app.style'

const PhdDashboard = () => {
  const { phdStudents, phdStudentsLoaded } = usePhdStudents()

  return (
    <>
      <Container>
        <Helmet>
          <title>PHD Tracker</title>
        </Helmet>
        <h1>PhD Tracking Dashboard</h1>
        {!phdStudentsLoaded && <Banner>Loading PhD students data...</Banner>}
        {phdStudentsLoaded && phdStudents.length === 0 && (
          <Banner>No PhD students data available.</Banner>
        )}
      </Container>
      {phdStudentsLoaded && phdStudents.length > 0 && (
        <Container section expandX>
          <PhdStudentsTable phdStudents={phdStudents} />
        </Container>
      )}
    </>
  )
}

export default PhdDashboard
