import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useErrorMessage } from './errorMessage.service'

export const useProfileImage = (year: string, username: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [imageBlob, setImageBlob] = useState<Blob | null>(null)

  useEffect(() => {
    axiosInstance
      .get(endpoints.profileImage(year, username), { responseType: 'blob' })
      .then((response) => {
        setImageBlob(response.data)
      })
      .catch(() => {
        displayError('Unable to load profile image')
        setImageBlob(null)
      })
  }, [axiosInstance, displayError, year, username])
  return { imageBlob }
}
