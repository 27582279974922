import { differenceInBusinessDays, differenceInCalendarWeeks, isMonday } from 'date-fns'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { MainBackground } from '../components/timeline/MainBackground'
import MobileTimeline from '../components/timeline/MobileTimeline'
import { Modules } from '../components/timeline/Modules'
import { Switcher } from '../components/timeline/Switcher'
import { Tracks } from '../components/timeline/Tracks'
import { Weeks } from '../components/timeline/Weeks'
import { INDEXING_OFFSET, NAVIGATION_HEIGHT } from '../constants/global'
import titles from '../constants/titles'
import { useUser } from '../contextManagers/user.context'
import { useResponsiveness } from '../hooks/breakpoints.service'
import { UseTimelineVars, useTimeline } from '../hooks/timeline.service'
import { Area, BaseViewPort, Corner, Scrollbar, Thumb } from '../styles/_app.style'
import { DesktopTimelineContainer } from '../styles/timeline/desktopTimeline.style'

// differenceInCalendarWeeks returns number of weekends in between the 2 dates
export function dateToColumn(date: Date, startDate: Date): number {
  if (!isMonday(startDate)) throw new Error('Parameter startDate MUST be a monday.')
  if (date < startDate) return -1
  return (
    INDEXING_OFFSET +
    differenceInBusinessDays(date, startDate) +
    differenceInCalendarWeeks(date, startDate, { weekStartsOn: 1 })
  )
}

/* Top margin to position the scroll area 1rem right under the navigation bar */
const TOP_MARGIN = `(${NAVIGATION_HEIGHT})`

/* The timeline's laid out in four sections: switcher, weeks, modules and main grid. The main grid is a stack of three
 * components: events, indicator and rows; note that the layers are ordered based on their relative heights.
 */
const Timeline = () => {
  const { userDetails } = useUser()
  const {
    terms,
    term,
    modulesForTerm,
    trackMapForTerm,
    rowHeights,
    setTerm,
    setModulesCohortFilter,
  }: UseTimelineVars = useTimeline()
  const { year } = useParams()
  const { isMobileLandscape } = useResponsiveness()

  return isMobileLandscape ? (
    <>
      <Helmet>
        <title>{titles.timeline(year, term?.name, userDetails?.cohortName)}</title>
      </Helmet>
      {term ? (
        <>
          <Area
            css={{
              height: `calc(100vh - ${TOP_MARGIN})`,
              marginTop: `calc${TOP_MARGIN}`,
            }}
          >
            <BaseViewPort>
              <DesktopTimelineContainer>
                <Switcher
                  term={term.name}
                  setTerm={setTerm}
                  terms={terms}
                  onModuleCohortFilterChange={setModulesCohortFilter}
                />
                <Weeks start={term.start} weeks={term.weeks} />
                <Modules modules={modulesForTerm} rowHeights={rowHeights} />
                <Tracks term={term} weeks={term.weeks} trackMap={trackMapForTerm} />
                <MainBackground cols={term.weeks} rowHeights={rowHeights} />
              </DesktopTimelineContainer>
            </BaseViewPort>
            <Scrollbar orientation="vertical">
              <Thumb />
            </Scrollbar>
            <Scrollbar orientation="horizontal">
              <Thumb />
            </Scrollbar>
            <Corner />
          </Area>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  ) : (
    <MobileTimeline trackMap={trackMapForTerm} />
  )
}

export default Timeline
