import React, { useContext, useRef, useState } from 'react'

import { ThemeContext } from '../../contextManagers/theme.context'
import { Button, Div } from '../../styles/_app.style'
import { StudentMilestone } from '../../types/phds'
import Tooltip from '../tooltip/Tooltip'

interface stageProps {
  studentLogin: string
  stage: StudentMilestone
}

export const PrrStage = ({ studentLogin, stage }: stageProps) => {
  const [currentFile, setCurrentFile] = useState<File>()
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentFile(selectedFiles?.[0])
  }

  const handleFileUpload = () => {
    console.log('Implement immediately')
    if (!currentFile) return

    // submitFile(studentLogin, 'rpc', currentFile, currentFile.name)
  }

  const [assessorFeedback, setAssessorFeedback] = useState<string | undefined>('xx')
  const [supervisorFeedback, setSupervisorFeedback] = useState<string | undefined>('xx')
  const assessorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const supervisorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const assessorRef = useRef<HTMLInputElement>(null)
  const secondRef = useRef<HTMLInputElement>(null)
  const { theme } = useContext(ThemeContext)
  const [milestoneStatus, setMilestoneStatus] = useState<string>('')
  console.log(milestoneStatus)
  const [startDate, setStartDate] = useState('')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
        }}
      >
        <p>Date Submitted: {stage.dateSubmitted}</p>
        <Tooltip label={'View submission'}>
          <Button>View submission</Button>
        </Tooltip>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
        }}
      >
        <p>Ready to write up:</p>
      </div>
    </Div>
  )
}
