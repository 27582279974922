import { addWeeks } from 'date-fns'

import { WeeksArea } from '../../styles/timeline/gridAreas'
import { Week } from './Week'

export const Weeks = ({ start, weeks }: { start: Date; weeks: number }) => (
  <WeeksArea css={{ gridTemplateColumns: `repeat(${weeks}, 15rem)` }}>
    {[...Array(weeks)].map((_, week) => (
      <Week key={week} start={addWeeks(start, week)} week={week} />
    ))}
  </WeeksArea>
)
