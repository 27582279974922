import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { PhDAdminUsers } from '../../constants/phdTracking'
import { useUser } from '../../contextManagers/user.context'

const DEFAULT_TARGET = '/404'

export const StaffAndPhDOnlyRoute = ({ to }: { to: ReactNode }) => {
  const { userDetails } = useUser()
  return userDetails?.isStaff || userDetails?.isPHDStudent ? (
    <>{to}</>
  ) : (
    <Navigate to={DEFAULT_TARGET} replace />
  )
}

export const PhDStudentOnlyRoute = ({ to }: { to: ReactNode }) => {
  const { userDetails } = useUser()
  return userDetails?.isPHDStudent ? <>{to}</> : <Navigate to={DEFAULT_TARGET} replace />
}

export const PhDAdminOnlyRoute = ({ to }: { to: ReactNode }) => {
  const { userDetails } = useUser()
  if (!userDetails) return <Navigate to={DEFAULT_TARGET} replace />

  return PhDAdminUsers.includes(userDetails.login) ? (
    <>{to}</>
  ) : (
    <Navigate to={DEFAULT_TARGET} replace />
  )
}
