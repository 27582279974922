import { styled } from './stitches.config'

export const Pill = styled('span', {
  background: '$blue4',
  fontSize: '$sm',
  borderRadius: '16px',
  padding: '4px 12px',
  display: 'inline-block',
  width: 'fit-content',
  margin: 0,
  textAlign: 'center',
})
