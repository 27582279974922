import * as Collapsible from '@radix-ui/react-collapsible'
import {
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@radix-ui/react-dropdown-menu'
import type { ComponentProps } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { styled, theme } from './stitches.config'

export const ScientiaTitle = styled(Link, {
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

/** Custom NavLink component to handle styles for active links  */
export const NavLinkWithActiveStyles = (props: ComponentProps<typeof NavLink>) => (
  <NavLink
    {...props}
    style={({ isActive }) => ({
      backgroundColor: isActive ? theme.colors.elementActive.value : undefined,
      ...props.style,
    })}
  >
    {props.children}
  </NavLink>
)

export const ReactRouterShortcutLink = styled(NavLinkWithActiveStyles, {
  height: '2.75rem',
  borderRadius: '0.5rem',
  fontSize: 'medium',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.75rem',
  '&:hover': {
    backgroundColor: '$elementHover',
  },
})

const itemStyle = {
  borderRadius: '0.5rem',
  height: '2.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '0.5rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$neutral3',
    color: '$sand12',
  },
  variants: {
    link: {
      true: {
        color: '$sand11',
        backgroundColor: 'transparent',
        textUnderlineThickness: '2px',
        textUnderlineOffset: '4px',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus-visible': {
          color: '$sand12',
          textDecoration: 'underline',
          outline: 'none',
        },
      },
    },
  },
}

export const DropdownIcon = styled('div', {
  padding: '0.75rem',
  svg: { display: 'block', size: 20 },
})

export const Header = styled('div', {
  height: '$navHeight',
  width: '100%',

  /* Make sure that the header is fixed and above every component */
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,

  display: 'flex',
  alignContent: 'center',

  padding: '0',
  borderBottom: '0.0625rem solid $separator',
  backgroundColor: '$appBackground',
})

export const Nav = styled('nav', {
  maxWidth: '$appMainWidth',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  padding: '0 1rem',
  margin: 'auto',
})

export const Logo = styled('img', {
  height: '2rem',
})

export const WebsiteTitle = styled('span', {
  margin: '0 0.5rem',
  fontSize: '$xl',
  fontWeight: 600,
})

export const Item = styled(DropdownMenuItem, itemStyle)

export const CheckboxItem = styled(DropdownMenuCheckboxItem, itemStyle)

export const Separator = styled(DropdownMenuSeparator, {
  height: '0.0625rem',
  margin: '0.25rem',
  backgroundColor: '$separator',
})

export const VerticalRule = styled('div', {
  borderLeft: '1px solid $neutral7',
  height: '100%',
  margin: '0 0.5rem',
})

export const YearSwitcherWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '0.5rem',
  fontSize: '$md',
  alignItems: 'center',
  verticalAlign: 'middle',
})

export const YearArrow = styled('button', {
  display: 'flex',
  width: 'fit-content',
  padding: '0.75rem',
  background: 'transparent',
  border: 'none',
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '$sm',
    backgroundColor: '$neutral3',
  },
  variants: {
    disabled: {
      true: {
        visibility: 'hidden',
      },
    },
  },
})

export const ExpandedNavContainer = styled(Collapsible.Content, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  left: '0',
  top: '$navHeight',
  width: '100%',
  backgroundColor: '$appBackground',
  color: 'white',
  overflowY: 'scroll',
  maxHeight: `calc(100vh - $navHeight)`,
  // Add a dropshadow
  boxShadow: '0px 24px 56px -8px rgba(0, 0, 0, 0.25)',
})

const NAVBAR_ITEM_HEIGHT = '3.25rem'
const expandedNavItemStyle = {
  display: 'flex',
  height: NAVBAR_ITEM_HEIGHT,
  padding: '0rem 0.75rem',
  alignItems: 'center',
  gap: '0.625rem',
  alignSelf: 'stretch',

  borderBottom: '1px solid',
  borderBottomColor: '$separator',

  '&:hover, &:active': {
    backgroundColor: '$neutral4',
    transition: 'background-color 0.3s ease-in-out',
  },
}
export const ReactExpandedNavItem = styled(NavLinkWithActiveStyles, expandedNavItemStyle)
export const ExternalExpandedNavItem = styled('a', expandedNavItemStyle)
export const ExpandedNavItem = styled('div', expandedNavItemStyle)

export const IconTouchTargetExpander = styled('div', {
  width: NAVBAR_ITEM_HEIGHT,
  height: NAVBAR_ITEM_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover, &:active': {
    backgroundColor: '$elementHover',
    transition: 'background-color 0.3s ease-in-out',
  },
})

export const CollapsibleNavTrigger = styled(Collapsible.Trigger, {
  borderRadius: '0.5rem',
  '&:hover, &:active': {
    backgroundColor: '$elementHover',
    transition: 'background-color 0.3s ease-in-out',
  },
  "&[aria-expanded='true']": {
    backgroundColor: '$elementActive',
  },
})

export const ExpandedNavSectionHeader = styled('h4', {
  marginTop: '1.25rem',
  marginBottom: '1rem',
})
