import React from 'react'

import { ListBlock, ListItem, ListItemContainer, ListItemMainText } from '../../styles/list.style'
import { Deliverable, LABTS_SUBMISSION } from '../../types/schemas/emarking'
import { Tabs } from '../Tabs'

const DeliverablesList = ({ deliverables }: { deliverables: Deliverable[] }) => {
  return (
    <ListBlock>
      <Tabs
        attribute={'name'}
        data={deliverables}
        generator={({ name, type }) => {
          let label = type === LABTS_SUBMISSION ? 'GitLab Hash (via LabTS)' : name
          return (
            <ListItemContainer>
              <ListItem>
                <ListItemMainText>{label}</ListItemMainText>
              </ListItem>
            </ListItemContainer>
          )
        }}
      />
    </ListBlock>
  )
}

export default DeliverablesList
