import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { differenceInCalendarWeeks, isWithinInterval } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { Check2Circle, ExclamationCircleFill } from 'react-bootstrap-icons'
import { Link, useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  CellValues,
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import Tooltip from '../../components/tooltip/Tooltip'
import { TUTORIAL_GROUP_TYPES } from '../../constants/global'
import { useAllStudents } from '../../hooks/allStudents.service'
import { useTerms } from '../../hooks/terms.service'
import { useTutorialSessions } from '../../hooks/tutorialSessions.service'
import { Banner, Button } from '../../styles/_app.style'
import { Main, Section, TableSection } from '../../styles/root.style'
import { StudentShortInfo } from '../../types/schemas/abc'
import { capitalizeEachWord, formatShortYear, toLookupTable } from '../../utils'

type Attendance = boolean | null

type AttendanceRow = {
  login: string
  name: string
  attendances: Attendance[]
}

const AttendanceIconCell = ({ attendance }: { attendance: Attendance }) => {
  if (attendance === null) return <span>-</span>
  const Icon = attendance ? Check2Circle : ExclamationCircleFill
  const colour = attendance ? 'green' : 'red'
  return <Icon color={colour} size={22} />
}

const TutorialAttendanceDashboard = () => {
  const terms = useTerms()
  const { year } = useParams()

  const { tutorialSessions } = useTutorialSessions(year as string)
  const { allStudents, allStudentsAreLoaded } = useAllStudents(year as string)
  const [currentView, setCurrentView] = useState(TUTORIAL_GROUP_TYPES[0])

  // We are only interested in Autumn and Spring terms -------------------------------
  const termLookup = useMemo(() => toLookupTable(terms, 'name'), [terms])
  const relevantTerms = useMemo(
    () => terms.filter((term) => /(autumn|spring) term/.test(term.name)),
    [terms]
  )
  const weekToIndex = useCallback(
    (term, week) => (/autumn/.test(term.name) ? week : week + termLookup.get('autumn term')!.weeks),
    [termLookup]
  )
  // ---------------------------------------------------------------------------------

  const columnHelper = createColumnHelper<AttendanceRow>()
  const columns = useMemo(
    () => [
      columnHelper.group({
        header: ' ',
        columns: [
          columnHelper.accessor((row) => row.login, {
            id: 'login',
            cell: (info) => {
              return (
                <Link
                  title={`link to ${info.getValue()} profile`}
                  style={{ textDecoration: 'underline' }}
                  to={`/${year as string}/students/${info.getValue()}`}
                >
                  {info.getValue()}
                </Link>
              )
            },
            header: 'Login',
          }),

          columnHelper.accessor((row) => row.name, createPlainColumn<AttendanceRow>('Name')),
        ],
      }),
      ...relevantTerms.map((term) =>
        columnHelper.group({
          header: capitalizeEachWord(term.name),
          columns: [...Array(term.weeks).keys()].map((weekNumber) =>
            columnHelper.accessor((row) => row.attendances, {
              id: `${term.name.replace(' ', '-')}-w${weekNumber + 1}`,
              cell: (info) => {
                let index = weekToIndex(term, weekNumber)
                return <AttendanceIconCell attendance={info.getValue()[index]} />
              },
              header: `w${weekNumber + 1}`,
              meta: {
                textIsCentred: true,
              },
            })
          ),
        })
      ),
    ],
    [columnHelper, relevantTerms, weekToIndex]
  )

  const data = useMemo(() => {
    function dateToIndex(inputDate: Date): number {
      const term = relevantTerms.find((t) =>
        isWithinInterval(inputDate, { start: t.start, end: t.end })
      )
      if (!term) return -1
      const weekNumber = differenceInCalendarWeeks(inputDate, term.start, { weekStartsOn: 1 })
      return weekToIndex(term, weekNumber)
    }

    if (relevantTerms.length !== 2 || allStudents.length === 0) return []

    const studentLookup = toLookupTable(allStudents, 'login')

    // 1. Create a mapping username -> list of attendances
    // with the latter pre-populated with (autumn-term #weeks + spring-term #weeks)x null values
    const maxAttendances = relevantTerms.reduce((acc, t) => acc + t.weeks, 0)
    const attendancesLookUp: Map<string, Attendance[]> = new Map(
      allStudents
        .filter((s: StudentShortInfo) => /[a-z]1/.test(s.cohort))
        .map((s) => [s.login, new Array(maxAttendances).fill(null)])
    )

    // 2. For the students whose tutorial session attendance was recorded,
    // adjust the mapped list of attendances from (1) accordingly
    const relevantSessions = tutorialSessions.filter((s) =>
      new RegExp(`${currentView} \\d+`).test(s.group)
    )
    for (let session of relevantSessions) {
      for (let attendance of session.attendances) {
        let mappedAttendances = attendancesLookUp.get(attendance.username)

        // Catch the unlikely (?) case in which
        // 'attendance.username' was not mapped in attendancesLookup
        mappedAttendances ??= new Array(maxAttendances).fill(null)
        mappedAttendances[dateToIndex(session.date)] = attendance.present!
        attendancesLookUp.set(attendance.username, mappedAttendances)
      }
    }

    // The outcome is a list of objects associating a username to a list of attendances with a value
    // for each week of the autumn and spring term. Possible values are:
    // - null for no attendance recorded
    // - false for absence
    // - true for presence
    return [...attendancesLookUp.entries()].map(([login, attendances]) => {
      let student = studentLookup.get(login)
      return {
        login,
        name: allStudentsAreLoaded ? student?.fullName ?? CellValues.NOT_FOUND : CellValues.LOADING,
        attendances,
      }
    })
  }, [allStudents, currentView, relevantTerms, tutorialSessions, weekToIndex])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Main>
      <PageHeader title="Year 1 Tutorial Attendance Dashboard">
        <p>
          <b>This is an overview of Y1 tutorial attendance for {formatShortYear(year)}.</b>
        </p>
      </PageHeader>
      <Section>
        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginBottom: '1rem' }}>
          {TUTORIAL_GROUP_TYPES.map((tutorialGroupType) => (
            <Tooltip label={`Filter by ${tutorialGroupType}`}>
              <Button
                key={tutorialGroupType}
                css={{ minWidth: '9rem', flex: '1 1 9rem', margin: 0 }}
                active={currentView === tutorialGroupType}
                onClick={() => setCurrentView(tutorialGroupType)}
                animate
              >
                {tutorialGroupType}
              </Button>
            </Tooltip>
          ))}
        </div>
        {!allStudentsAreLoaded ? (
          <Banner>Loading data...</Banner>
        ) : (
          allStudents.length === 0 && <Banner>No students to show.</Banner>
        )}
      </Section>
      {allStudentsAreLoaded && allStudents.length > 0 && (
        <TableSection expanded>
          <GenericTanStackTableRenderer table={table} size="wide" />
        </TableSection>
      )}
    </Main>
  )
}

export default TutorialAttendanceDashboard
