import React, { useState } from 'react'

import { Button, Div } from '../../styles/_app.style'
import { StudentMilestone } from '../../types/phds'
import Tooltip from '../tooltip/Tooltip'

interface stageProps {
  studentLogin: string

  stage: StudentMilestone
}

export const RpcStage = ({
  studentLogin,

  stage,
}: stageProps) => {
  const [currentFile, setCurrentFile] = useState<File>()
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentFile(selectedFiles?.[0])
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p>File Submitted : {'spoofedFile.pdf'}</p>
        <Tooltip label={'View RPC document'}>
          <Button>View RPC document</Button>
        </Tooltip>
      </div>

      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>Second Supervisor:</span>
        <span>...</span>
      </div>
    </Div>
  )
}
