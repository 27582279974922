import { CheckboxProps } from '@radix-ui/react-checkbox'
import React from 'react'
import { Check, Dash } from 'react-bootstrap-icons'

import { Checkbox, Indicator } from '../styles/_app.style'

interface IndeterminateCheckboxProps {
  checked: boolean | 'indeterminate'
  onCheckedChange: (checked: boolean | 'indeterminate') => void
  className?: string
}

export const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps & CheckboxProps> = ({
  checked,
  onCheckedChange,
  ...rest
}) => {
  return (
    <Checkbox checked={checked} onCheckedChange={onCheckedChange} {...rest}>
      <Indicator>
        {checked === 'indeterminate' ? (
          <Dash aria-label={'indeterminate-icon'} />
        ) : (
          <Check aria-label={'selected-icon'} />
        )}
      </Indicator>
    </Checkbox>
  )
}
