import { Accordion, Item } from '@radix-ui/react-accordion'
import { useEffect, useMemo, useState } from 'react'
import { PlusLg } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'

import PageHeader from '../components/common/PageHeader'
import TermPill from '../components/records/TermPill'
import Select from '../components/select/Select'
import TutorialWeekAttendance from '../components/tables/TutorialWeekAttendance'
import Tooltip from '../components/tooltip/Tooltip'
import { useUser } from '../contextManagers/user.context'
import { useTerms } from '../hooks/terms.service'
import { useTutorialGroups, useTutorialSessions } from '../hooks/tutorialGroups.service'
import { Banner, Button, Container, Wrapper } from '../styles/_app.style'
import { CardHeader } from '../styles/card.style'
import { Caret, Content, Header, Trigger } from '../styles/collapsible-list.style'
import { Main, Section } from '../styles/root.style'
import { TutorialGroup } from '../types/schemas/abc'
import { tutorialSessionTitle } from '../utils'

const AttendanceTracking = () => {
  const terms = useTerms()
  const { year } = useParams()
  const { userDetails } = useUser()
  const [openedSessions, setOpenedSessions] = useState<string[]>([])

  const { tutorialGroups } = useTutorialGroups(year as string)
  const [selectedGroup, setSelectedGroup] = useState<TutorialGroup | undefined>(undefined)

  const relevantTutorialGroups = useMemo(() => {
    if (!userDetails) return []
    return tutorialGroups.filter((t) => t.isUta(userDetails.login) || t.isTutor(userDetails.login))
  }, [tutorialGroups, userDetails])

  const tutorialGroupOptions = useMemo(
    () =>
      relevantTutorialGroups.map((group) => ({
        value: group.toString(),
        label: group.toString(),
      })),
    [relevantTutorialGroups]
  )

  const studentsLookup = useMemo(() => {
    const studentsMap = new Map<string, any>()
    if (selectedGroup) {
      selectedGroup.members.forEach((member) => {
        studentsMap.set(member.login, member)
      })
    }
    return studentsMap
  }, [selectedGroup])

  const { sessions, actions } = useTutorialSessions(year as string, selectedGroup)

  const handleGroupChange = (newValue: any) => {
    if (newValue)
      setSelectedGroup(relevantTutorialGroups.find((g) => g.toString() === newValue.value))
    setOpenedSessions([])
  }

  // Auto select the first group if none selected
  useEffect(() => {
    if (
      relevantTutorialGroups &&
      relevantTutorialGroups.length > 0 &&
      selectedGroup === undefined
    ) {
      setSelectedGroup(relevantTutorialGroups[0])
    }
  }, [relevantTutorialGroups, selectedGroup, setSelectedGroup])

  const TutorialGroupActionBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '1rem',
        }}
      >
        <Select
          options={tutorialGroupOptions}
          value={tutorialGroupOptions.find((o) => o.value === selectedGroup?.toString())}
          onChange={handleGroupChange}
          placeholder="Tutorial group"
        />
        <Tooltip label={'Add new session attendance record'}>
          <Button icon active onClick={actions.addSession}>
            <PlusLg />
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <Main>
      <PageHeader title="Tutorial Attendance">
        <p>
          <b>Here's the full attendance record for your tutorial groups.</b>
        </p>
      </PageHeader>
      <Section>
        {relevantTutorialGroups.length > 0 ? (
          <TutorialGroupActionBar />
        ) : (
          <Banner>No tutorial groups assigned to you yet.</Banner>
        )}
      </Section>
      <Section>
        {relevantTutorialGroups.length > 0 && (
          <Accordion type="multiple" value={openedSessions} onValueChange={setOpenedSessions}>
            {sessions.map((session) => (
              <Item value={session.id.toString(10)} key={session.id}>
                <Header>
                  <Trigger>
                    <Caret />
                    <Container inlineHeaderWithButtons={true}>
                      <CardHeader>{tutorialSessionTitle(session.date, terms)}</CardHeader>
                      <TermPill terms={terms} date={session.date} />
                    </Container>
                  </Trigger>
                </Header>
                <Content>
                  <Wrapper
                    css={{
                      padding: '0.5rem 0 0.5rem 0.5rem',
                      flexGrows: 1,
                    }}
                  >
                    <TutorialWeekAttendance
                      session={session}
                      terms={terms}
                      onSessionUpdate={actions.updateSession}
                      deleteSession={actions.deleteSession}
                      studentsLookup={studentsLookup}
                    />
                  </Wrapper>
                </Content>
              </Item>
            ))}
          </Accordion>
        )}
      </Section>
    </Main>
  )
}

export default AttendanceTracking
