import { plainToInstance } from 'class-transformer'
import { useCallback, useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { ApprovalStatus, ExternalModuleChoice } from '../types/schemas/moduleSelection'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useExternalChoicesReview = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [externalChoices, setExternalChoices] = useState<ExternalModuleChoice[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.externalModuleChoices(year))
      .then(({ data }) => {
        setExternalChoices(mapPlainToInstance(ExternalModuleChoice, data))
      })
      .catch(displayError('Failed to fetch external module choices'))
  }, [axiosInstance, displayError, year])

  function reviewChoice(choiceId: number, status: ApprovalStatus) {
    axiosInstance
      .patch(endpoints.externalModuleChoice(year, choiceId), { status })
      .then(({ data }) => {
        let newExternalModuleChoice = plainToInstance(ExternalModuleChoice, data)
        setExternalChoices((curr) =>
          curr.map((c) => (c.id === choiceId ? newExternalModuleChoice : c))
        )
      })
      .catch(displayError('Failed to update status of external module choices'))
  }

  return {
    externalChoices,
    reviewChoice: useCallback(reviewChoice, [axiosInstance, displayError, year]),
  }
}
