import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { ExerciseSubmission } from '../types/schemas/emarking'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useSubmissionsForYear = (year: string, queryParams?: { [key: string]: boolean }) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [submissions, setExercises] = useState<ExerciseSubmission[]>([])
  const [submissionsAreLoaded, setExercisesAreLoaded] = useState(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.submissionsForYear(year), queryParams ? { params: queryParams } : {})
      .then(({ data }) => {
        setExercises(mapPlainToInstance(ExerciseSubmission, data))
      })
      .catch(displayError('Error fetching submissions'))
      .finally(() => setExercisesAreLoaded(true))
  }, [axiosInstance, year, displayError])
  return { submissions, submissionsAreLoaded }
}
