import { useState } from 'react'

import { usePhdAdmin } from '../../hooks/phd.service'
import { Button, Container, Div } from '../../styles/_app.style'
import Tooltip from '../tooltip/Tooltip'

const PhdAdminView = () => {
  const [currentFile, setCurrentFile] = useState<File>()
  const [fileUploadComplete, setFileUploadComplete] = useState<Boolean>(false)

  const [currentSupervisorFile, setCurrentSupervisorFile] = useState<File>()
  const [supervisorFileUploadComplete, setSupervisorFileUploadComplete] = useState<Boolean>(false)

  const { submitCsvFile, submitSupervisorsCsvFile } = usePhdAdmin()

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentFile(selectedFiles?.[0])
  }

  const handleFileUpload = () => {
    if (!currentFile) {
      setFileUploadComplete(false)
      return
    }

    submitCsvFile(currentFile)
    setFileUploadComplete(true)
  }

  // Supervisor files
  const selectSupervisorFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentSupervisorFile(selectedFiles?.[0])
  }

  const handleSupervisorFileUpload = () => {
    if (!currentSupervisorFile) {
      setSupervisorFileUploadComplete(false)
      return
    }

    submitSupervisorsCsvFile(currentSupervisorFile)
    setSupervisorFileUploadComplete(true)
  }

  return (
    <Container>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          border: '1px solid $sand8',
          borderRadius: '1rem',
          padding: '1rem',
        }}
      >
        <h1>Admin Page</h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em',
          }}
        >
          <p>Use the upload button to upload milestone CSV data from banner</p>
        </div>
        <div
          style={{
            paddingBottom: '1em',
            paddingTop: '1em',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <label className="btn btn-default p-0">
            <input type="file" onChange={selectFile} />
          </label>

          <Tooltip label={'Upload Milestones'}>
            <Button disabled={!currentFile} onClick={handleFileUpload}>
              Upload Milestones
            </Button>
          </Tooltip>
        </div>

        <div
          style={{
            paddingBottom: '1em',
            paddingTop: '1em',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {fileUploadComplete ? (
            <h5>The milestone CSV file has been submitted</h5>
          ) : (
            <span>Milestones CSV file not submitted</span>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em',
          }}
        >
          <p>Use the upload button to upload student/supervisors CSV data from TeachDB</p>
        </div>
        <div
          style={{
            paddingBottom: '1em',
            paddingTop: '1em',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <label className="btn btn-default p-0">
            <input type="file" onChange={selectSupervisorFile} />
          </label>

          <Tooltip label={'Upload Supervisors'}>
            <Button disabled={!currentSupervisorFile} onClick={handleSupervisorFileUpload}>
              Upload Supervisors
            </Button>
          </Tooltip>
        </div>

        <div
          style={{
            paddingBottom: '1em',
            paddingTop: '1em',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {supervisorFileUploadComplete ? (
            <h5>The Supervisor CSV file has been submitted</h5>
          ) : (
            <span>Supervisor CSV file not submitted</span>
          )}
        </div>
      </Div>
    </Container>
  )
}

export default PhdAdminView
