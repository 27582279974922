import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { StudentResult } from '../types/schemas/exams'
import { useErrorMessage } from './errorMessage.service'

export const useExams = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [studentResult, setStudentResult] = useState<StudentResult>()
  const [studentResultIsLoaded, setStudentResultIsLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.myResults(year))
      .then(({ data }) => setStudentResult(plainToInstance(StudentResult, data)))
      .catch(displayError('Failed to get exams'))
      .finally(() => setStudentResultIsLoaded(true))
  }, [axiosInstance, displayError, year])

  return { studentResult, studentResultIsLoaded }
}
