import { useContext } from 'react'

import { ThemeContext } from '../contextManagers/theme.context'
import { Banner, Div } from '../styles/_app.style'
import { Logo } from '../styles/login.style'

const InvalidRequest404 = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Logo
        alt="Scientia logo"
        src="/assets/logo-light.svg"
        style={{ filter: `invert(${theme === 'dark' ? 1 : 0})`, margin: '4em' }}
      />
      <Banner style={{ fontWeight: 'bold', fontSize: 30 }}>
        <span>404</span>
        <span>The resource you're looking for doesn't exist</span>
      </Banner>
    </Div>
  )
}

export default InvalidRequest404
