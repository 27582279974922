import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PhdStudentDetails } from '../components/phd/PhdStudentDetails'
import { useUser } from '../contextManagers/user.context'
import { Container } from '../styles/_app.style'

export const PhdStudent = () => {
  const { userDetails } = useUser()
  const { year } = useParams()

  return (
    <>
      <Container>
        <Helmet>
          <title>Milestones</title>
        </Helmet>

        <PhdStudentDetails />
      </Container>
    </>
  )
}
