import React, { useEffect, useMemo, useState } from 'react'

import { useResponsiveness } from '../../hooks/breakpoints.service'
import { Button, Div, P } from '../../styles/_app.style'
import { Control, Field, Input, Label, LabelContainer, Submit } from '../../styles/form.style'
import { Mark } from '../../types/schemas/emarking'
import { isNumber } from '../../utils'
import Tooltip from '../tooltip/Tooltip'
import Dialog from './Dialog'

export type MarkCapEditPayload = {
  cap: number | null
  capReason: string
}

const EditMarkCapDialog = ({
  mark,
  passMark,
  open,
  onOpenChange,
  onSubmit,
}: {
  mark: Mark
  passMark: number | undefined
  open: boolean
  onOpenChange: (_: boolean) => void
  onSubmit: (markID: number, payload: MarkCapEditPayload) => void
}) => {
  // Quick workaround for minimal responsiveness
  const { isDesktop } = useResponsiveness()
  const initialData: MarkCapEditPayload = useMemo(
    () => ({
      cap: mark.cap,
      capReason: mark.capReason ?? '',
    }),
    [mark]
  )
  const [formData, setFormData] = useState<MarkCapEditPayload>(initialData)
  useEffect(() => setFormData(initialData), [initialData])

  function applyPatch(key: keyof MarkCapEditPayload, value: MarkCapEditPayload[typeof key]) {
    setFormData((current) => ({ ...current, [key]: value }))
  }

  const CapButtons = () => {
    return (
      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isDesktop ? 'row' : 'column',
          gap: '0.5rem',
          width: '100%',
        }}
      >
        <Tooltip label={'Cap at Zero'}>
          <Button disabled={formData.cap === 0} onClick={() => applyPatch('cap', 0)}>
            Cap at 0
          </Button>
        </Tooltip>
        {passMark && (
          <Tooltip label={'Cap at pass'}>
            <Button
              disabled={formData.cap === passMark}
              onClick={() => applyPatch('cap', passMark)}
            >
              Cap at pass mark ({passMark}%)
            </Button>
          </Tooltip>
        )}
        <Tooltip label={'Uncap'}>
          <Button disabled={formData.cap === null} onClick={() => applyPatch('cap', null)}>
            Uncap
          </Button>
        </Tooltip>
      </Div>
    )
  }

  function handleSubmit() {
    onSubmit(mark.id, formData)
    setFormData(initialData)
  }

  return (
    <Dialog
      title="Edit Mark Cap"
      secondaryButtonText="Cancel"
      onPrimaryClick={handleSubmit}
      onOpenChange={onOpenChange}
      open={open}
    >
      <Div css={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '2rem' }}>
        <P
          css={{
            textAlign: 'center',
            color: '$sand9',
          }}
        >
          Currently{' '}
          {!isNumber(mark.cap) ? 'not capped' : `capped at ${mark.cap ? `${mark.cap}%` : 'zero'}`}
        </P>
        <Field name="cap-reason">
          <LabelContainer>
            <Label>Cap Reason</Label>
          </LabelContainer>
          <Control asChild>
            <Input
              type="string"
              value={formData.capReason || ''}
              onChange={({ target: { value } }) => applyPatch('capReason', value)}
              required
            />
          </Control>
        </Field>
        <CapButtons />
        <Submit asChild>
          <Button>Submit</Button>
        </Submit>
      </Div>
    </Dialog>
  )
}

export default EditMarkCapDialog
