import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/common/PageHeader'
import { LevelProgressBar } from '../components/game/LevelProgressBar'
import SearchTool from '../components/search/SearchTool'
import { endpoints } from '../constants/endpoints'
import titles from '../constants/titles'
import { AxiosContext } from '../contextManagers/axios.context'
import { useGame } from '../contextManagers/game.context'
import { useToast } from '../contextManagers/toast.context'
import { useUser } from '../contextManagers/user.context'
import { useLevels } from '../hooks/levels.service'
import { Banner, Button, Container, Wrapper } from '../styles/_app.style'
import { Main, Section } from '../styles/root.style'
import { Module as ModuleType } from '../types/schemas/abc'

const Module = () => {
  const { gameSettingOn } = useGame()
  const { moduleCode } = useParams()
  const levelsManager = useLevels()
  const { pathname } = useLocation()
  const { year } = useParams()
  const axiosInstance = useContext(AxiosContext)
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { userDetails } = useUser()

  const [module, setModule] = useState<ModuleType | undefined>(undefined)
  const [moduleIsLoaded, setModuleIsLoaded] = useState<boolean>(false)
  useEffect(() => {
    axiosInstance
      .request({
        url: endpoints.module(year!, moduleCode as string),
        method: 'GET',
      })
      .then(({ data }) => {
        if (!data) setModule(undefined)
        setModule(plainToInstance(ModuleType, data))
      })
      .catch((error) => {
        addToast({ variant: 'error', title: 'Error fetching module' })
        console.error(error)
      })
      .finally(() => setModuleIsLoaded(true))
  }, [addToast, axiosInstance, moduleCode, year])

  const tabs = [
    { title: 'Materials', to: 'materials' },
    { title: 'Exercises', to: 'exercises' },
  ]

  if (module === undefined) {
    // TODO: Data-loading error page
    return (
      <Container>
        <h1> {moduleCode}</h1>
        {moduleIsLoaded && (
          <Banner>
            <span>Troubles loading data for this module. Please try refreshing.</span>
          </Banner>
        )}
      </Container>
    )
  }

  return (
    <Main>
      <PageHeader
        title={titles.module(year, module.code, module.title)}
        header={module.title}
        subHeader={module.code}
      >
        <Wrapper inline>
          {gameSettingOn &&
            !userDetails?.isStaff &&
            RegExp('.*/materials').test(pathname) &&
            levelsManager.loaded &&
            levelsManager.hasMinLevels && (
              <LevelProgressBar
                level={levelsManager.level}
                levelProgress={levelsManager.progress}
              />
            )}
        </Wrapper>
      </PageHeader>
      <Section>
        <SearchTool year={year as string} moduleCodes={[moduleCode as string]} />
        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginBottom: '1rem' }}>
          {tabs.map((tab) => (
            <Button
              key={tab.title}
              css={{ minWidth: '9rem', flex: '1 1 9rem', margin: 0 }}
              active={RegExp(`.*/${tab.to}`).test(pathname)}
              onClick={() => navigate(tab.to)}
              animate
            >
              {tab.title}
            </Button>
          ))}
        </div>
      </Section>
      <Outlet context={{ moduleCode, levelsManager }} />
    </Main>
  )
}

export default Module
