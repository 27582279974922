import { useContext } from 'react'
import { Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { useOutletContext, useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { getItemStyle, getListStyle } from '../styles/dragDrop.style'
import { useErrorMessage } from './errorMessage.service'
import { Resource } from './materials.service'

export type DragDropOptions = { dragEnabled: boolean; droppableId: string }

export const addDroppable = (
  data: any,
  attribute: string,
  { droppableId, dragEnabled }: DragDropOptions,
  renderTab: any
) => (
  <Droppable droppableId={droppableId}>
    {(droppableProvided, droppableSnapshot) => (
      <div ref={droppableProvided.innerRef} style={getListStyle(droppableSnapshot.isDraggingOver)}>
        {data?.map((tab: any, index: number) => (
          <Draggable
            key={tab[attribute]}
            draggableId={`${tab.id}`}
            index={index}
            isDragDisabled={!dragEnabled}
          >
            {(draggableProvided, draggableSnapshot) =>
              renderTab(tab, index, {
                ref: draggableProvided.innerRef,
                ...draggableProvided.draggableProps,
                ...draggableProvided.dragHandleProps,
                style: getItemStyle(
                  draggableSnapshot.isDragging,
                  draggableProvided.draggableProps.style
                ),
              })
            }
          </Draggable>
        ))}
        {droppableProvided.placeholder}
      </div>
    )}
  </Droppable>
)

export const useReordering = (setRawMaterials: (_: Resource[]) => void) => {
  const { year } = useParams()
  const axiosInstance = useContext(AxiosContext)
  const { moduleCode } = useOutletContext<{ moduleCode: string | null }>()
  const displayError = useErrorMessage()

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (
      !moduleCode ||
      !destination ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      // if dragged out of bounds or hasn't moved
      return
    }
    const draggedResourceId = parseInt(draggableId)
    if (isNaN(draggedResourceId)) return
    const newIndex = destination.index
    const newCategory = destination.droppableId

    axiosInstance
      .put(endpoints.resource(draggedResourceId), {
        category: newCategory,
        index: newIndex,
      })
      .then(() => {
        axiosInstance
          .get(endpoints.resources, { params: { year, course: moduleCode! } })
          .then(({ data }: any) => setRawMaterials(data))
          .catch(displayError('Error reloading resources.'))
      })
      .catch(displayError('Error reordering resources. Original order is unchanged.'))
  }
  return { onDragEnd }
}

function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const useReordering_ = (items: any[], updateItems: (_: any[]) => void) => {
  const onDragEnd = ({ source, destination }: DropResult) => {
    // If dropped outside the list or hasn't moved, do nothing
    if (!destination || source.index === destination.index) return
    const newItems = reorder(items, source.index, destination.index)
    updateItems(newItems)
  }

  return { onDragEnd }
}
