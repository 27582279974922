import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { useErrorMessage } from '../hooks/errorMessage.service'
import { darkTheme, theme as lightTheme } from '../styles/stitches.config'
import { fetchAccentClassName } from '../styles/theming/accentUtils'
import { CATE_THEME_ACCENT_NAME } from '../styles/theming/cateMode'
import { NO_ACCENT } from '../styles/theming/colourConstants'
import { SPECIAL_ACCENTS } from '../styles/theming/colours'
import { useToast } from './toast.context'

/**
 * Code for the theme provider was heavily inspired by Lucas Arundell (lucastobrazil):
 * https://codesandbox.io/s/stitches-dark-mode-te4ne
 * Lucas in turn references Okiki Ojo's post as inspiration:
 * https://bit.ly/3qIldSj
 *
 * The code uses Okiki's method of combining matchMedia, localStorage, and Stitches to
 * allow a user to toggle through multiple colour modes whilst also taking into account
 * the user's default OS theme preferences.
 *
 * To add a new theme:
 * 1. Create it in the stitches configuration file (see styles)
 * 2. Import the theme on line 2 and add it to the list of available themes (below)
 */

type Theme = string
type ThemeProviderType = {
  theme: Theme
  toggleTheme: () => void
  accent: string
  applyAccent: (accent: string) => void
}
type Themes = {
  [x: string]: string
}

const defaultTheme: ThemeProviderType = {
  theme: '',
  toggleTheme: () => {},
  accent: NO_ACCENT,
  applyAccent: () => {},
}

export const ThemeContext = createContext<ThemeProviderType>(defaultTheme)

/**
 * A dictionary of available themes
 * The value of each key returns the className from stitches' createTheme()
 */
const themes: Themes = {
  light: lightTheme.className /* Default theme on Stitches */,
  dark: darkTheme.className,
}

const saveTheme = (newTheme: Theme) => {
  try {
    if (typeof newTheme === 'string') window.localStorage.setItem('theme', newTheme)
  } catch (e) {
    console.warn(e)
  }
}

const getSavedThemePreference = (): Theme => {
  try {
    const savedTheme = window.localStorage.getItem('theme')
    /* If user has explicitly chosen a theme, it's applied. Else, the value's empty. */
    if (typeof savedTheme === 'string') return savedTheme
  } catch (e) {
    /* localStorage cannot be accessed in incognito mode on Chrome */
    console.warn(e)
    return ''
  }
  return ''
}

const getMediaTheme = (): Theme => {
  /* If the user hasn't explicity set a theme, then we check the media query */
  const mediaQueryList = matchMedia('(prefers-color-scheme: dark)')
  const hasMediaQueryPreference = typeof mediaQueryList.matches === 'boolean'
  if (hasMediaQueryPreference) return mediaQueryList.matches ? 'dark' : 'light'
  return ''
}

/** We create a hook to track classes we have added and clear them as necessary */
const useHTMLClassList = () => {
  const classListRef = useRef<string[]>([])

  const html = document.documentElement

  const clearAndSetClassList = (newClassList: string[], animate = true) => {
    html.classList.remove(...classListRef.current)
    html.classList.add(...newClassList)

    if (animate) {
      /* Add and remove animation override for smooth theme transition */
      document.body.classList.add('animate')
      setTimeout(() => document.body.classList.remove('animate'), 1000)
    }
    classListRef.current = newClassList
  }

  return { clearAndSetClassList }
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState(defaultTheme.theme)
  const [accent, setAccent] = useState(defaultTheme.accent)
  const { clearAndSetClassList } = useHTMLClassList()
  const { addToast } = useToast()

  /* Set theme in localStorage, as well as in the html tag */
  const applyTheme = (newTheme: Theme) => {
    // If we have a special case accent, apply its class - which happens to be from fetchAccentClassName!
    if (accent !== NO_ACCENT) {
      clearAndSetClassList([fetchAccentClassName(accent, newTheme)])
    } else {
      clearAndSetClassList([themes[newTheme]])
    }
    setTheme(newTheme)
  }

  let savedTheme = getSavedThemePreference()
  if (savedTheme === '') {
    /* If no localStorage exists, use the user's OS setting */
    savedTheme = getMediaTheme()
  }
  useEffect(() => {
    clearAndSetClassList([themes[savedTheme]], false)
  }, [])

  /* Re-render when the dependency, savedTheme, changes. */
  useEffect(() => {
    setTheme(savedTheme)
  }, [savedTheme])

  /* Pull accent from local storage on first load */
  useEffect(() => {
    try {
      const savedAccent = window.localStorage.getItem('accent')
      if (typeof savedAccent === 'string' && savedAccent !== NO_ACCENT)
        clearAndSetClassList([fetchAccentClassName(savedAccent, savedTheme)], false)
      setAccent(savedAccent ?? NO_ACCENT)
    } catch (e) {
      addToast({
        variant: 'error',
        title: `Unable to fetch accent from local storage`,
      })
      console.warn(e)
    }
  }, [])

  /** We use a custom function as watching the state directly would cause this (which animates the BG) to run on first load as well */
  const applyAccent = useCallback(
    (accent: string) => {
      if (accent !== NO_ACCENT) {
        clearAndSetClassList([fetchAccentClassName(accent, theme)])
      } else {
        clearAndSetClassList([themes[theme]])
      }

      setAccent(accent)
    },
    [theme]
  )

  /* Save accent into local storage when it changes */
  useEffect(() => {
    try {
      window.localStorage.setItem('accent', accent)
    } catch (e) {
      addToast({
        variant: 'error',
        title: `Unable to save accent to local storage`,
      })
      console.warn(e)
    }
  }, [accent])

  /**
   * Check if the user changes the OS theme, but don't save it in localStorage.
   * TODO: "Sync with system" option
   */
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    applyTheme(e.matches ? 'dark' : 'light')
  })

  window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (e) => {
    applyTheme(e.matches ? 'light' : 'dark')
  })

  /* Cycle between the available themes; code supports more than two themes! */
  const toggleTheme = (): void => {
    const keys = Object.keys(themes)
    let index = keys.indexOf(theme)
    if (index === keys.length - 1) {
      index = 0
    } else if (index >= 0) {
      index = index + 1
    }
    const newTheme = keys[index]

    applyTheme(newTheme)
    saveTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, accent, applyAccent }}>
      {children}
    </ThemeContext.Provider>
  )
}
