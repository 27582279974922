import React from 'react'
import { ArrowLeftRight, ArrowRepeat, FloppyFill, Trash3Fill } from 'react-bootstrap-icons'

import { Toolbar } from '../../components/Toolbar'
import Tooltip from '../../components/tooltip/Tooltip'
import { Button, Div } from '../../styles/_app.style'
import { RawMarks } from '../../types/global'

const MainToolbar = ({
  markingIsComplete,
  stagedMarks,
  publishEnabled,
  onToolbarToggle,
  onRefresh,
  onDelete,
  onPublish,
  onSaveMarks,
}: {
  markingIsComplete: boolean
  stagedMarks: RawMarks
  publishEnabled: boolean
  onToolbarToggle: () => void
  onRefresh: () => void
  onDelete: () => void
  onPublish: () => void
  onSaveMarks: () => void
}) => (
  <Toolbar>
    <Tooltip label={'Re-allocate submissions'}>
      <Button icon onClick={onToolbarToggle}>
        <ArrowLeftRight size={22} />
      </Button>
    </Tooltip>
    <Div css={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between' }}>
      <Tooltip label={'Delete distribution'}>
        <Button icon onClick={onDelete}>
          <Trash3Fill size={22} />
        </Button>
      </Tooltip>
      <Tooltip label={'Refresh Distribution'}>
        <Button icon onClick={onRefresh}>
          <ArrowRepeat size={22} />
        </Button>
      </Tooltip>

      <Tooltip label={'Save marks'}>
        <Button
          icon
          disabled={!Object.keys(stagedMarks).length}
          onClick={onSaveMarks}
          warning={!!Object.keys(stagedMarks).length}
        >
          <FloppyFill size={22} />
        </Button>
      </Tooltip>

      {publishEnabled && (
        <Tooltip label={(markingIsComplete ? 'Unpublish' : 'Publish') + ' marks and feedback'}>
          <Button onClick={onPublish}>{markingIsComplete ? 'Unpublish' : 'Publish'}</Button>
        </Tooltip>
      )}
    </Div>
  </Toolbar>
)

export default MainToolbar
