import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { CollapsibleList } from '../components/CollapsibleList'
import { endpoints } from '../constants/endpoints'
import { usePublicModules } from '../hooks/modules.service'
import { usePublicResources } from '../hooks/resource.service'
import { Banner, Container, Wrapper } from '../styles/_app.style'
import { Caret } from '../styles/collapsible-list.style'
import { Tag, Tags } from '../styles/materials.style'
import { ListItem } from '../styles/tabs.style'
import { TeachingMaterial } from '../types/schemas/materials'
import { buildLookupTable, encodeURL, groupByProperty, publicShortYear } from '../utils'

const PublicMaterials = () => {
  const year = publicShortYear()

  const { publicResources, resourcesAreLoaded } = usePublicResources(year)

  const { modules } = usePublicModules(year)
  const moduleLookup = useMemo(() => buildLookupTable(modules, 'code'), [modules])

  const publicResourcesByModule = useMemo(
    () => groupByProperty(publicResources, 'moduleCode', 'index'),
    [publicResources]
  )

  function listItemGenerator(resource: TeachingMaterial) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Wrapper inline>
          <span>{resource.title}</span>
        </Wrapper>
        <Tags>
          {resource.tags.map((tag: string) => (
            <Tag key={`${resource.id}${tag}`}>#{tag}</Tag>
          ))}
        </Tags>
      </div>
    )
  }

  function contentGenerator(header: string, resources: TeachingMaterial[]) {
    return resources.map((r) => (
      <ListItem>
        <a
          href={encodeURL(r.type === 'link' ? r.path! : endpoints.publicResourceFile(year, r.id))}
          target="_blank"
          rel="noreferrer"
        >
          {listItemGenerator(r)}
        </a>
      </ListItem>
    ))
  }

  // The 'header' here is a moduleCode
  const headerGenerator = (header: string, _: object[]) => (
    <>
      <Caret />
      <span>{`${header} ${moduleLookup.get(header)?.title || ''}`}</span>
    </>
  )

  return (
    <Container>
      <Helmet>
        <title>Public Materials</title>
      </Helmet>
      <section style={{ marginBottom: '2rem' }}>
        <h1>Public resources</h1>
        <p>
          Welcome! Please see below for some introductory material on modules taught in the
          Department of Computing at Imperial College London.
        </p>
      </section>
      {!resourcesAreLoaded && <Banner>Loading public resources...</Banner>}
      {resourcesAreLoaded && publicResources.length > 0 ? (
        <CollapsibleList
          data={publicResourcesByModule}
          contentGenerator={contentGenerator}
          headerGenerator={headerGenerator}
        />
      ) : (
        <Banner>No resources available at the moment.</Banner>
      )}
    </Container>
  )
}

export default PublicMaterials
