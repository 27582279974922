import { plainToInstance } from 'class-transformer'
import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { VoidHandler } from '../types/global'
import { TutorialGroup } from '../types/schemas/abc'
import { Exercise } from '../types/schemas/emarking'
import { Session, StudentTutoringSession, sessionInstanceToPlain } from '../types/schemas/tutoring'
import { mapPlainToInstance, now } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useTutorialGroupsForExercise = (exercise: Exercise | undefined) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [tutorialGroups, setTutorialGroups] = useState<TutorialGroup[]>([])
  useEffect(() => {
    if (!exercise || !exercise.isForYearOneTutorialGroups) return
    axiosInstance
      .get(endpoints.tutorialGroups(exercise.year), {
        params: { type: exercise.type.toLowerCase() },
      })
      .then(({ data }) => {
        setTutorialGroups(mapPlainToInstance(TutorialGroup, data))
      })
      .catch(displayError('Failed to get tutorial groups for exercise'))
  }, [axiosInstance, displayError, exercise])

  return {
    tutorialGroups,
  }
}

export const useTutorialGroups = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [tutorialGroups, setTutorialGroups] = useState<TutorialGroup[]>([])
  useEffect(() => {
    axiosInstance
      .get(endpoints.tutorialGroups(year))
      .then(({ data }) => {
        setTutorialGroups(mapPlainToInstance(TutorialGroup, data))
      })
      .catch(displayError('Failed to get tutorial groups'))
  }, [axiosInstance, displayError, year])

  return {
    tutorialGroups,
  }
}

export interface SessionsHook {
  actions: {
    addSession: VoidHandler
    deleteSession: (sessionId: number) => void
    updateSession: (sessionId: number, payload: Session) => void
  }
  sessions: Session[]
}

export const useTutorialSessions = (
  year: string,
  group: TutorialGroup | undefined
): SessionsHook => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [sessions, setSessions] = useState<Session[]>([])
  const groupName = group?.toString()

  useEffect(() => {
    if (!group) return
    axiosInstance
      .get(endpoints.tutorialSessions(year), {
        params: { group: groupName },
      })
      .then(({ data }) =>
        setSessions(
          mapPlainToInstance(Session, data).sort((a: Session, b: Session) => {
            return b.date.getTime() - a.date.getTime()
          })
        )
      )
      .catch(displayError(`Failed to get session info for group ${groupName}`))
  }, [axiosInstance, displayError, group, groupName, year])

  function addSession() {
    if (!group || !groupName) return
    const newSession = plainToInstance(Session, {
      group: groupName,
      date: format(now(), 'yyyy-MM-dd'),
      attendances: group.members.map((student) => ({
        username: student.login,
        present: false,
      })),
    })
    axiosInstance
      .post(endpoints.tutorialSessions(year), sessionInstanceToPlain(newSession))
      .then(({ data }) => {
        const newSession = plainToInstance(Session, data)
        newSession.attendances = newSession.attendances.map((attendance) => ({
          ...attendance,
          present: undefined,
        }))
        setSessions((oldSessions) => [newSession, ...oldSessions])
      })
      .catch(displayError(`Failed to add session for group ${groupName}`))
  }

  function deleteSession(sessionId: number) {
    axiosInstance
      .delete(endpoints.tutorialSession(year, sessionId))
      .then(() => {
        setSessions((oldSessions) => oldSessions.filter((session) => session.id !== sessionId))
      })
      .catch(displayError(`Failed to delete session ${sessionId}`))
  }

  function updateSession(sessionId: number, payload: Session) {
    axiosInstance
      .put(endpoints.tutorialSession(year, sessionId), sessionInstanceToPlain(payload))
      .then(({ data }) => {
        setSessions((oldSessions) =>
          oldSessions.map((session) =>
            session.id === sessionId ? plainToInstance(Session, data) : session
          )
        )
      })
      .catch(displayError(`Failed to update session ${sessionId}`))
  }

  return {
    sessions,
    actions: {
      addSession,
      deleteSession,
      updateSession,
    },
  }
}

export const useStudentTutoringSessions = (year: string, studentUsername: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [tutoringSessions, setTutoringSessions] = useState<StudentTutoringSession[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.studentTutoringSessions(year, studentUsername))
      .then(({ data }) => {
        setTutoringSessions(mapPlainToInstance(StudentTutoringSession, data))
      })
      .catch(displayError('Failed to get personal tutorial sessions'))
  }, [axiosInstance, displayError, studentUsername, year])

  return { tutoringSessions }
}
