import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { InternalModule, InternalModuleChoice } from '../types/schemas/moduleSelection'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useInternalModules = (year: string, degree: string | undefined) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [internalModulesOnOffer, setInternalModulesOnOffer] = useState<InternalModule[]>([])
  const [internalModulesAreLoaded, setInternalModulesAreLoaded] = useState(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.internalModulesOnOffer(year), { params: degree ? { degree: degree } : {} })
      .then(({ data }) => {
        setInternalModulesOnOffer(mapPlainToInstance(InternalModule, data))
      })
      .catch(displayError('Failed to fetch internal modules on offer'))
      .finally(() => setInternalModulesAreLoaded(true))
  }, [axiosInstance, displayError, degree, year])

  return {
    internalModulesOnOffer,
    internalModulesAreLoaded,
  }
}

export const useInternalModuleSelection = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const { addToast } = useToast()
  const displayError = useErrorMessage()
  const [chosenInternalModules, setChosenInternalModules] = useState<InternalModuleChoice[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.personalInternalModuleChoices(year))
      .then(({ data }) => {
        setChosenInternalModules(mapPlainToInstance(InternalModuleChoice, data))
      })
      .catch(displayError('Failed to fetch current internal module choices'))
  }, [axiosInstance, displayError, year])

  function add(moduleCode: string) {
    axiosInstance
      .post(endpoints.personalInternalModuleChoices(year), objectKeysToSnakeCase({ moduleCode }))
      .then(({ data }) => {
        let chosenInternalModule = plainToInstance(InternalModuleChoice, data)
        setChosenInternalModules((curr) => [...curr, chosenInternalModule])
        addToast({
          variant: 'success',
          title: `Module ${moduleCode} successfully added to your selection`,
        })
      })
      .catch(displayError('Failed to apply to internal module'))
  }

  function remove(choiceId: number) {
    axiosInstance
      .delete(endpoints.personalInternalModuleChoice(year, choiceId))
      .then(() => {
        setChosenInternalModules((curr) => curr.filter((m) => m.id !== choiceId))
        addToast({ variant: 'success', title: 'Module successfully removed from your selection' })
      })
      .catch(displayError('Failed to delete the module'))
  }

  return {
    chosenInternalModules,
    actions: {
      add,
      remove,
    },
  }
}
