import { Expose, Type } from 'class-transformer'
import { addDays, differenceInDays, isAfter, isBefore } from 'date-fns'

import { endpoints } from '../../constants/endpoints'
import { GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS, TUTORIAL_GROUP_TYPES } from '../../constants/global'
import { now, removeDuplicates } from '../../utils'

// These are the classes used to marshall data from the emarking API
export const LABTS_SUBMISSION = 'commit_hash'

export enum WorkloadSurveyOption {
  TOO_LOW = 'too_low',
  ABOUT_RIGHT = 'about_right',
  TOO_HIGH = 'too_high',
}

export class WorkloadSurveyResponse {
  id: number

  response: string

  @Type(() => Date)
  timestamp: Date
}

export class Extension {
  @Expose({ name: 'student_username' })
  studentUsername: string

  @Type(() => Date)
  @Expose({ name: 'revised_deadline' })
  revisedDeadline: Date
  reason: string

  @Expose({ name: 'granted_by' })
  grantedBy: string
}

export class Deliverable {
  id: number
  name: string
  type: string

  get isLabts(): boolean {
    return this.type === LABTS_SUBMISSION
  }
}

export enum ExerciseStatus {
  NOT_STARTED = 'Not started',
  /** No submission is required for this exercise, so it can not be considered "LATE", "DONE" or "DUE", but may be considered "NOT_STARTED" */
  NO_SUBMISSION_REQUIRED = 'No submission required',
  /** The student/group has not yet submitted everything, and the exercise is still running */
  DUE = 'Due',
  /** The student/group has not yet submitted everything, and the exercise is past due date, but within the grace period */
  LATE = 'Late',
  /** The student/group has not yet submitted everything, and the exercise is past due date AND grace period, so no further submissions are possible */
  PAST_GRACE_PERIOD = 'Past grace period',
  DONE = 'Done',
}

export class Exercise {
  id: number

  year: string

  @Expose({ name: 'module_code' })
  moduleCode: string
  number: number
  title: string
  type: string

  @Expose({ name: 'expected_hours' })
  estimatedWorkHours: number

  @Expose({ name: 'submission_type' })
  submissionType: string
  weight: number

  @Type(() => Date)
  @Expose({ name: 'start' })
  startDate: Date

  @Type(() => Date)
  @Expose({ name: 'end' })
  endDate: Date

  @Type(() => Date)
  @Expose({ name: 'marks_published' })
  marksPublished: Date | null

  @Expose({ name: 'module_name' })
  moduleName: string

  @Expose({ name: 'maximum_mark' })
  maximumMark: number

  // Personal version of the exercise
  @Type(() => Date)
  @Expose({ name: 'extended_end' })
  extendedEndDate: Date | null

  @Type(() => Mark)
  mark: Mark | null

  @Type(() => Mark)
  marks: Mark[]

  @Type(() => WorkloadSurveyResponse)
  @Expose({ name: 'workload_survey_response' })
  workloadSurveyResponse: WorkloadSurveyResponse | null

  @Type(() => Boolean)
  @Expose({ name: 'marks_hidden_to_students' })
  marksHiddenToStudents: boolean

  @Type(() => Extension)
  extensions: Extension[]

  @Type(() => Deliverable)
  deliverables: Deliverable[]

  @Type(() => Date)
  spec: Date | null

  @Expose({ name: 'supplementary_file' })
  supplementaryFile: string | null

  @Expose({ name: 'model_answer' })
  modelAnswer: string | null

  @Type(() => Date)
  @Expose({ name: 'model_answer_visible_on' })
  modelAnswerVisibleOn: Date | null

  @Type(() => Date)
  locked: Date | null

  @Type(() => ExerciseSubmission)
  submissions: ExerciseSubmission[] | undefined

  @Type(() => SubmissionFeedback)
  feedback: SubmissionFeedback | null

  get hasProvidedFiles(): boolean {
    return !!this.spec || !!this.supplementaryFile || this.modelAnswerIsAvailable
  }

  get moduleContribution(): string {
    return this.weight === 200 ? 'avg' : `${this.weight}%`
  }

  get isAssessed(): boolean {
    return this.weight > 0
  }

  get isForYearOneTutorialGroups(): boolean {
    return TUTORIAL_GROUP_TYPES.includes(this.type)
  }

  get deadline(): Date {
    return this.extendedEndDate ?? this.endDate
  }

  get latePeriodDeadline(): Date {
    return addDays(this.deadline, GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS)
  }

  get isGroup(): boolean {
    return this.submissionType === 'group'
  }

  get isGroupFormation(): boolean {
    return this.type === 'GF'
  }

  get isClosed(): boolean {
    return new Date().getTime() > this.endDate.getTime()
  }

  get isOpen(): boolean {
    return new Date().getTime() >= this.startDate.getTime()
  }

  get isLocked(): boolean {
    return !!this.locked
  }

  get modelAnswerIsAvailable(): boolean {
    return !!this.modelAnswerVisibleOn && new Date().getTime() > this.modelAnswerVisibleOn.getTime()
  }

  /** E.g. 1 | CW */
  get shortTitle(): string {
    return `${this.number} | ${this.type}`
  }

  /** E.g. 1 | CW: Introduction to Computer Science */
  get fullTitle(): string {
    return `${this.number} | ${this.type}: ${this.title}`
  }

  get exerciseStatus(): ExerciseStatus {
    // 1: If we have not yet reached startDate (use data-fns), we don't care and consider it "NOT_STARTED"
    if (isBefore(now(), this.startDate)) {
      return ExerciseStatus.NOT_STARTED
    }

    // 2: If there's nothing to submit, we consider it "NO_SUBMISSION_REQUIRED"
    if (this.deliverables?.length === 0) {
      return ExerciseStatus.NO_SUBMISSION_REQUIRED
    }

    // 3: Ok so the exercise has started
    // Have we submitted everything? (or more than required?)
    if ((this.submissions?.length ?? 0) >= (this.deliverables?.length ?? 0)) {
      return ExerciseStatus.DONE
    }

    // Ok so some submissions are pending, so we're either DUE or LATE
    // 4: If we have passed endDate, we consider it late
    if (isAfter(now(), this.deadline)) {
      // 4.1: But, if we are ALSO past the grace period, no further submissions are possible!
      if (differenceInDays(now(), this.deadline) > GRACE_PERIOD_AFTER_DEADLINE_IN_DAYS) {
        return ExerciseStatus.PAST_GRACE_PERIOD
      }
      // Else, we are within the grace period, so can still submit but the submission would be regarded as "LATE"
      return ExerciseStatus.LATE
    } else {
      // We must be during the exercise, so it's "DUE"
      return ExerciseStatus.DUE
    }
  }
}

export class CandidateGroupMember {
  id: number
  login: string
  available: boolean
  firstname: string
  lastname: string

  get fullname(): string {
    return this.firstname + ' ' + this.lastname
  }
}

export class GroupMember {
  id: number
  username: string

  @Expose({ name: 'is_leader' })
  isLeader: boolean

  invited: string | null
  accepted: string | null

  get role(): string {
    return this.isLeader ? 'leader' : this.accepted ? 'member' : 'invited'
  }

  get isConfirmed(): boolean {
    return this.isLeader || !!this.accepted
  }

  fullName(enrolledStudents: CandidateGroupMember[]): string {
    return (
      enrolledStudents.find((person: CandidateGroupMember) => person.login === this.username)
        ?.fullname ?? ''
    )
  }
}

export class Group {
  id: number
  year: string

  @Type(() => GroupMember)
  members: GroupMember[]

  @Expose({ name: 'exercise_number' })
  exerciseNumber: number

  @Expose({ name: 'module_code' })
  moduleCode: string

  get leader(): string | null {
    return this.members.find((m) => m.isLeader)?.username ?? null
  }

  getMember(login: string): GroupMember | undefined {
    return this.members.find((m) => m.username === login)
  }

  isMember(login: string): boolean {
    return this.members.some((member) => member.username === login)
  }
}

export class Mark {
  id: number
  student_username: string
  marker: string
  mark: number | null
  cap: number | null

  @Expose({ name: 'cap_reason' })
  capReason: string | null

  @Type(() => MarkHistory)
  history: MarkHistory[]
}

export class MarkHistory {
  @Expose({ name: 'student_username' })
  studentUsername: string
  marker: string
  mark: number | null
  cap: number | null

  @Type(() => Date)
  timestamp: Date
}

export class ExerciseSubmission {
  id: number

  @Expose({ name: 'exercise_id' })
  exerciseId: number
  username: string

  @Type(() => Date)
  timestamp: Date
  @Expose({ name: 'target_submission_file_name' })
  targetFileName: string
  @Expose({ name: 'file_size' })
  fileSize: number

  @Expose({ name: 'gitlab_hash' })
  gitlabHash: string

  url(exercise: Exercise): string {
    return endpoints.submissionFile(exercise.year, exercise.moduleCode, exercise.number, this.id)
  }
}

export class CollatedSubmission {
  id: number

  @Expose({ name: 'student_username' })
  studentUsername: string

  @Type(() => Date)
  timestamp: Date
  marker: string
}

export class SubmissionFeedback {
  id: number

  @Expose({ name: 'distribution_id' })
  distributionId: number

  @Expose({ name: 'student_username' })
  studentUsername: string

  @Type(() => Date)
  timestamp: Date
  marker: string

  @Type(() => Date)
  seen: Date

  @Expose({ name: 'exercise_number' })
  exerciseNumber: number

  @Expose({ name: 'module_code' })
  moduleCode: string
}

export class Distribution {
  id: number

  @Expose({ name: 'exercise_id' })
  exerciseId: number

  @Expose({ name: 'distributed_by' })
  distributedBy: string

  platform: string

  @Expose({ name: 'target_files' })
  targetFiles: string | null

  @Type(() => Date)
  timestamp: Date

  @Expose({ name: 'feedback_published' })
  feedbackPublished: boolean

  @Expose({ name: 'tutorial_group' })
  tutorialGroup: string | null

  @Type(() => CollatedSubmission)
  submissions: CollatedSubmission[]

  @Type(() => SubmissionFeedback)
  feedback: SubmissionFeedback[]

  get markers(): string[] {
    return removeDuplicates(this.submissions.map((s) => s.marker))
  }
}
