import React, { ComponentProps, HTMLAttributes, useRef } from 'react'
import { Upload } from 'react-bootstrap-icons'

import { Button } from '../styles/_app.style'
import Tooltip from './tooltip/Tooltip'

// https://github.com/facebook/react/issues/3468#issuecomment-1031366038
declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string
    webkitdirectory?: string
  }
}

interface FileUploadButtonProps extends ComponentProps<typeof Button> {
  onFilesReceived: (receivedFiles: FileList) => void
  buttonContent?: string | JSX.Element
  directory?: boolean
  accept?: string[]
  tooltipLabel?: string
}

export const FileUploadButton = ({
  onFilesReceived,
  buttonContent = 'Upload',
  directory = false,
  accept = ['.pdf'],
  tooltipLabel,
  ...buttonProps
}: FileUploadButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click()
  }

  const handleChange = (event: any) => {
    let input = event.currentTarget as HTMLInputElement
    if (input?.files) {
      onFilesReceived(input.files)
      input.value = ''
    }
  }

  return (
    <>
      <input
        type="file"
        hidden={true}
        ref={hiddenFileInput}
        onChange={handleChange}
        accept={accept.join(',')}
        directory={directory ? '' : undefined}
        webkitdirectory={directory ? '' : undefined}
        multiple={directory}
      />
      <Tooltip label={tooltipLabel ?? `Upload ${directory ? 'folder' : 'file'}`}>
        <Button {...buttonProps} onClick={handleClick}>
          {buttonProps.icon ? <Upload size={22} /> : buttonContent}
        </Button>
      </Tooltip>
    </>
  )
}
