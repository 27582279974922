import { Award } from 'react-bootstrap-icons'

import { Banner } from '../../styles/_app.style'

const NoExercisesBanner = ({ text }: { text: string }) => {
  return (
    <Banner mobileTimeline>
      <Award size={35} />
      <h5>{text}</h5>
    </Banner>
  )
}

export default NoExercisesBanner
