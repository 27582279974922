import { Expose, Type } from 'class-transformer'

export class TeachingMaterial {
  id: number
  index: number
  path?: string
  title: string
  type: string
  year: string

  @Expose({ name: 'course' })
  moduleCode: string
  category: string
  tags: string[]
  downloads?: number

  @Expose({ name: 'visible_after' })
  @Type(() => Date)
  visibleAfter: Date
}
