import { useCallback } from 'react'

import { useToast } from '../contextManagers/toast.context'
import { ToastVariant } from '../styles/toast.style'
import { errorMessage } from '../utils'

export const useErrorMessage = () => {
  const { addToast } = useToast()

  function displayError(defaultMessage: string, level?: ToastVariant) {
    return (error: any) =>
      addToast({ variant: level ?? 'error', title: errorMessage(error) || defaultMessage })
  }

  return useCallback(displayError, [addToast])
}
