// `rehype-katex` does not import the CSS for you
import { LanguageFn } from 'highlight.js'
import bash from 'highlight.js/lib/languages/bash'
import cLang from 'highlight.js/lib/languages/c'
import cppLang from 'highlight.js/lib/languages/cpp'
import css from 'highlight.js/lib/languages/css'
import delphi from 'highlight.js/lib/languages/delphi'
import diff from 'highlight.js/lib/languages/diff'
import fortran from 'highlight.js/lib/languages/fortran'
import glsl from 'highlight.js/lib/languages/glsl'
import haskell from 'highlight.js/lib/languages/haskell'
import ini from 'highlight.js/lib/languages/ini'
import java from 'highlight.js/lib/languages/java'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import kotlin from 'highlight.js/lib/languages/kotlin'
import latex from 'highlight.js/lib/languages/latex'
import lisp from 'highlight.js/lib/languages/lisp'
import markdown from 'highlight.js/lib/languages/markdown'
import matlab from 'highlight.js/lib/languages/matlab'
import plaintext from 'highlight.js/lib/languages/plaintext'
import prolog from 'highlight.js/lib/languages/prolog'
import python from 'highlight.js/lib/languages/python'
import ruby from 'highlight.js/lib/languages/ruby'
import rust from 'highlight.js/lib/languages/rust'
import scala from 'highlight.js/lib/languages/scala'
import sh from 'highlight.js/lib/languages/shell'
import shell from 'highlight.js/lib/languages/shell'
import sql from 'highlight.js/lib/languages/sql'
import verilog from 'highlight.js/lib/languages/verilog'
import x86asm from 'highlight.js/lib/languages/x86asm'
import xml from 'highlight.js/lib/languages/xml'
import 'highlight.js/styles/base16/default-dark.css'
import cypher from 'highlightjs-cypher'
import solidity from 'highlightjs-solidity/src/languages/solidity'
import 'katex/dist/katex.min.css'
import { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'
import RehypeCodeHighlighter from 'rehype-highlight'
import RehypeKaTeXPlugin from 'rehype-katex'
import RemarkMathPlugin from 'remark-math'

const languages: Record<string, LanguageFn> = {
  bash,
  haskell,
  // HACK: Replace with actualy ASM language
  assembly: x86asm,
  c: cLang,
  cpp: cppLang,
  css,
  cypher,
  diff,
  fortran,
  glsl,
  xml,
  html: xml,
  ini,
  java,
  javascript,
  json,
  kotlin,
  latex,
  lisp,
  markdown,
  matlab,
  pascal: delphi,
  prolog,
  plaintext,
  python,
  ruby,
  rust,
  scala,
  sh,
  shell,
  solidity,
  sql,
  verilog,
  delphi,
}

const Markdown = (props: ComponentProps<typeof ReactMarkdown>) => (
  <ReactMarkdown
    children={props.children}
    remarkPlugins={[RemarkMathPlugin]}
    rehypePlugins={[
      RehypeKaTeXPlugin,
      [RehypeCodeHighlighter, { ignoreMissing: true, languages, plaintext: ['wacc'] }],
    ]}
  />
)

export default Markdown
