import { Submit } from '@radix-ui/react-form'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import titles from '../constants/titles'
import { ThemeContext } from '../contextManagers/theme.context'
import { useUser } from '../contextManagers/user.context'
import useAuth, { Credentials } from '../hooks/auth.service'
import { Button, Container } from '../styles/_app.style'
import { Control, Field, Form, Label, Logo, Message, Name, Tagline } from '../styles/login.style'
import { LocationState } from '../types/global'

/* TODO: Add a help toggle to the login form (i.e. information for new users to the platform) */
const Login = () => {
  const { loginUser, isLoggedIn } = useAuth()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { theme } = useContext(ThemeContext)

  const { userDetails } = useUser()
  const homePath = `/${userDetails?.year}/${userDetails?.isStaff ? 'modules' : 'timeline'}`

  useEffect(() => {
    const { next } = (state as LocationState) || { next: undefined }
    if (isLoggedIn) navigate(next || homePath, { replace: true })
  }, [isLoggedIn, navigate, state, homePath])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = Object.fromEntries(new FormData(event.currentTarget))
    loginUser(data as Credentials)
  }
  return (
    <Container center expand dotted css={{ paddingTop: 0 }}>
      <Helmet>
        <title>{titles.login}</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <Logo
          alt="Scientia logo"
          src="assets/logo-light.svg"
          style={{ filter: `invert(${theme === 'dark' ? 1 : 0})` }}
        />
        <Name>Scientia</Name>
        <Tagline style={{ marginBottom: '2rem' }}>A Unified DoC EdTech Platform</Tagline>

        <Field
          name="username"
          css={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '0.5rem',
          }}
        >
          <Label>Username</Label>
          <Control required />
          <Message match="valueMissing">Please enter your username</Message>
        </Field>

        <Field
          name="password"
          css={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '0.5rem',
          }}
        >
          <Label>Password</Label>
          <Control type="password" required autoComplete="on" />
          <Message match="valueMissing">Please enter your password</Message>
        </Field>
        <Submit asChild>
          <Button block>Login</Button>
        </Submit>
        <Link to={`/public-resources`}>Looking for our public resources?</Link>
      </Form>
    </Container>
  )
}

export default Login
