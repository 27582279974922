import React, { useContext, useMemo, useRef, useState } from 'react'

import { endpoints } from '../../constants/endpoints'
import { PhDAdminUsers } from '../../constants/phdTracking'
import { ThemeContext } from '../../contextManagers/theme.context'
import { useUser } from '../../contextManagers/user.context'
import { usePhdStudent } from '../../hooks/phd.service'
import { AnchorButton, Button, Div } from '../../styles/_app.style'
import { Form } from '../../styles/form.style'
import { CalendarInput } from '../../styles/resource-details-form.style'
import { StudentMilestone } from '../../types/phds'
import Select from '../select/Select'
import Tooltip from '../tooltip/Tooltip'
import { SubmissionStatus } from './StatusIcons'

function formatStringToDateTime(stringTime: string): string | null {
  return stringTime
    ? new Date(stringTime).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : null
}

interface stageProps {
  studentLogin: string
  isSupervisor: boolean
  isAssessor: boolean
  stage: StudentMilestone
}

export const EsaStage = ({ studentLogin, isSupervisor, isAssessor, stage }: stageProps) => {
  const { postFeedbackForMilestone, postStatusForMilestone, adminApproveMilestone } =
    usePhdStudent()

  const { userDetails } = useUser()

  const assessorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const supervisorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const [supervisorFeedback, setSupervisorFeedback] = useState<string | undefined>('')
  const [assessorFeedback, setAssessorFeedback] = useState<string | undefined>('')

  const { theme } = useContext(ThemeContext)
  const [milestoneStatus, setMilestoneStatus] = useState<string>('')
  const [attemptDate, setAttemptDate] = useState('')

  const milestoneStatusOptions = [
    { value: 'pass', label: 'Pass' },
    { value: 'revise', label: 'Revise' },
    { value: 'second-attempt', label: '2nd Attempt' },
    { value: 'fail', label: 'Fail' },
  ]

  function handleMilestoneStatus(newValue: any) {
    if (newValue) setMilestoneStatus(newValue.value)
  }

  const initialPayload = useMemo(() => {
    setSupervisorFeedback(stage.supervisorFeedback ?? '')
    setAssessorFeedback(stage.assessorFeedback ?? '')
    setMilestoneStatus(stage.status)
    setAttemptDate(stage.attemptDate)
    return {
      status: stage.status,
      supervisorFeedback: stage.supervisorFeedback,
      assessorFeedback: stage.assessorFeedback,
      attemptDate: stage.attemptDate,
    }
  }, [stage])

  const handleSupervisorFeedbackChange = () => {
    const { current } = supervisorFeedbackRef
    setSupervisorFeedback(current?.value)

    if (milestoneStatus) {
      postStatusForMilestone(studentLogin, 'ESA', milestoneStatus, attemptDate)
    }

    if (current?.value) {
      postFeedbackForMilestone(studentLogin, 'esa', current?.value)
    }
  }

  const handleAdminApproval = () => {
    adminApproveMilestone(studentLogin, 'esa', true)
  }

  const handleAssessorFeedbackChange = () => {
    const { current } = assessorFeedbackRef
    setAssessorFeedback(current?.value)

    if (current?.value) {
      postFeedbackForMilestone(studentLogin, 'esa', current?.value)
    }
  }

  const isPhdAdmin = PhDAdminUsers.includes(userDetails!.login)

  let link = endpoints.submitMilestoneFile('2324', studentLogin, 'esa')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      {isPhdAdmin && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1em',
            alignItems: 'center',
          }}
        >
          <span>PhD Admin Approval</span>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              handleAdminApproval()
            }}
          >
            <Tooltip label={'Approve milestone'}>
              <Button>Approve milestone</Button>
            </Tooltip>
          </Form>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '1em',
          alignItems: 'center',
        }}
      >
        <span>Date Required: {formatStringToDateTime(stage.dateRequired)}</span>
        <SubmissionStatus submissionStatus={milestoneStatus} stageStatus={milestoneStatus} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
        }}
      >
        <span>Date Submitted: {formatStringToDateTime(stage?.dateSubmitted!)}</span>
        <Tooltip label={'View Submission'}>
          <AnchorButton href={`/external-resource?url=${link}`}>View</AnchorButton>
        </Tooltip>
      </div>

      <Form
        css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        onSubmit={(event) => {
          event.preventDefault()
          handleSupervisorFeedbackChange()
        }}
      >
        {isSupervisor && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <span>Select Status:</span>
            <div>
              <Select
                options={milestoneStatusOptions}
                value={milestoneStatusOptions.find((o) => o.value === milestoneStatus)}
                onChange={handleMilestoneStatus}
                placeholder="Milestone status"
              />
            </div>
          </div>
        )}

        {milestoneStatus === 'fail' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <span>Select Date</span>
            <div
              style={{
                width: '50%',
                display: 'flex',
                colorScheme: theme,
              }}
            >
              <CalendarInput
                type="date"
                value={attemptDate}
                onChange={(event) => setAttemptDate(event.target.value)}
                required
              />
            </div>
          </div>
        ) : (
          <div />
        )}

        <div
          style={{
            padding: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span>Approved by PhD Office:</span>
          <span>{formatStringToDateTime(stage?.adminApproval!)} </span>
        </div>

        {isSupervisor && (
          <div
            style={{
              padding: '1em',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>Supervisor Feedback:</h4>
            <textarea
              style={{
                backgroundColor: 'transparent',
                background: 'transparent',
                padding: '0.5em',
                marginBottom: '1em',
              }}
              placeholder="Enter supervisor feedback"
              ref={supervisorFeedbackRef}
              rows={5}
              cols={80}
              defaultValue={supervisorFeedback}
            ></textarea>
            <Tooltip label={'Save feedback'}>
              <Button>Save Feedback &amp; Status</Button>
            </Tooltip>
          </div>
        )}
      </Form>

      {isAssessor && (
        <Form
          css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          onSubmit={(event) => {
            event.preventDefault()
            handleAssessorFeedbackChange()
          }}
        >
          <div
            style={{
              padding: '1em',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>Assessor Feedback: </h4>
            <textarea
              style={{
                backgroundColor: 'transparent',
                background: 'transparent',
                padding: '0.5em',
                marginBottom: '1em',
              }}
              placeholder="Enter Assessor feedback"
              ref={assessorFeedbackRef}
              rows={5}
              cols={80}
              defaultValue={assessorFeedback}
            ></textarea>

            <Tooltip label={'Save Feedback'}>
              <Button>Save Feedback</Button>
            </Tooltip>
          </div>
        </Form>
      )}
    </Div>
  )
}
