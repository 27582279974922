import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'

import Select, { StandardSelectOption } from '../../components/select/Select'
import GenericTanStackTableRenderer, {
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import cohortMappings from '../../constants/cohortMappings'
import { useAllStudents } from '../../hooks/allStudents.service'
import { useExercisesForYear } from '../../hooks/exercises.service'
import { useModules } from '../../hooks/modules.service'
import { Banner, Container } from '../../styles/_app.style'
import { Exercise } from '../../types/schemas/emarking'
import { calculatePercentageGrade, groupByProperty, isNumber } from '../../utils'

type CohortMarkingRow = {
  login: string
  name: string
}

function exerciseHeader(exercise: Exercise): JSX.Element {
  return (
    <Link
      to={`/${exercise.year}/modules/${exercise.moduleCode}/exercises/${exercise.number}`}
      target="_blank"
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: 'center' }}
      >
        <span>{exercise.number}</span>
        <span>{exercise.moduleContribution}</span>
      </div>
    </Link>
  )
}

const CohortMarkingDashboard = () => {
  const { year } = useParams()
  const [selectedCohort, setSelectedCohort] = useState<StandardSelectOption>()
  const [tableDataIsReady, setTableDataIsReady] = useState(false)

  const { modules, modulesAreLoaded } = useModules(year as string)
  const { allStudents, allStudentsAreLoaded } = useAllStudents(year as string)
  const { exercises } = useExercisesForYear(year as string)

  const groupedExercises = useMemo(
    () =>
      groupByProperty(
        exercises.filter((e) => e.weight > 0),
        'moduleCode',
        'number'
      ),
    [exercises]
  )
  const marksLookup = useMemo(
    () =>
      new Map(
        exercises.map((e) => [
          `${e.moduleCode}-${e.number}`,
          new Map(e.marks.map((m) => [m.student_username, m])),
        ])
      ),
    [exercises]
  )
  const modulesForCohort = useMemo(
    () =>
      selectedCohort
        ? modules.filter(
            (m) =>
              m.applicableCohorts.includes(selectedCohort.value) &&
              groupedExercises.hasOwnProperty(m.code)
          )
        : [],
    [groupedExercises, modules, selectedCohort]
  )
  const studentsForCohort = useMemo(
    () => (selectedCohort ? allStudents.filter((s) => s.cohort === selectedCohort.value) : []),
    [allStudents, selectedCohort]
  )

  const columnHelper = createColumnHelper<CohortMarkingRow>()
  const columns = useMemo(
    () => [
      columnHelper.group({
        header: ' ',
        columns: [
          columnHelper.accessor((row) => row.login, createPlainColumn<CohortMarkingRow>('Login')),
          columnHelper.accessor((row) => row.name, createPlainColumn<CohortMarkingRow>('Name')),
        ],
      }),
      ...modulesForCohort.map((m) =>
        columnHelper.group({
          header: `${m.code} | ${m.title}`,
          columns: (groupedExercises[m.code] || []).map((e) =>
            columnHelper.accessor((row) => row.login, {
              id: `${m.code}-${e.number}`,
              header: () => exerciseHeader(e),
              cell: (info) => {
                const login = info.getValue()
                const mark = marksLookup.get(`${e.moduleCode}-${e.number}`)?.get(login)?.mark
                return isNumber(mark) ? calculatePercentageGrade(mark, e.maximumMark) : null
              },
              meta: {
                textIsCentred: true,
              },
              enableSorting: false,
            })
          ),
        })
      ),
    ],
    [columnHelper, groupedExercises, marksLookup, modulesForCohort]
  )

  function handleCohortChange(newValue: any) {
    setTableDataIsReady(false)
    setSelectedCohort(newValue ?? undefined)
  }

  const data = useMemo(() => {
    let newData = studentsForCohort.map((s) => ({ login: s.login, name: s.fullName }))
    setTableDataIsReady(true)
    return newData
  }, [studentsForCohort])

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <Container>
        <Helmet>
          <title>Cohort Marking Dashboard</title>
        </Helmet>
        <section style={{ marginBottom: '2rem' }}>
          <h1>Cohort Marking Dashboard</h1>
          <p>
            Choose a cohort to visualise the current overall state of the marking for all the
            relevant modules.
          </p>
        </section>
        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          <Select
            options={Object.entries(cohortMappings).map(([value, name]) => ({
              value,
              label: `${value}: ${name}`,
            }))}
            value={selectedCohort}
            onChange={handleCohortChange}
            placeholder="Select a cohort..."
          />
          {selectedCohort && !tableDataIsReady && <Banner>Loading data...</Banner>}
          {selectedCohort && tableDataIsReady && !data.length && <Banner>No data to show.</Banner>}
        </div>
      </Container>
      {allStudentsAreLoaded && !!modulesForCohort.length && (
        <Container section expandX>
          <div style={{ overflowX: 'scroll' }}>
            <GenericTanStackTableRenderer
              table={table}
              size="wide"
              additionalCellCSSGenerator={() => ({ borderRight: '0.1rem solid $neutral7' })}
            />
          </div>
        </Container>
      )}
    </>
  )
}

export default CohortMarkingDashboard
