import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { Mapping } from '../types/global'
import { EnrolledStudent, Module } from '../types/schemas/abc'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'
import { ExercisePayload } from './exercise.service'

export const useModule = () => {
  const axiosInstance = useContext(AxiosContext)
  const navigate = useNavigate()
  const { year, moduleCode } = useParams()
  const displayError = useErrorMessage()

  const [module, setModule] = useState<Module>()
  const [moduleIsLoaded, setModuleIsLoaded] = useState<boolean>(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.module(year!, moduleCode as string))
      .then(({ data }) => {
        setModule(plainToInstance(Module, data))
      })
      .catch(displayError('Failed to fetch module'))
      .finally(() => setModuleIsLoaded(true))
  }, [axiosInstance, displayError, moduleCode, year])

  function addExercise(exercise: ExercisePayload) {
    axiosInstance
      .post(
        endpoints.emarkingExercises(year!, moduleCode as string),
        objectKeysToSnakeCase(exercise)
      )
      .then(() => {
        navigate(`./../${exercise.number}/staff`)
      })
      .catch(displayError('Failed to create exercise'))
  }

  return { module, setModule, moduleIsLoaded, addExercise }
}

export const useEnrolledStudentsLookup = () => {
  const axiosInstance = useContext(AxiosContext)
  const { year, moduleCode } = useParams()
  const displayError = useErrorMessage()

  const [enrolledStudentsLookup, setEnrolledStudentsLookup] = useState<
    Mapping<string, EnrolledStudent>
  >({})
  useEffect(() => {
    axiosInstance
      .get(endpoints.enrolledStudents(year!, moduleCode!))
      .then(({ data }) => {
        let enrolled = mapPlainToInstance(EnrolledStudent, data)
        setEnrolledStudentsLookup(
          enrolled.reduce((acc: Mapping<string, EnrolledStudent>, student: EnrolledStudent) => {
            return { ...acc, [student.login]: student }
          }, {})
        )
      })
      .catch(displayError('Failed to get enrolled students'))
  }, [axiosInstance, displayError, moduleCode, year])

  return { enrolledStudentsLookup }
}
