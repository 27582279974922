import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import Dialog from '../../components/dialogs/Dialog'
import { DistributionCreationForm } from '../../components/forms/DistributionCreationForm'
import { endpoints } from '../../constants/endpoints'
import { ALL_MARKER_ROLES, CW_MARKER_ROLES } from '../../constants/roles'
import { useUser } from '../../contextManagers/user.context'
import {
  DistributionConfiguration,
  useEmarkingDistribution,
} from '../../hooks/emarkingDistribution.service'
import { useMarks } from '../../hooks/marks.service'
import { Banner } from '../../styles/_app.style'
import { Section } from '../../styles/root.style'
import { Mapping, RawMarks } from '../../types/global'
import { EnrolledStudent, Module, TutorialGroup } from '../../types/schemas/abc'
import { Exercise, Mark } from '../../types/schemas/emarking'
import { EmarkingDataRow } from '../../types/tablesDataRows'
import { groupByProperty } from '../../utils'
import MainToolbar from './MainToolbar'
import { MarkingBatches } from './MarkingBatches'
import ReallocationToolbar from './ReallocationToolbar'

const zipLoginFullName = (object: any) => [object.login, object.fullName]

const EmarkingDistribution: React.FC = () => {
  const { enrolledStudentsLookup, module, exercise, tutorialGroups } = useOutletContext<{
    module: Module
    exercise: Exercise
    tutorialGroups: TutorialGroup[]
    enrolledStudentsLookup: Mapping<string, EnrolledStudent>
  }>()
  const { userDetails } = useUser()
  const { distribution, deleteDistribution, distributionIsLoaded, actions } =
    useEmarkingDistribution(exercise)
  const [reallocationModeOn, setReallocationModeOn] = useState(false)
  const submissionsToReallocate = useRef<Set<string>>(new Set())

  const updateSubmissionsToReallocate = useCallback((toAdd: string[], toRemove: string[]) => {
    toAdd.forEach((s) => submissionsToReallocate.current.add(s))
    toRemove.forEach((s) => submissionsToReallocate.current.delete(s))
  }, [])

  function handleReallocation(marker: string) {
    actions.reallocate(marker, [...submissionsToReallocate.current])
    setReallocationModeOn(false)
  }

  function handleRefresh() {
    exercise.isForYearOneTutorialGroups
      ? actions.refresh({ allocations: markingAllocations })
      : actions.refresh({ markers: distribution!.markers })
  }

  let writeActionsEnabledForAllBatches =
    !exercise.isForYearOneTutorialGroups ||
    userDetails!.isStaffForModule(module.code) ||
    userDetails!.isYearCoordinator

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const availableMarkers = [...module.staff, ...module.helpers].map((s) => ({
    value: s.login,
    label: s.fullName,
  }))

  const markingAllocations = useMemo(() => {
    if (!exercise.isForYearOneTutorialGroups) return
    return Object.fromEntries(
      tutorialGroups.flatMap((g: TutorialGroup) => {
        let designatedMarker = exercise.type === 'MMT' ? g.tutor.login : g.uta!.login
        return g.members.map(({ login }) => [login, designatedMarker])
      })
    )
  }, [exercise, tutorialGroups])

  // Lookup tables ----------------------------------
  const studentLookup = useMemo(
    () => Object.fromEntries(Object.values(enrolledStudentsLookup).map(zipLoginFullName)),
    [enrolledStudentsLookup]
  )
  const markerLookup = useMemo(
    () =>
      Object.fromEntries(
        module.staff
          .map(zipLoginFullName)
          .concat(
            module.helpers
              .filter((h) =>
                (exercise.type === 'CW' ? CW_MARKER_ROLES : ALL_MARKER_ROLES).includes(h.role)
              )
              .map(zipLoginFullName)
          )
      ),
    [exercise, module]
  )
  const gutaLookup = useMemo(() => {
    if (!exercise.isForYearOneTutorialGroups) return
    let flattenedGroups = tutorialGroups.map((g) => ({
      label: g.toString(),
      marker: g.type === 'MMT' ? g.tutor.login : g.uta!.login,
    }))
    let gutaToGroups = groupByProperty(flattenedGroups, 'marker', 'label')
    return Object.fromEntries(
      Object.entries(gutaToGroups).map(([m, gs]) => {
        return [m, `(${gs.map((g) => g.label).join(', ')}) ${markerLookup[m]}`]
      })
    )
  }, [exercise, markerLookup, tutorialGroups])

  const feedbackLookup: Mapping<string, number> = useMemo(
    () =>
      distribution?.feedback.reduce(
        (acc, { id, studentUsername }) => ({
          ...acc,
          [studentUsername]: id,
        }),
        {}
      ) ?? {},
    [distribution]
  )

  // Marks ------------------------------------------------
  const { marks, marksArePublished, actions: marksActions } = useMarks(exercise)
  const lastSavedMarks = useMemo(
    () =>
      marks.reduce((acc: Mapping<string, number | null>, mark: Mark) => {
        return { ...acc, [mark.student_username]: mark.mark }
      }, {}),
    [marks]
  )
  const [markingBatches, setMarkingBatches] = useState<Mapping<string, EmarkingDataRow[]>>({})
  const [stagedMarks, setStagedMarks] = useState<RawMarks>({})

  useEffect(() => {
    if (!distribution) return
    let groupedRows = distribution.submissions.reduce(
      (acc: Mapping<string, EmarkingDataRow[]>, { id, marker, studentUsername }) => {
        return {
          ...acc,
          [marker]: [
            ...(acc[marker] || []),
            {
              login: studentUsername,
              fullName: studentLookup[studentUsername],
              distributionId: distribution.id,
              submissionId: id,
              mark:
                lastSavedMarks && lastSavedMarks[studentUsername] !== null
                  ? lastSavedMarks[studentUsername]
                  : null,
              feedbackId: feedbackLookup[studentUsername] || null,
            },
          ],
        }
      },
      {}
    )
    setMarkingBatches(groupedRows)
  }, [distribution, feedbackLookup, lastSavedMarks, studentLookup])

  function handleBatchUpdate(marker: string, rows: EmarkingDataRow[]) {
    setMarkingBatches((curr) => ({ ...curr, [marker]: rows }))
    setStagedMarks((curr) => {
      let updates = rows
        .filter((r) => !isNaN(r.mark as number) && r.mark !== lastSavedMarks[r.login])
        .map((r) => [r.login, r.mark])
      return { ...curr, ...Object.fromEntries(updates) }
    })
  }

  function saveMarks(marks: RawMarks) {
    marksActions.postMarks(marks, true).then(() => setStagedMarks({}))
  }

  const markingIsComplete = useMemo(
    () => Boolean(distribution?.feedbackPublished && marksArePublished),
    [distribution, marksArePublished]
  )

  function handleCompletionState(state: boolean) {
    if (distribution?.feedbackPublished !== state) actions.feedback.toggleVisibility(state)
    if (marksArePublished !== state) marksActions.toggleVisibility(state)
  }

  if (!['CW', 'T', 'PPT', 'PMT', 'MMT'].includes(exercise.type))
    return (
      <Section>
        <Banner>No emarking available for this type of exercise.</Banner>
      </Section>
    )

  if (!distributionIsLoaded)
    return (
      <Section>
        <Banner>Loading emarking data...</Banner>
      </Section>
    )

  if (distribution)
    return (
      <>
        <Section>
          {reallocationModeOn ? (
            <ReallocationToolbar
              availableMarkers={availableMarkers}
              onClose={() => setReallocationModeOn(false)}
              onConfirm={handleReallocation}
            />
          ) : (
            <MainToolbar
              markingIsComplete={markingIsComplete}
              stagedMarks={stagedMarks}
              publishEnabled={writeActionsEnabledForAllBatches}
              onToolbarToggle={() => setReallocationModeOn(true)}
              onRefresh={handleRefresh}
              onDelete={() => setDeleteDialogOpen(true)}
              onPublish={() => handleCompletionState(!markingIsComplete)}
              onSaveMarks={() => saveMarks(stagedMarks)}
            />
          )}
        </Section>
        <Section>
          <MarkingBatches
            exercise={exercise}
            markingBatches={markingBatches}
            labelLookup={{ ...studentLookup, ...markerLookup, ...gutaLookup }}
            currentUser={userDetails!.login}
            distributionDownloadURL={endpoints.zippedDistribution(distribution.id)}
            writeEnabledForAllBatches={writeActionsEnabledForAllBatches}
            selectionEnabled={reallocationModeOn}
            onSelectionChange={updateSubmissionsToReallocate}
            onFeedbackBatchUpload={actions.feedback.uploadBatch}
            onFeedbackUpload={actions.feedback.uploadSingle}
            onFeedbackReUpload={actions.feedback.reUploadSingle}
            onFeedbackDelete={actions.feedback.delete}
            onBatchUpdate={handleBatchUpdate}
          />
          <Dialog
            title={'Are you sure you want to delete the selected distribution?'}
            primaryButtonText={'Delete'}
            secondaryButtonText={'Cancel'}
            onPrimaryClick={() => deleteDistribution(distribution.id)}
            onOpenChange={setDeleteDialogOpen}
            open={deleteDialogOpen}
          />
        </Section>
      </>
    )

  return (
    <Section>
      <DistributionCreationForm
        exercise={exercise}
        availableMarkers={markerLookup}
        createDistribution={
          exercise.isForYearOneTutorialGroups
            ? (payload: DistributionConfiguration) =>
                actions.create({ ...payload, allocations: markingAllocations })
            : actions.create
        }
      />
    </Section>
  )
}

export default EmarkingDistribution
