import {
  FormControl,
  FormField,
  FormLabel,
  FormMessage,
  Root as FormRoot,
  FormSubmit,
} from '@radix-ui/react-form'

import { styled } from './stitches.config'

export const Input = styled('input', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '0.5rem',
  border: '0.0625rem solid $elementBorder',
  padding: '0.75rem',
  height: '2.75rem',
  backgroundColor: '$appBackground',
  transition: 'background-color 0.3s ease-in-out',
  '&:hover': {
    borderColor: '$solidBackground',
  },
  '&:focus': {
    borderColor: '$solidBackground',
  },
  '&:disabled': {
    opacity: '40%',
    cursor: 'not-allowed',
  },
})

export const Form = styled(FormRoot)
export const Field = styled(FormField, {
  variants: {
    inline: {
      true: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.5rem',
      },
    },
  },
})

export const LabelContainer = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
})

export const Label = styled(FormLabel)
export const Message = styled(FormMessage, {
  color: '$red10',
  variants: {
    info: {
      true: { color: '$lowContrast' },
    },
  },
})
export const Control = styled(FormControl)
export const Submit = styled(FormSubmit)
