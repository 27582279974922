import { CSS } from '@stitches/react'
import { isSameDay } from 'date-fns'
import React, { ReactNode } from 'react'
import { ExclamationCircle } from 'react-bootstrap-icons'

import { Span } from '../../styles/_app.style'
import { ExerciseLinkButton } from '../../styles/exercise/buttons.style'
import { SubText } from '../../styles/exercise/exercisesPage.style'
import { Exercise, ExerciseStatus } from '../../types/schemas/emarking'
import { now } from '../../utils'

const commonExerciseButtonProps = (exercise: Exercise) => ({
  disabled: exercise.startDate > now(),
  'aria-disabled': exercise.startDate > now(),
  to:
    exercise.startDate <= now()
      ? `/${exercise.year}/modules/${exercise.moduleCode}/exercises/${exercise.number}`
      : '#',
  title: 'View exercise details',
})

export const ExerciseButton = ({
  exercise,
  subtextChild,
  css,
}: {
  exercise: Exercise
  subtextChild: ReactNode
  css: CSS
}) => {
  return (
    <ExerciseLinkButton {...commonExerciseButtonProps(exercise)} css={css}>
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        {isSameDay(now(), exercise.deadline) && exercise.exerciseStatus === ExerciseStatus.DUE && (
          <ExclamationCircle size={22} color="red" />
        )}
        <div>
          <p>{exercise.fullTitle}</p>
          <SubText>{subtextChild}</SubText>
        </div>
      </div>
      <Span css={{ color: '$lowContrast' }}>{exercise.moduleContribution}</Span>
    </ExerciseLinkButton>
  )
}

export const ExercisePillButton = ({ exercise, css }: { exercise: Exercise; css: CSS }) => {
  return (
    <ExerciseLinkButton pill {...commonExerciseButtonProps(exercise)} css={css}>
      <p>{exercise.shortTitle}</p>
    </ExerciseLinkButton>
  )
}

export default ExerciseButton
