import { Expose, Type } from 'class-transformer'

class ModuleResult {
  @Expose({ name: 'module_code' })
  moduleCode: string
  outcome: string
}

class YearResult {
  year: number
  mark: number
  message: string
}

export class StudentResult {
  username: string

  @Type(() => ModuleResult)
  modules: ModuleResult[]

  @Type(() => YearResult)
  years: YearResult[]
}
