import { useEffect } from 'react'
import { Search } from 'react-bootstrap-icons'

import { Button } from '../../styles/_app.style'
import { SearchDiv, ShortcutLabel, TextIconGroup } from '../../styles/search/search.style'
import Tooltip from '../tooltip/Tooltip'

const SearchBox = ({ onClick }: { onClick: () => void }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault()
        onClick()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClick])

  return (
    <SearchDiv>
      <Tooltip label={'Search'}>
        <Button search={'desktop'} onClick={onClick}>
          <TextIconGroup>
            <Search size={16} />
            <span>Search course notes, lecture videos and more...</span>
          </TextIconGroup>
          <ShortcutLabel>Ctrl K</ShortcutLabel>
        </Button>
      </Tooltip>
    </SearchDiv>
  )
}

export default SearchBox
