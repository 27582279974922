import { Dictionary, head, sumBy } from 'lodash'
import React, { FC, useMemo } from 'react'
import { Trash3Fill } from 'react-bootstrap-icons'

import { Tabs } from '../../components/Tabs'
import ProgressBar from '../../components/progressBar/ProgressBar'
import Tooltip from '../../components/tooltip/Tooltip'
import { Banner, Button } from '../../styles/_app.style'
import {
  ActionBlock,
  ActionItemContainer,
  ActionableListContainer,
  ListBlock,
  ListItem,
  ListItemContainer,
  ListItemMainText,
  ListItemSubText,
} from '../../styles/list.style'
import { Pill } from '../../styles/pill.style'
import {
  ApprovalStatus,
  ExternalModule,
  ExternalModuleChoice,
  InternalModule,
  InternalModuleChoice,
  OfferingGroupLabel,
} from '../../types/schemas/moduleSelection'
import { ModuleOptionGroups } from './AvailableModulesPane'
import { MAX_ECTS, MIN_ECTS, TERMS } from './constants'
import { ChosenModulesPaneMain, ChosenModulesPaneMainScrollableArea } from './moduleSelection.style'

function approvalStatusToColour(status: ApprovalStatus) {
  switch (status) {
    case ApprovalStatus.APPROVED:
      return { backgroundColor: '$green7' }
    case ApprovalStatus.REJECTED:
      return { backgroundColor: '$red7' }
    case ApprovalStatus.PENDING:
      return { backgroundColor: '$amber7' }
    default:
      throw new Error('Invalid approval status')
  }
}

interface ChosenModulesPaneProps {
  availableExternalModules: Dictionary<ExternalModule>
  availableInternalModules: Dictionary<InternalModule>
  chosenInternalModules: InternalModuleChoice[]
  onChoiceDelete: (_: any) => void
  chosenExternalModules: ExternalModuleChoice[]
  onExternalApplyButtonClick: () => void
}

const ChosenModulesPane: FC<ChosenModulesPaneProps> = ({
  availableExternalModules,
  availableInternalModules,
  chosenInternalModules,
  chosenExternalModules,
  onExternalApplyButtonClick,
  onChoiceDelete,
}) => {
  const ectsTotal = useMemo(
    () => sumBy(chosenInternalModules, 'degreeRegulations.ects'),
    [chosenInternalModules]
  )
  const ExternalModuleChoices = ({ moduleChoices }: { moduleChoices: ExternalModuleChoice[] }) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1rem 0px 0.5rem',
          }}
        >
          <h3>{ModuleOptionGroups.EXTERNAL.toString()}</h3>
          <Tooltip label={'Apply for external module'}>
            <Button onClick={onExternalApplyButtonClick}>Choose</Button>
          </Tooltip>
        </div>
        <ListBlock>
          <Tabs
            attribute={'id'}
            data={moduleChoices}
            generator={({ status, externalModuleId }) => {
              return (
                <ListItemContainer css={{ justifyContent: 'space-between' }}>
                  <ListItem>
                    {availableExternalModules[externalModuleId]?.title ?? 'Unknown title'}
                  </ListItem>
                  <Pill css={approvalStatusToColour(status)}>{status}</Pill>
                </ListItemContainer>
              )
            }}
          />
        </ListBlock>
      </>
    )
  }

  const TermChoicesSummary = ({ term }: { term: number }) => {
    let chosenForTerm = chosenInternalModules.filter(
      (c) => head(availableInternalModules[c.degreeRegulations.moduleId]?.terms ?? []) === term
    )
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <h4>Term {term}</h4>
        {chosenForTerm.length === 0 ? (
          <Banner thin>No modules chosen for this term</Banner>
        ) : (
          <ActionableListContainer>
            <ListBlock>
              <Tabs
                attribute={'id'}
                data={chosenForTerm}
                generator={(c) => {
                  const module = availableInternalModules[c.degreeRegulations.moduleId]
                  return (
                    <ListItemContainer size="lg" style={{ justifyContent: 'space-between' }}>
                      <ListItem>
                        <ListItemMainText>{`${module.code} ${module.title}`}</ListItemMainText>
                        <ListItemSubText>
                          {head(module.regulations)?.offeringGroup.label}
                        </ListItemSubText>
                      </ListItem>
                    </ListItemContainer>
                  )
                }}
              />
            </ListBlock>

            <ActionBlock>
              {chosenForTerm.map((m) => (
                <ActionItemContainer key={m.id} size="lg">
                  <Tooltip label={'Delete module from your selection'}>
                    <Button
                      disabled={
                        m.degreeRegulations.offeringGroup.label === OfferingGroupLabel.REQUIRED
                      }
                      icon
                      onClick={() => onChoiceDelete(m.id)}
                    >
                      <Trash3Fill size={20} />
                    </Button>
                  </Tooltip>
                </ActionItemContainer>
              ))}
            </ActionBlock>
          </ActionableListContainer>
        )}
      </div>
    )
  }

  return (
    <ChosenModulesPaneMain>
      <ChosenModulesPaneMainScrollableArea>
        <h3>Your chosen modules</h3>
        <ProgressBar
          value={ectsTotal}
          max={MAX_ECTS}
          style={{
            transform: `translateX(-${100 - (ectsTotal / MAX_ECTS) * 100}%)`,
            backgroundColor: ectsTotal <= MIN_ECTS ? 'rgb(197,60,0)' : '#009c1d',
            willChange: 'transform',
          }}
          caption={`${ectsTotal} ECTS selected`}
        />
        {TERMS.map((t) => (
          <TermChoicesSummary key={t} term={t} />
        ))}
        <ExternalModuleChoices moduleChoices={chosenExternalModules} />
      </ChosenModulesPaneMainScrollableArea>
    </ChosenModulesPaneMain>
  )
}

export default ChosenModulesPane
