import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { MarkCapEditPayload } from '../components/dialogs/EditMarkCapDialog'
import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { Mapping } from '../types/global'
import { Exercise, Mark } from '../types/schemas/emarking'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useMarks = (exercise: Exercise) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { addToast } = useToast()
  const [marks, setMarks] = useState<Mark[]>([])
  const [marksArePublished, setMarksArePublished] = useState<boolean>(!!exercise.marksPublished)

  useEffect(() => {
    axiosInstance
      .get(endpoints.marks(exercise.year, exercise.moduleCode, exercise.number))
      .then(({ data }) => setMarks(mapPlainToInstance(Mark, data)))
      .catch(displayError('Unable to get marks'))
  }, [axiosInstance, displayError, exercise])

  function postMarks(marks: Mapping<string, number>, propagate?: boolean) {
    return axiosInstance
      .post(
        endpoints.marks(exercise.year, exercise.moduleCode, exercise.number),
        Object.entries(marks).map(([login, mark]) => {
          return { student_username: login, mark: mark }
        }),
        { ...(propagate ? { params: { propagate } } : {}) }
      )
      .then(({ data }) => {
        let newMarks = mapPlainToInstance(Mark, data)
        setMarks((current) => {
          return [
            ...newMarks,
            ...current.filter((m) => !Object.keys(marks).includes(m.student_username)),
          ]
        })
        addToast({ variant: 'success', title: 'Marks saved!' })
      })
      .catch(displayError('Unable to post marks'))
  }

  function deleteMark(username: string) {
    return axiosInstance
      .delete(endpoints.mark(exercise.year, exercise.moduleCode, exercise.number, username))
      .then(({ data }) => {
        let deletedMark = plainToInstance(Mark, data)
        setMarks((current) => current.map((m) => (m.id === deletedMark.id ? deletedMark : m)))
        addToast({ variant: 'success', title: 'Mark deleted' })
      })
      .catch(displayError('Unable to delete mark'))
  }

  function capMark(markID: number, payload: MarkCapEditPayload) {
    return axiosInstance
      .patch(
        endpoints.markByID(exercise.year, exercise.moduleCode, exercise.number, markID),
        objectKeysToSnakeCase(payload)
      )
      .then(({ data }) => {
        let editedMark = plainToInstance(Mark, data)
        setMarks((current) => current.map((m) => (m.id === markID ? editedMark : m)))
        addToast({ variant: 'success', title: 'Mark uncapped' })
      })
      .catch(displayError('Unable to uncap mark'))
  }

  function toggleVisibility(newVisibility: boolean) {
    axiosInstance
      .patch(endpoints.emarkingExercise(exercise.year, exercise.moduleCode, exercise.number), {
        marks_published: newVisibility,
      })
      .then(() => {
        setMarksArePublished(newVisibility)
        let message = exercise.marksHiddenToStudents
          ? `Marks ${newVisibility ? 'finalised' : 'un-finalised'}`
          : `Marks now ${newVisibility ? 'marksArePublished to' : 'hidden from'} students`
        addToast({ variant: 'success', title: message })
      })
      .catch(displayError('Unable to change marks visibility'))
  }

  return {
    marks,
    marksArePublished,
    actions: {
      toggleVisibility,
      postMarks,
      deleteMark,
      capMark,
    },
  }
}
