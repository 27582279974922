import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { baseURL } from '../constants/endpoints'
import useQueryParams from '../hooks/queryParams.service'
import useTokenRefresh from '../hooks/tokenRefresh.service'
import { Banner, Container } from '../styles/_app.style'
import { shortYear } from '../utils'

const ExternalResource = () => {
  const queryParams = useQueryParams()
  const url = queryParams.get('url')

  const { refreshAccessToken } = useTokenRefresh()

  // Redirect to `url`. Make sure to refresh access token
  // if the URL is under the Scientia-API domain
  useEffect(() => {
    if (url) {
      if (url.startsWith(baseURL)) refreshAccessToken().then(() => window.location.replace(url))
      else window.location.replace(url)
    }
  }, [refreshAccessToken, url])

  return url ? (
    <Container>
      <Banner>Opening resource...</Banner>
    </Container>
  ) : (
    <Navigate to={`/${shortYear()}/modules`} replace />
  )
}

export default ExternalResource
