import { Link } from 'react-router-dom'

import { styled } from './stitches.config'

export const ListItem = styled('div', {
  padding: '0.75rem',
  color: '$highContrast',
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  justifyContent: 'space-between',
  borderRadius: '0.5rem',

  '&:hover': {
    backgroundColor: '$neutral3',
  },
})

export const TabsWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
})

export const Tab = styled(Link, {
  padding: '0.75rem',
  color: '$highContrast',
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  justifyContent: 'space-between',
  borderRadius: '0.5rem',

  '&:hover': {
    backgroundColor: '$neutral3',
  },

  variants: {
    // Tabs are used as lists and as tabs proper.
    // This duplicitous semantics should be split
    // in two different styled components
    trueTab: {
      true: {
        backgroundColor: '$elementBackground',
        '&:hover': {
          backgroundColor: '$elementHover',
        },
        '&:active': {
          backgroundColor: '$elementActive',
        },
        "&[data-state='open']": {
          backgroundColor: '$elementActive',
        },
      },
    },
  },
})
