import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Check } from 'react-bootstrap-icons'

import { useReordering_ } from '../../hooks/dragDrop.service'
import { DistributionConfiguration } from '../../hooks/emarkingDistribution.service'
import { Banner, Button, Checkbox, Footnote, Indicator, Panel } from '../../styles/_app.style'
import { ListBlock, ListItem, ListItemContainer } from '../../styles/list.style'
import { Deliverable, Exercise } from '../../types/schemas/emarking'
import { Tabs } from '../Tabs'

enum Platforms {
  LABTS = 'labts',
  SCIENTIA = 'scientia',
}

const LABTS_FILES = ['preview.pdf', 'final.pdf'].map((f) => ({ id: f, selected: false }))
const OPTIONS = [{ id: 'addBlankPage', label: 'Add blank page', selected: false }]

interface Selectable {
  id: string
  label?: string
  selected: boolean
}

const inferPlatform = (exercise: Exercise) => {
  return exercise.deliverables.some((d) => d.name === 'commit')
    ? Platforms.LABTS
    : Platforms.SCIENTIA
}

const computeAvailableFiles = (deliverables: Deliverable[], platform: string): Selectable[] => {
  const labtsFiles = platform === Platforms.LABTS ? LABTS_FILES : []
  const scientiaFiles = deliverables
    .filter((d) => d.name !== 'commit')
    .map((d) => ({ id: d.name, selected: false }))
  return [...labtsFiles, ...scientiaFiles]
}

const LabelledCheckbox = ({
  selectable,
  callback,
}: {
  selectable: Selectable
  callback: (_: any) => void
}) => {
  let { id, label, selected } = selectable
  return (
    <>
      <Checkbox
        id={`${id}-checkbox`}
        checked={selected}
        onCheckedChange={() => {
          callback((curr: Selectable[]) =>
            curr.map((c: Selectable) => (c.id === id ? { ...c, selected: !selected } : c))
          )
        }}
      >
        <Indicator>
          <Check />
        </Indicator>
      </Checkbox>
      <label style={{ cursor: 'grab' }} htmlFor={`${id}-checkbox`}>
        {label ?? id}
      </label>
    </>
  )
}

export const DistributionCreationForm = ({
  exercise,
  availableMarkers,
  createDistribution,
}: {
  exercise: Exercise
  availableMarkers: { [_: string]: string }
  createDistribution: (_: DistributionConfiguration) => void
}) => {
  const platform = useMemo(() => inferPlatform(exercise), [exercise])
  const [selectedMarkers, setSelectedMarkers] = useState<Selectable[]>([])
  useEffect(() => {
    setSelectedMarkers(
      Object.entries(availableMarkers).map(([username, fullname]) => ({
        id: username,
        label: fullname,
        selected: false,
      }))
    )
  }, [availableMarkers])
  const [selectedFiles, setSelectedFiles] = useState<Selectable[]>([])
  useEffect(
    () => setSelectedFiles(computeAvailableFiles(exercise.deliverables, platform)),
    [exercise, platform]
  )
  const { onDragEnd } = useReordering_(selectedFiles, setSelectedFiles)
  const [selectedOptions, setSelectedOptions] = useState<Selectable[]>(Object.values(OPTIONS))

  function handleSubmit() {
    let targetFiles = selectedFiles.filter((cf) => cf.selected).map((cf) => cf.id)
    createDistribution({
      platform,
      target_files: targetFiles.join(' ') || null,
      add_blank_page: selectedOptions.find((o) => o.id === 'addBlankPage')!.selected,
      markers: selectedMarkers.filter((m) => m.selected).map((m) => m.id),
    })
  }

  const BulkSelectionButtons = ({ callback }: { callback: (_: any) => void }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem',
        }}
      >
        {[true, false].map((selected) => {
          let label = selected ? 'Select All' : 'Deselect All'
          return (
            <Button
              key={label}
              onClick={() =>
                callback((curr: Selectable[]) => curr.map((m) => ({ ...m, selected })))
              }
            >
              {label}
            </Button>
          )
        })}
      </div>
    )
  }

  const MarkersSection = () => {
    return exercise.isForYearOneTutorialGroups ? (
      <Banner>
        Each student submission will be distributed to the tutorial group's tutor/UTA assigned to
        the student at the beginning of the year.
      </Banner>
    ) : (
      <>
        <ListBlock>
          <Tabs
            attribute={'id'}
            data={selectedMarkers}
            allTabsCss={{ padding: '0.25rem' }}
            generator={(selectableMarker) => {
              return (
                <ListItemContainer>
                  <ListItem inline>
                    <LabelledCheckbox selectable={selectableMarker} callback={setSelectedMarkers} />
                  </ListItem>
                </ListItemContainer>
              )
            }}
          />
        </ListBlock>
        <BulkSelectionButtons callback={setSelectedMarkers} />
      </>
    )
  }

  const OptionsSection = () => {
    return (
      <ListBlock>
        <Tabs
          attribute={'id'}
          data={selectedOptions}
          allTabsCss={{ padding: '0.25rem' }}
          generator={(selectedOption) => {
            return (
              <ListItemContainer>
                <ListItem inline>
                  <LabelledCheckbox selectable={selectedOption} callback={setSelectedOptions} />
                </ListItem>
              </ListItemContainer>
            )
          }}
        />
      </ListBlock>
    )
  }

  const DeliveredFilesSection = () => {
    return (
      <>
        <ListBlock>
          <DragDropContext onDragEnd={onDragEnd}>
            <Tabs
              attribute={'id'}
              data={selectedFiles}
              dragDropOptions={{
                dragEnabled: true,
                droppableId: 'fileList',
              }}
              allTabsCss={{ padding: '0.25rem' }}
              generator={(selectableFile) => {
                return (
                  <ListItemContainer>
                    <ListItem inline>
                      <LabelledCheckbox selectable={selectableFile} callback={setSelectedFiles} />
                    </ListItem>
                  </ListItemContainer>
                )
              }}
            />
          </DragDropContext>
        </ListBlock>
        <BulkSelectionButtons callback={setSelectedFiles} />
      </>
    )
  }

  return (
    <Panel>
      <h3>Select markers</h3>
      <MarkersSection />
      <h3>Choose files to collect</h3>
      <Footnote muted>
        Select the files you wish to mark and reorder them as you see fit. <br />
        {platform === Platforms.LABTS
          ? "Note: 'preview.pdf' and 'final.pdf' were autogenerated by LabTS."
          : ''}
      </Footnote>
      <DeliveredFilesSection />
      <h3>Options</h3>
      <OptionsSection />
      <Button
        disabled={
          !exercise.isForYearOneTutorialGroups &&
          (!selectedMarkers.map((m) => m.selected).some(Boolean) ||
            !selectedFiles.map((f) => f.selected).some(Boolean))
        }
        onClick={handleSubmit}
      >
        Collect and allocate submissions
      </Button>
    </Panel>
  )
}
