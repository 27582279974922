import { format } from 'date-fns'
import React, { useContext, useState } from 'react'

import { ThemeContext } from '../../contextManagers/theme.context'
import { Button } from '../../styles/_app.style'
import { Submit } from '../../styles/form.style'
import {
  CalendarContainer,
  FromToHeader,
  PeriodContainer,
} from '../../styles/moduleSelectionConfiguration'
import { CalendarInput, TimeInput } from '../../styles/resource-details-form.style'
import Dialog from './Dialog'

export interface ModuleSelectionPeriodPayload {
  startDate: Date
  endDate: Date
  startTime: Date
  endTime: Date
}

const ModuleSelectionPeriodDialog = ({
  open,
  onOpenChange,
  onSubmit,
}: {
  open: boolean
  onOpenChange: (_: boolean) => void
  onSubmit: (payload: ModuleSelectionPeriodPayload) => void
}) => {
  const DATETIME_LOCAL_FORMAT = 'yyyy-MM-dd'
  const TIME_FORMAT = 'HH:mm'
  const { theme } = useContext(ThemeContext)
  const initialData = {
    startDate: new Date(),
    endDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
  }

  const [formData, setFormData] = useState<ModuleSelectionPeriodPayload>(initialData)

  function applyPatch(
    key: keyof ModuleSelectionPeriodPayload,
    value: ModuleSelectionPeriodPayload[typeof key]
  ) {
    setFormData((current) => ({ ...current, [key]: value }))
  }

  const timeStringToDate = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number)
    const baseDate = new Date(formData.startTime)
    baseDate.setHours(hours, minutes, 0, 0)
    return baseDate
  }

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      onPrimaryClick={() => onSubmit(formData)}
      title="Add Module Selection Period"
    >
      <PeriodContainer>
        <FromToHeader>From</FromToHeader>
        <CalendarContainer style={{ colorScheme: theme }}>
          <CalendarInput
            type="date"
            style={{ marginRight: '1rem' }}
            required
            value={format(formData.startDate, DATETIME_LOCAL_FORMAT)}
            onChange={({ target: { value } }) =>
              applyPatch('startDate', new Date(Date.parse(value)))
            }
          />
          <TimeInput
            type="time"
            required
            value={format(formData.startTime, TIME_FORMAT)}
            onChange={({ target: { value } }) => applyPatch('startTime', timeStringToDate(value))}
          />
        </CalendarContainer>

        <FromToHeader>To</FromToHeader>
        <CalendarContainer style={{ colorScheme: theme }}>
          <CalendarInput
            type="date"
            style={{ marginRight: '1rem' }}
            required
            value={format(formData.endDate, DATETIME_LOCAL_FORMAT)}
            onChange={({ target: { value } }) => applyPatch('endDate', new Date(Date.parse(value)))}
          />
          <TimeInput
            type="time"
            required
            value={format(formData.endTime, TIME_FORMAT)}
            onChange={({ target: { value } }) => applyPatch('endTime', timeStringToDate(value))}
          />
        </CalendarContainer>

        <Submit asChild>
          <Button>Submit</Button>
        </Submit>
      </PeriodContainer>
    </Dialog>
  )
}

export default ModuleSelectionPeriodDialog
