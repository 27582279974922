import { styled } from './stitches.config'

export const Main = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '7rem',
  marginBottom: '3rem',
  gap: '2rem',

  variants: {
    noMargins: {
      true: {
        marginTop: '$navMargin',
        marginBottom: 0,
      },
    },
    fullHeight: {
      true: {
        height: `calc(100vh - $navHeight)`,
      },
    },
  },
})

export const Section = styled('section', {
  width: '100%',
  maxWidth: '$appMainWidth',
  margin: '0 auto',
  padding: '0 1rem 0 1rem',

  variants: {
    expanded: {
      true: {
        maxWidth: '95%',
      },
    },
  },
})

export const TableSection = styled(Section, {
  overflowX: 'scroll',
})

export const SubHeader = styled('h3', {
  color: '$lowContrast',
  fontWeight: '400',
  margin: '0',
})
