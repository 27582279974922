import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { Session } from '../types/schemas/tutoring'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useTutorialSessions = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const [tutorialSessions, setTutorialSessions] = useState<Session[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.tutorialSessions(year))
      .then(({ data }) => setTutorialSessions(mapPlainToInstance(Session, data)))
      .catch(displayError('Failed to get session attendances'))
  }, [axiosInstance, displayError, year])

  return {
    tutorialSessions,
  }
}
