import ReactMarkdown from 'react-markdown'

import { FlexContainer } from '../../styles/_app.style'
import { styled } from '../../styles/stitches.config'

export const GRID_ITEM_PADDING = '1rem'

export const Grid = styled('div', {
  display: 'grid',
  height: '100%',
  gridTemplateColumns: '25% 50% 25%',
  '@media (max-width: 1050px)': {
    gridTemplateColumns: '100%',
  },
})

export const GridItem = styled('div', {
  position: 'relative',
})

export const GridItemContent = styled('div', {
  padding: GRID_ITEM_PADDING,
  height: '100%',
  width: '100%',
  '@media (max-width: 1050px)': {
    marginBottom: '4rem',
  },
})

export const LeftSidebarHeader = styled('div', {
  display: 'flex',
  justifyContent: 'left',
  flexWrap: 'wrap',
})

export const Card = styled('div', {
  width: '100%',
  backgroundColor: '$elementHover',
  borderRadius: '1rem',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  overflowY: 'auto',
  minWidth: '400px',
  '@media (max-width: 1224px) and (orientation: portrait)': {
    margin: '0',
    padding: '1rem',
    minWidth: '0',
  },
})

export const Markdown = styled(ReactMarkdown, {
  '> p:not(:last-child)': {
    marginBottom: '0.5rem',
  },
})

export const StatusLabel = styled('div', {
  borderRadius: '8px',
  padding: '0.5rem',
  fontSize: '0.75rem',
  cursor: 'default',
  marginBottom: '1rem',
  textAlign: 'center',
  marginLeft: '1rem',
  height: 'fit-content',
  marginTop: 'auto',
  fontWeight: 600,
  '&.closed': {
    border: '2px solid #ffa0a0',
    color: '#ffa0a0',
  },
  '&.open': {
    border: '2px solid #0a9400',
    color: '#0a9400',
  },
})

/* ChosenModulesPane */

export const ChosenModulesPaneMain = styled(FlexContainer, {
  justifyContent: 'space-between',
  gap: 0,
  flexDirection: 'column',
})

export const ChosenModulesPaneMainScrollableArea = styled(FlexContainer, {
  height: `calc(100vh - $navHeight - 6rem)`,
  overflowY: 'auto',
  flexDirection: 'column',
})

export const FixedBottomContainer = styled('div', {
  position: 'absolute',
  zIndex: 2,
  bottom: 0,
  backgroundColor: '$appBackground',
  width: '100%',
  padding: '1rem',
  height: '4.75rem',
  alignContent: 'center',
})
