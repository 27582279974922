import { Expose, Type, instanceToPlain } from 'class-transformer'
import { format } from 'date-fns'

export class Attendance {
  id: number
  username: string
  present?: boolean
}

export class Session {
  id: number

  @Type(() => Date)
  date: Date

  group: string

  @Type(() => Attendance)
  attendances: Attendance[]

  @Expose({ name: 'tutor_is_present' })
  tutorIsPresent: boolean
}

/**
 * For some reason instanceToPlain() won't let me serialise Date() properly in
 * {@link Session} (you're meant to use @Transform decorators but these seem to never be called).
 *
 * So I wrote my own function to do it and make sure the Date field is correct
 */
export function sessionInstanceToPlain(session: Session) {
  return {
    ...instanceToPlain(session),
    date: format(session.date, 'yyyy-MM-dd'),

    // Ensure any present: undefined fields are set to false in attendances
    attendances: session.attendances.map((attendance) => ({
      ...attendance,
      present: attendance.present ?? false,
    })),
    tutor_is_present: session.tutorIsPresent,
  }
}

/** API response for when we are requesting /me routes for sessions as a logged in student */
export class StudentTutoringSession {
  id: number

  @Type(() => Date)
  date: Date

  group: string

  @Type(() => Attendance)
  present: boolean
}

export class PersonalTutorMeeting {
  id: number
  year: string
  tutor: string
  tutee: string
  label: string
  @Expose({ name: 'meeting_date' })
  @Type(() => Date)
  date: Date
}
