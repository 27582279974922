import { Dictionary } from 'lodash'
import React, { FC } from 'react'
import { ExclamationCircle, PlusCircle } from 'react-bootstrap-icons'

import { Tabs } from '../../components/Tabs'
import Tooltip from '../../components/tooltip/Tooltip'
import { Banner, Button } from '../../styles/_app.style'
import {
  ActionBlock,
  ActionItemContainer,
  ActionableListContainer,
  ListBlock,
  ListItem,
  ListItemContainer,
  ListItemMainText,
} from '../../styles/list.style'
import { ToggleGroup, ToggleGroupItem } from '../../styles/toggleGroup.style'
import { Mapping } from '../../types/global'
import {
  InternalModule,
  OfferingGroupLabel,
  OfferingGroupLabelOrder,
} from '../../types/schemas/moduleSelection'
import { TERMS } from './constants'
import { LeftSidebarHeader, StatusLabel } from './moduleSelection.style'

export enum ModuleOptionGroups {
  REQUIRED = 'Required',
  SELECTIVE = 'Selective',
  EXTERNAL = 'External',
  OPTIONAL = 'Option',
}

interface AvailableModulesPaneProps {
  moduleSelectionIsOpen: boolean
  // todo: Perhaps this should be its own type?
  modulesPerTerm: Mapping<number, Mapping<string, InternalModule[]>>
  selectedTerm: number
  onTermChange: (_: number) => void
  onModuleInfoClick: (_: any | undefined) => void
  onModuleAdd: (moduleCode: string) => void
  chosenModuleIDs: Set<number>
  timetableClashes: Dictionary<string>
}

const AvailableModulesPane: FC<AvailableModulesPaneProps> = ({
  moduleSelectionIsOpen,
  modulesPerTerm,
  selectedTerm,
  onTermChange,
  onModuleInfoClick,
  onModuleAdd,
  chosenModuleIDs,
  timetableClashes,
}) => {
  const InternalModulesGroup = ({
    groupLabel,
    modules,
  }: {
    groupLabel: string
    modules: InternalModule[]
  }) => {
    // This is a kind of hack at the moment. We should really query the constraints for the degree
    // and get the relevant values for the appropriate offering group from there
    const groupMin = modules[0].regulations[0].offeringGroup.min
    const groupMax = modules[0].regulations[0].offeringGroup.max
    return (
      <div>
        <div
          style={{
            margin: '.5rem 0 .5rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3 style={{ margin: 0 }}>{groupLabel}</h3>
          {groupLabel !== OfferingGroupLabel.REQUIRED && (
            <span>
              {groupMin} - {groupMax} ECTS
            </span>
          )}
        </div>
        <ActionableListContainer>
          <ListBlock>
            <Tabs
              attribute={'id'}
              data={modules}
              generator={(m) => {
                return (
                  <ListItemContainer onClick={() => onModuleInfoClick(m)}>
                    <ListItem>
                      <ListItemMainText>
                        {m.code} {m.title}
                      </ListItemMainText>
                    </ListItem>
                  </ListItemContainer>
                )
              }}
            />
          </ListBlock>
          {groupLabel !== OfferingGroupLabel.REQUIRED && (
            <ActionBlock>
              {modules.map((m) => {
                const hasClash = ![undefined, m.code].includes(
                  timetableClashes[m.examTimetableConstraint]
                )
                return (
                  <ActionItemContainer key={m.id}>
                    <Tooltip
                      label={
                        hasClash
                          ? 'Module incompatible with current selection'
                          : 'Add module to your selection'
                      }
                    >
                      <Button
                        icon
                        disabled={chosenModuleIDs.has(m.id) || hasClash}
                        onClick={() => onModuleAdd(m.code)}
                      >
                        {hasClash ? <ExclamationCircle size={20} /> : <PlusCircle size={20} />}
                      </Button>
                    </Tooltip>
                  </ActionItemContainer>
                )
              })}
            </ActionBlock>
          )}
        </ActionableListContainer>
      </div>
    )
  }

  return (
    <>
      <LeftSidebarHeader>
        <h2>Modules on offer</h2>
        <StatusLabel className={moduleSelectionIsOpen ? 'open' : 'closed'}>
          {moduleSelectionIsOpen ? 'Open' : 'Closed'}
        </StatusLabel>
      </LeftSidebarHeader>
      <ToggleGroup
        block
        type="single"
        value={selectedTerm.toString()}
        defaultValue={TERMS[0].toString()}
        onValueChange={(v) => v && onTermChange(parseInt(v))}
      >
        {TERMS.map((t) => {
          return (
            <ToggleGroupItem block key={t} value={t.toString()} aria-label="Term selection">
              Term {t}
            </ToggleGroupItem>
          )
        })}
      </ToggleGroup>
      <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {selectedTerm in modulesPerTerm ? (
          OfferingGroupLabelOrder.filter((l) => l in (modulesPerTerm[selectedTerm] ?? {})).map(
            (l) => (
              <InternalModulesGroup
                key={l}
                groupLabel={l}
                modules={modulesPerTerm[selectedTerm][l]}
              />
            )
          )
        ) : (
          <Banner key={`${selectedTerm}-placeholder`}>No modules on offer for this term</Banner>
        )}
      </div>
    </>
  )
}

export default AvailableModulesPane
