import { instanceToPlain, plainToInstance } from 'class-transformer'
import { useCallback, useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { Configuration, ModuleSelectionStatus } from '../types/schemas/moduleSelection'
import { useErrorMessage } from './errorMessage.service'

export interface PeriodPayload {
  start: Date
  end: Date
}

export const useModuleSelectionConfiguration = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [configuration, setConfiguration] = useState<Configuration>()
  const { addToast } = useToast()

  useEffect(() => {
    axiosInstance
      .get(endpoints.configuration(year))
      .then(({ data }) => {
        setConfiguration(plainToInstance(Configuration, data))
      })
      .catch(displayError('Failed to fetch configurations'))
  }, [axiosInstance, displayError, year])

  function updateConfiguration(status: ModuleSelectionStatus) {
    if (!configuration) return
    axiosInstance
      .patch(endpoints.configuration(year), {
        status,
      })
      .then(({ data }) => {
        setConfiguration(plainToInstance(Configuration, data))
        addToast({ variant: 'success', title: `Configuration Status Updated` })
      })
      .catch(displayError('Failed to update configuration'))
  }

  function addPeriod(payload: PeriodPayload) {
    if (!configuration) return
    axiosInstance
      .post(endpoints.configurationPeriods(year), payload)
      .then(({ data }) => {
        let rawConfiguration = instanceToPlain(configuration)
        setConfiguration(
          plainToInstance(Configuration, {
            ...rawConfiguration,
            periods: [...rawConfiguration.periods, data],
          })
        )
        addToast({ variant: 'success', title: 'Module selection period added' })
      })
      .catch(displayError('Failed to add module selection period'))
  }

  function deletePeriod(periodId: number) {
    if (!configuration) return
    axiosInstance
      .delete(endpoints.configurationPeriod(year, periodId))
      .then(() => {
        let rawConfiguration = instanceToPlain(configuration)
        setConfiguration(
          plainToInstance(Configuration, {
            ...rawConfiguration,
            periods: rawConfiguration.periods.filter((p: any) => p.id !== periodId),
          })
        )
        addToast({ variant: 'success', title: 'Module selection period deleted' })
      })
      .catch(displayError('Failed to delete module selection period'))
  }

  function updatePeriod(periodID: number, payload: PeriodPayload) {
    axiosInstance
      .put(endpoints.configurationPeriod(year, periodID), payload)
      .then(({ data }) => {
        let rawConfiguration = instanceToPlain(configuration)
        setConfiguration(
          plainToInstance(Configuration, {
            ...rawConfiguration,
            periods: [
              ...rawConfiguration.periods.filter((p: any) => p.id !== periodID),
              { id: data.id, start: data.start, end: data.end },
            ],
          })
        )
        addToast({ variant: 'success', title: 'Module selection period edited' })
      })
      .catch(displayError('Failed to edit module selection period'))
  }

  return {
    configuration,
    updateConfiguration: useCallback(updateConfiguration, [
      addToast,
      axiosInstance,
      configuration,
      displayError,
      year,
    ]),
    periodsActions: {
      addPeriod: useCallback(addPeriod, [
        addToast,
        axiosInstance,
        configuration,
        displayError,
        year,
      ]),
      deletePeriod: useCallback(deletePeriod, [
        addToast,
        axiosInstance,
        configuration,
        displayError,
        year,
      ]),
      updatePeriod: useCallback(updatePeriod, [
        addToast,
        axiosInstance,
        configuration,
        displayError,
        year,
      ]),
    },
  }
}
