import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useUser } from '../../contextManagers/user.context'

const DEFAULT_TARGET = '/404'
export const RoleProtectedRoute = ({ roles, to }: { roles: string[]; to: ReactNode }) => {
  const { userDetails } = useUser()

  return userDetails?.rolesInDepartment.some((r) =>
    roles.some((t) => new RegExp(t, 'i').test(r))
  ) ? (
    <>{to}</>
  ) : (
    <Navigate to={DEFAULT_TARGET} replace />
  )
}
