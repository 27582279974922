import React from 'react'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'

import { Banner } from '../../styles/_app.style'
import { UserDetails } from '../../types/schemas/abc'

export function levelEnrolmentIssue(userDetails?: UserDetails, moduleCode?: string) {
  if (userDetails?.isStaff || userDetails?.isGUTAForModule(moduleCode as string)) return false
  const moduleLevel = userDetails?.learningModules.find((m) => m.code === moduleCode)?.level
  return moduleLevel === 1 || moduleLevel === 2
}

interface LevelSubmissionWarningProps {
  userDetails?: UserDetails
  moduleCode?: string
}

export const LevelSubmissionWarning = ({
  userDetails,
  moduleCode,
}: LevelSubmissionWarningProps) => {
  if (!userDetails || !moduleCode) return null

  const WARNING_MESSAGE = (
    <>
      You are not enrolled in this module at Level 3. If you wish to take the module for credit,{' '}
      <a href="https://infosys.doc.ic.ac.uk/internalreg" target="_blank" style={{ color: 'blue' }}>
        register at Level 3
      </a>{' '}
      by Monday 28 October.
    </>
  )

  return levelEnrolmentIssue(userDetails, moduleCode) ? (
    <Banner thin level={'alert'} style={{ marginBottom: '1em' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ExclamationTriangleFill size={50} />
        <strong style={{ textAlign: 'left', marginLeft: '1em' }}>{WARNING_MESSAGE}</strong>
      </div>
    </Banner>
  ) : null
}
