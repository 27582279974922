import { styled } from '../stitches.config'

export const SubmissionStatusContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.35rem',

  b: {
    fontSize: '$sm',
  },

  variants: {
    status: {
      gnfp: {
        'b, svg': {
          color: '$sand9',
          fill: '$sand9',
        },
      },
      pending: {
        'b, svg': {
          color: '$sand9',
          fill: '$sand9',
        },
      },
      late: {
        'b, svg': {
          color: '$red8',
          fill: '$red8',
        },
      },
      missed: {
        'b, svg': {
          color: '$red8',
          fill: '$red8',
        },
      },
      overdue: {
        'b, svg': {
          color: '$red9',
          fill: '$red9',
        },
      },
      submitted: {
        'b, svg': {
          color: '$blue9',
          fill: '$blue9',
        },
      },
    },
  },
})
