import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useUser } from '../../contextManagers/user.context'

const DEFAULT_TARGET = '/404'
export const StaffOnlyRoute = ({ to }: { to: ReactNode }) => {
  const { userDetails } = useUser()
  return userDetails?.isStaff ? <>{to}</> : <Navigate to={DEFAULT_TARGET} replace />
}
