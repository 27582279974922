import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { PersonalTutoringAllocation } from '../types/schemas/abc'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const usePersonalTutoringAllocations = (year: string) => {
  const [tutoringAllocations, setTutoringAllocations] = useState<PersonalTutoringAllocation[]>([])
  const [tutoringAllocationsAreLoaded, setTutoringAllocationsAreLoaded] = useState(false)
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  useEffect(() => {
    axiosInstance
      .get(endpoints.tutoringAllocations(year))
      .then(({ data }) => {
        setTutoringAllocations(mapPlainToInstance(PersonalTutoringAllocation, data))
      })
      .catch(displayError('Failed to retrieve list of tutoring allocations'))
      .finally(() => setTutoringAllocationsAreLoaded(true))
  }, [year, axiosInstance, displayError])

  return { tutoringAllocations, tutoringAllocationsAreLoaded }
}

export const usePersonalTutoringAllocationsForTutor = (year: string, tutor: string) => {
  const [tutoringAllocations, setTutoringAllocations] = useState<PersonalTutoringAllocation[]>([])
  const [tutoringAllocationsAreLoaded, setTutoringAllocationsAreLoaded] = useState(false)
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  useEffect(() => {
    if (!tutor) return
    axiosInstance
      .get(endpoints.tutoringAllocations(year), { params: { tutor } })
      .then(({ data }) => {
        setTutoringAllocations(mapPlainToInstance(PersonalTutoringAllocation, data))
      })
      .catch(displayError('Failed to retrieve list of tutoring allocations'))
      .finally(() => setTutoringAllocationsAreLoaded(true))
  }, [tutor, year, axiosInstance, displayError])

  return { tutoringAllocations, tutoringAllocationsAreLoaded }
}
