import { format } from 'date-fns'
import React, { useContext, useState } from 'react'

import { ThemeContext } from '../../contextManagers/theme.context'
import { PeriodPayload } from '../../hooks/moduleSelectionConfiguration.service'
import { getUTCDatetime } from '../../hooks/resource.service'
import { ActionButton } from '../../styles/dialog.style'
import {
  CalendarContainer,
  FromToHeader,
  PeriodContainer,
} from '../../styles/moduleSelectionConfiguration'
import { CalendarInput, TimeInput } from '../../styles/resource-details-form.style'
import { ModuleSelectionPeriod } from '../../types/schemas/moduleSelection'
import ModuleSelectionPeriodDialog from '../dialogs/ModuleSelectionPeriodDialog'

export const PeriodSettings = ({
  periods,
  actions,
}: {
  periods: ModuleSelectionPeriod[]
  actions: {
    addPeriod: (_: PeriodPayload) => void
    deletePeriod: (_: number) => void
    updatePeriod: (id: number, payload: PeriodPayload) => void
  }
}) => {
  const { theme } = useContext(ThemeContext)
  const [addNewPeriodDialogOpen, setAddNewPeriodDialogOpen] = useState(false)

  const toDateTime = (date: Date, time: Date) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      0,
      0
    )
  }
  const SinglePeriod = ({ id, start, end }: { id: number; start: Date; end: Date }) => {
    const [dateFrom, setDateFrom] = useState<Date>(start)
    const [dateTo, setDateTo] = useState<Date>(end)
    return (
      <PeriodContainer key={id}>
        <FromToHeader>From</FromToHeader>
        <CalendarContainer style={{ colorScheme: theme }}>
          <CalendarInput
            type="date"
            style={{ marginRight: '1rem' }}
            required
            value={format(dateFrom, 'yyyy-MM-dd')}
            onChange={(e) => setDateFrom(getUTCDatetime(e.target.value, format(dateFrom, 'HH:mm')))}
          />
          <TimeInput
            type="time"
            required
            value={format(dateFrom, 'HH:mm')}
            onChange={(e) =>
              setDateFrom(getUTCDatetime(format(dateFrom, 'yyyy-MM-dd'), e.target.value))
            }
          />
        </CalendarContainer>

        <FromToHeader>To</FromToHeader>
        <CalendarContainer style={{ colorScheme: theme }}>
          <CalendarInput
            type="date"
            style={{ marginRight: '1rem' }}
            required
            value={format(dateTo, 'yyyy-MM-dd')}
            onChange={(e) => setDateTo(getUTCDatetime(e.target.value, format(dateTo, 'HH:mm')))}
          />
          <TimeInput
            type="time"
            required
            value={format(dateTo, 'HH:mm')}
            onChange={(e) =>
              setDateTo(getUTCDatetime(format(dateTo, 'yyyy-MM-dd'), e.target.value))
            }
          />
        </CalendarContainer>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <ActionButton.Tertiary onClick={() => actions.deletePeriod(id)}>
              Delete
            </ActionButton.Tertiary>
            <ActionButton.Secondary
              onClick={(e) =>
                actions.updatePeriod(id, {
                  start: dateFrom,
                  end: dateTo,
                })
              }
            >
              Update
            </ActionButton.Secondary>
          </div>
        </div>
      </PeriodContainer>
    )
  }

  return (
    <>
      <h2>Periods</h2>
      <p style={{ marginBottom: '1rem' }}>
        Enrolment periods are dates between which enrolment is active for students. To use this
        feature, make sure 'Use Periods' is set above.
      </p>
      {periods
        .sort((a, b) => a.id - b.id)
        .map((period) => (
          <SinglePeriod {...period} key={period.id} />
        ))}
      <ModuleSelectionPeriodDialog
        open={addNewPeriodDialogOpen}
        onOpenChange={setAddNewPeriodDialogOpen}
        onSubmit={({ startDate, startTime, endDate, endTime }) => {
          actions.addPeriod({
            start: toDateTime(startDate, startTime),
            end: toDateTime(endDate, endTime),
          })
        }}
      />
      <ActionButton.Tertiary
        style={{ width: '100%', marginBottom: '2rem' }}
        onClick={() => setAddNewPeriodDialogOpen(true)}
      >
        Add New Period
      </ActionButton.Tertiary>
    </>
  )
}
