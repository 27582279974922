import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  CellValues,
  createExpanderColumn,
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useAllStudents } from '../../hooks/allStudents.service'
import { usePersonalTutoringMeetings } from '../../hooks/personaTutoring.service'
import { usePersonalTutoringAllocations } from '../../hooks/ptAllocations.service'
import { Banner } from '../../styles/_app.style'
import { Input } from '../../styles/form.style'
import { Main, Section, TableSection } from '../../styles/root.style'
import { formatShortYear, groupByProperty, toLookupTable } from '../../utils'

const COHORT_WITHOUT_PT_REGEX = /([irx])\d/

type PTMeetingRow = {
  login: string
  name: string
  cohort: string
  tutor: string
  date: Date | null
  subRows?: PTMeetingRow[]
}

const PersonalTutoringDashboard = () => {
  const { year } = useParams()
  const { personalTutorMeetings } = usePersonalTutoringMeetings(year as string)
  const { tutoringAllocations, tutoringAllocationsAreLoaded } = usePersonalTutoringAllocations(
    year as string
  )
  const { allStudents, allStudentsAreLoaded } = useAllStudents(year as string)

  const columnHelper = createColumnHelper<PTMeetingRow>()
  const columns = [
    columnHelper.accessor((row) => {}, createExpanderColumn<PTMeetingRow>()),
    columnHelper.accessor((row) => row.login, createPlainColumn<PTMeetingRow>('Login')),
    columnHelper.accessor((row) => row.name, createPlainColumn<PTMeetingRow>('Name')),
    columnHelper.accessor((row) => row.cohort, createPlainColumn<PTMeetingRow>('Cohort')),
    columnHelper.accessor((row) => row.tutor, createPlainColumn<PTMeetingRow>('Tutor')),
    columnHelper.accessor((row) => row.date, {
      id: 'date',
      cell: (info) => {
        let value = info.getValue()
        return value ? format(value, 'Y-M-dd') : null
      },
      header: 'Date',
      sortingFn: 'datetime',
      sortUndefined: 1,
      enableGlobalFilter: false,
    }),
  ]

  const data = useMemo(() => {
    const tutorLookup = toLookupTable(tutoringAllocations, 'tuteeUsername')
    const groupedMeetings = groupByProperty(personalTutorMeetings, 'tutee', 'date', false, false)
    const meetingsLookup = new Map(Object.entries(groupedMeetings))
    return allStudents
      .filter((s) => !COHORT_WITHOUT_PT_REGEX.test(s.cohort))
      .map((s) => {
        const tutorUsername = !tutoringAllocationsAreLoaded
          ? CellValues.LOADING
          : tutorLookup.get(s.login)?.tutorUsername ?? CellValues.NOT_FOUND
        let meetings = meetingsLookup.get(s.login) || []
        return {
          login: s.login,
          name: `${s.firstname} ${s.lastname}`,
          cohort: s.cohort,
          tutor: tutorUsername,
          date: meetings.length > 0 ? meetings[0].date : null,
          subRows: meetings.slice(1).map((m) => ({
            login: s.login,
            name: `${s.firstname} ${s.lastname}`,
            cohort: s.cohort,
            tutor: tutorUsername,
            date: m.date,
          })),
        }
      })
  }, [allStudents, personalTutorMeetings, tutoringAllocations, tutoringAllocationsAreLoaded])

  const [globalFilter, setGlobalFilter] = React.useState('')
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      expanded,
      sorting,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <Main>
      <PageHeader title="Personal Tutoring Dashboard">
        <p>This is an overview of personal tutoring for {formatShortYear(year)}.</p>
      </PageHeader>

      <Section>
        {!allStudentsAreLoaded ? (
          <Banner>Loading data...</Banner>
        ) : allStudents.length === 0 ? (
          <Banner>No students to show.</Banner>
        ) : (
          <>
            <Input
              type="text"
              placeholder="Type your filter here..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </>
        )}
      </Section>
      <TableSection>
        <GenericTanStackTableRenderer table={table} size="wide" />
      </TableSection>
    </Main>
  )
}

export default PersonalTutoringDashboard
