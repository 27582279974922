import chroma from 'chroma-js'

import { HexValue } from './types'

/** Utils */
/** By GPT-4 */
export function generateColourScale<ColourName extends string>(
  hexBase: string,
  name: ColourName,
  brightest?: string,
  darkest?: string
): Record<`${ColourName}${number}`, HexValue> {
  const baseColor = chroma(hexBase)
  const scale = chroma
    .scale([brightest ?? chroma('white'), baseColor, darkest ?? chroma('black')])
    .mode('rgb')
    .colors(12)
  const result: Record<string, HexValue> = {}

  for (let i = 1; i <= 12; i++) {
    const color = scale[i - 1]
    if (color) {
      const hex = chroma(color).hex()
      result[`${name}${i}`] = hex as HexValue
    }
  }
  return result
}
