import { DropdownMenuContent } from '@radix-ui/react-dropdown-menu'

import { styled } from '../../../styles/stitches.config'

export const Content = styled(DropdownMenuContent, {
  width: 'fit-content',
  backgroundColor: '$appBackground',
  borderRadius: '0.75rem',
  padding: '0.5rem',
  border: '0.0625rem solid $separator',
  boxShadow: '$md',
})
