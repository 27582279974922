import { format, isValid } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'

import { Button } from '../../styles/_app.style'
import {
  Control,
  Field,
  Input,
  Label,
  LabelContainer,
  Message,
  Submit,
} from '../../styles/form.style'
import { PersonalTutorMeeting } from '../../types/schemas/tutoring'
import Dialog from './Dialog'

export interface PTMeetingPayload {
  meetingDate: Date
  label: string
}

interface PersonalTutorMeetingDialogProps {
  meeting?: PersonalTutorMeeting
  open: boolean
  onOpenChange: (_: boolean) => void
  onSubmit: (payload: PTMeetingPayload) => void
}

const PersonalTutorMeetingDialog: FC<PersonalTutorMeetingDialogProps> = ({
  meeting,
  open,
  onOpenChange,
  onSubmit,
}) => {
  const DATETIME_LOCAL_FORMAT = 'yyyy-MM-dd'
  const initialData = {
    meetingDate: new Date(),
    label: '',
  }
  const [formData, setFormData] = useState<PTMeetingPayload>(initialData)
  useEffect(() => {
    if (!!meeting) setFormData({ meetingDate: meeting.date, label: meeting.label })
  }, [meeting])

  function applyPatch(key: keyof PTMeetingPayload, value: PTMeetingPayload[typeof key]) {
    setFormData((current) => ({ ...current, [key]: value }))
  }

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newDate = Date.parse(e.target.value)
    if (isValid(newDate)) applyPatch('meetingDate', new Date(newDate))
  }

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      onPrimaryClick={() => onSubmit(formData)}
      title="Edit Personal Tutor Meeting"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Field name="date">
          <LabelContainer>
            <Label asChild>Meeting Date</Label>
            <Message match="valueMissing">A date is required</Message>
          </LabelContainer>
          <Control asChild>
            <Input
              type="date"
              value={format(formData.meetingDate, DATETIME_LOCAL_FORMAT)}
              onChange={handleDateChange}
              required
            />
          </Control>
        </Field>
        <Field name="label">
          <LabelContainer>
            <Label asChild>Meeting label</Label>
            <Message match="valueMissing">Label Required</Message>
          </LabelContainer>
          <Control asChild>
            <Input
              type="text"
              value={formData.label}
              onChange={({ target: { value } }) => applyPatch('label', value)}
              required
            />
          </Control>
        </Field>
        <Submit asChild>
          <Button>Submit</Button>
        </Submit>
      </div>
    </Dialog>
  )
}

export default PersonalTutorMeetingDialog
