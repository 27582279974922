import { Container } from '../_app.style'
import { styled } from '../stitches.config'

export const RecordsContainer = styled(Container, {
  variants: {
    records: {
      full: {
        alignItems: 'center',
        gap: '26px',
        margin: '0',
        maxWidth: 'none',
        position: 'relative',
      },
      cardContent: {
        maxWidth: 'none',
        padding: 0,
        gap: '8px',
        width: '100%',
      },
    },
  },
})

export const TableDiv = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'start',
  alignContent: 'start',
  gap: '20px',
})
