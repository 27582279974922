import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { Exercise } from '../types/schemas/emarking'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useStudentExerciseSummaries = (
  year: string,
  studentUsername: string,
  moduleCodes: string[]
) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [exercises, setExercises] = useState<Exercise[]>([])
  useEffect(() => {
    axiosInstance
      .get(endpoints.studentExerciseSummaries(year, studentUsername), {
        params: { module_code: moduleCodes },
      })
      .then(({ data }) => {
        setExercises(data !== null ? mapPlainToInstance(Exercise, data) : [])
      })
      .catch(displayError(`Error fetching exercise summaries for ${studentUsername}`))
  }, [axiosInstance, year, moduleCodes, displayError, studentUsername])
  return exercises
}

export const useExercisesForYear = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [exercises, setExercises] = useState<Exercise[]>([])
  const [exercisesAreLoaded, setExercisesAreLoaded] = useState(false)
  useEffect(() => {
    axiosInstance
      .get(endpoints.exercisesForYear(year))
      .then(({ data }) => {
        setExercises(data !== null ? mapPlainToInstance(Exercise, data) : [])
      })
      .catch(displayError('Error fetching exercises'))
      .finally(() => setExercisesAreLoaded(true))
  }, [axiosInstance, year, displayError])
  return { exercises, exercisesAreLoaded }
}

export const useExercisesForModule = (year: string, moduleCode: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [exercises, setExercises] = useState<Exercise[]>([])
  useEffect(() => {
    axiosInstance
      .get(endpoints.emarkingExercises(year, moduleCode))
      .then(({ data }) => {
        setExercises(data !== null ? mapPlainToInstance(Exercise, data) : [])
      })
      .catch(displayError('Error fetching exercises'))
  }, [axiosInstance, year, displayError, moduleCode])
  return { exercises }
}

export const useMyExercises = (year: string, moduleCodes: string[]) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [exercises, setExercises] = useState<Exercise[]>([])
  useEffect(() => {
    axiosInstance
      .get(endpoints.myExercises(year!), { params: { module_code: moduleCodes } })
      .then(({ data }) => {
        setExercises(data !== null ? mapPlainToInstance(Exercise, data) : [])
      })
      .catch(displayError('Error fetching exercises'))
  }, [axiosInstance, year, moduleCodes, displayError])
  return { exercises }
}
