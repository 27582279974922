import { styled } from './stitches.config'

export const OutlinedCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid $separator',
  borderRadius: '8px',
  padding: '20px 10px',
  width: '100%',
  flex: '1 0 0px',
  minWidth: '20rem',
  '@isDesktop': {
    minWidth: '35rem',
    padding: '1.25rem',
  },
  variants: {
    result: {
      true: {
        cursor: 'pointer',
      },
    },
    pill: {
      true: {
        width: '3rem',
        color: '$lowContrast',
        cursor: 'pointer',
        padding: '6px 13px',
        fontSize: '14px',
        display: 'inline',
        minWidth: '3.5rem',
        textAlign: 'center',
      },
    },
    padding: {
      medium: {
        padding: '20px',
      },
    },
    gap: {
      medium: {
        gap: '1rem',
      },
    },
    spaceBottom: {
      true: {
        marginBottom: '2rem',
      },
    },
  },
})

export const Container = styled('div', {
  width: '100%',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$neutral2',
  padding: '4rem 1rem 1rem',
  position: 'relative',
})

export const CardHeader = styled('h5', {
  fontWeight: 600,
})

export const Header = styled('div', {
  display: 'flex',
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 'bold',
  height: '43px',
  width: '100%',
  backgroundColor: '$neutral4',
  borderRadius: '8px 8px 0 0',
  padding: '0 1rem',
})
