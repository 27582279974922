import { Expose } from 'class-transformer'

export enum SearchResultTypes {
  MATERIALS_PDF = 'materials_pdf',
  PANOPTO_VIDEO = 'panopto_video',
}

const PAGE_REGEX = RegExp('#page=(\\d+)')
const SECONDS_REGEX = RegExp('#start=(\\d+)')
const DEFAULT_OFFSET = 'UNKNOWN'
const SEC_IN_MIN = 60

function extractOffsetValue(url: string, regex: RegExp): number | null {
  const match = url.match(regex)
  return match && match[1] ? parseInt(match[1], 10) : null
}

export class SearchResult {
  title: string
  year: string

  @Expose({ name: 'module_code' })
  moduleCode: string

  snippet: string
  url: string

  get fileType(): SearchResultTypes {
    return this.url.includes('page')
      ? SearchResultTypes.MATERIALS_PDF
      : SearchResultTypes.PANOPTO_VIDEO
  }

  get offset(): string {
    if (this.fileType === SearchResultTypes.MATERIALS_PDF) {
      const offset = extractOffsetValue(this.url, PAGE_REGEX)
      return `page ${offset ?? DEFAULT_OFFSET}`
    }
    const offset = extractOffsetValue(this.url, SECONDS_REGEX)
    let formattedVideoOffset = offset
      ? `${Math.floor(offset / SEC_IN_MIN)}:${offset % SEC_IN_MIN}`
      : DEFAULT_OFFSET
    return `at minute ${formattedVideoOffset ?? DEFAULT_OFFSET}`
  }
}
