import React, { InputHTMLAttributes, useState } from 'react'

import { Input } from '../../../styles/table.style'

export const EditableCell = ({
  inputProps,
  currentValue,
  onEdit,
}: {
  inputProps: InputHTMLAttributes<HTMLInputElement>
  currentValue: string | null
  onEdit: (_: string) => void
}) => {
  const [value, setValue] = useState(currentValue ?? '')
  return (
    <Input
      bordered
      {...inputProps}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => onEdit(value)}
    />
  )
}
