import {
  RowData,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { FileEarmarkArrowUpFill, Trash3Fill } from 'react-bootstrap-icons'

import { FileUploadButton } from '../../components/FileUploadButton'
import { IndeterminateCheckbox } from '../../components/IndeterminateCheckbox'
import { EditableCell } from '../../components/tables/tableRenderer/EditableCell'
import GenericTanStackTableRenderer from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import Tooltip from '../../components/tooltip/Tooltip'
import { endpoints } from '../../constants/endpoints'
import { AnchorButton, Button } from '../../styles/_app.style'
import { EmarkingDataRow } from '../../types/tablesDataRows'

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateRows: (
      columnId: keyof EmarkingDataRow,
      currentRow: EmarkingDataRow,
      value: string
    ) => void
  }
}

const ClickableHeader = ({ text }: { text: string }) => {
  return <span style={{ cursor: 'pointer' }}>{text}</span>
}

const ActionButtonsCell = ({
  row,
  actionsDisabled,
  onFeedbackDelete,
  onFeedbackReUpload,
}: {
  row: EmarkingDataRow
  actionsDisabled: boolean
  onFeedbackDelete: (feedbackId: number) => void
  onFeedbackReUpload: (feedbackId: number) => (files: FileList) => void
}) => {
  return (
    <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
      <FileUploadButton
        tooltipLabel={'Re-upload feedback'}
        buttonContent={<FileEarmarkArrowUpFill size={22} />}
        disabled={actionsDisabled || !row.feedbackId}
        onFilesReceived={onFeedbackReUpload(row.feedbackId!)}
      />
      <Tooltip label={'Delete feedback'}>
        <Button
          disabled={actionsDisabled || !row.feedbackId}
          icon
          onClick={() => onFeedbackDelete(row.feedbackId!)}
        >
          <Trash3Fill size={22} />
        </Button>
      </Tooltip>
    </div>
  )
}

const FeedbackCell = ({
  row,
  uploadDisabled,
  onFeedbackUpload,
}: {
  row: EmarkingDataRow
  uploadDisabled: boolean
  onFeedbackUpload: (submissionId: number) => (files: FileList) => void
}) => {
  if (!row.feedbackId)
    return (
      <FileUploadButton
        disabled={uploadDisabled}
        onFilesReceived={onFeedbackUpload(row.submissionId)}
      />
    )

  let link = endpoints.feedbackFile(row.distributionId, row.feedbackId)
  return (
    <AnchorButton block href={`/external-resource?url=${link}`}>
      View
    </AnchorButton>
  )
}

const CollatedSubmissionCell = ({ row }: { row: EmarkingDataRow }) => {
  let link = endpoints.collatedSubmissionFile(row.distributionId, row.submissionId)
  return (
    <AnchorButton block thin href={`/external-resource?url=${link}`}>
      View
    </AnchorButton>
  )
}

interface MarkerDistributionTableProps {
  data: EmarkingDataRow[]
  maximumMark: number
  writeActionsEnabled: boolean
  rowSelectionEnabled: boolean
  onSelectionChange: (added: string[], removed: string[]) => void
  onMarkChange: (batch: EmarkingDataRow[]) => void
  onFeedbackDelete: (feedbackId: number) => void
  onFeedbackUpload: (feedbackId: number) => (files: FileList) => void
  onFeedbackReUpload: (feedbackId: number) => (files: FileList) => void
}

const MarkerDistributionTable: React.FC<MarkerDistributionTableProps> = ({
  data,
  maximumMark,
  writeActionsEnabled,
  rowSelectionEnabled,
  onSelectionChange,
  onMarkChange,
  onFeedbackDelete,
  onFeedbackUpload,
  onFeedbackReUpload,
}: MarkerDistributionTableProps) => {
  const columnHelper = createColumnHelper<EmarkingDataRow>()
  const selectionColumn = columnHelper.accessor((row) => row.login, {
    id: 'select',
    meta: {
      textIsCentred: true,
    },
    cell: ({ row }) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        onCheckedChange={row.getToggleSelectedHandler()}
        disabled={!row.getCanSelect()}
      />
    ),
    header: ({ table }) => (
      <IndeterminateCheckbox
        checked={
          table.getIsAllRowsSelected()
            ? true
            : table.getIsSomeRowsSelected()
            ? 'indeterminate'
            : false
        }
        onCheckedChange={(newState) => {
          if (newState === 'indeterminate' || newState) {
            table.toggleAllRowsSelected(true)
          } else {
            table.toggleAllRowsSelected(false)
          }
        }}
      />
    ),
    footer: (info) => info.column.id,
    enableSorting: false,
  })

  const columns = [
    ...(rowSelectionEnabled ? [selectionColumn] : []),
    columnHelper.accessor((row) => row.login, {
      id: 'login',
      cell: (info) => info.getValue(),
      header: () => <ClickableHeader text={'Login'} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.fullName, {
      id: 'fullName',
      cell: (info) => (
        <p
          style={{
            width: '10rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {info.getValue()}
        </p>
      ),
      header: () => <ClickableHeader text={'Full name'} />,
      footer: (info) => info.column.id,
      size: 300,
    }),
    columnHelper.accessor((row) => row.mark, {
      id: 'mark',
      cell: ({ getValue, row, column, table }) => {
        function editValue(value: string) {
          table.options.meta?.updateRows(column.id as keyof EmarkingDataRow, row.original, value)
        }

        const inputProps = {
          type: 'number',
          min: 0,
          max: maximumMark,
          size: 3,
        }
        const value = getValue() as string
        return writeActionsEnabled ? (
          <EditableCell inputProps={inputProps} currentValue={value} onEdit={editValue} />
        ) : value ? (
          value
        ) : null
      },
      header: () => 'Mark',
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row, {
      id: 'collatedSubmission',
      cell: (info) => <CollatedSubmissionCell row={info.getValue()} />,
      header: () => 'Submission',
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: 'feedback',
      cell: (info) => (
        <FeedbackCell
          row={info.getValue()}
          uploadDisabled={!writeActionsEnabled}
          onFeedbackUpload={onFeedbackUpload}
        />
      ),
      header: () => 'Feedback',
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: 'actions',
      cell: (info) => {
        return (
          <ActionButtonsCell
            row={info.getValue()}
            actionsDisabled={!writeActionsEnabled}
            onFeedbackDelete={onFeedbackDelete}
            onFeedbackReUpload={onFeedbackReUpload}
          />
        )
      },
      header: 'Actions',
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
  ]

  function updateRows(columnId: keyof EmarkingDataRow, row: EmarkingDataRow, value: string) {
    const updatedBatch = data.map((oldRow) => {
      if (oldRow.login === row.login && oldRow.mark !== parseInt(value))
        return { ...oldRow, [columnId]: parseInt(value) }
      return { ...oldRow }
    })
    onMarkChange(updatedBatch)
  }

  const [rowSelection, setRowSelection] = React.useState({})
  useEffect(() => {
    let toAdd = data.filter((_, i) => rowSelection.hasOwnProperty(i)).map((d) => d.login)
    let toRemove = data.filter((_, i) => !rowSelection.hasOwnProperty(i)).map((d) => d.login)
    onSelectionChange(toAdd, toRemove)
  }, [data, onSelectionChange, rowSelection])

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: (row) => !row.original.feedbackId,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: { updateRows },
  })
  return (
    <GenericTanStackTableRenderer style={{ table: { width: '100%' } }} table={table} size="wide" />
  )
}

export default MarkerDistributionTable
