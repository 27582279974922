import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import { PeriodSettings } from '../../components/moduleSelectionConfiguration/ModuleSelectionPeriods'
import { ModuleSelectionStatusToggle } from '../../components/moduleSelectionConfiguration/ModuleSelectionStatusToggle'
import { useModuleSelectionConfiguration } from '../../hooks/moduleSelectionConfiguration.service'
import { Banner } from '../../styles/_app.style'
import { Main, Section } from '../../styles/root.style'
import { Configuration } from '../../types/schemas/moduleSelection'

const ModuleSelectionConfiguration = () => {
  const { year } = useParams()
  const { configuration, updateConfiguration, periodsActions } = useModuleSelectionConfiguration(
    year as string
  )

  const ConfigurationSection = useCallback(
    ({ configuration }: { configuration: Configuration }) => (
      <>
        <ModuleSelectionStatusToggle
          moduleSelectionStatus={configuration.status}
          onStatusUpdate={updateConfiguration}
        />
        <PeriodSettings periods={configuration.periods} actions={periodsActions} />
      </>
    ),
    [periodsActions, updateConfiguration]
  )

  const mainBody = useMemo(() => {
    if (!configuration) return <Banner>No module selection configuration found</Banner>
    return <ConfigurationSection configuration={configuration} />
  }, [ConfigurationSection, configuration])

  return (
    <Main>
      <PageHeader title={'Module Selection Configuration'} />
      <Section>{mainBody}</Section>
    </Main>
  )
}

export default ModuleSelectionConfiguration
