import { useMemo } from 'react'
import {
  Book,
  CalendarDate,
  CardChecklist,
  Dice5Fill,
  PeopleFill,
  PersonFill,
  ShieldFill,
} from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'

import { globalGameEnabled, useGame } from '../../contextManagers/game.context'
import { useUser } from '../../contextManagers/user.context'
import { useResponsiveness } from '../../hooks/breakpoints.service'
import { DesktopNavigation } from './DesktopNavigation'
import { MobileNavigation } from './MobileNavigation'

export const NavigationSwitcher = () => {
  const { isDesktop } = useResponsiveness()

  const { year } = useParams()
  const { userDetails } = useUser()
  const { gameSettingOn } = useGame()

  const scientiaLinks = useMemo(() => {
    const COMMON_LINKS = [
      {
        to: `/${year}/modules`,
        title: 'Modules',
        'aria-label': 'Modules',
        icon: Book,
      },
      {
        to: `/${year}/timeline`,
        title: 'Timeline',
        'aria-label': 'Timeline',
        icon: CalendarDate,
      },
    ]
    const SENIOR_TUTOR_HOME_LINK = {
      to: `/${year}/senior-tutor`,
      title: 'Senior Tutor Home',
      'aria-label': 'Senior Tutor Home',
      icon: ShieldFill,
    }
    const GAME_ANALYTICS_LINK = {
      to: `/${year}/analytics`,
      title: 'View Game analytics',
      'aria-label': 'Analytics',
      icon: Dice5Fill,
    }

    const PERSONAL_RECORD_LINK = {
      to: `/${year}/students/${userDetails?.login}`,
      title: 'Student Record',
      'aria-label': 'Student Record',
      icon: PersonFill,
    }
    const TUTORIAL_ATTENDANCE_LINK = {
      to: `/${year}/attendance`,
      title: 'Tutorial Attendance Tracking',
      'aria-label': 'Tutorial Attendance Tracking',
      icon: CardChecklist,
    }
    const PASTORAL_CARE_LINK = {
      to: `/${year}/pastoral-care`,
      title: 'Pastoral care',
      'aria-label': 'Pastoral care',
      icon: PeopleFill,
    }

    let links = COMMON_LINKS

    if (userDetails?.isStaff) links = [PASTORAL_CARE_LINK, TUTORIAL_ATTENDANCE_LINK, ...links]
    else {
      if (userDetails?.isGUTAForAnyModule()) links = [TUTORIAL_ATTENDANCE_LINK, ...links]
      if (userDetails) links = [PERSONAL_RECORD_LINK, ...links]
    }

    if (userDetails?.isSeniorTutor) links = [SENIOR_TUTOR_HOME_LINK, ...links]

    if (globalGameEnabled && gameSettingOn) {
      links = [...links, GAME_ANALYTICS_LINK]
    }

    return links
  }, [year, userDetails, gameSettingOn])

  const props = {
    reactRouterLinks: scientiaLinks,
  }

  return isDesktop ? <DesktopNavigation {...props} /> : <MobileNavigation {...props} />
}
