import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { Pencil, Trash3Fill } from 'react-bootstrap-icons'

import { Button, Div } from '../../styles/_app.style'
import { PersonalTutorMeeting } from '../../types/schemas/tutoring'
import { groupByProperty } from '../../utils'
import Tooltip from '../tooltip/Tooltip'
import GenericTanStackTableRenderer, {
  createDatetimeColumn,
  createExpanderColumn,
  createPlainColumn,
} from './tableRenderer/GenericTanStackTableRenderer'

type PTMeetingRow = {
  id: number
  login: string
  tutor: string
  date: Date | null
  label: string
  subRows?: PTMeetingRow[]
}

const PersonalTutorMeetingsTable = ({
  meetings,
  handleDelete,
  handleEdit,
}: {
  meetings: PersonalTutorMeeting[]
  handleDelete: (meetingID: number) => void
  handleEdit: (meetingID: number) => void
}) => {
  const columnHelper = createColumnHelper<PTMeetingRow>()
  const columns = [
    columnHelper.accessor((row) => {}, createExpanderColumn<PTMeetingRow>()),
    columnHelper.accessor((row) => row.login, createPlainColumn<PTMeetingRow>('Login')),
    columnHelper.accessor((row) => row.tutor, createPlainColumn<PTMeetingRow>('Tutor')),
    columnHelper.accessor(
      (row) => row.date,
      createDatetimeColumn<PTMeetingRow>('Date', { dateFormat: 'Y-M-dd' })
    ),
    columnHelper.accessor((row) => row.label, createPlainColumn<PTMeetingRow>('Label')),
    columnHelper.accessor((row) => row.id, {
      id: 'actions',
      header: 'Actions',
      cell: (info) => (
        <>
          <Div css={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Tooltip label={'Delete meeting'}>
              <Button onClick={() => handleDelete(info.getValue())}>
                <Trash3Fill />
              </Button>
            </Tooltip>
            <Tooltip label={'Edit meeting'}>
              <Button onClick={() => handleEdit(info.getValue())}>
                <Pencil />
              </Button>
            </Tooltip>
          </Div>
        </>
      ),
    }),
  ]

  const data = useMemo(() => {
    const groupedMeetings = groupByProperty(meetings, 'tutee', 'date', false, false)
    return Object.entries(groupedMeetings).map(([tutee, meetings]) => {
      let [latestMeeting, ...pastMeetings] = meetings
      return {
        login: tutee,
        id: latestMeeting.id,
        tutor: latestMeeting.tutor,
        date: latestMeeting.date,
        label: latestMeeting.label,
        subRows: pastMeetings.map((m) => ({
          login: tutee,
          id: m.id,
          tutor: m.tutor,
          date: m.date,
          label: m.label,
        })),
      }
    })
  }, [])

  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  return <GenericTanStackTableRenderer table={table} size="wide" />
}

export default PersonalTutorMeetingsTable
