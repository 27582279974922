import { instanceToPlain, plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { useUser } from '../contextManagers/user.context'
import { PhDStaff, PhdStudentDetailMilestones } from '../types/phds'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

// List of students
export const usePhdStudents = () => {
  const axiosInstance = useContext(AxiosContext)
  const { year } = useParams()
  const displayError = useErrorMessage()

  const [isPhdDetailLoading, setIsPhdDetailLoading] = useState(true)

  const supervisorLogin = useUser().userDetails?.login

  const [phdStudents, setPhdStudents] = useState<PhdStudentDetailMilestones[]>([])
  const [phdStudentsLoaded, setPhdStudentsLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.phdStudentsForSupervisor(year!, supervisorLogin!))
      .then(({ data }: { data: any }) => {
        setPhdStudents(mapPlainToInstance(PhdStudentDetailMilestones, data))
      })
      .catch(displayError('Unable to retrieve list of PhD students'))
      .finally(() => setPhdStudentsLoaded(true))
  }, [axiosInstance, displayError, supervisorLogin, year])

  return {
    phdStudents,
    phdStudentsLoaded,
  }
}

// Single student record and milestones
export const usePhdStudent = () => {
  const axiosInstance = useContext(AxiosContext)
  const { login, year } = useParams()
  const studentLogin = login
  const user = useUser()
  const { addToast } = useToast()

  const [phdDetailLoading, setPhdDetailLoading] = useState(true)
  const [milestoneReportUploaded, setMilestoneReportUploaded] = useState(false)

  const [phdStudent, setPhdStudent] = useState<PhdStudentDetailMilestones>()
  useEffect(() => {
    axiosInstance
      .request({
        method: 'GET',
        url: endpoints.phdOneStudent(year!, studentLogin!),
      })
      .then(({ data }: { data: any }) => {
        setPhdStudent(plainToInstance(PhdStudentDetailMilestones, data[0]))
        setPhdDetailLoading(false)
      })
      .catch(() => {
        setMilestoneReportUploaded(true)
        addToast({ variant: 'error', title: 'Error fetching student list ' })
      })
  }, [axiosInstance, year])

  function postStatusForMilestone(
    studentLogin: string,
    milestoneCode: string,
    status: string,
    attemptDate: string
  ) {
    return axiosInstance
      .request({
        method: 'PATCH',
        url: endpoints.submitMilestoneStatus(year!, studentLogin, milestoneCode),

        data: attemptDate
          ? { status: status, second_attempt_date: attemptDate }
          : { status: status },
      })
      .then(() => {
        addToast({ variant: 'success', title: 'Status Submitted' })
      })
      .catch(() => addToast({ variant: 'error', title: 'Unable to save status' }))
  }

  function postFeedbackForMilestone(studentLogin: string, milestoneCode: string, feedback: string) {
    return axiosInstance
      .request({
        method: 'PATCH',
        url: endpoints.submitPhDFeedback(year!, studentLogin, milestoneCode),
        data: { feedback_text: feedback },
      })
      .then(() => {
        addToast({ variant: 'success', title: 'Feedback saved' })
      })
      .catch(() => addToast({ variant: 'error', title: 'Unable to save feedback' }))
  }

  function submitFile(
    studentLogin: string,
    milestoneCode: string,
    file: File,
    targetFileName: string
  ) {
    let formData = new FormData()
    formData.append('file', new File([file], targetFileName))

    axiosInstance
      .post(endpoints.submitMilestoneFile(year!, studentLogin!, milestoneCode), formData)

      .then(() => {
        addToast({
          variant: 'success',
          title: `${targetFileName} submitted successfully.`,
        })
      })
      .catch((error: any) => {
        addToast({
          variant: 'error',
          title: 'Error submitting file.',
        })
      })
  }

  function adminApproveMilestone(studentLogin: string, milestoneCode: string, approve: boolean) {
    return axiosInstance
      .request({
        method: 'PATCH',
        url: endpoints.approveMilestone(year!, studentLogin, milestoneCode),
        params: { approve },
      })
      .then(() => {
        addToast({ variant: 'success', title: 'Admin Approved' })
      })
      .catch(() => addToast({ variant: 'error', title: 'Could not approve milestone' }))
  }

  function updatePhdTitle(phdTitle: string) {
    return axiosInstance
      .request({
        method: 'PATCH',
        url: endpoints.phdTitle(year!),
        data: { phd_title: phdTitle },
      })
      .then(() => {
        addToast({ variant: 'success', title: 'PhD title updated' })
      })
      .catch(() => addToast({ variant: 'error', title: 'Unable to update the phd title' }))
  }

  return {
    phdStudent,
    phdDetailLoading,
    postStatusForMilestone,
    postFeedbackForMilestone,
    submitFile,
    adminApproveMilestone,
    milestoneReportUploaded,
    setMilestoneReportUploaded,
    updatePhdTitle,
  }
}

export const usePhdAdmin = () => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const { addToast } = useToast()

  function submitCsvFile(file: File) {
    let formData = new FormData()
    formData.append('file', new File([file], 'file.csv'))

    axiosInstance
      .request({
        method: 'POST',
        url: endpoints.milestoneCsv(),
        data: formData,
      })
      .then(() => {
        addToast({ variant: 'success', title: 'Milestone files uploaded' })
      })
      .catch((error: any) => {
        addToast({
          variant: 'error',
          title: 'Error uploading milestone file.',
        })
      })
  }

  function submitSupervisorsCsvFile(file: File) {
    let formData = new FormData()
    formData.append('file', new File([file], 'file.csv'))

    axiosInstance
      .request({
        method: 'POST',
        url: endpoints.supervisorsCsv(),
        data: formData,
      })
      .then(() => {
        addToast({ variant: 'success', title: 'Supervisor file uploaded' })
      })

      .catch((error: any) => {
        addToast({
          variant: 'error',
          title: 'Error uploading supervisors file.',
        })
      })
  }

  return {
    submitCsvFile,
    submitSupervisorsCsvFile,
  }
}

export const usePhdSupervisors = () => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { year } = useParams()
  const { addToast } = useToast()
  const [phdSupervisors, setPhdSupervisors] = useState<PhDStaff[]>([])
  const [supervisorsLoaded, setSupervisorsLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.phdSupervisors(year!))
      .then(({ data }: { data: any }) => {
        setPhdSupervisors(mapPlainToInstance(PhDStaff, data))
        setSupervisorsLoaded(true)
      })
      .catch(displayError('Unable to retrieve list of PhD students'))
      .finally(() => setSupervisorsLoaded(true))
  }, [axiosInstance, displayError, year])

  function updateAssessorForStudent(studentLogin: string, assessorLogin: string) {
    return axiosInstance
      .request({
        method: 'PATCH',
        url: endpoints.phdAssessor(year!, studentLogin, assessorLogin),
      })
      .then(() => {
        addToast({ variant: 'success', title: 'PhD Assessor has been updated' })
      })
      .catch(() => addToast({ variant: 'error', title: 'Unable save the PhDAssessor' }))
  }

  return {
    phdSupervisors,
    supervisorsLoaded,
    updateAssessorForStudent,
  }
}
