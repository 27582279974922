import { zonedTimeToUtc } from 'date-fns-tz'
import { useContext, useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { LONDON_TIMEZONE } from '../constants/global'
import { AxiosContext } from '../contextManagers/axios.context'
import { ResourceCreate } from '../types/materials'
import { TeachingMaterial } from '../types/schemas/materials'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'
import { Resource } from './materials.service'

export const getUTCDatetime = (date: string, time: string) =>
  zonedTimeToUtc(date + ' ' + time, LONDON_TIMEZONE)

export const usePublicResources = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [publicResources, setPublicResources] = useState<TeachingMaterial[]>([])
  const [resourcesAreLoaded, setResourcesAreLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.publicResources(year))
      .then(({ data }: any) => {
        setPublicResources(mapPlainToInstance(TeachingMaterial, data))
      })
      .catch(displayError('Failed to fetch public resources.'))
      .finally(() => setResourcesAreLoaded(true))
  }, [axiosInstance, displayError, year])

  return { publicResources, resourcesAreLoaded }
}

export const useResources = (): any => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { year } = useParams()
  const { moduleCode } = useOutletContext<{ moduleCode: string | null }>()

  const uploadResource = async (
    { file, ...resource }: ResourceCreate,
    setRawMaterials: (_: Resource[]) => void
  ) => {
    await axiosInstance
      .post(endpoints.resources, {
        ...resource,
        year,
        course: moduleCode,
      })
      .then(({ data }: any) => {
        if (resource.type === 'file') {
          let formData = new FormData()
          formData.append('file', file!)
          axiosInstance
            .put(endpoints.resourceFileToUpdate(data.id), formData)
            .catch(displayError('Error uploading file' + file?.name ? `'${file?.name}'.` : '.'))
        }
      })
      .then(() => {
        axiosInstance
          .get(endpoints.resources, { params: { year, course: moduleCode } })
          .then(({ data }: any) => setRawMaterials(data))
          .catch((error: any) => {
            // TODO: When would this fail, what errors would catch here?
            // do we want to extract this callback somewhere else?
            console.error(error)
          })
      })
      .catch(displayError(`Error creating resource '${resource.title}'.`))
  }
  return { uploadResource }
}
