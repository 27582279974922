import React from 'react'
import { useParams } from 'react-router-dom'

import { Tabs } from '../components/Tabs'
import PageHeader from '../components/common/PageHeader'
import SearchTool from '../components/search/SearchTool'
import titles from '../constants/titles'
import { useUser } from '../contextManagers/user.context'
import { useResponsiveness } from '../hooks/breakpoints.service'
import { Main, Section } from '../styles/root.style'
import { css } from '../styles/stitches.config'

const Modules = () => {
  const { userDetails } = useUser()
  const { year } = useParams()
  const { isDesktop } = useResponsiveness()

  return (
    <Main>
      <PageHeader title={titles.modules(year, userDetails?.cohortName)} header="Modules">
        <p>
          <b>Not seeing the modules you expect? Please try logging out and logging in again.</b>
        </p>
        <p>
          These are the modules you are currently enrolled for. Click on any to access the relevant
          teaching materials and resources. A yellow dot indicates that a module's under
          construction and doesn't house any resources at the moment.
        </p>
      </PageHeader>
      <Section>
        {isDesktop && (
          <SearchTool
            year={year as string}
            moduleCodes={userDetails?.modules.map(({ code }) => code)}
          />
        )}
        <Tabs
          attribute={'code'}
          data={userDetails?.modules
            .concat(userDetails?.modulesHelped ?? [])
            .sort((a, b) => a.code.localeCompare(b.code))}
          generator={(tab: any) => (
            <>
              {/* TODO: Update title style if module has no resources */}
              <span>{tab.title}</span>
              <span className={css({ color: '$lowContrast' })()}>{tab.code}</span>
            </>
          )}
          href={(tab) => `/${year}/modules/${tab.code}/materials`}
        />
      </Section>
    </Main>
  )
}

export default Modules
