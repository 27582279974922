import { Link } from 'react-router-dom'

import { styled } from '../stitches.config'
import { shadowSm } from './exercisesPage.style'

// TODO: Reduce duplication

export const ExerciseLinkButton = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 'min(fit-content,1px)',
  padding: '0.75rem',

  borderRadius: '8px',
  border: '1px solid $sand7',
  boxShadow: shadowSm,

  color: '$sand12',
  fontWeight: 500,
  fontSize: '$md',

  cursor: 'pointer',
  transition: 'backgroundColor .1s ease-in',
  backgroundColor: '$sand1',
  '&:hover': {
    backgroundColor: '$sand4',
  },
  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: '70%',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
    },
    pill: {
      true: {
        display: 'flex',
        padding: '0.3125rem 0rem',
        width: '4.25rem',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '$sm',
        fontWeight: 400,
      },
    },
  },
})
