/**
 * Contains the frontend code for a table of marks for a particular module
 * show to students in the student record page.
 */
import { RowData, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { ComponentProps } from 'react'

import { useResponsiveness } from '../../hooks/breakpoints.service'
import { ExerciseStyleOptions, styleForExercise } from '../../styles/exerciseItem.style'
import { ForceSingleLineBold } from '../../styles/records/forceSingleLineBold.style'
import { SubmissionStatusContainer } from '../../styles/records/tooltips.style'
import { Td, Th } from '../../styles/table.style'
import { Exercise, ExerciseStatus } from '../../types/schemas/emarking'
import { calculateGrade, isNumber } from '../../utils'
import { FormattedMark } from '../FormattedMark'
import { ScientiaTooltip } from '../ScientiaTooltip'
import { ExercisePillButton } from '../exercise/ExerciseButtons'
import { FeedbackLink } from '../exercise/FeedbackButton'
import GenericTanStackTableRenderer from './tableRenderer/GenericTanStackTableRenderer'

const BLANK_TABLE_CELL_TEXT = '-'

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    textIsCentred?: boolean
    desktopOnly?: boolean
    css?: ComponentProps<typeof Td | typeof Th>['css']
  }
}

const StatusLine = ({
  status,
  tooltipText,
}: {
  status: ComponentProps<typeof SubmissionStatusContainer>['status'] & string
  tooltipText: string
}) => {
  return (
    <SubmissionStatusContainer status={status}>
      <ScientiaTooltip tooltipText={tooltipText}>
        <ForceSingleLineBold>{(status || BLANK_TABLE_CELL_TEXT).toUpperCase()}</ForceSingleLineBold>
      </ScientiaTooltip>
    </SubmissionStatusContainer>
  )
}

const ExerciseStatusCell = ({ exercise }: { exercise: Exercise }) => {
  if (exercise.marksHiddenToStudents) {
    return <StatusLine status="gnfp" tooltipText="Grades not for publication" />
  }
  switch (exercise.exerciseStatus) {
    case ExerciseStatus.LATE:
      return <StatusLine status="overdue" tooltipText="Submission overdue" />
    case ExerciseStatus.DONE:
      return <StatusLine status="submitted" tooltipText="Submission complete" />
    case ExerciseStatus.PAST_GRACE_PERIOD:
      return <StatusLine status="missed" tooltipText="Submission missed" />
    case ExerciseStatus.DUE:
      return <StatusLine status="pending" tooltipText="Submission pending" />
    default:
      return <ForceSingleLineBold>N/A</ForceSingleLineBold>
  }
}

const StudentModuleMarksTable = ({
  data,
  exerciseStyleOptions,
}: {
  data: Exercise[]
  exerciseStyleOptions: ExerciseStyleOptions
}) => {
  const columnHelper = createColumnHelper<Exercise>()

  const columns = [
    columnHelper.accessor((exercise) => exercise, {
      id: 'exercise',
      cell: (info) => {
        const exercise = info.getValue()
        return (
          <ExercisePillButton
            exercise={exercise}
            css={styleForExercise(exercise, exerciseStyleOptions)}
          />
        )
      },
      header: 'Exercise',
      meta: {
        css: {
          width: '6rem',
          '@isDesktop': {
            width: '8rem',
          },
        },
      },
    }),
    columnHelper.accessor((exercise) => exercise.title, {
      id: 'title',
      cell: (info) => info.getValue(),
      header: 'Title',
      meta: {
        css: {
          width: '50%',
        },
      },
    }),
    columnHelper.accessor((exercise) => exercise, {
      id: 'status',
      cell: (info) => <ExerciseStatusCell exercise={info.getValue()} />,
      header: 'Status',
      meta: {
        textIsCentred: true,
      },
    }),
    columnHelper.accessor((exercise) => exercise, {
      id: 'mark',
      header: 'Mark',
      cell: (info) => {
        let exercise = info.getValue()
        let mark = exercise.mark
        return mark && isNumber(mark.mark) ? (
          <FormattedMark mark={mark.mark} cap={mark.cap} maximumMark={exercise.maximumMark} />
        ) : (
          BLANK_TABLE_CELL_TEXT
        )
      },
      meta: {
        textIsCentred: true,
      },
    }),
    columnHelper.accessor((exercise) => exercise, {
      id: 'grade',
      header: 'Grade',
      cell: (info) => {
        let exercise = info.getValue()
        let mark = exercise.mark
        return mark && isNumber(mark.mark) ? (
          <b>{calculateGrade(mark.mark, exercise.maximumMark)}</b>
        ) : null
      },
      meta: {
        textIsCentred: true,
      },
    }),
    columnHelper.accessor((row) => row, {
      id: 'feedback',
      header: 'Feedback',
      cell: (info) => {
        const exercise = info.getValue()
        return exercise.feedback ? (
          <FeedbackLink
            css={{ fontSize: '$sm' }}
            feedback={exercise.feedback}
            exerciseTitle={exercise.title}
          />
        ) : (
          BLANK_TABLE_CELL_TEXT
        )
      },
      meta: {
        textIsCentred: true,
        desktopOnly: true,
        css: {
          minWidth: '5.375rem',
        },
      },
    }),
  ]

  const { isDesktop } = useResponsiveness()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <GenericTanStackTableRenderer
      table={table}
      additionalCellCSSGenerator={(_, cellIndex) => ({
        paddingLeft: cellIndex === 0 ? '0' : undefined,
      })}
      showHeader={isDesktop}
    />
  )
}

export default StudentModuleMarksTable
