import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '../../styles/_app.style'
import {
  Control,
  Field,
  Input,
  Label,
  LabelContainer,
  Message,
  Submit,
} from '../../styles/form.style'
import { EnrolledStudent } from '../../types/schemas/abc'
import { Exercise } from '../../types/schemas/emarking'
import Dialog from '../dialogs/Dialog'
import Select, { StandardSelectOption } from '../select/Select'

export interface ExtensionPayload {
  studentUsername: string
  revisedDeadline: Date
  reason: string
}

const ExtensionDialog = ({
  exercise,
  enrolledStudents,
  open,
  onOpenChange,
  onSubmit,
}: {
  exercise: Exercise
  enrolledStudents: EnrolledStudent[]
  open: boolean
  onOpenChange: (_: boolean) => void
  onSubmit: (_: ExtensionPayload) => void
}) => {
  const DATETIME_LOCAL_FORMAT = "yyyy-MM-dd'T'HH:mm"
  const initialData = useMemo(
    () => ({
      studentUsername: '',
      revisedDeadline: exercise.deadline,
      reason: '',
    }),
    [exercise.deadline]
  )

  const [formData, setFormData] = useState<ExtensionPayload>(initialData)
  const [studentSelected, setStudentSelected] = useState<StandardSelectOption>()

  const studentOptions = useMemo(
    () =>
      enrolledStudents.map(({ firstname, lastname, login }) => ({
        value: login,
        label: `${firstname} ${lastname} (${login})`,
      })),
    [enrolledStudents]
  )
  const saveButtonDisabled = useMemo(
    () => JSON.stringify(formData) === JSON.stringify(initialData),
    [formData, initialData]
  )
  const inputsAreDisabled = useMemo(() => !!exercise && exercise.isLocked, [exercise])

  function applyPatch(key: keyof ExtensionPayload, value: ExtensionPayload[typeof key]) {
    setFormData((current) => ({ ...current, [key]: value }))
  }

  const studentsDropDownHandler = (option: any) => {
    option ? applyPatch('studentUsername', option.value) : setStudentSelected(option ?? undefined)
  }

  const datesAreInconsistent = useCallback(
    (extensionDate: Date) => exercise.deadline.getTime() >= extensionDate.getTime(),
    [exercise.deadline]
  )

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      onPrimaryClick={() => onSubmit(formData)}
      title="Grant an extension"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Select
          options={studentOptions}
          value={studentSelected}
          onChange={studentsDropDownHandler}
          placeholder="Select a student..."
        />
        <Field name="date">
          <LabelContainer>
            <Label>Extension Date</Label>
            <Message match="valueMissing">!! A date is required</Message>
            <Message match={() => datesAreInconsistent(formData.revisedDeadline)}>
              Extension date has to be later than the current deadline
            </Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="datetime-local"
              value={format(formData.revisedDeadline, DATETIME_LOCAL_FORMAT)}
              onChange={({ target: { value } }) =>
                applyPatch('revisedDeadline', new Date(Date.parse(value)))
              }
              required
            />
          </Control>
        </Field>
        <Field name="reason">
          <LabelContainer>
            <Label>Reason for Extension</Label>
            <Message match="valueMissing">Reason required</Message>
          </LabelContainer>
          <Control asChild>
            <Input
              disabled={inputsAreDisabled}
              type="text"
              value={formData.reason}
              onChange={({ target: { value } }) => applyPatch('reason', value)}
              required
            />
          </Control>
        </Field>
        <Submit asChild>
          <Button disabled={saveButtonDisabled}>Submit</Button>
        </Submit>
      </div>
    </Dialog>
  )
}

export default ExtensionDialog
