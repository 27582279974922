import { Wrapper } from '../_app.style'
import { styled } from '../stitches.config'
import { TIMELINE_COL_WIDTH } from './constants.style'

/* Background layout: general overview */
/* +------------+------------+------------+------------+------------+--------+ */
/* | Week                                                                    | */
/* | Mon          Tue          Wed          Thu          Fri                 | */
/* +------------+------------+------------+------------+------------+--------+ */
/* | <- 3rem -> | <- 3rem -> | <- 3rem -> | <- 3rem -> | <- 3rem -> | 0.5rem | */
export const MainBackgroundArea = styled('div', {
  gridArea: 'background',
  display: 'grid',
  gridTemplateRows: 'auto',
  rowGap: 0,
  columnGap: '0.5rem',
  zIndex: 1,
})

export const ModulesArea = styled(Wrapper, {
  gridArea: 'modules',
  display: 'grid',
  gridGap: '0.5rem',
  padding: '0.5rem 0 0.5rem 0',
  position: 'sticky',
  left: '0',
  zIndex: 4,
  backgroundColor: '$appBackground',
})

export const SwitcherArea = styled(Wrapper, {
  gridArea: 'switcher',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  alignItems: 'center',
  position: 'sticky',
  zIndex: 10,
  paddingTop: '1rem',
  background: '$appBackground',
  borderRight: '1px solid $elementBorder',
  top: 0,
  left: 0,
  width: TIMELINE_COL_WIDTH,
})

export const WeeksArea = styled(Wrapper, {
  gridArea: 'weeks',
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '0.5rem',
  position: 'sticky',
  top: '0',
  paddingRight: '1rem',
  paddingTop: '1rem',
  zIndex: '4',
  backgroundColor: '$appBackground',
})
