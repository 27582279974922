import React from 'react'
import { Trash3Fill } from 'react-bootstrap-icons'

import { endpoints } from '../../constants/endpoints'
import { ProvidedFilesActions } from '../../hooks/exercise.service'
import { Button, Div, Footnote } from '../../styles/_app.style'
import {
  ActionBlock,
  ActionItemContainer,
  ActionableListContainer,
  ListBlock,
  ListItem,
  ListItemContainer,
  ListItemMainText,
} from '../../styles/list.style'
import { Exercise } from '../../types/schemas/emarking'
import { encodeURL } from '../../utils'
import Card from '../Card'
import { FileUploadButton } from '../FileUploadButton'
import { Tabs } from '../Tabs'
import Tooltip from '../tooltip/Tooltip'

enum ProvidedFileType {
  Spec = 'Spec',
  SampleAnswer = 'Sample Answer',
  DataFile = 'Data File',
}

const EditableProvidedFilesSection = ({
  exercise,
  actions,
}: {
  exercise: Exercise
  actions: ProvidedFilesActions
}) => {
  const { year, moduleCode, number } = exercise
  const buildLink = (endpoint: string) => `/external-resource?url=${encodeURL(endpoint)}`

  const providedFiles = [
    {
      fileType: ProvidedFileType.Spec,
      link: exercise.spec ? buildLink(endpoints.exerciseSpec(year, moduleCode, number)) : null,
      uploadCallback: actions.uploadSpec,
      allowedFileExtensions: ['.pdf'],
    },
    {
      fileType: ProvidedFileType.DataFile,
      link: exercise.supplementaryFile
        ? buildLink(endpoints.exerciseSupplementaryFile(year, moduleCode, number))
        : null,
      uploadCallback: actions.uploadDataFile,
      allowedFileExtensions: ['*'],
    },
    {
      fileType: ProvidedFileType.SampleAnswer,
      link: exercise.modelAnswer
        ? buildLink(endpoints.exerciseModelAnswer(year, moduleCode, number))
        : null,
      uploadCallback: actions.uploadModelAnswer,
      deleteCallBack: actions.deleteModelAnswer,
      allowedFileExtensions: ['*'],
    },
  ]

  return (
    <Card title="Provided files">
      <ActionableListContainer>
        <ListBlock>
          <Tabs
            attribute={'fileType'}
            data={providedFiles}
            href={({ fileType }) => {
              let providedFile = providedFiles.find((f) => f.fileType === fileType)
              return providedFile?.link || '#'
            }}
            generator={({ fileType, link }) => {
              let label = link ? fileType : <em>No {fileType} provided</em>
              return (
                <ListItemContainer>
                  <ListItem>
                    <ListItemMainText>{label}</ListItemMainText>
                  </ListItem>
                </ListItemContainer>
              )
            }}
          />
        </ListBlock>
        <ActionBlock>
          {providedFiles.map(
            ({ fileType, allowedFileExtensions, uploadCallback, deleteCallBack, link }) => (
              <ActionItemContainer size={'doubleWidth'} key={fileType}>
                {fileType !== ProvidedFileType.SampleAnswer ? (
                  <FileUploadButton
                    accept={allowedFileExtensions}
                    icon
                    onFilesReceived={uploadCallback}
                  />
                ) : link ? (
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '0.5rem',
                    }}
                  >
                    <FileUploadButton
                      accept={allowedFileExtensions}
                      icon
                      onFilesReceived={uploadCallback}
                    />

                    <Tooltip label={'Delete provided file'}>
                      <Button icon onClick={deleteCallBack}>
                        <Trash3Fill size={22} />
                      </Button>
                    </Tooltip>
                  </Div>
                ) : (
                  <FileUploadButton
                    accept={allowedFileExtensions}
                    icon
                    onFilesReceived={uploadCallback}
                  />
                )}
              </ActionItemContainer>
            )
          )}
        </ActionBlock>
      </ActionableListContainer>
      {exercise.modelAnswer && (
        <Footnote muted center css={{ marginTop: '1rem' }}>
          {exercise.type === 'TUT'
            ? `Sample answer visible to students and staff on ${exercise.modelAnswerVisibleOn?.toDateString()}`
            : 'Sample answer is visible to markers and helpers'}
        </Footnote>
      )}
    </Card>
  )
}
export default EditableProvidedFilesSection
