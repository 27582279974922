import { styled } from '../stitches.config'

export const ProfileCardDiv = styled('div', {
  display: 'flex',
  width: '100%',
  backgroundColor: '$blue6',
  borderRadius: '8px',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1.5rem',
  '@isDesktop': {
    maxWidth: '90%',
  },
})

export const ProfileCardInnerContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '36px',
  flexDirection: 'column',
  '@isDesktop': {
    flexDirection: 'row',
  },
})

export const ProfileCardImageDiv = styled('div', {
  display: 'flex',
  flex: 1,
  borderRadius: '100%',
  maxWidth: '80%',
})

export const ProfileCardInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '7px',
})
