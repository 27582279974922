import { Container } from '../_app.style'
import { Pill } from '../pill.style'
import { styled } from '../stitches.config'

export const AttendanceContainer = styled(Container, {
  gap: '35px',
  alignItems: 'center',
  paddingBottom: '7rem',
})

export const Button = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$primary7',
  color: '$textPrimary',
  padding: '8px 18px',
  fontWeight: 'bold',
  fontSize: 'medium',
  gap: '10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$primary8',
  },
  variants: {
    danger: {
      true: {
        backgroundColor: '$red7',
        '&:hover': {
          backgroundColor: '$red8',
        },
      },
    },
  },
})

export const Heading1 = styled('p', {
  fontSize: '22px',
  fontWeight: '600',
  '@isDesktop': {
    fontSize: '24px',
  },
})

export const Heading2 = styled('p', {
  fontSize: '14px',
  fontWeight: '600',
  '@isDesktop': {
    fontSize: '15px',
  },
})

export const StatusMarkerContainer = styled('div', {
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  gap: '0.375rem',
  flex: '1 0 0',

  variants: {
    status: {
      present: {
        'span, svg': {
          color: '$green10',
          fill: '$green10',
        },
      },
      absent: {
        'span, svg': {
          color: '$red9',
          fill: '$red9',
        },
      },
    },
  },
})

export const StatusText = styled('b', {
  variants: {
    color: {
      green: {
        color: '$green10',
      },
      red: {
        color: '$red9',
      },
    },
  },
})

export const BaseTermPill = styled(Pill, {
  variants: {
    term: {
      autumn: {
        backgroundColor: '$orange4',
      },
      spring: {
        backgroundColor: '$lime5',
      },
      summer: {
        background: '$yellow5',
      },
      holidays: {
        backgroundColor: '$neutral4',
      },
    },
  },
})
