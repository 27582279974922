import { CheckCircle, XCircle } from 'react-bootstrap-icons'

import { StatusMarkerContainer } from '../../styles/attendance/attendance.style'

export enum AttendanceStatus {
  PRESENT = 'present',
  ABSENT = 'absent',
  NO_STATUS = 'none',
}

export namespace AttendanceStatus {
  /** `undefined` wil be regarded as "NO_STATUS" */
  export const fromValue = (present: boolean | undefined) =>
    typeof present === 'undefined'
      ? AttendanceStatus.NO_STATUS
      : present
      ? AttendanceStatus.PRESENT
      : AttendanceStatus.ABSENT
}

export const ComputedStatusMarker = ({ status }: { status: AttendanceStatus }) => {
  switch (status) {
    case AttendanceStatus.PRESENT:
      return (
        <StatusMarkerContainer status={status}>
          <span>Present</span> <CheckCircle />
        </StatusMarkerContainer>
      )
    case AttendanceStatus.ABSENT:
      return (
        <StatusMarkerContainer status={status}>
          <span>Absent</span> <XCircle />
        </StatusMarkerContainer>
      )
    case AttendanceStatus.NO_STATUS:
      return <StatusMarkerContainer>—</StatusMarkerContainer>
  }
}
