import { useCallback, useContext, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { SearchResult } from '../types/schemas/search'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export enum SearchStatus {
  IDLE,
  LOADING,
  ERROR,
}

export const useSearch = (year: string, moduleCodes?: string[]) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [results, setResults] = useState<SearchResult[]>([])
  const [status, setStatus] = useState(SearchStatus.IDLE)

  function doSearch(query: string) {
    setStatus(SearchStatus.LOADING)
    axiosInstance
      .get(endpoints.search(), { params: { year, query, module_code: moduleCodes } })
      .then(({ data }) => {
        setResults(mapPlainToInstance(SearchResult, data))
        setStatus(SearchStatus.IDLE)
      })
      .catch((e) => {
        setStatus(SearchStatus.ERROR)
        displayError('Error fetching search results')(e)
      })
  }

  const resetSearch = useCallback(() => {
    setResults([])
    setStatus(SearchStatus.IDLE)
  }, [])

  return {
    results,
    status,
    doSearch,
    resetSearch,
  }
}
