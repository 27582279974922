import { plainToInstance } from 'class-transformer'
import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'

import { PTMeetingPayload } from '../components/dialogs/PersonalTutorMeetingDialog'
import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useToast } from '../contextManagers/toast.context'
import { PersonalTutorMeeting } from '../types/schemas/tutoring'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const usePersonalTutoringMeetings = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [personalTutorMeetings, setPersonalTutorMeetings] = useState<PersonalTutorMeeting[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.personalTutorMeetings(year))
      .then(({ data }) => {
        let meetings = mapPlainToInstance(PersonalTutorMeeting, data)
        setPersonalTutorMeetings(meetings)
      })
      .catch(displayError('Failed to fetch personal tutor meetings'))
  }, [axiosInstance, displayError, year])

  return { personalTutorMeetings }
}

export const useOwnPersonalTutoringMeetings = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const { addToast } = useToast()
  const [personalTutorMeetings, setPersonalTutorMeetings] = useState<PersonalTutorMeeting[]>([])
  const [meetingsLoaded, setMeetingsLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.ownPersonalTutorMeetings(year))
      .then(({ data }) => {
        let meetings = mapPlainToInstance(PersonalTutorMeeting, data)
        setPersonalTutorMeetings(meetings)
      })
      .catch(displayError('Failed to fetch personal tutor meetings'))
      .finally(() => setMeetingsLoaded(true))
  }, [axiosInstance, displayError, year])

  function recordMeeting(tutee: string, label: string, date: Date | undefined = undefined) {
    let meetingDate = date || new Date()
    const payload = {
      meeting_date: format(meetingDate, 'yyyy-MM-dd'),
      tutee,
      label,
    }
    axiosInstance
      .post(endpoints.ownPersonalTutorMeetings(year), payload)
      .then(({ data }) => {
        setPersonalTutorMeetings((meetings) => [
          ...meetings,
          plainToInstance(PersonalTutorMeeting, data),
        ])
        addToast({ variant: 'success', title: `Meeting with ${tutee} logged` })
      })
      .catch(displayError('Failed to fetch personal tutor meetings'))
  }

  function deleteMeeting(meetingId: number) {
    axiosInstance
      .delete(endpoints.ownPersonalTutorMeeting(year, meetingId))
      .then(() => {
        setPersonalTutorMeetings((meetings) => meetings.filter((m) => m.id !== meetingId))
        addToast({ variant: 'success', title: `Meeting deleted` })
      })
      .catch(displayError('Failed to delete personal tutor meeting'))
  }

  function editMeeting(meetingId: number, payload: PTMeetingPayload) {
    axiosInstance
      .patch(endpoints.ownPersonalTutorMeeting(year, meetingId), objectKeysToSnakeCase(payload))
      .then(({ data }) => {
        setPersonalTutorMeetings((meetings) => [
          ...meetings.filter((m) => m.id !== meetingId),
          plainToInstance(PersonalTutorMeeting, data),
        ])
        addToast({ variant: 'success', title: `Meeting edited` })
      })
      .catch(displayError('Failed to edit personal tutor meeting'))
  }

  return {
    personalTutorMeetings,
    meetingsLoaded,
    actions: {
      recordMeeting,
      deleteMeeting,
      editMeeting,
    },
  }
}
