import { useState } from 'react'

import { useSearch } from '../../hooks/search.service'
import SearchBox from './SearchBox'
import SearchModal from './SearchModal'

const SearchTool = ({ year, moduleCodes }: { year: string; moduleCodes?: string[] }) => {
  const search = useSearch(year, moduleCodes)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <SearchBox onClick={() => setModalIsOpen(true)} />
      <SearchModal isOpen={modalIsOpen} close={() => setModalIsOpen(false)} {...search} />
    </>
  )
}

export default SearchTool
