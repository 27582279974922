import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  CellValues,
  createDatetimeColumn,
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useAllStudents } from '../../hooks/allStudents.service'
import { useExercisesForYear } from '../../hooks/exercises.service'
import { Banner } from '../../styles/_app.style'
import { Main, Section, TableSection } from '../../styles/root.style'
import { formatShortYear, toLookupTable } from '../../utils'

type ExtensionRow = {
  login: string
  name: string
  module: string
  exercise: string
  deadline: Date
  extension: Date
  grantedBy: string
  reason?: string
}

const DeadlineExtensionsDashboard = () => {
  const { year } = useParams()
  const { allStudents, allStudentsAreLoaded } = useAllStudents(year as string)
  const { exercises, exercisesAreLoaded } = useExercisesForYear(year as string)

  const columnHelper = createColumnHelper<ExtensionRow>()
  const columns = [
    columnHelper.accessor((row) => row.login, createPlainColumn<ExtensionRow>('Login')),
    columnHelper.accessor((row) => row.name, createPlainColumn<ExtensionRow>('Name')),
    columnHelper.accessor((row) => row.module, createPlainColumn<ExtensionRow>('Module')),
    columnHelper.accessor((row) => row.exercise, createPlainColumn<ExtensionRow>('Exercise')),
    columnHelper.accessor((row) => row.deadline, createDatetimeColumn<ExtensionRow>('Deadline')),
    columnHelper.accessor((row) => row.extension, createDatetimeColumn<ExtensionRow>('Extension')),
    columnHelper.accessor((row) => row.grantedBy, createPlainColumn<ExtensionRow>('Granted By')),
    columnHelper.accessor((row) => row.reason, createPlainColumn<ExtensionRow>('Reason')),
  ]

  const data = useMemo(() => {
    const studentLookup = toLookupTable(allStudents, 'login')
    return exercises
      .filter((e) => e.extensions.length > 0)
      .sort((e1, e2) =>
        e1.moduleCode > e2.moduleCode || (e1.moduleCode === e2.moduleCode && e1.number > e2.number)
          ? 1
          : -1
      )
      .flatMap((e) =>
        e.extensions.map((extension) => {
          return {
            login: extension.studentUsername,
            name: allStudentsAreLoaded
              ? studentLookup.get(extension.studentUsername)?.fullName ?? CellValues.NOT_FOUND
              : CellValues.LOADING,
            module: e.moduleCode,
            exercise: e.number.toString(),
            deadline: e.endDate,
            extension: extension.revisedDeadline,
            grantedBy: extension.grantedBy,
            reason: extension.reason,
          }
        })
      )
  }, [allStudents, allStudentsAreLoaded, exercises])

  const [globalFilter, setGlobalFilter] = React.useState('')
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      expanded,
      sorting,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <Main>
      <PageHeader title="Deadline Extensions Dashboard">
        <p>This is an overview of deadline extensions for {formatShortYear(year)}.</p>
      </PageHeader>
      <Section>
        {!exercisesAreLoaded ? (
          <Banner>Loading data...</Banner>
        ) : (
          data.length === 0 && <Banner>No extensions to show.</Banner>
        )}
      </Section>
      {exercisesAreLoaded && data.length > 0 && (
        <TableSection expanded>
          <GenericTanStackTableRenderer table={table} size="wide" />
        </TableSection>
      )}
    </Main>
  )
}

export default DeadlineExtensionsDashboard
