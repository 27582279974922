interface CircleImageProps {
  image: Blob
  imageAlt: string
  size: number
}

export const ProfileImage = ({ image, imageAlt, size }: CircleImageProps) => {
  const containerStyle = {
    width: `${size}em`,
    height: `${size}em`,
    overflow: 'hidden',
  }

  return (
    <div style={containerStyle}>
      <img
        src={URL.createObjectURL(image)}
        alt={imageAlt}
        className="circle-image"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center center',
          borderRadius: '5%',
        }}
      />
    </div>
  )
}
