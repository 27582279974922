import React from 'react'

import {
  EnrolmentStatusLabel,
  ToggleGroupItem,
  ToggleGroupRoot,
} from '../../styles/moduleSelectionConfiguration'
import { ModuleSelectionStatus } from '../../types/schemas/moduleSelection'
import { formatEnumValue } from '../../utils'

export const ModuleSelectionStatusToggle = ({
  moduleSelectionStatus,
  onStatusUpdate,
}: {
  moduleSelectionStatus: ModuleSelectionStatus
  onStatusUpdate: (_: ModuleSelectionStatus) => void
}) => {
  return (
    <>
      <ToggleGroupRoot type="single" value={moduleSelectionStatus} onValueChange={onStatusUpdate}>
        {Object.values(ModuleSelectionStatus).map((v) => (
          <ToggleGroupItem key={v} value={v}>
            {formatEnumValue(v)}
          </ToggleGroupItem>
        ))}
      </ToggleGroupRoot>
      <EnrolmentStatusLabel className={moduleSelectionStatus.toString()}>
        Enrolment Status: {formatEnumValue(moduleSelectionStatus)}
      </EnrolmentStatusLabel>
    </>
  )
}
