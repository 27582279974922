import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import { Section, SubHeader } from '../../styles/root.style'

interface PageHeaderProps {
  title: string
  header?: string
  subHeader?: string
  children?: ReactNode
}

const PageHeader = ({ title, header, subHeader, children }: PageHeaderProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Section>
      <h1>{header ?? title}</h1>
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>{children}</div>
    </Section>
  </>
)

export default PageHeader
