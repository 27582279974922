import { styled } from '../stitches.config'

export const DropDownHeaderDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.625rem',
  width: '100%',
  '@isDesktop': {
    justifyContent: 'start',
  },
})

export const AllTablesDiv = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2.375rem',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '1.25rem',
})

export const TableDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  padding: '1.25rem',
  borderRadius: '0.5rem',
  border: '1px solid $separator',
  maxWidth: '21.5625rem',
})

export const DropDownTitle = styled('p', {
  fontSize: '1.375rem',
  fontWeight: '600',
  cursor: 'pointer',
})

export const AccordianDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '2rem',
})

export const TutorialTableHeaderDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '0.625rem',
  variants: {
    subHeading: {
      true: {
        marginLeft: '0.625rem',
        gap: '0.1875rem',
      },
    },
  },
})

export const TutorialTableHeader = styled('p', {
  fontSize: '1.125rem',
  fontWeight: '600',
  variants: {
    subHeading: {
      true: {
        fontSize: '1rem',
      },
    },
  },
})
