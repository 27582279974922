import { useContext } from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { ThemeContext } from '../../contextManagers/theme.context'
import { useUser } from '../../contextManagers/user.context'
import useAuth from '../../hooks/auth.service'
import { ActionButton } from '../../styles/dialog.style'
import {
  Header,
  Logo,
  Nav,
  ReactRouterShortcutLink,
  ScientiaTitle,
  VerticalRule,
  WebsiteTitle,
} from '../../styles/navigation.style'
import { CATE_THEME_ACCENT_NAME } from '../../styles/theming/cateMode'
import { formatShortYear, shortYear } from '../../utils'
import ExternalLinksDropdown from './navDropdowns/ExternalLinksDropdown'
import TimelineGuideDropdown from './navDropdowns/TimelineGuideDropdown'
import UserSettingsDropdown from './navDropdowns/UserSettingsDropdown'
import { GenericNavigationBar } from './types'

/**
 * TODO: Extract the colours as a constant and implement functionality for the buttons!
 * TODO: Show a banner under the header if the requested year isn't the current year.
 */
export const DesktopNavigation: GenericNavigationBar = ({ reactRouterLinks }) => {
  const { userDetails } = useUser()
  const { year } = useParams()
  const currentYear = shortYear()
  const { theme, accent } = useContext(ThemeContext)
  const navigate = useNavigate()
  const { isLoggedIn } = useAuth()
  const { pathname } = useLocation()

  const homePage = userDetails?.isStaff ? 'modules' : 'timeline'
  return (
    <Header>
      <Nav>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <ScientiaTitle to={isLoggedIn ? `/${year}/${homePage}` : '/'} title={`View ${homePage}`}>
            <Logo
              alt="Scientia logo"
              src="/assets/logo-light.svg"
              style={{ filter: `invert(${theme === 'dark' ? 1 : 0})` }}
            />
            {accent === CATE_THEME_ACCENT_NAME ? (
              <WebsiteTitle>
                <span style={{ textDecoration: 'line-through' }}>Scientia</span> CATe
              </WebsiteTitle>
            ) : (
              <WebsiteTitle>Scientia</WebsiteTitle>
            )}
          </ScientiaTitle>
          <VerticalRule />

          {reactRouterLinks.map((linkProps, linkIndex) => (
            <ReactRouterShortcutLink {...linkProps} key={linkIndex}>
              <linkProps.icon size={22} />
            </ReactRouterShortcutLink>
          ))}

          {year !== currentYear && (
            <ActionButton.Primary
              style={{ padding: '0.5rem 1rem' }}
              onClick={() => navigate('/' + currentYear + window.location.pathname.slice(5))}
            >
              Go to {formatShortYear()}
            </ActionButton.Primary>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          {matchPath({ path: '/:year/timeline' }, pathname) && <TimelineGuideDropdown />}
          <ExternalLinksDropdown />
          <UserSettingsDropdown />
        </div>
      </Nav>
    </Header>
  )
}
