import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { PhDAdminUsers, PhDAssessorRole } from '../../constants/phdTracking'
import { ThemeContext } from '../../contextManagers/theme.context'
import { useUser } from '../../contextManagers/user.context'
import { usePhdStudent, usePhdSupervisors } from '../../hooks/phd.service'
import { AnchorButton, Button, Div } from '../../styles/_app.style'
import { Form } from '../../styles/form.style'
import Select from '../select/Select'
import Tooltip from '../tooltip/Tooltip'

interface SupervisorProps {
  studentLogin: string
  isSupervisor: boolean
  isAssessor: boolean
}

export const SetSupervisors = ({ studentLogin, isSupervisor, isAssessor }: SupervisorProps) => {
  const { phdStudent, adminApproveMilestone } = usePhdStudent()

  const { phdSupervisors, supervisorsLoaded, updateAssessorForStudent } = usePhdSupervisors()
  const { userDetails } = useUser()
  const { theme } = useContext(ThemeContext)

  const [assessorLogin, setAssessorLogin] = useState<string | undefined>('')

  useEffect(() => {
    setAssessorLogin(
      phdStudent?.supervisors
        .filter((user) => user.role === PhDAssessorRole)
        .map((user) => user.login)[0]
    )
  }, [phdStudent, supervisorsLoaded])

  const selectSupervisorsList = phdSupervisors.map((option) => ({
    value: option.login,
    label: `${option.fullName}- (${option.login})`,
  }))

  function handleSetAssessor(newValue: any) {
    if (newValue) setAssessorLogin(newValue.value)
  }

  const handleSubmitAssessor = () => {
    if (assessorLogin) updateAssessorForStudent(studentLogin, assessorLogin)
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <Form
        css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmitAssessor()
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em',
          }}
        >
          <span>Select PhD Assessor:</span>
          <div>
            <Select
              options={selectSupervisorsList}
              value={selectSupervisorsList.find((o) => o.value === assessorLogin)}
              onChange={handleSetAssessor}
              placeholder="Select Assessor"
            />
          </div>
        </div>

        <Tooltip label={'Save Feedback'}>
          <Button>Save Assessor</Button>
        </Tooltip>
      </Form>
    </Div>
  )
}
