import { ProgressIndicatorProps, ProgressProps } from '@radix-ui/react-progress'
import React from 'react'

import { SubText } from '../../styles/_app.style'
import { ProgressIndicator, ProgressRoot } from './progressBar.style'

interface CustomProgressProps {
  caption?: string
}

const ProgressBar = ({
  value,
  max,
  style,
  caption,
}: ProgressProps & ProgressIndicatorProps & CustomProgressProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
      <ProgressRoot value={value} max={max}>
        <ProgressIndicator style={style} />
      </ProgressRoot>
      {caption && <SubText>{caption}</SubText>}
    </div>
  )
}

export default ProgressBar
