import { ReactNode } from 'react'

import { Container, Header } from '../styles/card.style'

const Card = ({ children, title }: { children: ReactNode; title: string }) => {
  return (
    <Container>
      <Header>
        <span>{title}</span>
      </Header>
      {children}
    </Container>
  )
}

export default Card
