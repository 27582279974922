import { CSS } from '@stitches/react'

import { Exercise, ExerciseStatus } from '../types/schemas/emarking'

export interface ExerciseStyleOptions {
  allowRedBorder?: boolean
}

export const styleForSubmissionType = (submissionType: string, isAssessed: Boolean): CSS => {
  let style: CSS = { borderWidth: 1, borderStyle: 'solid', '&:hover': {} }
  if (isAssessed) {
    if (submissionType === 'individual') {
      style = {
        ...style,
        borderColor: '$green7',
        backgroundColor: '$green4',
        '&:hover': { backgroundColor: '$green5' },
      }
    }
    if (submissionType === 'group') {
      style = {
        ...style,
        borderColor: '$plum7',
        backgroundColor: '$plum4',
        '&:hover': { backgroundColor: '$plum5' },
      }
    }
  } else {
    if (submissionType === 'no submission required') {
      style = {
        ...style,
        borderColor: '$sand7',
        backgroundColor: '$neutral1',
        '&:hover': { backgroundColor: '$neutral3' },
      }
    } else {
      style = {
        ...style,
        borderColor: '$orange7',
        backgroundColor: '$orange4',
        '&:hover': { backgroundColor: '$orange5' },
      }
    }
  }
  return style
}

function updateStyleOnSubmissionStatus(style: CSS, exercise: Exercise): CSS {
  if (
    exercise.submissionType !== 'no submission required' &&
    (exercise.exerciseStatus === ExerciseStatus.DUE ||
      (exercise.exerciseStatus === ExerciseStatus.LATE && exercise.isAssessed))
  ) {
    return {
      ...style,
      borderColor: '$red9',
      '&:hover': { ...(style as any)['&:hover'], borderColor: '$red10' },
      borderWidth: '1.5px',
    }
  }
  return style
}

export const styleForExercise = (exercise: Exercise, options: ExerciseStyleOptions): CSS => {
  const { submissionType, isAssessed } = exercise
  let style = styleForSubmissionType(submissionType, isAssessed)
  if (options.allowRedBorder) style = updateStyleOnSubmissionStatus(style, exercise)
  return style
}
