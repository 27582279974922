import * as Tooltip from '@radix-ui/react-tooltip'
import { styled } from '@stitches/react'

export const Content = styled(Tooltip.Content, {
  padding: '0.6rem .9rem',
  borderRadius: '0.4rem',
  backgroundColor: '$elementBackground',
  color: '$highContrast',
})

export const Arrow = styled(Tooltip.Arrow, {
  fill: '$elementBackground',
  marginBottom: '.5rem',
})
