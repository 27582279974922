import { plainToInstance } from 'class-transformer'
import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { ExternalModule, ExternalModuleChoice } from '../types/schemas/moduleSelection'
import { mapPlainToInstance, objectKeysToSnakeCase } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useExternalModules = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [externalModulesOnOffer, setExternalModulesOnOffer] = useState<ExternalModule[]>([])
  const [externalModulesAreLoaded, setExternalModulesAreLoaded] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(endpoints.externalModulesOnOffer(year))
      .then(({ data }) => {
        setExternalModulesOnOffer(mapPlainToInstance(ExternalModule, data))
      })
      .catch(displayError('Failed to fetch external modules on offer'))
      .finally(() => setExternalModulesAreLoaded(true))
  }, [axiosInstance, displayError, year])

  return {
    externalModulesOnOffer,
    externalModulesAreLoaded,
  }
}

export const useExternalModuleSelection = (year: string) => {
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()
  const [chosenExternalModules, setChosenExternalModules] = useState<ExternalModuleChoice[]>([])

  useEffect(() => {
    axiosInstance
      .get(endpoints.personalExternalModuleChoices(year))
      .then(({ data }) => {
        setChosenExternalModules(mapPlainToInstance(ExternalModuleChoice, data))
      })
      .catch(displayError('Failed to fetch current external module choices'))
  }, [axiosInstance, displayError, year])

  function apply(moduleCode: string) {
    axiosInstance
      .post(endpoints.personalExternalModuleChoices(year), objectKeysToSnakeCase({ moduleCode }))
      .then(({ data }) => {
        let chosenExternalModule = plainToInstance(ExternalModuleChoice, data)
        setChosenExternalModules((curr) => [...curr, chosenExternalModule])
      })
      .catch(displayError('Failed to apply to external module'))
  }

  return {
    chosenExternalModules,
    actions: {
      apply,
    },
  }
}
