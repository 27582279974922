/**
 * Contains colour accents for scientia
 */
import { createTheme } from '@stitches/react'

import { colorFamily, darkThemeBase } from '../stitches.config'
import cateTheme, { CATE_THEME_ACCENT_NAME } from './cateMode'
import {
  ACCENT_THEME_OVERRIDES,
  EXTRACT_PICKER_COLOUR_FROM_INDEX,
  THEMES_RAW,
} from './colourConstants'
import { ThemeGeneratee } from './types'

/** Extra accents we add */
const extraAccents = { [CATE_THEME_ACCENT_NAME]: cateTheme }

/** Define a list of accents that give special case behaviour */
export const SPECIAL_ACCENTS = [CATE_THEME_ACCENT_NAME]

/** Complete list of themes, with radix UI colour info  */
export const ACCENTS: Record<string, ThemeGeneratee> = {
  ...extraAccents,
  ...Object.fromEntries(
    THEMES_RAW.map(([name, light, dark, pickerColor]) => [
      name,
      {
        mainColors: {
          light: light[`${name}${pickerColor ?? EXTRACT_PICKER_COLOUR_FROM_INDEX}`],
          dark: dark[`${name}${pickerColor ?? EXTRACT_PICKER_COLOUR_FROM_INDEX}`],
        },
        name,
        colors: {
          light,
          dark,
        },
        radixThemes: {
          light: createTheme({
            colors: {
              ...ACCENT_THEME_OVERRIDES,
              ...colorFamily('neutral', light),
            },
          }),
          dark: createTheme({
            colors: {
              ...darkThemeBase,
              ...colorFamily('neutral', dark),
            },
          }),
        },
      },
    ])
  ),
}
