import React, { useMemo, useRef, useState } from 'react'

import { usePhdStudent } from '../../hooks/phd.service'
import { Button, Div } from '../../styles/_app.style'
import { Form } from '../../styles/form.style'
import { StudentMilestone } from '../../types/phds'
import Select from '../select/Select'
import Tooltip from '../tooltip/Tooltip'
import { SubmissionStatus } from './StatusIcons'

interface stageProps {
  studentLogin: string
  isSupervisor: boolean
  isAssessor: boolean
  stage: StudentMilestone
}

export const LsrStage = ({ studentLogin, isSupervisor, isAssessor, stage }: stageProps) => {
  const { postFeedbackForMilestone, postStatusForMilestone } = usePhdStudent()
  const assessorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const supervisorFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const [supervisorFeedback, setSupervisorFeedback] = useState<string | undefined>('')
  const [assessorFeedback, setAssessorFeedback] = useState<string | undefined>('')

  const [milestoneStatus, setMilestoneStatus] = useState<string>('')

  const milestoneStatusOptions = [
    { value: 'pass', label: 'Pass' },
    { value: 'fail', label: 'Fail' },
  ]

  function handleMilestoneStatus(newValue: any) {
    if (newValue) setMilestoneStatus(newValue.value)
  }

  const initialPayload = useMemo(() => {
    setSupervisorFeedback(stage.supervisorFeedback ?? '')
    setAssessorFeedback(stage.assessorFeedback ?? '')
    setMilestoneStatus(stage.status)
    return {
      status: stage.status,
      supervisorFeedback: stage.supervisorFeedback,
      assessorFeedback: stage.assessorFeedback,
    }
  }, [stage])

  const handleSupervisorFeedbackChange = () => {
    const { current } = supervisorFeedbackRef
    setSupervisorFeedback(current?.value)

    if (milestoneStatus) {
      postStatusForMilestone(studentLogin, 'lsr', milestoneStatus, '')
    }

    if (current?.value) {
      postFeedbackForMilestone(studentLogin, 'lsr', current?.value)
    }
  }

  const handleAssessorFeedbackChange = () => {
    const { current } = assessorFeedbackRef
    setAssessorFeedback(current?.value)

    if (current?.value) {
      postFeedbackForMilestone(studentLogin, 'lsr', current?.value)
    }
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '1em',
          alignItems: 'center',
        }}
      >
        <span>Date Required: {stage.dateRequired}</span>
        <SubmissionStatus submissionStatus={stage.status} stageStatus={stage.status} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
        }}
      >
        <span>Date Submitted: {stage.dateSubmitted}</span>
        <Tooltip label={'View submission'}>
          <Button>View submission</Button>
        </Tooltip>
      </div>

      <Form
        css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        onSubmit={(event) => {
          event.preventDefault()
          handleSupervisorFeedbackChange()
        }}
      >
        {isSupervisor && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <span>Select Status:</span>
            <div>
              <Select
                options={milestoneStatusOptions}
                value={milestoneStatusOptions.find((o) => o.value === milestoneStatus)}
                onChange={handleMilestoneStatus}
                placeholder="Milestone status"
              />
            </div>
          </div>
        )}

        <div />

        <div
          style={{
            padding: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span>Approved by PhD Office:</span>
          <span>{stage.adminApproval} </span>
        </div>

        {isSupervisor && (
          <div
            style={{
              padding: '1em',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>Supervisor Feedback:</h4>
            <textarea
              style={{
                backgroundColor: 'transparent',
                background: 'transparent',
                padding: '0.5em',
                marginBottom: '1em',
              }}
              placeholder="Enter supervisor feedback"
              ref={supervisorFeedbackRef}
              rows={5}
              cols={80}
              defaultValue={supervisorFeedback}
            ></textarea>
            <Tooltip label={'Save Feedback'}>
              <Button>Save Feedback &amp; Status</Button>
            </Tooltip>
          </div>
        )}
      </Form>

      {isAssessor && (
        <Form
          css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          onSubmit={(event) => {
            event.preventDefault()
            handleAssessorFeedbackChange()
          }}
        >
          <div
            style={{
              padding: '1em',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>Assessor Feedback: </h4>
            <textarea
              style={{
                backgroundColor: 'transparent',
                background: 'transparent',
                padding: '0.5em',
                marginBottom: '1em',
              }}
              placeholder="Enter Assessor feedback"
              ref={assessorFeedbackRef}
              rows={5}
              cols={80}
              defaultValue={assessorFeedback}
            ></textarea>
            <Tooltip label={'Save Feedback'}>
              <Button>Save Feedback</Button>
            </Tooltip>
          </div>
        </Form>
      )}
    </Div>
  )
}
