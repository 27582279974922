import { ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'
import { format as formatTimeAgo } from 'timeago.js'

import { useUser } from '../../contextManagers/user.context'
import { Div, Span } from '../../styles/_app.style'
import { styleForExercise } from '../../styles/exerciseItem.style'
import { css } from '../../styles/stitches.config'
import { TrackItemTitle, trackItemStyle } from '../../styles/timeline/trackItem.style'
import { Term } from '../../types/schemas/abc'
import { Exercise } from '../../types/schemas/emarking'
import { now } from '../../utils'

export const TrackItem = (props: {
  exercise: Exercise
  term: Term
  startColumn: number
  endColumn: number
  row: number
}) => {
  const { exercise, startColumn, endColumn, term } = props
  const { year } = useParams()
  const { userDetails } = useUser()

  const AnchorWrapper = ({ children }: { children: ReactNode }) => {
    const title = `${exercise.type}: ${exercise.title} - due ${formatTimeAgo(exercise.deadline)}`
    const style = {
      className: css({
        ...trackItemStyle(props),
        ...styleForExercise(exercise, {
          allowRedBorder: !userDetails?.isStaffOrTAForModule(exercise.moduleCode),
        }),

        // If the exercise started before this term, remove left border
        ...(exercise.startDate < term.start
          ? {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftStyle: 'dashed',
              borderLeftWidth: '0.21875rem',
            }
          : {}),
        // If the exercise ends after this term, remove right border
        ...(exercise.deadline > term.end
          ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRightStyle: 'dashed',
              borderRightWidth: '0.21875rem',
            }
          : {}),
      })(),
    }

    return userDetails?.isStaff || exercise.startDate <= now() ? (
      <Link
        to={`/${year}/modules/${exercise.moduleCode}/exercises/${exercise.number}`}
        title={title}
        {...style}
      >
        {children}
      </Link>
    ) : (
      <div title={title} {...style}>
        {children}
      </div>
    )
  }

  const isSingleDay = endColumn - startColumn < 2
  return (
    <AnchorWrapper>
      <TrackItemTitle>
        {isSingleDay ? (
          <span style={{ fontWeight: 500 }}>{exercise.number}</span>
        ) : (
          <>
            <span style={{ fontWeight: 500 }}>{exercise.type}:</span> {exercise.title}
            {exercise.estimatedWorkHours ? (
              <Div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  columnGap: '0.5rem',
                  fontSize: '0.9rem',
                }}
              >
                <Span css={{ color: '$sand10' }}>Estimate: {exercise.estimatedWorkHours}h</Span>
                <Span css={{ color: '$sand10' }}>{exercise.moduleContribution}</Span>
              </Div>
            ) : (
              ''
            )}
          </>
        )}
      </TrackItemTitle>
    </AnchorWrapper>
  )
}
