/**
 * Contains the frontend code for displaying a table created by the TanStack library's useReactTable hook
 */
import {
  Cell,
  CellContext,
  Getter,
  Header,
  Row,
  RowData,
  Table as TanStackTable,
  flexRender,
} from '@tanstack/react-table'
import { DisplayColumnDef } from '@tanstack/table-core/build/lib/types'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import type { ComponentProps } from 'react'
import React from 'react'
import { CaretDownFill, CaretRightFill, CaretUpFill, Icon } from 'react-bootstrap-icons'

import { useResponsiveness } from '../../../hooks/breakpoints.service'
import { StitchesCSSProperties } from '../../../styles/stitches.config'
import { Table, Td, Th } from '../../../styles/table.style'

export enum CellValues {
  NOT_FOUND = 'Not found',
  LOADING = 'Loading...',
}

/**
 * Custom typings for the `meta` property on column accessors
 */
declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    textIsCentred?: boolean
    desktopOnly?: boolean
    css?: ComponentProps<typeof Td | typeof Th>['css']
  }
}

interface CreateColOptions {
  id?: string
}

interface CreateDatetimeColOptions extends CreateColOptions {
  dateFormat?: string
}

export function caretIcon(rowIsExpanded: boolean): Icon {
  return rowIsExpanded ? CaretDownFill : CaretRightFill
}

export function createExpanderColumn<T>() {
  return {
    id: 'expander',
    cell: ({ row, getValue }: { row: Row<T>; getValue: Getter<void> }) => {
      const Caret = caretIcon(row.getIsExpanded())
      return (
        row.getCanExpand() && (
          <Caret onClick={row.getToggleExpandedHandler()} style={{ cursor: 'pointer' }} />
        )
      )
    },
    header: ({ table }: { table: TanStackTable<T> }) => {
      const Caret = caretIcon(table.getIsAllRowsExpanded())
      return <Caret onClick={table.getToggleAllRowsExpandedHandler()} />
    },
    enableSorting: false,
  }
}

export function createPlainColumn<T>(
  header: string,
  opts?: CreateColOptions
): DisplayColumnDef<T, string> {
  return {
    id: opts?.id ?? header.toLowerCase(),
    cell: (info: CellContext<T, string>) => info.getValue(),
    header,
  }
}

export function createDatetimeColumn<T>(
  header: string,
  opts?: CreateDatetimeColOptions
): DisplayColumnDef<T, Date | null> {
  return {
    id: opts?.id ?? header.toLowerCase(),
    cell: (info: CellContext<T, Date | null>) => {
      let value = info.getValue()
      return value ? format(value, opts?.dateFormat ?? 'Y-M-dd HH:mm') : null
    },
    header,
    sortingFn: 'datetime',
    sortUndefined: 1,
    enableGlobalFilter: false,
  }
}

export function columnsFromAttributes(
  attributes: string[]
): { accessorKey: string; header: string }[] {
  return attributes.map((v) => ({
    accessorKey: v,
    header: startCase(v),
  }))
}

const GenericTanStackTableRenderer = <T extends RowData>({
  table,
  style,
  size = 'compact',
  showHeader = true,
  inlineHeader = false,
  additionalCellCSSGenerator = () => ({}),
}: {
  table: TanStackTable<T>
  style?: {
    table?: StitchesCSSProperties
  }
  size?: ComponentProps<typeof Td | typeof Th>['size']
  showHeader?: boolean
  /** Show header as if it is another row of the table */
  inlineHeader?: boolean
  additionalCellCSSGenerator?: (
    cell: Header<T, unknown> | Cell<T, unknown>,
    cellIndex: number
  ) => StitchesCSSProperties
}) => {
  const { isDesktop } = useResponsiveness()

  // Apparently there's no OFFICIAL way to determine whether a table was configured for sorting
  // other than checking whether a particular sortChange handler function was passed
  const sortingEnabled = !!table.options.onSortingChange?.name

  const generateCellCSS = (cell: Header<T, unknown> | Cell<T, unknown>, cellIndex: number) => {
    return {
      cursor: sortingEnabled && cell.column.getCanSort() ? 'pointer' : 'auto',
      display:
        cell.column.columnDef.meta?.desktopOnly && !isDesktop
          ? cell.column.columnDef.meta?.css?.display || 'none'
          : undefined,
      ...cell.column.columnDef.meta?.css,
      ...additionalCellCSSGenerator(cell, cellIndex),
    }
  }

  // TODO: Reduce duplication of this and RawSubmissionsTable
  return (
    <Table css={style?.table}>
      {showHeader && (
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  expander={headerIndex === 0}
                  size={size}
                  textCentred={!!header.column.columnDef.meta?.textIsCentred}
                  inlineHeader={inlineHeader}
                  css={generateCellCSS(header, headerIndex)}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {sortingEnabled &&
                    ({
                      asc: <CaretUpFill />,
                      desc: <CaretDownFill />,
                    }[header.column.getIsSorted() as string] ??
                      null)}
                </Th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, cellIndex) => (
              <Td
                key={cell.id}
                expander={cellIndex === 0}
                subRow={cellIndex > 0 && row.depth > 0}
                size={size}
                css={generateCellCSS(cell, cellIndex)}
                textCentred={cell.column.columnDef.meta?.textIsCentred}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GenericTanStackTableRenderer
