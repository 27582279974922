import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import PagePlaceholder from '../../components/PagePlaceholder'
import PageHeader from '../../components/common/PageHeader'
import { LevelSubmissionWarning } from '../../components/exercise/LevelSubmissionWarning'
import titles from '../../constants/titles'
import { useUser } from '../../contextManagers/user.context'
import { useExercise } from '../../hooks/exercise.service'
import { useEnrolledStudentsLookup, useModule } from '../../hooks/module.service'
import { useTutorialGroupsForExercise } from '../../hooks/tutorialGroups.service'
import { P } from '../../styles/_app.style'
import { Link } from '../../styles/exercise/exercise.style'
import { Main, SubHeader } from '../../styles/root.style'
import { displayTimestamp } from '../../utils'

const Exercise = () => {
  const { year, moduleCode, exerciseNumber } = useParams()
  const { userDetails } = useUser()
  const { module } = useModule()
  const { enrolledStudentsLookup } = useEnrolledStudentsLookup()
  const { exercise, setExercise, exerciseIsLoaded, actions } = useExercise()
  const { tutorialGroups } = useTutorialGroupsForExercise(exercise)

  const pageTitle = titles.exercise(
    exercise?.year || year!,
    exercise?.moduleCode || moduleCode!,
    exercise?.number || exerciseNumber!,
    exercise?.title
  )

  if (!userDetails || !module || !exercise) {
    return (
      <PagePlaceholder
        title={titles.exercise(year!, moduleCode!, exerciseNumber!)}
        header={`${moduleCode}: ${exerciseNumber}`}
        text={exerciseIsLoaded ? 'No information for this exercise.' : 'Loading exercise...'}
      />
    )
  }

  return (
    <Main>
      <PageHeader title={pageTitle} header={`${exercise.type}: ${exercise.title}`}>
        <Link
          href={`/${exercise.year}/modules/${exercise.moduleCode}/materials`}
          css={{ textDecoration: 'none' }}
        >
          <SubHeader>
            {exercise.moduleCode} {module?.title || ''}
          </SubHeader>
        </Link>
        <P
          css={{
            fontSize: '$md',
            fontWeight: 600,
            color: '$highContrast',
          }}
        >
          Due {displayTimestamp(exercise.deadline)}
        </P>
        {/* warning if student not enrolled at level 3 */}
        <LevelSubmissionWarning userDetails={userDetails} moduleCode={moduleCode} />
      </PageHeader>
      <Outlet
        context={{
          module,
          enrolledStudentsLookup,
          exercise,
          setExercise,
          actions,
          tutorialGroups,
        }}
      />
    </Main>
  )
}

export default Exercise
