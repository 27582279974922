import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PageHeader from '../components/common/PageHeader'
import AllModulesCard from '../components/records/AllModulesCard'
import ProfileCard from '../components/records/ProfileCard'
import { WeeklyTutorialAttendanceSection } from '../components/records/WeeklyTutorialAttendanceSection'
import { useStudentExerciseSummaries } from '../hooks/exercises.service'
import { useStudent } from '../hooks/student.service'
import { useStudentTutoringSessions, useTutorialGroups } from '../hooks/tutorialGroups.service'
import { Banner } from '../styles/_app.style'
import { Main, Section } from '../styles/root.style'
import { UserDetails } from '../types/schemas/abc'
import { groupByProperty } from '../utils'

const StudentRecords = ({ studentDetails }: { studentDetails: UserDetails }) => {
  const { tutoringSessions } = useStudentTutoringSessions(studentDetails.year, studentDetails.login)
  const { tutorialGroups } = useTutorialGroups(studentDetails.year)

  const moduleCodes = useMemo(() => studentDetails.modules.map((m) => m.code), [studentDetails])
  const exercises = useStudentExerciseSummaries(
    studentDetails.year,
    studentDetails.login,
    moduleCodes
  )
  const moduleSummariesMap = useMemo(
    () => new Map(Object.entries(groupByProperty(exercises, 'moduleCode', 'number'))),
    [exercises]
  )
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <ProfileCard studentDetails={studentDetails} />
      </div>
      <AllModulesCard
        studentModules={studentDetails.modules}
        moduleSummaries={moduleSummariesMap}
        exerciseStyleOptions={{ allowRedBorder: true }}
      />
      {tutoringSessions.length > 0 && (
        <WeeklyTutorialAttendanceSection
          tutoringSessions={tutoringSessions}
          tutorialGroups={tutorialGroups}
        />
      )}
    </div>
  )
}

const StudentRecord = () => {
  const { year, username } = useParams()
  const studentDetails = useStudent(year as string, username as string)

  return (
    <Main>
      <Section>
        <PageHeader title={`Student record: ${username}`} header="Student record" />
        {!!studentDetails ? (
          <StudentRecords studentDetails={studentDetails} />
        ) : (
          <Banner>No records found for {username}.</Banner>
        )}
      </Section>
    </Main>
  )
}

export default StudentRecord
