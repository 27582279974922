import { useParams } from 'react-router-dom'

import { TIMELINE_TRACK_HEIGHT } from '../../constants/global'
import { useResponsiveness } from '../../hooks/breakpoints.service'
import { css } from '../../styles/stitches.config'
import { TIMELINE_COL_WIDTH } from '../../styles/timeline/constants.style'
import { ModulesArea } from '../../styles/timeline/gridAreas'
import { Module } from '../../types/schemas/abc'
import { Tabs } from '../Tabs'

const MODULE_TITLE_ELLIPSIS_MARGIN = '2rem'

export const Modules = ({
  modules,
  rowHeights,
}: {
  modules: Module[]
  rowHeights: { [code: string]: string }
}) => {
  const { year } = useParams()
  const { isDesktop } = useResponsiveness()

  return (
    <ModulesArea css={{ borderRight: modules.length > 0 ? '1px solid $elementBorder' : '' }}>
      <Tabs
        data={modules}
        attribute={'code'}
        dividers={modules.length > 1}
        href={(module) => `/${year}/modules/${module.code}/materials`}
        tabProps={modules.map((_) => {
          return { css: { width: TIMELINE_COL_WIDTH } }
        })}
        generator={(module: Module) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              height: rowHeights[module.code] ?? TIMELINE_TRACK_HEIGHT,
              width: TIMELINE_COL_WIDTH,
            }}
          >
            {isDesktop && (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  minWidth: '0',
                  overflow: 'hidden',
                  width: `calc(${TIMELINE_COL_WIDTH} - ${MODULE_TITLE_ELLIPSIS_MARGIN})`,
                }}
              >
                {module.title}
              </span>
            )}
            <span className={css({ color: '$lowContrast' })()}>{module.code}</span>
          </div>
        )}
      />
    </ModulesArea>
  )
}
