import React from 'react'
import { Navigate, useOutletContext } from 'react-router-dom'

import { useUser } from '../../contextManagers/user.context'
import { Exercise } from '../../types/schemas/emarking'

const ExerciseSubRouter = () => {
  const { userDetails } = useUser()
  const { exercise } = useOutletContext<{
    exercise: Exercise
  }>()

  if (userDetails!.isStaff || userDetails!.isGTAForModule(exercise.moduleCode))
    return <Navigate to={'staff'} replace />
  if (exercise.isForYearOneTutorialGroups && userDetails!.isUTAForModule(exercise.moduleCode))
    return <Navigate to={'uta'} replace />
  return <Navigate to={'student'} replace />
}

export default ExerciseSubRouter
