import React from 'react'
import { PersonCircle } from 'react-bootstrap-icons'

interface ContributorImageProps {
  image?: string
  size: number
}

const ContributorImage: React.FC<ContributorImageProps> = ({ image, size }) => {
  return image ? (
    <img
      src={image}
      alt="Contributor profile"
      style={{
        objectFit: 'cover',
        objectPosition: 'center center',
        borderRadius: size / 2,
        height: size,
        width: size,
      }}
    />
  ) : (
    <PersonCircle size={size} />
  )
}

export default ContributorImage
