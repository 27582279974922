import React from 'react'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'

import SelectStyle from './select.style'

export interface StandardSelectOption {
  label: string
  value: string
}
const Select = (props: ReactSelectProps) => {
  return <ReactSelect styles={SelectStyle} closeMenuOnSelect={true} {...props} />
}

export default Select
