import React from 'react'

import { B, FlexContainer, Span } from '../../styles/_app.style'
import { Mark, SubmissionFeedback } from '../../types/schemas/emarking'
import { calculateGrade, isNumber } from '../../utils'
import Card from '../Card'
import { FormattedMark } from '../FormattedMark'
import { FeedbackLink } from './FeedbackButton'

const LargeTextFeedbackItem: React.FC<{ content: string }> = ({ content }) => {
  return (
    <FlexContainer column centred css={{ gap: '0.25em', flex: '1 0 0' }}>
      <Span css={{ fontSize: '$xl' }} noWrap>
        <B>{content}</B>
      </Span>
    </FlexContainer>
  )
}

const FeedbackSection: React.FC<{
  mark: Mark | null
  feedback: SubmissionFeedback | null
  exerciseTitle: string
  maximumMark: number
}> = ({ feedback, exerciseTitle, mark, maximumMark }) => {
  const PLACEHOLDER = '-'

  return (
    <Card title="Results">
      <FlexContainer centred>
        {mark && isNumber(mark.mark) ? (
          <FormattedMark
            mark={mark.mark}
            cap={mark.cap}
            maximumMark={maximumMark}
            markTextCss={{ fontSize: '$xl' }}
            containerCss={{ flex: '1 0 0' }}
          />
        ) : (
          <LargeTextFeedbackItem content={PLACEHOLDER} />
        )}

        <LargeTextFeedbackItem
          content={
            mark && isNumber(mark.mark) ? calculateGrade(mark.mark, maximumMark) : PLACEHOLDER
          }
        />
        {feedback ? (
          <FeedbackLink
            feedback={feedback}
            exerciseTitle={exerciseTitle}
            css={{ flex: '1 0 0', textAlign: 'center' }}
          />
        ) : (
          <LargeTextFeedbackItem content={PLACEHOLDER} />
        )}
      </FlexContainer>
    </Card>
  )
}

export default FeedbackSection
