import { theme } from '../../styles/stitches.config'

const { colors } = theme

const SelectStyle = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '0.9rem',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isDisabled ? colors.highContrast.toString() : colors.sand12.toString(),
    backgroundColor: state.isSelected
      ? colors.elementActive.toString()
      : colors.appBackground.toString(),
    '&:hover': {
      backgroundColor: state.isDisabled ? '' : colors.elementHover.toString(),
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: colors.sand12.toString(),
  }),
  multiValueContainer: (provided: any) => ({
    ...provided,
    width: 'fit-content',
    // https://react-select.com/styles#select-props
    // https://stackoverflow.com/questions/50620369/react-select-is-disabled-cant-change-color
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    width: 'fit-content',
    border: '2px solid ' + colors.sand6.toString(),
    borderRadius: '14px',
    padding: '4px',
    backgroundColor: colors.sand2.toString(),
    color: colors.highContrast.toString(),
    cursor: 'default',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderRadius: '16px',
    cursor: 'pointer',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    borderRadius: '0.5rem',
  }),
  menu: (provided: any) => ({
    ...provided,
    padding: 0,
    marginTop: '0.5rem',
    border: 'none',
    borderRadius: '0.5rem',
    backgroundColor: colors.appBackground.toString(),
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: colors.highContrast.toString(),
    cursor: 'pointer',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: colors.highContrast.toString(),
    cursor: 'pointer',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    boxSizing: 'border-box',
    width: '100%',
    height: '2.75rem',
    borderColor: state.isFocused
      ? colors.solidBackground.toString()
      : colors.elementBorder.toString(),
    borderRadius: '0.5rem',
    padding: '0.25rem',
    backgroundColor: colors.appBackground.toString(),
    cursor: 'text',
  }),
  input: (provided: any) => ({
    ...provided,
    color: colors.highContrast.toString(),
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.solidBackground.toString(),
  }),
  container: (provided: any) => ({
    ...provided,
    width: 'auto',
    flexGrow: 1,
  }),
  records: () => ({
    padding: '0.9rem',
    color: colors.highContrast.toString(),
    backgroundColor: colors.elementActive.toString(),
    '&:hover': {
      backgroundColor: colors.elementHover.toString(),
    },
  }),
}

export default SelectStyle
