import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PhdStudentDetailsPersonal } from '../../components/phd/PhdStudentDetailsPersonal'
import { useUser } from '../../contextManagers/user.context'
import { Container } from '../../styles/_app.style'

// import { PhdStudentDetailsPersonal } from '../components/phd/PhdStudentDetailsPersonal'
// import { useUser } from '../contextManagers/user.context'
// import { Container } from '../styles/_app.style'

export const PhdStudentPersonal = () => {
  const { userDetails } = useUser()
  const { year } = useParams()

  return (
    <>
      <Container>
        <Helmet>
          <title>PhD Tracker - My Page</title>
        </Helmet>
        <PhdStudentDetailsPersonal />
      </Container>
    </>
  )
}
