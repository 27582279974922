import * as Tooltip from '@radix-ui/react-tooltip'

import { styled } from './stitches.config'

export const ToolTipTrigger = styled('button', {
  background: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ToolTipContent = styled(Tooltip.Content, {
  background: '$neutral4',
  padding: '0.25rem 0.5rem',
  fontSize: '$sm',
  borderRadius: '0.25rem',
})
