import React, { ComponentProps } from 'react'

import { B, Div, P } from '../styles/_app.style'
import { isNumber } from '../utils'

export const FormattedMark = ({
  mark,
  cap,
  maximumMark,
  markTextCss,
  containerCss,
}: {
  mark: number
  cap: number | null
  maximumMark: number
  markTextCss?: ComponentProps<typeof B>['css']
  containerCss?: ComponentProps<typeof Div>['css']
}) => {
  let actualMark = (
    <B css={{ whiteSpace: 'nowrap', ...markTextCss }}>
      {mark} / {maximumMark}
    </B>
  )
  return (
    <Div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...containerCss }}>
      {isNumber(cap) ? <del>{actualMark}</del> : actualMark}
      {isNumber(cap) && (
        <P css={{ whiteSpace: 'nowrap', color: '$sand9', fontSize: '$sm' }}>
          Capped at {cap ? 'pass mark' : cap}
        </P>
      )}
    </Div>
  )
}
