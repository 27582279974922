import React, { useState } from 'react'
import { Trash3Fill } from 'react-bootstrap-icons'

import { DeliverablesActions } from '../../hooks/exercise.service'
import { Banner, Button } from '../../styles/_app.style'
import { ActionBlock, ActionItemContainer, ActionableListContainer } from '../../styles/list.style'
import { Exercise, LABTS_SUBMISSION } from '../../types/schemas/emarking'
import Card from '../Card'
import Tooltip from '../tooltip/Tooltip'
import DeliverablesList from './DeliverablesList'
import { NewFileDeliverableDialog } from './NewFileDeliverableDialog'

const EditableDeliverablesSection = ({
  exercise,
  actions,
}: {
  exercise: Exercise
  actions: DeliverablesActions
}) => {
  const [deliverableDialogIsOpen, setDeliverableDialogIsOpen] = useState(false)

  return (
    <>
      <Card title="Deliverables">
        {exercise.deliverables.length > 0 ? (
          <ActionableListContainer>
            <DeliverablesList deliverables={exercise.deliverables} />
            {!exercise.isOpen && (
              <ActionBlock>
                {exercise.deliverables.map((d) => (
                  <ActionItemContainer key={d.id}>
                    <Tooltip label={'Delete deliverable'}>
                      <Button icon onClick={() => actions.deleteDeliverable(d.id)}>
                        <Trash3Fill size={20} />
                      </Button>
                    </Tooltip>
                  </ActionItemContainer>
                ))}
              </ActionBlock>
            )}
          </ActionableListContainer>
        ) : (
          <Banner>Not configured</Banner>
        )}
        {!exercise.isClosed && (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'end',
              gap: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Tooltip label={'Add GitLab Hash (i.e. LabTS) deliverable'}>
              <Button
                disabled={!!exercise.deliverables.find((d) => d.type === LABTS_SUBMISSION)}
                onClick={() => actions.addDeliverable({ name: 'commit', type: LABTS_SUBMISSION })}
              >
                Add GitLab Hash
              </Button>
            </Tooltip>
            <Tooltip label={'Add file'}>
              <Button onClick={() => setDeliverableDialogIsOpen(true)}>Add File</Button>
            </Tooltip>
          </div>
        )}
      </Card>
      <NewFileDeliverableDialog
        open={deliverableDialogIsOpen}
        onOpenChange={setDeliverableDialogIsOpen}
        addDeliverable={actions.addDeliverable}
        deliverableNames={exercise.deliverables.map((d) => d.name)}
      />
    </>
  )
}

export default EditableDeliverablesSection
