import { Github, Globe, Icon, Linkedin } from 'react-bootstrap-icons'

import { useResponsiveness } from '../../hooks/breakpoints.service'
import { Contributor } from '../credits/ContributionCard'
import ContributorImage from '../credits/ContributorImage'
import Dialog from './Dialog'

const ICON_SIZE = 22

const iconsLookup: Record<string, Icon> = {
  github: Github,
  linkedin: Linkedin,
  personal: Globe,
}

const ContributionDialog = ({
  contributor,
  open,
  onOpenChange,
}: {
  contributor: Contributor | null
  open: boolean
  onOpenChange: (_: boolean) => void
}) => {
  const { isDesktop } = useResponsiveness()
  const imageSize = isDesktop ? 200 : 120

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.75em',
          textAlign: 'center',
          width: isDesktop ? 'auto' : '75vw',
        }}
      >
        <ContributorImage image={contributor?.image} size={imageSize} />
        <h3>{contributor?.name}</h3>
        <p>
          <b>
            {contributor?.role}, {contributor?.year}
          </b>
        </p>
        <p style={{ paddingBlock: '0.2em', textAlign: 'left' }}>{contributor?.contribution}</p>
        {contributor?.links && (
          <div style={{ display: 'flex', gap: '1em' }}>
            {Object.entries(contributor.links).map(([name, link]) => {
              const SocialIcon = iconsLookup[name]
              return (
                <a href={link}>
                  <SocialIcon size={ICON_SIZE} />
                </a>
              )
            })}
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default ContributionDialog
